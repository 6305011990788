import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
import { Label, Color } from 'ng2-charts';
import { InfoModalComponent } from '../info-modal/info-modal.component';
import { ChartColorService } from '../shared/chart-color.service';
import { progPhotovoltaikFv } from './prog-photovolaik-fv.data';
import { progPhotovoltaikVv } from './prog-photovolaik-vv.data';

@Component({
  selector: 'nim-prog-photovoltaik-linechart',
  templateUrl: './prog-photovoltaik-linechart.component.html',
  styleUrls: ['./prog-photovoltaik-linechart.component.css'],
})
export class ProgPhotovoltaikLinechartComponent implements OnInit {
  public lineChartLabelsFV: Label[] = [];

  public lineChartLabelsVV: Label[] = [];

  public chartHeight = this.getChartHeight();

  public lineChartOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    legend: { display: false },
    scales: {
      xAxes: [
        {
          stacked: true,
          scaleLabel: {
            display: true,
            labelString: 'Jahre',
            fontSize: window.innerWidth < 700 ? 9 : 12,
          },
          ticks: { fontSize: window.innerWidth < 700 ? 9 : 12 },
        },
      ],
      yAxes: [
        {
          stacked: true,
          display: true,
          scaleLabel: {
            display: true,
            labelString:
              window.innerWidth < 700
                ? 'Anz. Photovoltaikanlagen pro Jahr'
                : 'Anzahl Photovoltaikanlagen pro Jahr',
            fontSize: window.innerWidth < 700 ? 9 : 12,
          },
          ticks: { fontSize: window.innerWidth < 700 ? 9 : 12 },
        },
      ],
    },
    tooltips: {
      callbacks: {
        labelColor: (tooltipItem, chart) => {
          return {
            borderColor: this.lineChartColors[1].backgroundColor,
            backgroundColor: this.lineChartColors[1].backgroundColor,
          };
        },
      },
    },
  };

  public lineChartColors: Color[];

  public lineChartLegend = true;

  public lineChartType: ChartType = 'line';

  public lineChartPlugins = [];

  public lineChartDataFV: ChartDataSets[] = [];

  public lineChartDataVV: ChartDataSets[] = [];

  dataLoadedFV = false;

  dataLoadedVV = false;

  dataFV;

  dataVV;

  labels = ['Photovoltaikanlagen'];

  years = [
    2012,
    2013,
    2014,
    2015,
    2016,
    2017,
    2018,
    2019,
    2020,
    2021,
    2022,
    2023,
    2024,
    2025,
    2026,
    2027,
    2028,
    2029,
    2030,
    2031,
    2032,
    2033,
    2034,
    2035,
  ];

  constructor(
    private colorService: ChartColorService,
    public dialog: MatDialog,
  ) {}

  ngOnInit() {
    this.lineChartColors = this.colorService.getEnergyChartColors();
    this.getFvData();
    this.getVvData();
    
  }

  private getFvData() {
    const res = progPhotovoltaikFv;
    this.dataFV = res;
    this.prepareDataFV(this.dataFV, this.years);
    this.dataLoadedFV = true;
  }

  private getVvData() {
    const res = progPhotovoltaikVv;
    this.dataVV = res;
    this.prepareDataVV(this.dataVV, this.years);
    this.dataLoadedVV = true;
  }

  private prepareDataFV(data, years) {
    this.lineChartDataFV = [];
    const dataSet = {
      fill: true,
      data: [],
      backgroundColor: this.lineChartColors[1].backgroundColor,
      borderColor: this.lineChartColors[1].backgroundColor,
      pointBackgroundColor: 'white',
      pointBorderColor: this.lineChartColors[1].backgroundColor,
      pointHoverBorderColor: this.lineChartColors[1].backgroundColor,
    };

    years.forEach((l) => {
      dataSet.data.push(data.find((o) => o.Jahr === l).Anzahl);
    });
    this.lineChartDataFV.push(dataSet);
  }

  private prepareDataVV(data, years) {
    this.lineChartDataVV = [];
    const dataSet = {
      fill: true,
      data: [],
      backgroundColor: this.lineChartColors[1].backgroundColor,
      borderColor: this.lineChartColors[1].backgroundColor,
      pointBackgroundColor: 'white',
      pointBorderColor: this.lineChartColors[1].backgroundColor,
      pointHoverBorderColor: this.lineChartColors[1].backgroundColor,
    };

    years.forEach((l) => {
      dataSet.data.push(data.find((o) => o.Jahr === l).Anzahl);
    });
    this.lineChartDataVV.push(dataSet);
  }

  onInfoButtonTap(content: string) {
    this.dialog.open(InfoModalComponent, {
      data: {
        content: content,
      },
    });
  }

  public getChartHeight() {
    return window.innerWidth < 700 ? '300px' : '500px';
  }
}
