export const ernEnergienVvNeu = [
  {
    id: 1,
    Jahr: 2019,
    Kategorie: 'Verwaltungsgebäude',
    Anz_Erneuerbar: 14,
    Anz_Nicht_Erneuerbar: 5,
  },
  {
    id: 2,
    Jahr: 2019,
    Kategorie: 'Bildung',
    Anz_Erneuerbar: 96,
    Anz_Nicht_Erneuerbar: 80,
  },
  {
    id: 3,
    Jahr: 2019,
    Kategorie: 'Sport und Freizeit',
    Anz_Erneuerbar: 12,
    Anz_Nicht_Erneuerbar: 18,
  },
  {
    id: 4,
    Jahr: 2019,
    Kategorie: 'Wohnen und Aufenthalt',
    Anz_Erneuerbar: 2,
    Anz_Nicht_Erneuerbar: 6,
  },
  {
    id: 5,
    Jahr: 2019,
    Kategorie: 'Kultur und Geselligkeit',
    Anz_Erneuerbar: 6,
    Anz_Nicht_Erneuerbar: 11,
  },
  {
    id: 6,
    Jahr: 2019,
    Kategorie: 'Infrastrukturbauten',
    Anz_Erneuerbar: 6,
    Anz_Nicht_Erneuerbar: 12,
  },
  {
    id: 7,
    Jahr: 2020,
    Kategorie: 'Verwaltungsgebäude',
    Anz_Erneuerbar: 15,
    Anz_Nicht_Erneuerbar: 5,
  },
  {
    id: 8,
    Jahr: 2020,
    Kategorie: 'Bildung',
    Anz_Erneuerbar: 110,
    Anz_Nicht_Erneuerbar: 80,
  },
  {
    id: 9,
    Jahr: 2020,
    Kategorie: 'Sport und Freizeit',
    Anz_Erneuerbar: 12,
    Anz_Nicht_Erneuerbar: 18,
  },
  {
    id: 10,
    Jahr: 2020,
    Kategorie: 'Wohnen und Aufenthalt',
    Anz_Erneuerbar: 2,
    Anz_Nicht_Erneuerbar: 6,
  },
  {
    id: 11,
    Jahr: 2020,
    Kategorie: 'Kultur und Geselligkeit',
    Anz_Erneuerbar: 6,
    Anz_Nicht_Erneuerbar: 11,
  },
  {
    id: 12,
    Jahr: 2020,
    Kategorie: 'Infrastrukturbauten',
    Anz_Erneuerbar: 8,
    Anz_Nicht_Erneuerbar: 19,
  },
  {
    id: 13,
    Jahr: 2021,
    Kategorie: 'Verwaltungsgebäude',
    Anz_Erneuerbar: 15,
    Anz_Nicht_Erneuerbar: 7,
  },
  {
    id: 14,
    Jahr: 2021,
    Kategorie: 'Bildung',
    Anz_Erneuerbar: 115,
    Anz_Nicht_Erneuerbar: 77,
  },
  {
    id: 15,
    Jahr: 2021,
    Kategorie: 'Sport und Freizeit',
    Anz_Erneuerbar: 13,
    Anz_Nicht_Erneuerbar: 17,
  },
  {
    id: 16,
    Jahr: 2021,
    Kategorie: 'Wohnen und Aufenthalt',
    Anz_Erneuerbar: 3,
    Anz_Nicht_Erneuerbar: 8,
  },
  {
    id: 17,
    Jahr: 2021,
    Kategorie: 'Kultur und Geselligkeit',
    Anz_Erneuerbar: 6,
    Anz_Nicht_Erneuerbar: 10,
  },
  {
    id: 18,
    Jahr: 2021,
    Kategorie: 'Infrastrukturbauten',
    Anz_Erneuerbar: 9,
    Anz_Nicht_Erneuerbar: 23,
  },
];
