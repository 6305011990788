export const denkmalpflegeFv = [
  {
    id: 1,
    Jahr: 2012,
    Kategorie: 'Wohnliegenschaften',
    Beachtenswert: 18,
    Erhaltenswert: 252,
    Schuetzenswert: 86,
    Nicht_Inventarisiert: 37,
    WorldHeritage: 23,
    Gebaeude_Nach_1990: 0,
  },
  {
    id: 2,
    Jahr: 2012,
    Kategorie: 'Geschäftsliegenschaften',
    Beachtenswert: 4,
    Erhaltenswert: 4,
    Schuetzenswert: 15,
    Nicht_Inventarisiert: 12,
    WorldHeritage: 6,
    Gebaeude_Nach_1990: 0,
  },
  {
    id: 3,
    Jahr: 2012,
    Kategorie: 'Landwirtschaft',
    Beachtenswert: 0,
    Erhaltenswert: 1,
    Schuetzenswert: 0,
    Nicht_Inventarisiert: 2,
    WorldHeritage: 0,
    Gebaeude_Nach_1990: 0,
  },
  {
    id: 4,
    Jahr: 2013,
    Kategorie: 'Wohnliegenschaften',
    Beachtenswert: 18,
    Erhaltenswert: 251,
    Schuetzenswert: 85,
    Nicht_Inventarisiert: 46,
    WorldHeritage: 23,
    Gebaeude_Nach_1990: 0,
  },
  {
    id: 5,
    Jahr: 2013,
    Kategorie: 'Geschäftsliegenschaften',
    Beachtenswert: 4,
    Erhaltenswert: 4,
    Schuetzenswert: 15,
    Nicht_Inventarisiert: 12,
    WorldHeritage: 6,
    Gebaeude_Nach_1990: 0,
  },
  {
    id: 6,
    Jahr: 2013,
    Kategorie: 'Landwirtschaft',
    Beachtenswert: 0,
    Erhaltenswert: 1,
    Schuetzenswert: 0,
    Nicht_Inventarisiert: 2,
    WorldHeritage: 0,
    Gebaeude_Nach_1990: 0,
  },
  {
    id: 7,
    Jahr: 2014,
    Kategorie: 'Wohnliegenschaften',
    Beachtenswert: 20,
    Erhaltenswert: 250,
    Schuetzenswert: 85,
    Nicht_Inventarisiert: 51,
    WorldHeritage: 25,
    Gebaeude_Nach_1990: 0,
  },
  {
    id: 8,
    Jahr: 2014,
    Kategorie: 'Geschäftsliegenschaften',
    Beachtenswert: 3,
    Erhaltenswert: 5,
    Schuetzenswert: 15,
    Nicht_Inventarisiert: 12,
    WorldHeritage: 4,
    Gebaeude_Nach_1990: 0,
  },
  {
    id: 9,
    Jahr: 2014,
    Kategorie: 'Landwirtschaft',
    Beachtenswert: 0,
    Erhaltenswert: 1,
    Schuetzenswert: 0,
    Nicht_Inventarisiert: 2,
    WorldHeritage: 0,
    Gebaeude_Nach_1990: 0,
  },
  {
    id: 10,
    Jahr: 2015,
    Kategorie: 'Wohnliegenschaften',
    Beachtenswert: 26,
    Erhaltenswert: 249,
    Schuetzenswert: 86,
    Nicht_Inventarisiert: 51,
    WorldHeritage: 21,
    Gebaeude_Nach_1990: 0,
  },
  {
    id: 11,
    Jahr: 2015,
    Kategorie: 'Geschäftsliegenschaften',
    Beachtenswert: 3,
    Erhaltenswert: 7,
    Schuetzenswert: 16,
    Nicht_Inventarisiert: 12,
    WorldHeritage: 10,
    Gebaeude_Nach_1990: 0,
  },
  {
    id: 12,
    Jahr: 2015,
    Kategorie: 'Landwirtschaft',
    Beachtenswert: 0,
    Erhaltenswert: 1,
    Schuetzenswert: 0,
    Nicht_Inventarisiert: 2,
    WorldHeritage: 0,
    Gebaeude_Nach_1990: 0,
  },
  {
    id: 13,
    Jahr: 2016,
    Kategorie: 'Wohnliegenschaften',
    Beachtenswert: 27,
    Erhaltenswert: 250,
    Schuetzenswert: 87,
    Nicht_Inventarisiert: 53,
    WorldHeritage: 21,
    Gebaeude_Nach_1990: 0,
  },
  {
    id: 14,
    Jahr: 2016,
    Kategorie: 'Geschäftsliegenschaften',
    Beachtenswert: 3,
    Erhaltenswert: 7,
    Schuetzenswert: 16,
    Nicht_Inventarisiert: 13,
    WorldHeritage: 11,
    Gebaeude_Nach_1990: 0,
  },
  {
    id: 15,
    Jahr: 2016,
    Kategorie: 'Landwirtschaft',
    Beachtenswert: 0,
    Erhaltenswert: 1,
    Schuetzenswert: 0,
    Nicht_Inventarisiert: 2,
    WorldHeritage: 0,
    Gebaeude_Nach_1990: 0,
  },
  {
    id: 16,
    Jahr: 2017,
    Kategorie: 'Wohnliegenschaften',
    Beachtenswert: 27,
    Erhaltenswert: 250,
    Schuetzenswert: 89,
    Nicht_Inventarisiert: 53,
    WorldHeritage: 21,
    Gebaeude_Nach_1990: 0,
  },
  {
    id: 17,
    Jahr: 2017,
    Kategorie: 'Geschäftsliegenschaften',
    Beachtenswert: 4,
    Erhaltenswert: 8,
    Schuetzenswert: 17,
    Nicht_Inventarisiert: 13,
    WorldHeritage: 12,
    Gebaeude_Nach_1990: 0,
  },
  {
    id: 18,
    Jahr: 2017,
    Kategorie: 'Landwirtschaft',
    Beachtenswert: 0,
    Erhaltenswert: 1,
    Schuetzenswert: 0,
    Nicht_Inventarisiert: 2,
    WorldHeritage: 0,
    Gebaeude_Nach_1990: 0,
  },
  {
    id: 19,
    Jahr: 2018,
    Kategorie: 'Wohnliegenschaften',
    Beachtenswert: 198,
    Erhaltenswert: 72,
    Schuetzenswert: 94,
    Nicht_Inventarisiert: 60,
    WorldHeritage: 21,
    Gebaeude_Nach_1990: 0,
  },
  {
    id: 20,
    Jahr: 2018,
    Kategorie: 'Geschäftsliegenschaften',
    Beachtenswert: 2,
    Erhaltenswert: 9,
    Schuetzenswert: 17,
    Nicht_Inventarisiert: 8,
    WorldHeritage: 11,
    Gebaeude_Nach_1990: 1,
  },
  {
    id: 21,
    Jahr: 2018,
    Kategorie: 'Landwirtschaft',
    Beachtenswert: 0,
    Erhaltenswert: 1,
    Schuetzenswert: 0,
    Nicht_Inventarisiert: 2,
    WorldHeritage: 0,
    Gebaeude_Nach_1990: 0,
  },
  {
    id: 22,
    Jahr: 2019,
    Kategorie: 'Wohnliegenschaften',
    Beachtenswert: 209,
    Erhaltenswert: 76,
    Schuetzenswert: 93,
    Nicht_Inventarisiert: 79,
    WorldHeritage: 22,
    Gebaeude_Nach_1990: 0,
  },
  {
    id: 23,
    Jahr: 2019,
    Kategorie: 'Geschäftsliegenschaften',
    Beachtenswert: 3,
    Erhaltenswert: 12,
    Schuetzenswert: 17,
    Nicht_Inventarisiert: 10,
    WorldHeritage: 11,
    Gebaeude_Nach_1990: 1,
  },
  {
    id: 24,
    Jahr: 2019,
    Kategorie: 'Landwirtschaft',
    Beachtenswert: 0,
    Erhaltenswert: 1,
    Schuetzenswert: 0,
    Nicht_Inventarisiert: 2,
    WorldHeritage: 0,
    Gebaeude_Nach_1990: 0,
  },
  {
    id: 25,
    Jahr: 2020,
    Kategorie: 'Wohnliegenschaften',
    Beachtenswert: 216,
    Erhaltenswert: 77,
    Schuetzenswert: 91,
    Nicht_Inventarisiert: 81,
    WorldHeritage: 22,
    Gebaeude_Nach_1990: 0,
  },
  {
    id: 26,
    Jahr: 2020,
    Kategorie: 'Geschäftsliegenschaften',
    Beachtenswert: 4,
    Erhaltenswert: 12,
    Schuetzenswert: 17,
    Nicht_Inventarisiert: 10,
    WorldHeritage: 10,
    Gebaeude_Nach_1990: 1,
  },
  {
    id: 27,
    Jahr: 2020,
    Kategorie: 'Landwirtschaft',
    Beachtenswert: 0,
    Erhaltenswert: 1,
    Schuetzenswert: 0,
    Nicht_Inventarisiert: 2,
    WorldHeritage: 0,
    Gebaeude_Nach_1990: 0,
  },
  {
    id: 28,
    Jahr: 2021,
    Kategorie: 'Wohnliegenschaften',
    Beachtenswert: 216,
    Erhaltenswert: 77,
    Schuetzenswert: 92,
    Nicht_Inventarisiert: 84,
    WorldHeritage: 22,
    Gebaeude_Nach_1990: 0,
  },
  {
    id: 29,
    Jahr: 2021,
    Kategorie: 'Geschäftsliegenschaften',
    Beachtenswert: 4,
    Erhaltenswert: 12,
    Schuetzenswert: 17,
    Nicht_Inventarisiert: 10,
    WorldHeritage: 11,
    Gebaeude_Nach_1990: 1,
  },
  {
    id: 30,
    Jahr: 2021,
    Kategorie: 'Landwirtschaft',
    Beachtenswert: 0,
    Erhaltenswert: 1,
    Schuetzenswert: 0,
    Nicht_Inventarisiert: 2,
    WorldHeritage: 0,
    Gebaeude_Nach_1990: 0,
  },
];
