import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'nim-smallScreenFilterLegend',
  templateUrl: './smallScreenFilterLegend.component.html',
  styleUrls: ['./smallScreenFilterLegend.component.css'],
})
export class SmallScreenFilterLegendComponent implements OnInit {
  @Input() labels;

  @Input() years;

  @Input() categories;

  @Input() labelsFiltered;

  @Input() yearsFiltered;

  @Input() categoriesFiltered;

  @Input() useNewSystem;

  @Input() showThirdFilter;

  @Input() showNewSystemFilter;

  @Input() hideYearFilter;

  public changes = [];

  constructor(
    private dialogRef: MatDialogRef<SmallScreenFilterLegendComponent>,
    @Inject(MAT_DIALOG_DATA) data,
  ) {
    this.labels = data.labels;
    this.years = data.years;
    this.categories = data.categories;
    this.labelsFiltered = data.labelsFiltered;
    this.yearsFiltered = data.yearsFiltered;
    this.categoriesFiltered = data.categoriesFiltered;
    this.showThirdFilter = data.showThirdFilter;
    this.showNewSystemFilter = data.showNewSystemFilter;
    this.useNewSystem = data.useNewSystem;
    this.hideYearFilter = data.hideYearFilter;
  }

  ngOnInit() {}

  abort() {
    this.dialogRef.close();
  }

  public changeFilter(changes) {
    this.changes = changes;
  }
}
