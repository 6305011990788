<div class="home">
  <span class="info-text">
    <p class="text-block">
      Die Ziele des
      <a
        href="https://www.bern.ch/politik-und-verwaltung/stadtverwaltung/fpi/immobilien-stadt-bern/nachhaltiges-immobilienmanagement"
        target="_blank"
        style="display: inline"
        >Nachhaltigen Immobilienmanagements von Immobilien Stadt Bern (ISB)</a
      >
      stehen im Einklang mit den bestehenden Vorgaben des Bundes, des Kantons
      und der Stadt Bern. Die jeweiligen Vorgaben aus Leitbild
      (Finanz-/Fondsvermögen) und Strategie (Verwaltungsvermögen) fliessen bei
      den jeweiligen Bestellungen an die Baufachorgane ein. Über eine eigens für
      die Nachhaltigkeitsbeurteilung programmierte Datenbank-Software können
      alle Gebäude in den drei Nachhaltigkeitsdimensionen Gesellschaft,
      Wirtschaft und Umwelt beurteilt werden. Dank der wachsenden Datengrundlage
      kann sich ISB mit dem Instrument des Nachhaltigen Immobilienmanagements
      inzwischen einen wertvollen Überblick über das Gesamtportfolio
      verschaffen.
    </p>

    <p class="text-block">
      In Bezug auf die Auswertungen werden folgende Abgrenzungen gemacht:
    </p>
    <ul>
      <li>nur beheizte Liegenschaften</li>
      <li>
        nur Liegenschaften, welche ihren Standort in der Stadt Bern und
        Agglomeration haben
      </li>
      <li>keine kurzfristig im Einsatz befindlichen Modulbauten</li>
      <li>keine Zumieten</li>
      <li>keine Zivilschutzanlagen</li>
    </ul>

    <p class="text-block bold">
      Mit der Publikation des Faktenblatts Nachhaltigkeit 2021 erscheint die
      Berichterstattung erstmals in einer verkürzten Fassung mit zentralen
      Beobachtungen zur Nachhaltigen Entwicklung der beiden städtischen
      Immobilienportfolios.
    </p>
    <p class="text-block bold">
      Sämtliche aktuellen, wie auch Daten der früheren Erhebungen sind in
      interaktiver Form auf dieser Webseite visualisiert und können je nach
      Interesse und Thematik betrachtet werden.
    </p>
    <p class="text-block bold">
      Nach 10 Jahren Berichterstattung, unterteilt nach Finanz-/Fondsvermögen
      und Verwaltungsvermögen, kann ISB mit Stolz behaupten, im Sinne einer
      Nachhaltigen Entwicklung viele Fortschritte erzielt zu haben. Trotzdem:
      Nach wie vor gibt es Bereiche mit erheblichen Optimierungsbedarf, um nicht
      nur partiell, sondern in allen Teilportfolios die hochgesteckten Ziele zu
      erreichen.
    </p>
  </span>
</div>
<div class="outer">
  <h2><strong>Nachhaltigkeitsberichterstattung:</strong></h2>
  <div class="flex">
    <button id="left" class="sick-slider-button" (click)="scroll('right')">
      <mat-icon>arrow_back_ios</mat-icon>
    </button>
    <div class="inner" #slide>
      <div>
        <a
          href="assets/pdf/00Nachhaltigkeitsberichte/Faktenblatt_Nachhaltigkeitsbericht_2021.pdf"
          target="_blank"
        >
          <img
            class="preview-image"
            src="assets/pdf/00Nachhaltigkeitsberichte/preview-images/2021.png"
            alt="2021"
          />
        </a>
        <div class="preview-year">2021</div>
      </div>
      <div>
        <a
          href="assets/pdf/00Nachhaltigkeitsberichte/2020%20ISB%20Nachhaltigkeitsbericht.pdf"
          target="_blank"
        >
          <img
            class="preview-image"
            src="assets/pdf/00Nachhaltigkeitsberichte/preview-images/2020.png"
            alt="2020"
          />
        </a>
        <div class="preview-year">2020</div>
      </div>
      <div>
        <a
          href="assets/pdf/00Nachhaltigkeitsberichte/2019%20ISB%20Nachhaltigkeitsbericht.pdf"
          target="_blank"
        >
          <img
            class="preview-image"
            src="assets/pdf/00Nachhaltigkeitsberichte/preview-images/2019.png"
            alt="2019"
          />
        </a>
        <div class="preview-year">2019</div>
      </div>
      <div>
        <a
          href="assets/pdf/00Nachhaltigkeitsberichte/2018%20ISB%20Nachhaltigkeitsbericht.pdf"
          target="_blank"
        >
          <img
            class="preview-image"
            src="assets/pdf/00Nachhaltigkeitsberichte/preview-images/2018.png"
            alt="2018"
          />
        </a>
        <div class="preview-year">2018</div>
      </div>
      <div>
        <a
          href="assets/pdf/00Nachhaltigkeitsberichte/2017%20ISB%20Nachhaltigkeitsbericht.pdf"
          target="_blank"
        >
          <img
            class="preview-image"
            src="assets/pdf/00Nachhaltigkeitsberichte/preview-images/2017.png"
            alt="2017"
          />
        </a>
        <div class="preview-year">2017</div>
      </div>
      <div>
        <a
          href="assets/pdf/00Nachhaltigkeitsberichte/2016%20ISB%20Nachhaltigkeitsbericht.pdf"
          target="_blank"
        >
          <img
            class="preview-image"
            src="assets/pdf/00Nachhaltigkeitsberichte/preview-images/2016.png"
            alt="2016"
          />
        </a>
        <div class="preview-year">2016</div>
      </div>
      <div>
        <a
          href="assets/pdf/00Nachhaltigkeitsberichte/2015%20ISB%20Nachhaltigkeitsbericht.pdf"
          target="_blank"
        >
          <img
            class="preview-image"
            src="assets/pdf/00Nachhaltigkeitsberichte/preview-images/2015.png"
            alt="2015"
          />
        </a>
        <div class="preview-year">2015</div>
      </div>
      <div>
        <a
          href="assets/pdf/00Nachhaltigkeitsberichte/2014%20Nachhaltigkeitsbericht%20Fonds.pdf"
          target="_blank"
        >
          <img
            class="preview-image"
            src="assets/pdf/00Nachhaltigkeitsberichte/preview-images/2014.png"
            alt="2014"
          />
        </a>
        <div class="preview-year">2014</div>
      </div>
      <div>
        <a
          href="assets/pdf/00Nachhaltigkeitsberichte/2013%20Nachhaltigkeitsbericht%20Fonds.pdf"
          target="_blank"
        >
          <img
            class="preview-image"
            src="assets/pdf/00Nachhaltigkeitsberichte/preview-images/2013.png"
            alt="2013"
          />
        </a>
        <div class="preview-year">2013</div>
      </div>
      <div>
        <a
          href="assets/pdf/00Nachhaltigkeitsberichte/2012%20Nachhaltigkeitsbericht%20Fonds.pdf"
          target="_blank"
        >
          <img
            class="preview-image"
            src="assets/pdf/00Nachhaltigkeitsberichte/preview-images/2012.png"
            alt="2012"
          />
        </a>
        <div class="preview-year">2012</div>
      </div>
    </div>
    <button id="right" class="sick-slider-button" (click)="scroll('left')">
      <mat-icon>arrow_forward_ios</mat-icon>
    </button>
  </div>
</div>
