export const handlungsBedarfVv = [
  {
    id: 1,
    Jahr: 2017,
    Kategorie: 'Ohne',
    Anz_Kein: 47,
    Anz_Absehbar: 127,
    Anz_Dringend: 99,
  },
  {
    id: 2,
    Jahr: 2018,
    Kategorie: 'Ohne',
    Anz_Kein: 50,
    Anz_Absehbar: 137,
    Anz_Dringend: 109,
  },
  {
    id: 3,
    Jahr: 2019,
    Kategorie: 'Ohne',
    Anz_Kein: 55,
    Anz_Absehbar: 141,
    Anz_Dringend: 93,
  },
  {
    id: 4,
    Jahr: 2020,
    Kategorie: 'Ohne',
    Anz_Kein: 63,
    Anz_Absehbar: 161,
    Anz_Dringend: 88,
  },
  {
    id: 5,
    Jahr: 2021,
    Kategorie: 'Ohne',
    Anz_Kein: 65,
    Anz_Absehbar: 162,
    Anz_Dringend: 81,
  },
];
