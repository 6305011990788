import { Component } from '@angular/core';

@Component({
  selector: 'nim-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderComponent {
  toggle = true;
}
