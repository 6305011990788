export const ausgewerteteVvNeu = [
  {
    id: 1,
    Jahr: 2019,
    Kategorie: 'Verwaltungsgebäude',
    Anzahl_Gebaeude: 16,
    Anteil_Gebaeude: 5.54,
  },
  {
    id: 2,
    Jahr: 2019,
    Kategorie: 'Bildung',
    Anzahl_Gebaeude: 195,
    Anteil_Gebaeude: 67.47,
  },
  {
    id: 3,
    Jahr: 2019,
    Kategorie: 'Sport und Freizeit',
    Anzahl_Gebaeude: 26,
    Anteil_Gebaeude: 9,
  },
  {
    id: 4,
    Jahr: 2019,
    Kategorie: 'Wohnen und Aufenthalt',
    Anzahl_Gebaeude: 10,
    Anteil_Gebaeude: 3.46,
  },
  {
    id: 5,
    Jahr: 2019,
    Kategorie: 'Kultur und Geselligkeit',
    Anzahl_Gebaeude: 22,
    Anteil_Gebaeude: 7.62,
  },
  {
    id: 6,
    Jahr: 2019,
    Kategorie: 'Infrastrukturbauten',
    Anzahl_Gebaeude: 20,
    Anteil_Gebaeude: 6.92,
  },
  {
    id: 7,
    Jahr: 2019,
    Kategorie: 'Total',
    Anzahl_Gebaeude: 289,
    Anteil_Gebaeude: 100,
  },
  {
    id: 8,
    Jahr: 2020,
    Kategorie: 'Verwaltungsgebäude',
    Anzahl_Gebaeude: 16,
    Anteil_Gebaeude: 5.13,
  },
  {
    id: 9,
    Jahr: 2020,
    Kategorie: 'Bildung',
    Anzahl_Gebaeude: 203,
    Anteil_Gebaeude: 65.06,
  },
  {
    id: 10,
    Jahr: 2020,
    Kategorie: 'Sport und Freizeit',
    Anzahl_Gebaeude: 26,
    Anteil_Gebaeude: 8.33,
  },
  {
    id: 11,
    Jahr: 2020,
    Kategorie: 'Wohnen und Aufenthalt',
    Anzahl_Gebaeude: 10,
    Anteil_Gebaeude: 3.21,
  },
  {
    id: 12,
    Jahr: 2020,
    Kategorie: 'Kultur und Geselligkeit',
    Anzahl_Gebaeude: 22,
    Anteil_Gebaeude: 7.05,
  },
  {
    id: 13,
    Jahr: 2020,
    Kategorie: 'Infrastrukturbauten',
    Anzahl_Gebaeude: 35,
    Anteil_Gebaeude: 11.22,
  },
  {
    id: 14,
    Jahr: 2020,
    Kategorie: 'Total',
    Anzahl_Gebaeude: 312,
    Anteil_Gebaeude: 100,
  },
  {
    id: 15,
    Jahr: 2021,
    Kategorie: 'Verwaltungsgebäude',
    Anzahl_Gebaeude: 17,
    Anteil_Gebaeude: '',
  },
  {
    id: 16,
    Jahr: 2021,
    Kategorie: 'Bildung',
    Anzahl_Gebaeude: 193,
    Anteil_Gebaeude: '',
  },
  {
    id: 17,
    Jahr: 2021,
    Kategorie: 'Sport und Freizeit',
    Anzahl_Gebaeude: 25,
    Anteil_Gebaeude: '',
  },
  {
    id: 18,
    Jahr: 2021,
    Kategorie: 'Wohnen und Aufenthalt',
    Anzahl_Gebaeude: 13,
    Anteil_Gebaeude: '',
  },
  {
    id: 19,
    Jahr: 2021,
    Kategorie: 'Kultur und Geselligkeit',
    Anzahl_Gebaeude: 22,
    Anteil_Gebaeude: '',
  },
  {
    id: 20,
    Jahr: 2021,
    Kategorie: 'Infrastrukturbauten',
    Anzahl_Gebaeude: 38,
    Anteil_Gebaeude: '',
  },
  {
    id: 21,
    Jahr: 2021,
    Kategorie: 'Total',
    Anzahl_Gebaeude: 308,
    Anteil_Gebaeude: 100,
  },
];
