<div class="filter">
  <mat-form-field class="select-form">
    <mat-select
      placeholder="Gebäudearten"
      name="buliding"
      class="filter-select"
      [(ngModel)]="selectedBuildings"
      multiple
      #buildingSelect="ngModel"
      (selectionChange)="selectionChangeBuildings($event)"
    >
      <mat-optgroup disabled="disabled" class="filter-option">
        <button
          mat-raised-button
          class="mat-accent fill text-sm"
          (click)="deselectAll(buildingSelect, 'Buildings')"
        >
          Zurücksetzen
        </button>
      </mat-optgroup>
      <mat-option *ngFor="let category of categories" [value]="category">{{
        category
      }}</mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field class="select-form" *ngIf="!hideYearFilter">
    <mat-select
      placeholder="Jahre"
      name="year"
      class="filter-select"
      [(ngModel)]="selectedYears"
      multiple
      #yearsSelect="ngModel"
      (selectionChange)="selectionChangeYears($event)"
    >
      <mat-optgroup disabled="disabled" class="filter-option">
        <button
          mat-raised-button
          class="mat-accent fill text-sm"
          (click)="deselectAll(yearsSelect, 'Years')"
        >
          Zurücksetzen
        </button>
      </mat-optgroup>
      <mat-option *ngFor="let year of availableYears" [value]="year">{{
        year
      }}</mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field *ngIf="showThirdFilter" class="select-form">
    <mat-select
      placeholder="Kategorien"
      name="category"
      class="filter-select"
      [(ngModel)]="selectedCategories"
      multiple
      #categoriesSelect="ngModel"
      (selectionChange)="selectionChangeCategories($event)"
    >
      <mat-optgroup disabled="disabled" class="filter-option">
        <button
          mat-raised-button
          class="mat-accent fill text-sm"
          (click)="deselectAll(categoriesSelect, 'Categories')"
        >
          Zurücksetzen
        </button>
      </mat-optgroup>
      <mat-option
        class="category-labels"
        *ngFor="let label of labels"
        [value]="label"
        >{{ label }}</mat-option
      >
    </mat-select>
  </mat-form-field>
  <mat-form-field *ngIf="showNewSystemFilter" class="select-form">
    <mat-select
      placeholder="System"
      name="system"
      class="filter-select"
      [(ngModel)]="selectedSystem"
      #csystemSelect="ngModel"
      (selectionChange)="selectionChangeSystem($event)"
    >
      <mat-option *ngFor="let system of systems" [value]="system">{{
        system
      }}</mat-option>
    </mat-select>
  </mat-form-field>
  <button
    mat-raised-button
    color="warn"
    style="margin-left: 1vw"
    (click)="resetFilter()"
  >
    zurücksetzen
  </button>
</div>
<div class="filterList">
  Ausgewählte Filter:
  <span *ngFor="let year of selectedYears"> {{ year }} </span>
  <span *ngFor="let building of selectedBuildings"> {{ building }} </span>
  <span *ngFor="let category of selectedCategories"> {{ category }} </span>
</div>
