import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ChartColorService {
  defaultFondsColors = [
    {
      backgroundColor: Colors.BERN_TURQOUISE,
      borderColor: Colors.BERN_TURQOUISE,
      pointBackgroundColor: Colors.BERN_TURQOUISE,
      pointBorderColor: Colors.BERN_TURQOUISE,
      pointHoverBackgroundColor: Colors.BERN_TURQOUISE,
      pointHoverBorderColor: Colors.BERN_TURQOUISE,
    },
    {
      backgroundColor: Colors.BERN_BROWN_LIGHT,
      borderColor: Colors.BERN_BROWN_LIGHT,
      pointBackgroundColor: Colors.BERN_BROWN_LIGHT,
      pointBorderColor: Colors.BERN_BROWN_LIGHT,
      pointHoverBackgroundColor: Colors.BERN_BROWN_LIGHT,
      pointHoverBorderColor: Colors.BERN_BROWN_LIGHT,
    },
    {
      backgroundColor: Colors.BERN_GREEN,
      borderColor: Colors.BERN_GREEN,
      pointBackgroundColor: Colors.BERN_GREEN,
      pointBorderColor: Colors.BERN_GREEN,
      pointHoverBackgroundColor: Colors.BERN_GREEN,
      pointHoverBorderColor: Colors.BERN_GREEN,
    },
  ];

  defaultVVNeuColors = [
    {
      backgroundColor: Colors.BERN_RED_DARK,
      borderColor: Colors.BERN_RED_DARK,
      pointBackgroundColor: Colors.BERN_RED_DARK,
      pointBorderColor: Colors.BERN_RED_DARK,
      pointHoverBackgroundColor: Colors.BERN_RED_DARK,
      pointHoverBorderColor: Colors.BERN_RED_DARK,
    },
    {
      backgroundColor: Colors.BERN_YELLOW,
      borderColor: Colors.BERN_YELLOW,
      pointBackgroundColor: Colors.BERN_YELLOW,
      pointBorderColor: Colors.BERN_YELLOW,
      pointHoverBackgroundColor: Colors.BERN_YELLOW,
      pointHoverBorderColor: Colors.BERN_YELLOW,
    },
    {
      backgroundColor: Colors.BERN_GREEN_LIGHT,
      borderColor: Colors.BERN_GREEN_LIGHT,
      pointBackgroundColor: Colors.BERN_GREEN_LIGHT,
      pointBorderColor: Colors.BERN_GREEN_LIGHT,
      pointHoverBackgroundColor: Colors.BERN_GREEN_LIGHT,
      pointHoverBorderColor: Colors.BERN_GREEN_LIGHT,
    },

    {
      backgroundColor: Colors.BERN_TURQOUISE,
      borderColor: Colors.BERN_TURQOUISE,
      pointBackgroundColor: Colors.BERN_TURQOUISE,
      pointBorderColor: Colors.BERN_TURQOUISE,
      pointHoverBackgroundColor: Colors.BERN_TURQOUISE,
      pointHoverBorderColor: Colors.BERN_TURQOUISE,
    },
    {
      backgroundColor: Colors.BERN_BLUE_DARK,
      borderColor: Colors.BERN_BLUE_DARK,
      pointBackgroundColor: Colors.BERN_BLUE_DARK,
      pointBorderColor: Colors.BERN_BLUE_DARK,
      pointHoverBackgroundColor: Colors.BERN_BLUE_DARK,
      pointHoverBorderColor: Colors.BERN_BLUE_DARK,
    },
    {
      backgroundColor: Colors.BERN_RED_LIGHT,
      borderColor: Colors.BERN_RED_LIGHT,
      pointBackgroundColor: Colors.BERN_RED_LIGHT,
      pointBorderColor: Colors.BERN_RED_LIGHT,
      pointHoverBackgroundColor: Colors.BERN_RED_LIGHT,
      pointHoverBorderColor: Colors.BERN_RED_LIGHT,
    },
  ];

  defaultVVAltColors = [
    {
      backgroundColor: Colors.BERN_RED_DARK,
      borderColor: Colors.BERN_RED_DARK,
      pointBackgroundColor: Colors.BERN_RED_DARK,
      pointBorderColor: Colors.BERN_RED_DARK,
      pointHoverBackgroundColor: Colors.BERN_RED_DARK,
      pointHoverBorderColor: Colors.BERN_RED_DARK,
    },
    {
      backgroundColor: Colors.BERN_YELLOW,
      borderColor: Colors.BERN_YELLOW,
      pointBackgroundColor: Colors.BERN_YELLOW,
      pointBorderColor: Colors.BERN_YELLOW,
      pointHoverBackgroundColor: Colors.BERN_YELLOW,
      pointHoverBorderColor: Colors.BERN_YELLOW,
    },
    {
      backgroundColor: Colors.BERN_GREEN_LIGHT,
      borderColor: Colors.BERN_GREEN_LIGHT,
      pointBackgroundColor: Colors.BERN_GREEN_LIGHT,
      pointBorderColor: Colors.BERN_GREEN_LIGHT,
      pointHoverBackgroundColor: Colors.BERN_GREEN_LIGHT,
      pointHoverBorderColor: Colors.BERN_GREEN_LIGHT,
    },
    {
      backgroundColor: Colors.BERN_BLUE_LIGHT,
      borderColor: Colors.BERN_BLUE_LIGHT,
      pointBackgroundColor: Colors.BERN_BLUE_LIGHT,
      pointBorderColor: Colors.BERN_BLUE_LIGHT,
      pointHoverBackgroundColor: Colors.BERN_BLUE_LIGHT,
      pointHoverBorderColor: Colors.BERN_BLUE_LIGHT,
    },
    {
      backgroundColor: Colors.BERN_BLUE_DARK,
      borderColor: Colors.BERN_BLUE_DARK,
      pointBackgroundColor: Colors.BERN_BLUE_DARK,
      pointBorderColor: Colors.BERN_BLUE_DARK,
      pointHoverBackgroundColor: Colors.BERN_BLUE_DARK,
      pointHoverBorderColor: Colors.BERN_BLUE_DARK,
    },
    {
      backgroundColor: Colors.BERN_RED_LIGHT,
      borderColor: Colors.BERN_RED_LIGHT,
      pointBackgroundColor: Colors.BERN_RED_LIGHT,
      pointBorderColor: Colors.BERN_RED_LIGHT,
      pointHoverBackgroundColor: Colors.BERN_RED_LIGHT,
      pointHoverBorderColor: Colors.BERN_RED_LIGHT,
    },
    {
      backgroundColor: Colors.BERN_GRAY,
      borderColor: Colors.BERN_GRAY,
      pointBackgroundColor: Colors.BERN_GRAY,
      pointBorderColor: Colors.BERN_GRAY,
      pointHoverBackgroundColor: Colors.BERN_GRAY,
      pointHoverBorderColor: Colors.BERN_GRAY,
    },
    {
      backgroundColor: Colors.BERN_TURQOUISE,
      borderColor: Colors.BERN_TURQOUISE,
      pointBackgroundColor: Colors.BERN_TURQOUISE,
      pointBorderColor: Colors.BERN_TURQOUISE,
      pointHoverBackgroundColor: Colors.BERN_TURQOUISE,
      pointHoverBorderColor: Colors.BERN_TURQOUISE,
    },

    {
      backgroundColor: Colors.BERN_BLUE,
      borderColor: Colors.BERN_BLUE,
      pointBackgroundColor: Colors.BERN_BLUE,
      pointBorderColor: Colors.BERN_BLUE,
      pointHoverBackgroundColor: Colors.BERN_BLUE,
      pointHoverBorderColor: Colors.BERN_BLUE,
    },
  ];

  radialChartColors = [
    {
      backgroundColor: changeOpacity(Colors.BERN_GRAY, 0.3),
      borderColor: Colors.BERN_GRAY,
      pointBackgroundColor: Colors.BERN_GRAY,
      pointBorderColor: Colors.BERN_GRAY,
      pointHoverBackgroundColor: Colors.BERN_GRAY,
      pointHoverBorderColor: Colors.BERN_GRAY,
    },
    {
      backgroundColor: changeOpacity(Colors.BERN_RED, 0.3),
      borderColor: Colors.BERN_RED,
      pointBackgroundColor: Colors.BERN_RED,
      pointBorderColor: Colors.BERN_RED,
      pointHoverBackgroundColor: Colors.BERN_RED,
      pointHoverBorderColor: Colors.BERN_RED,
    },

    {
      backgroundColor: Colors.BERN_RED,
      borderColor: Colors.BERN_RED,
      pointBackgroundColor: Colors.BERN_RED,
      pointBorderColor: Colors.BERN_RED,
      pointHoverBackgroundColor: Colors.BERN_RED,
      pointHoverBorderColor: Colors.BERN_RED,
    },
    {
      backgroundColor: Colors.BERN_RED_DARK,
      borderColor: Colors.BERN_RED_DARK,
      pointBackgroundColor: Colors.BERN_RED_DARK,
      pointBorderColor: Colors.BERN_RED_DARK,
      pointHoverBackgroundColor: Colors.BERN_RED_DARK,
      pointHoverBorderColor: Colors.BERN_RED_DARK,
    },
    {
      backgroundColor: Colors.BERN_GRAY_DARK,
      borderColor: Colors.BERN_GRAY_DARK,
      pointBackgroundColor: Colors.BERN_GRAY_DARK,
      pointBorderColor: Colors.BERN_GRAY_DARK,
      pointHoverBackgroundColor: Colors.BERN_GRAY_DARK,
      pointHoverBorderColor: Colors.BERN_GRAY_DARK,
    },
    {
      backgroundColor: Colors.BERN_BLUE_LIGHT,
      borderColor: Colors.BERN_BLUE_LIGHT,
      pointBackgroundColor: Colors.BERN_BLUE_LIGHT,
      pointBorderColor: Colors.BERN_BLUE_LIGHT,
      pointHoverBackgroundColor: Colors.BERN_BLUE_LIGHT,
      pointHoverBorderColor: Colors.BERN_BLUE_LIGHT,
    },
    {
      backgroundColor: Colors.BERN_BLUE,
      borderColor: Colors.BERN_BLUE,
      pointBackgroundColor: Colors.BERN_BLUE,
      pointBorderColor: Colors.BERN_BLUE,
      pointHoverBackgroundColor: Colors.BERN_BLUE,
      pointHoverBorderColor: Colors.BERN_BLUE,
    },
    {
      backgroundColor: Colors.BERN_BLUE_DARK,
      borderColor: Colors.BERN_BLUE_DARK,
      pointBackgroundColor: Colors.BERN_BLUE_DARK,
      pointBorderColor: Colors.BERN_BLUE_DARK,
      pointHoverBackgroundColor: Colors.BERN_BLUE_DARK,
      pointHoverBorderColor: Colors.BERN_BLUE_DARK,
    },
    {
      backgroundColor: Colors.BERN_BROWN,
      borderColor: Colors.BERN_BROWN,
      pointBackgroundColor: Colors.BERN_BROWN,
      pointBorderColor: Colors.BERN_BROWN,
      pointHoverBackgroundColor: Colors.BERN_BROWN,
      pointHoverBorderColor: Colors.BERN_BROWN,
    },
    {
      backgroundColor: Colors.BERN_BROWN_LIGHT,
      borderColor: Colors.BERN_BROWN_LIGHT,
      pointBackgroundColor: Colors.BERN_BROWN_LIGHT,
      pointBorderColor: Colors.BERN_BROWN_LIGHT,
      pointHoverBackgroundColor: Colors.BERN_BROWN_LIGHT,
      pointHoverBorderColor: Colors.BERN_BROWN_LIGHT,
    },
    {
      backgroundColor: Colors.BERN_RED_LIGHT,
      borderColor: Colors.BERN_RED_LIGHT,
      pointBackgroundColor: Colors.BERN_RED_LIGHT,
      pointBorderColor: Colors.BERN_RED_LIGHT,
      pointHoverBackgroundColor: Colors.BERN_RED_LIGHT,
      pointHoverBorderColor: Colors.BERN_RED_LIGHT,
    },
    {
      backgroundColor: Colors.ENERGY_DARK_GREEN,
      borderColor: Colors.ENERGY_DARK_GREEN,
      pointBackgroundColor: Colors.ENERGY_DARK_GREEN,
      pointBorderColor: Colors.ENERGY_DARK_GREEN,
      pointHoverBackgroundColor: Colors.ENERGY_DARK_GREEN,
      pointHoverBorderColor: Colors.ENERGY_DARK_GREEN,
    },
  ];

  energyChartColors = [
    {
      backgroundColor: Colors.ENERGY_DARK_BLUE,
      borderColor: Colors.ENERGY_DARK_BLUE,
      pointBackgroundColor: Colors.ENERGY_DARK_BLUE,
      pointBorderColor: Colors.ENERGY_DARK_BLUE,
      pointHoverBackgroundColor: Colors.ENERGY_DARK_BLUE,
      pointHoverBorderColor: Colors.ENERGY_DARK_BLUE,
    },

    {
      backgroundColor: Colors.ENERGY_BLUE,
      borderColor: Colors.ENERGY_BLUE,
      pointBackgroundColor: Colors.ENERGY_BLUE,
      pointBorderColor: Colors.ENERGY_BLUE,
      pointHoverBackgroundColor: Colors.ENERGY_BLUE,
      pointHoverBorderColor: Colors.ENERGY_BLUE,
    },
    {
      backgroundColor: Colors.ENERGY_LIGHT_GREEN,
      borderColor: Colors.ENERGY_LIGHT_GREEN,
      pointBackgroundColor: Colors.ENERGY_LIGHT_GREEN,
      pointBorderColor: Colors.ENERGY_LIGHT_GREEN,
      pointHoverBackgroundColor: Colors.ENERGY_LIGHT_GREEN,
      pointHoverBorderColor: Colors.ENERGY_LIGHT_GREEN,
    },
    {
      backgroundColor: Colors.ENERGY_DARK_GREEN,
      borderColor: Colors.ENERGY_DARK_GREEN,
      pointBackgroundColor: Colors.ENERGY_DARK_GREEN,
      pointBorderColor: Colors.ENERGY_DARK_GREEN,
      pointHoverBackgroundColor: Colors.ENERGY_DARK_GREEN,
      pointHoverBorderColor: Colors.ENERGY_DARK_GREEN,
    },
    {
      backgroundColor: Colors.ENERGY_GREEN,
      borderColor: Colors.ENERGY_GREEN,
      pointBackgroundColor: Colors.ENERGY_GREEN,
      pointBorderColor: Colors.ENERGY_GREEN,
      pointHoverBackgroundColor: Colors.ENERGY_GREEN,
      pointHoverBorderColor: Colors.ENERGY_GREEN,
    },

    {
      backgroundColor: Colors.ENERGY_TURQUOISE,
      borderColor: Colors.ENERGY_TURQUOISE,
      pointBackgroundColor: Colors.ENERGY_TURQUOISE,
      pointBorderColor: Colors.ENERGY_TURQUOISE,
      pointHoverBackgroundColor: Colors.ENERGY_TURQUOISE,
      pointHoverBorderColor: Colors.ENERGY_TURQUOISE,
    },
    {
      backgroundColor: Colors.ENERGY_ORANGE,
      borderColor: Colors.ENERGY_ORANGE,
      pointBackgroundColor: Colors.ENERGY_ORANGE,
      pointBorderColor: Colors.ENERGY_ORANGE,
      pointHoverBackgroundColor: Colors.ENERGY_ORANGE,
      pointHoverBorderColor: Colors.ENERGY_ORANGE,
    },
    {
      backgroundColor: Colors.ENERGY_DARK_RED,
      borderColor: Colors.ENERGY_DARK_RED,
      pointBackgroundColor: Colors.ENERGY_DARK_RED,
      pointBorderColor: Colors.ENERGY_DARK_RED,
      pointHoverBackgroundColor: Colors.ENERGY_DARK_RED,
      pointHoverBorderColor: Colors.ENERGY_DARK_RED,
    },
    {
      backgroundColor: Colors.ENERGY_PYRE,
      borderColor: Colors.ENERGY_PYRE,
      pointBackgroundColor: Colors.ENERGY_PYRE,
      pointBorderColor: Colors.ENERGY_PYRE,
      pointHoverBackgroundColor: Colors.ENERGY_PYRE,
      pointHoverBorderColor: Colors.ENERGY_PYRE,
    },

    {
      backgroundColor: Colors.ENERGY_RED,
      borderColor: Colors.ENERGY_RED,
      pointBackgroundColor: Colors.ENERGY_RED,
      pointBorderColor: Colors.ENERGY_RED,
      pointHoverBackgroundColor: Colors.ENERGY_RED,
      pointHoverBorderColor: Colors.ENERGY_RED,
    },
    {
      backgroundColor: Colors.ENERGY_MED_RED,
      borderColor: Colors.ENERGY_MED_RED,
      pointBackgroundColor: Colors.ENERGY_MED_RED,
      pointBorderColor: Colors.ENERGY_MED_RED,
      pointHoverBackgroundColor: Colors.ENERGY_MED_RED,
      pointHoverBorderColor: Colors.ENERGY_MED_RED,
    },

    {
      backgroundColor: Colors.ENERGY_FLARE,
      borderColor: Colors.ENERGY_FLARE,
      pointBackgroundColor: Colors.ENERGY_FLARE,
      pointBorderColor: Colors.ENERGY_FLARE,
      pointHoverBackgroundColor: Colors.ENERGY_FLARE,
      pointHoverBorderColor: Colors.ENERGY_FLARE,
    },
    {
      backgroundColor: Colors.ENERGY_YELLOW,
      borderColor: Colors.ENERGY_YELLOW,
      pointBackgroundColor: Colors.ENERGY_YELLOW,
      pointBorderColor: Colors.ENERGY_YELLOW,
      pointHoverBackgroundColor: Colors.ENERGY_YELLOW,
      pointHoverBorderColor: Colors.ENERGY_YELLOW,
    },
  ];

  denkmalpflegeColors = [
    {
      backgroundColor: Colors.BERN_RED_DARK,
      borderColor: Colors.BERN_RED_DARK,
      pointBackgroundColor: Colors.BERN_RED_DARK,
      pointBorderColor: Colors.BERN_RED_DARK,
      pointHoverBackgroundColor: Colors.BERN_RED_DARK,
      pointHoverBorderColor: Colors.BERN_RED_DARK,
    },
    {
      backgroundColor: Colors.BERN_RED,
      borderColor: Colors.BERN_RED,
      pointBackgroundColor: Colors.BERN_RED,
      pointBorderColor: Colors.BERN_RED,
      pointHoverBackgroundColor: Colors.BERN_RED,
      pointHoverBorderColor: Colors.BERN_RED,
    },
    {
      backgroundColor: Colors.BERN_RED_LIGHT,
      borderColor: Colors.BERN_RED_LIGHT,
      pointBackgroundColor: Colors.BERN_RED_LIGHT,
      pointBorderColor: Colors.BERN_RED_LIGHT,
      pointHoverBackgroundColor: Colors.BERN_RED_LIGHT,
      pointHoverBorderColor: Colors.BERN_RED_LIGHT,
    },
    {
      backgroundColor: Colors.BERN_GRAY_LIGHT,
      borderColor: Colors.BERN_GRAY_LIGHT,
      pointBackgroundColor: Colors.BERN_GRAY_LIGHT,
      pointBorderColor: Colors.BERN_GRAY_LIGHT,
      pointHoverBackgroundColor: Colors.BERN_GRAY_LIGHT,
      pointHoverBorderColor: Colors.BERN_GRAY_LIGHT,
    },
    {
      backgroundColor: Colors.BERN_GRAY_DARK,
      borderColor: Colors.BERN_GRAY_DARK,
      pointBackgroundColor: Colors.BERN_GRAY_DARK,
      pointBorderColor: Colors.BERN_GRAY_DARK,
      pointHoverBackgroundColor: Colors.BERN_GRAY_DARK,
      pointHoverBorderColor: Colors.BERN_GRAY_DARK,
    },
    {
      backgroundColor: Colors.BERN_BLACK,
      borderColor: Colors.BERN_BLACK,
      pointBackgroundColor: Colors.BERN_BLACK,
      pointBorderColor: Colors.BERN_BLACK,
      pointHoverBackgroundColor: Colors.BERN_BLACK,
      pointHoverBorderColor: Colors.BERN_BLACK,
    },
  ];

  getRadialChartColors() {
    return this.radialChartColors;
  }

  getDefaultFondsColors() {
    return this.defaultFondsColors;
  }

  getDefaultVVNeuColors() {
    return this.defaultVVNeuColors;
  }

  getDefaultVVAltColors() {
    return this.defaultVVAltColors;
  }

  getEnergyChartColors() {
    return this.energyChartColors;
  }

  getDenkmalpflegeColors() {
    return this.denkmalpflegeColors;
  }
}

enum Colors {
  BERN_YELLOW = '#ffff66',
  BERN_TURQOUISE = '#29a7a5',
  BERN_GREEN = '#008000',
  BERN_GREEN_LIGHT = '#89c064',
  BERN_RED = '#ff0000',
  BERN_RED_LIGHT = '#ffa071',
  BERN_RED_DARK = '#c00000',
  BERN_GRAY = '#87888a',
  BERN_GRAY_LIGHT = '#b7b8ba',
  BERN_GRAY_DARK = '#808080',
  BERN_BLACK = '#000000',
  BERN_WHITE = '#ffffff',
  BERN_BLACK_LIGHT = '#403d42',
  BERN_BLUE = '#0066ff',
  BERN_BLUE_LIGHT = '#8eaadb',
  BERN_BLUE_DARK = '#002f8e',
  BERN_BROWN = '#663300',
  BERN_BROWN_LIGHT = '#c38649',
  ENERGY_DARK_GREEN = '#165914',
  ENERGY_MED_GREEN = '#0a750c',
  ENERGY_GREEN = '#499E5A',
  ENERGY_LEAF_GREEN = '#20a72d',
  ENERGY_LIGHT_GREEN = '#1adc51',
  ENERGY_TURQUOISE = '#9AE6C2',
  ENERGY_DARK_RED = '#820401',
  ENERGY_MED_RED = '#c02323',
  ENERGY_RED = '#de542c',
  ENERGY_FLARE = '#ef7e32',
  ENERGY_ORANGE = '#ee9a3a',
  ENERGY_PYRE = '#EBAD26',
  ENERGY_YELLOW = '#e7e34e',
  ENERGY_DARK_BLUE = '#01579B',
  ENERGY_BLUE = '#039BE5',
}
function changeOpacity(color: string, opacity: number): string {
  const _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 150);
  return color + _opacity.toString(16).toUpperCase();
}
