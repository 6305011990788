<h1 mat-dialog-title>Beispielprojekte</h1>
<div mat-dialog-content>
  <mat-grid-list [cols]="numberOfCols" rowHeight="1:1" gutterSize="0.5em">
    <mat-grid-tile
      ><a href="http://www.stoeckackersued.ch/" target="_blank"
        ><img
          width="100%"
          src="assets/construction-projects/stoeckacker.jpg"
        /> </a
    ></mat-grid-tile>
    <mat-grid-tile
      ><a
        href="https://www.bern.ch/themen/planen-und-bauen/stadtentwicklung/stadtentwicklungsprojekte/reichenbachstrasse-118/das-projekt"
        target="_blank"
        ><img
          width="100%"
          src="assets/construction-projects/reichenbachstrasse.jpg"
        /> </a
    ></mat-grid-tile>
    <mat-grid-tile
      ><a
        href="https://www.bern.ch/themen/planen-und-bauen/stadtentwicklung/stadtentwicklungsprojekte/viererfeld/"
        target="_blank"
        ><img
          width="100%"
          src="assets/construction-projects/viererfeld.jpg"
        /> </a
    ></mat-grid-tile>
  </mat-grid-list>
</div>
<div mat-dialog-actions>
  <button mat-raised-button [mat-dialog-close]>Schliessen</button>
</div>
