export const veloplaetzeVvNeu = [
  {
    id: 1,
    Jahr: 2017,
    Kategorie: 'Verwaltungsgebäude',
    Veloplaetze: 26.12,
  },
  {
    id: 2,
    Jahr: 2018,
    Kategorie: 'Verwaltungsgebäude',
    Veloplaetze: 26.12,
  },
  {
    id: 3,
    Jahr: 2019,
    Kategorie: 'Verwaltungsgebäude',
    Veloplaetze: 27.56,
  },
  {
    id: 4,
    Jahr: 2020,
    Kategorie: 'Verwaltungsgebäude',
    Veloplaetze: 27.56,
  },
  {
    id: 5,
    Jahr: 2021,
    Kategorie: 'Verwaltungsgebäude',
    Veloplaetze: 27.12,
  },
  {
    id: 6,
    Jahr: 2017,
    Kategorie: 'Bildung',
    Veloplaetze: 23.47,
  },
  {
    id: 7,
    Jahr: 2018,
    Kategorie: 'Bildung',
    Veloplaetze: 23.08,
  },
  {
    id: 8,
    Jahr: 2019,
    Kategorie: 'Bildung',
    Veloplaetze: 23.6,
  },
  {
    id: 9,
    Jahr: 2020,
    Kategorie: 'Bildung',
    Veloplaetze: 23.21,
  },
  {
    id: 10,
    Jahr: 2021,
    Kategorie: 'Bildung',
    Veloplaetze: 23.23,
  },
  {
    id: 11,
    Jahr: 2017,
    Kategorie: 'Sport und Freizeit',
    Veloplaetze: 26.95,
  },
  {
    id: 12,
    Jahr: 2018,
    Kategorie: 'Sport und Freizeit',
    Veloplaetze: 26.95,
  },
  {
    id: 13,
    Jahr: 2019,
    Kategorie: 'Sport und Freizeit',
    Veloplaetze: 29.65,
  },
  {
    id: 14,
    Jahr: 2020,
    Kategorie: 'Sport und Freizeit',
    Veloplaetze: 29.65,
  },
  {
    id: 15,
    Jahr: 2021,
    Kategorie: 'Sport und Freizeit',
    Veloplaetze: 31.21,
  },
  {
    id: 16,
    Jahr: 2018,
    Kategorie: 'Wohnen und Aufenthalt',
    Veloplaetze: 4.56,
  },
  {
    id: 17,
    Jahr: 2019,
    Kategorie: 'Wohnen und Aufenthalt',
    Veloplaetze: 4.1,
  },
  {
    id: 18,
    Jahr: 2020,
    Kategorie: 'Wohnen und Aufenthalt',
    Veloplaetze: 4.1,
  },
  {
    id: 19,
    Jahr: 2021,
    Kategorie: 'Wohnen und Aufenthalt',
    Veloplaetze: 3.77,
  },
  {
    id: 20,
    Jahr: 2017,
    Kategorie: 'Kultur und Geselligkeit',
    Veloplaetze: 4,
  },
  {
    id: 21,
    Jahr: 2018,
    Kategorie: 'Kultur und Geselligkeit',
    Veloplaetze: 1.94,
  },
  {
    id: 22,
    Jahr: 2019,
    Kategorie: 'Kultur und Geselligkeit',
    Veloplaetze: 1.59,
  },
  {
    id: 23,
    Jahr: 2020,
    Kategorie: 'Kultur und Geselligkeit',
    Veloplaetze: 1.59,
  },
  {
    id: 24,
    Jahr: 2021,
    Kategorie: 'Kultur und Geselligkeit',
    Veloplaetze: 1.59,
  },
  {
    id: 25,
    Jahr: 2017,
    Kategorie: 'Infrastrukturbauten',
    Veloplaetze: 1.81,
  },
  {
    id: 26,
    Jahr: 2018,
    Kategorie: 'Infrastrukturbauten',
    Veloplaetze: 1.81,
  },
  {
    id: 27,
    Jahr: 2019,
    Kategorie: 'Infrastrukturbauten',
    Veloplaetze: 1.93,
  },
  {
    id: 28,
    Jahr: 2020,
    Kategorie: 'Infrastrukturbauten',
    Veloplaetze: 6,
  },
  {
    id: 29,
    Jahr: 2021,
    Kategorie: 'Infrastrukturbauten',
    Veloplaetze: 5.71,
  },
];
