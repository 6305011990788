<div class="info-modal">
  <h2 mat-dialog-title>{{ modalData?.title }}</h2>
  <mat-dialog-content class="mat-typography">
    <p>
      {{ modalData?.description }}
    </p>
    <div *ngFor="let item of modalData?.items">
      <h3 style="font-weight: bold">{{ item?.title }}</h3>
      <p [style]="item?.bold ? 'font-weight: bold;' : ''">
        {{ item?.description }}
      </p>
    </div>
    <a href="{{ modalData?.link?.link }}" target="_blank">{{
      modalData?.link?.text
    }}</a>
    <div class="tables">
      <div *ngFor="let tables of modalData?.tables">
        <div class="table">
          <div
            class="aspects"
            *ngFor="let table of tables.table"
            [style.background-color]="getColor(table.aspect)"
          >
            <p class="aspect">
              {{ table.aspect }}
            </p>
            <div>
              <div class="topics" *ngFor="let aspect of table.topics">
                <p class="topic">{{ aspect.name }}</p>
                <div>
                  <div
                    class="criterias"
                    *ngFor="let criteria of aspect.criterias"
                  >
                    <p class="criteria">{{ criteria }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button [mat-dialog-close]="true" cdkFocusInitial>
      Schliessen
    </button>
  </mat-dialog-actions>
</div>
