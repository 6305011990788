import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, NgModel } from '@angular/forms';

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss'],
})
export class FilterComponent implements OnInit {
  @Input() years: number[];

  @Input() categories: string[];

  @Input() labels: string[];

  @Input() showThirdFilter: boolean;

  @Input() showNewSystemFilter: boolean;

  @Input() new_years: number[];
  @Input() old_years: number[];

  public availableYears;

  @Input() selectedYears;

  @Input() selectedCategories;

  @Input() selectedBuildings;

  @Input() hideYearFilter;

  public selectedSystem;

  public systems = ['Altes System', 'Neues System'];

  @Input() useNewSystem;

  ngOnInit() {
    this.availableYears = this.years;
    this.useNewSystem
      ? (this.selectedSystem = this.systems[1])
      : (this.selectedSystem = this.systems[0]);
    if (this.useNewSystem) this.selectedYears = this.new_years;
  }

  filter = new FormControl();

  @Output() newItemEvent = new EventEmitter();

  public selectionChangeBuildings(ob) {
    this.selectedBuildings = ob.value;
    this.newItemEvent.emit({
      cat: this.selectedBuildings,
      labels: this.selectedCategories,
      years: this.selectedYears,
      system: this.selectedSystem,
      newSystem: this.useNewSystem,
    });
  }

  public selectionChangeCategories(ob) {
    this.selectedCategories = ob.value;
    this.newItemEvent.emit({
      cat: this.selectedBuildings,
      labels: this.selectedCategories,
      years: this.selectedYears,
      system: this.selectedSystem,
      newSystem: this.useNewSystem,
    });
  }

  public selectionChangeYears(ob) {
    this.selectedYears = ob.value;
    this.newItemEvent.emit({
      cat: this.selectedBuildings,
      labels: this.selectedCategories,
      years: this.selectedYears,
      system: this.selectedSystem,
      newSystem: this.useNewSystem,
    });
  }

  public selectionChangeSystem(ob) {
    this.selectedSystem = ob.value;
    this.setToChosenSystem(this.selectedSystem);
    this.newItemEvent.emit({
      cat: this.selectedBuildings,
      labels: this.selectedCategories,
      years: this.selectedYears,
      system: this.selectedSystem,
      newSystem: this.useNewSystem,
    });
  }

  public setToChosenSystem(selectedSystem) {
    if (selectedSystem === this.systems[0]) {
      this.availableYears = this.old_years;
      this.selectedYears = [];
      this.useNewSystem = false;
    } else {
      this.availableYears = this.new_years;
      this.selectedYears = this.availableYears;
      this.useNewSystem = true;
    }
  }

  public resetFilter() {
    if (this.showNewSystemFilter) {
      this.availableYears = this.new_years;
      this.selectedSystem = this.systems[1];
      this.useNewSystem = true;
      this.selectedYears = this.availableYears;
    } else {
      this.availableYears = this.years;
      this.selectedYears = [];
    }
    this.selectedBuildings = [];
    this.selectedCategories = [];

    this.newItemEvent.emit({
      cat: [],
      labels: [],
      years: this.selectedYears,
      system: this.selectedSystem,
      newSystem: this.useNewSystem,
    });
  }

  public deselectAll(select: NgModel, filterName: string) {
    select.update.emit([]);
    if (filterName === 'Buildings') this.selectedBuildings = [];
    if (filterName === 'Categories') this.selectedCategories = [];
    if (filterName === 'Years') this.selectedYears = [];
    this.newItemEvent.emit({
      cat: this.selectedBuildings,
      labels: this.selectedCategories,
      years: this.selectedYears,
      system: this.selectedSystem,
      newSystem: this.useNewSystem,
    });
  }
}
