export const energietraegerVvNeu = [
  {
    id: 1,
    Jahr: 2019,
    Kategorie: 'Verwaltungsgebäude',
    Energietraeger: 'Gas',
    Anzahl: 5,
  },
  {
    id: 2,
    Jahr: 2019,
    Kategorie: 'Verwaltungsgebäude',
    Energietraeger: 'Öl',
    Anzahl: 0,
  },
  {
    id: 3,
    Jahr: 2019,
    Kategorie: 'Verwaltungsgebäude',
    Energietraeger: 'Kombi Gas/Öl',
    Anzahl: 0,
  },
  {
    id: 4,
    Jahr: 2019,
    Kategorie: 'Verwaltungsgebäude',
    Energietraeger: 'Elektrizität',
    Anzahl: 0,
  },
  {
    id: 5,
    Jahr: 2019,
    Kategorie: 'Verwaltungsgebäude',
    Energietraeger: 'Fernwärme',
    Anzahl: 13,
  },
  {
    id: 6,
    Jahr: 2019,
    Kategorie: 'Verwaltungsgebäude',
    Energietraeger: 'Wärmepumpen',
    Anzahl: 0,
  },
  {
    id: 7,
    Jahr: 2019,
    Kategorie: 'Verwaltungsgebäude',
    Energietraeger: 'Holz',
    Anzahl: 0,
  },
  {
    id: 8,
    Jahr: 2019,
    Kategorie: 'Verwaltungsgebäude',
    Energietraeger: 'Photovoltaikanlagen',
    Anzahl: 0,
  },
  {
    id: 9,
    Jahr: 2019,
    Kategorie: 'Verwaltungsgebäude',
    Energietraeger: 'Solaranlagen',
    Anzahl: 1,
  },
  {
    id: 10,
    Jahr: 2019,
    Kategorie: 'Bildung',
    Energietraeger: 'Gas',
    Anzahl: 61,
  },
  {
    id: 11,
    Jahr: 2019,
    Kategorie: 'Bildung',
    Energietraeger: 'Öl',
    Anzahl: 17,
  },
  {
    id: 12,
    Jahr: 2019,
    Kategorie: 'Bildung',
    Energietraeger: 'Kombi Gas/Öl',
    Anzahl: 1,
  },
  {
    id: 13,
    Jahr: 2019,
    Kategorie: 'Bildung',
    Energietraeger: 'Elektrizität',
    Anzahl: 1,
  },
  {
    id: 14,
    Jahr: 2019,
    Kategorie: 'Bildung',
    Energietraeger: 'Fernwärme',
    Anzahl: 55,
  },
  {
    id: 15,
    Jahr: 2019,
    Kategorie: 'Bildung',
    Energietraeger: 'Wärmepumpen',
    Anzahl: 17,
  },
  {
    id: 16,
    Jahr: 2019,
    Kategorie: 'Bildung',
    Energietraeger: 'Holz',
    Anzahl: 3,
  },
  {
    id: 17,
    Jahr: 2019,
    Kategorie: 'Bildung',
    Energietraeger: 'Photovoltaikanlagen',
    Anzahl: 16,
  },
  {
    id: 18,
    Jahr: 2019,
    Kategorie: 'Bildung',
    Energietraeger: 'Solaranlagen',
    Anzahl: 5,
  },
  {
    id: 19,
    Jahr: 2019,
    Kategorie: 'Sport und Freizeit',
    Energietraeger: 'Gas',
    Anzahl: 13,
  },
  {
    id: 20,
    Jahr: 2019,
    Kategorie: 'Sport und Freizeit',
    Energietraeger: 'Öl',
    Anzahl: 0,
  },
  {
    id: 21,
    Jahr: 2019,
    Kategorie: 'Sport und Freizeit',
    Energietraeger: 'Kombi Gas/Öl',
    Anzahl: 0,
  },
  {
    id: 22,
    Jahr: 2019,
    Kategorie: 'Sport und Freizeit',
    Energietraeger: 'Elektrizität',
    Anzahl: 5,
  },
  {
    id: 23,
    Jahr: 2019,
    Kategorie: 'Sport und Freizeit',
    Energietraeger: 'Fernwärme',
    Anzahl: 8,
  },
  {
    id: 24,
    Jahr: 2019,
    Kategorie: 'Sport und Freizeit',
    Energietraeger: 'Wärmepumpen',
    Anzahl: 2,
  },
  {
    id: 25,
    Jahr: 2019,
    Kategorie: 'Sport und Freizeit',
    Energietraeger: 'Holz',
    Anzahl: 0,
  },
  {
    id: 26,
    Jahr: 2019,
    Kategorie: 'Sport und Freizeit',
    Energietraeger: 'Photovoltaikanlagen',
    Anzahl: 2,
  },
  {
    id: 27,
    Jahr: 2019,
    Kategorie: 'Sport und Freizeit',
    Energietraeger: 'Solaranlagen',
    Anzahl: 0,
  },
  {
    id: 28,
    Jahr: 2019,
    Kategorie: 'Wohnen und Aufenthalt',
    Energietraeger: 'Gas',
    Anzahl: 3,
  },
  {
    id: 29,
    Jahr: 2019,
    Kategorie: 'Wohnen und Aufenthalt',
    Energietraeger: 'Öl',
    Anzahl: 3,
  },
  {
    id: 30,
    Jahr: 2019,
    Kategorie: 'Wohnen und Aufenthalt',
    Energietraeger: 'Kombi Gas/Öl',
    Anzahl: 0,
  },
  {
    id: 31,
    Jahr: 2019,
    Kategorie: 'Wohnen und Aufenthalt',
    Energietraeger: 'Elektrizität',
    Anzahl: 0,
  },
  {
    id: 32,
    Jahr: 2019,
    Kategorie: 'Wohnen und Aufenthalt',
    Energietraeger: 'Fernwärme',
    Anzahl: 1,
  },
  {
    id: 33,
    Jahr: 2019,
    Kategorie: 'Wohnen und Aufenthalt',
    Energietraeger: 'Wärmepumpen',
    Anzahl: 1,
  },
  {
    id: 34,
    Jahr: 2019,
    Kategorie: 'Wohnen und Aufenthalt',
    Energietraeger: 'Holz',
    Anzahl: 0,
  },
  {
    id: 35,
    Jahr: 2019,
    Kategorie: 'Wohnen und Aufenthalt',
    Energietraeger: 'Photovoltaikanlagen',
    Anzahl: 0,
  },
  {
    id: 36,
    Jahr: 2019,
    Kategorie: 'Wohnen und Aufenthalt',
    Energietraeger: 'Solaranlagen',
    Anzahl: 0,
  },
  {
    id: 37,
    Jahr: 2019,
    Kategorie: 'Kultur und Geselligkeit',
    Energietraeger: 'Gas',
    Anzahl: 7,
  },
  {
    id: 38,
    Jahr: 2019,
    Kategorie: 'Kultur und Geselligkeit',
    Energietraeger: 'Öl',
    Anzahl: 1,
  },
  {
    id: 39,
    Jahr: 2019,
    Kategorie: 'Kultur und Geselligkeit',
    Energietraeger: 'Kombi Gas/Öl',
    Anzahl: 0,
  },
  {
    id: 40,
    Jahr: 2019,
    Kategorie: 'Kultur und Geselligkeit',
    Energietraeger: 'Elektrizität',
    Anzahl: 3,
  },
  {
    id: 41,
    Jahr: 2019,
    Kategorie: 'Kultur und Geselligkeit',
    Energietraeger: 'Fernwärme',
    Anzahl: 5,
  },
  {
    id: 42,
    Jahr: 2019,
    Kategorie: 'Kultur und Geselligkeit',
    Energietraeger: 'Wärmepumpen',
    Anzahl: 0,
  },
  {
    id: 43,
    Jahr: 2019,
    Kategorie: 'Kultur und Geselligkeit',
    Energietraeger: 'Holz',
    Anzahl: 1,
  },
  {
    id: 44,
    Jahr: 2019,
    Kategorie: 'Kultur und Geselligkeit',
    Energietraeger: 'Photovoltaikanlagen',
    Anzahl: 0,
  },
  {
    id: 45,
    Jahr: 2019,
    Kategorie: 'Kultur und Geselligkeit',
    Energietraeger: 'Solaranlagen',
    Anzahl: 0,
  },
  {
    id: 46,
    Jahr: 2019,
    Kategorie: 'Infrastrukturbauten',
    Energietraeger: 'Gas',
    Anzahl: 3,
  },
  {
    id: 47,
    Jahr: 2019,
    Kategorie: 'Infrastrukturbauten',
    Energietraeger: 'Öl',
    Anzahl: 0,
  },
  {
    id: 48,
    Jahr: 2019,
    Kategorie: 'Infrastrukturbauten',
    Energietraeger: 'Kombi Gas/Öl',
    Anzahl: 0,
  },
  {
    id: 49,
    Jahr: 2019,
    Kategorie: 'Infrastrukturbauten',
    Energietraeger: 'Elektrizität',
    Anzahl: 9,
  },
  {
    id: 50,
    Jahr: 2019,
    Kategorie: 'Infrastrukturbauten',
    Energietraeger: 'Fernwärme',
    Anzahl: 2,
  },
  {
    id: 51,
    Jahr: 2019,
    Kategorie: 'Infrastrukturbauten',
    Energietraeger: 'Wärmepumpen',
    Anzahl: 1,
  },
  {
    id: 52,
    Jahr: 2019,
    Kategorie: 'Infrastrukturbauten',
    Energietraeger: 'Holz',
    Anzahl: 0,
  },
  {
    id: 53,
    Jahr: 2019,
    Kategorie: 'Infrastrukturbauten',
    Energietraeger: 'Photovoltaikanlagen',
    Anzahl: 3,
  },
  {
    id: 54,
    Jahr: 2019,
    Kategorie: 'Infrastrukturbauten',
    Energietraeger: 'Solaranlagen',
    Anzahl: 0,
  },
  {
    id: 55,
    Jahr: 2020,
    Kategorie: 'Verwaltungsgebäude',
    Energietraeger: 'Gas',
    Anzahl: 5,
  },
  {
    id: 56,
    Jahr: 2020,
    Kategorie: 'Verwaltungsgebäude',
    Energietraeger: 'Öl',
    Anzahl: 0,
  },
  {
    id: 57,
    Jahr: 2020,
    Kategorie: 'Verwaltungsgebäude',
    Energietraeger: 'Kombi Gas/Öl',
    Anzahl: 0,
  },
  {
    id: 58,
    Jahr: 2020,
    Kategorie: 'Verwaltungsgebäude',
    Energietraeger: 'Elektrizität',
    Anzahl: 0,
  },
  {
    id: 59,
    Jahr: 2020,
    Kategorie: 'Verwaltungsgebäude',
    Energietraeger: 'Fernwärme',
    Anzahl: 13,
  },
  {
    id: 60,
    Jahr: 2020,
    Kategorie: 'Verwaltungsgebäude',
    Energietraeger: 'Wärmepumpen',
    Anzahl: 0,
  },
  {
    id: 61,
    Jahr: 2020,
    Kategorie: 'Verwaltungsgebäude',
    Energietraeger: 'Holz',
    Anzahl: 0,
  },
  {
    id: 62,
    Jahr: 2020,
    Kategorie: 'Verwaltungsgebäude',
    Energietraeger: 'Photovoltaikanlagen',
    Anzahl: 1,
  },
  {
    id: 63,
    Jahr: 2020,
    Kategorie: 'Verwaltungsgebäude',
    Energietraeger: 'Solaranlagen',
    Anzahl: 1,
  },
  {
    id: 64,
    Jahr: 2020,
    Kategorie: 'Bildung',
    Energietraeger: 'Gas',
    Anzahl: 62,
  },
  {
    id: 65,
    Jahr: 2020,
    Kategorie: 'Bildung',
    Energietraeger: 'Öl',
    Anzahl: 16,
  },
  {
    id: 66,
    Jahr: 2020,
    Kategorie: 'Bildung',
    Energietraeger: 'Kombi Gas/Öl',
    Anzahl: 1,
  },
  {
    id: 67,
    Jahr: 2020,
    Kategorie: 'Bildung',
    Energietraeger: 'Elektrizität',
    Anzahl: 1,
  },
  {
    id: 68,
    Jahr: 2020,
    Kategorie: 'Bildung',
    Energietraeger: 'Fernwärme',
    Anzahl: 56,
  },
  {
    id: 69,
    Jahr: 2020,
    Kategorie: 'Bildung',
    Energietraeger: 'Wärmepumpen',
    Anzahl: 21,
  },
  {
    id: 70,
    Jahr: 2020,
    Kategorie: 'Bildung',
    Energietraeger: 'Holz',
    Anzahl: 3,
  },
  {
    id: 71,
    Jahr: 2020,
    Kategorie: 'Bildung',
    Energietraeger: 'Photovoltaikanlagen',
    Anzahl: 24,
  },
  {
    id: 72,
    Jahr: 2020,
    Kategorie: 'Bildung',
    Energietraeger: 'Solaranlagen',
    Anzahl: 6,
  },
  {
    id: 73,
    Jahr: 2020,
    Kategorie: 'Sport und Freizeit',
    Energietraeger: 'Gas',
    Anzahl: 13,
  },
  {
    id: 74,
    Jahr: 2020,
    Kategorie: 'Sport und Freizeit',
    Energietraeger: 'Öl',
    Anzahl: 0,
  },
  {
    id: 75,
    Jahr: 2020,
    Kategorie: 'Sport und Freizeit',
    Energietraeger: 'Kombi Gas/Öl',
    Anzahl: 0,
  },
  {
    id: 76,
    Jahr: 2020,
    Kategorie: 'Sport und Freizeit',
    Energietraeger: 'Elektrizität',
    Anzahl: 5,
  },
  {
    id: 77,
    Jahr: 2020,
    Kategorie: 'Sport und Freizeit',
    Energietraeger: 'Fernwärme',
    Anzahl: 8,
  },
  {
    id: 78,
    Jahr: 2020,
    Kategorie: 'Sport und Freizeit',
    Energietraeger: 'Wärmepumpen',
    Anzahl: 2,
  },
  {
    id: 79,
    Jahr: 2020,
    Kategorie: 'Sport und Freizeit',
    Energietraeger: 'Holz',
    Anzahl: 0,
  },
  {
    id: 80,
    Jahr: 2020,
    Kategorie: 'Sport und Freizeit',
    Energietraeger: 'Photovoltaikanlagen',
    Anzahl: 2,
  },
  {
    id: 81,
    Jahr: 2020,
    Kategorie: 'Sport und Freizeit',
    Energietraeger: 'Solaranlagen',
    Anzahl: 0,
  },
  {
    id: 82,
    Jahr: 2020,
    Kategorie: 'Wohnen und Aufenthalt',
    Energietraeger: 'Gas',
    Anzahl: 3,
  },
  {
    id: 83,
    Jahr: 2020,
    Kategorie: 'Wohnen und Aufenthalt',
    Energietraeger: 'Öl',
    Anzahl: 3,
  },
  {
    id: 84,
    Jahr: 2020,
    Kategorie: 'Wohnen und Aufenthalt',
    Energietraeger: 'Kombi Gas/Öl',
    Anzahl: 0,
  },
  {
    id: 85,
    Jahr: 2020,
    Kategorie: 'Wohnen und Aufenthalt',
    Energietraeger: 'Elektrizität',
    Anzahl: 0,
  },
  {
    id: 86,
    Jahr: 2020,
    Kategorie: 'Wohnen und Aufenthalt',
    Energietraeger: 'Fernwärme',
    Anzahl: 1,
  },
  {
    id: 87,
    Jahr: 2020,
    Kategorie: 'Wohnen und Aufenthalt',
    Energietraeger: 'Wärmepumpen',
    Anzahl: 1,
  },
  {
    id: 88,
    Jahr: 2020,
    Kategorie: 'Wohnen und Aufenthalt',
    Energietraeger: 'Holz',
    Anzahl: 0,
  },
  {
    id: 89,
    Jahr: 2020,
    Kategorie: 'Wohnen und Aufenthalt',
    Energietraeger: 'Photovoltaikanlagen',
    Anzahl: 0,
  },
  {
    id: 90,
    Jahr: 2020,
    Kategorie: 'Wohnen und Aufenthalt',
    Energietraeger: 'Solaranlagen',
    Anzahl: 0,
  },
  {
    id: 91,
    Jahr: 2020,
    Kategorie: 'Kultur und Geselligkeit',
    Energietraeger: 'Gas',
    Anzahl: 7,
  },
  {
    id: 92,
    Jahr: 2020,
    Kategorie: 'Kultur und Geselligkeit',
    Energietraeger: 'Öl',
    Anzahl: 1,
  },
  {
    id: 93,
    Jahr: 2020,
    Kategorie: 'Kultur und Geselligkeit',
    Energietraeger: 'Kombi Gas/Öl',
    Anzahl: 0,
  },
  {
    id: 94,
    Jahr: 2020,
    Kategorie: 'Kultur und Geselligkeit',
    Energietraeger: 'Elektrizität',
    Anzahl: 3,
  },
  {
    id: 95,
    Jahr: 2020,
    Kategorie: 'Kultur und Geselligkeit',
    Energietraeger: 'Fernwärme',
    Anzahl: 5,
  },
  {
    id: 96,
    Jahr: 2020,
    Kategorie: 'Kultur und Geselligkeit',
    Energietraeger: 'Wärmepumpen',
    Anzahl: 0,
  },
  {
    id: 97,
    Jahr: 2020,
    Kategorie: 'Kultur und Geselligkeit',
    Energietraeger: 'Holz',
    Anzahl: 1,
  },
  {
    id: 98,
    Jahr: 2020,
    Kategorie: 'Kultur und Geselligkeit',
    Energietraeger: 'Photovoltaikanlagen',
    Anzahl: 0,
  },
  {
    id: 99,
    Jahr: 2020,
    Kategorie: 'Kultur und Geselligkeit',
    Energietraeger: 'Solaranlagen',
    Anzahl: 0,
  },
  {
    id: 100,
    Jahr: 2020,
    Kategorie: 'Infrastrukturbauten',
    Energietraeger: 'Gas',
    Anzahl: 6,
  },
  {
    id: 101,
    Jahr: 2020,
    Kategorie: 'Infrastrukturbauten',
    Energietraeger: 'Öl',
    Anzahl: 0,
  },
  {
    id: 102,
    Jahr: 2020,
    Kategorie: 'Infrastrukturbauten',
    Energietraeger: 'Kombi Gas/Öl',
    Anzahl: 0,
  },
  {
    id: 103,
    Jahr: 2020,
    Kategorie: 'Infrastrukturbauten',
    Energietraeger: 'Elektrizität',
    Anzahl: 13,
  },
  {
    id: 104,
    Jahr: 2020,
    Kategorie: 'Infrastrukturbauten',
    Energietraeger: 'Fernwärme',
    Anzahl: 2,
  },
  {
    id: 105,
    Jahr: 2020,
    Kategorie: 'Infrastrukturbauten',
    Energietraeger: 'Wärmepumpen',
    Anzahl: 2,
  },
  {
    id: 106,
    Jahr: 2020,
    Kategorie: 'Infrastrukturbauten',
    Energietraeger: 'Holz',
    Anzahl: 0,
  },
  {
    id: 107,
    Jahr: 2020,
    Kategorie: 'Infrastrukturbauten',
    Energietraeger: 'Photovoltaikanlagen',
    Anzahl: 3,
  },
  {
    id: 108,
    Jahr: 2020,
    Kategorie: 'Infrastrukturbauten',
    Energietraeger: 'Solaranlagen',
    Anzahl: 1,
  },
  {
    id: 109,
    Jahr: 2021,
    Kategorie: 'Verwaltungsgebäude',
    Energietraeger: 'Gas',
    Anzahl: 5,
  },
  {
    id: 110,
    Jahr: 2021,
    Kategorie: 'Verwaltungsgebäude',
    Energietraeger: 'Öl',
    Anzahl: 2,
  },
  {
    id: 111,
    Jahr: 2021,
    Kategorie: 'Verwaltungsgebäude',
    Energietraeger: 'Kombi Gas/Öl',
    Anzahl: 0,
  },
  {
    id: 112,
    Jahr: 2021,
    Kategorie: 'Verwaltungsgebäude',
    Energietraeger: 'Elektrizität',
    Anzahl: 0,
  },
  {
    id: 113,
    Jahr: 2021,
    Kategorie: 'Verwaltungsgebäude',
    Energietraeger: 'Fernwärme',
    Anzahl: 13,
  },
  {
    id: 114,
    Jahr: 2021,
    Kategorie: 'Verwaltungsgebäude',
    Energietraeger: 'Wärmepumpen',
    Anzahl: 0,
  },
  {
    id: 115,
    Jahr: 2021,
    Kategorie: 'Verwaltungsgebäude',
    Energietraeger: 'Holz',
    Anzahl: 0,
  },
  {
    id: 116,
    Jahr: 2021,
    Kategorie: 'Verwaltungsgebäude',
    Energietraeger: 'Photovoltaikanlagen',
    Anzahl: 1,
  },
  {
    id: 117,
    Jahr: 2021,
    Kategorie: 'Verwaltungsgebäude',
    Energietraeger: 'Solaranlagen',
    Anzahl: 1,
  },
  {
    id: 118,
    Jahr: 2021,
    Kategorie: 'Bildung',
    Energietraeger: 'Gas',
    Anzahl: 62,
  },
  {
    id: 119,
    Jahr: 2021,
    Kategorie: 'Bildung',
    Energietraeger: 'Öl',
    Anzahl: 13,
  },
  {
    id: 120,
    Jahr: 2021,
    Kategorie: 'Bildung',
    Energietraeger: 'Kombi Gas/Öl',
    Anzahl: 1,
  },
  {
    id: 121,
    Jahr: 2021,
    Kategorie: 'Bildung',
    Energietraeger: 'Elektrizität',
    Anzahl: 1,
  },
  {
    id: 122,
    Jahr: 2021,
    Kategorie: 'Bildung',
    Energietraeger: 'Fernwärme',
    Anzahl: 57,
  },
  {
    id: 123,
    Jahr: 2021,
    Kategorie: 'Bildung',
    Energietraeger: 'Wärmepumpen',
    Anzahl: 21,
  },
  {
    id: 124,
    Jahr: 2021,
    Kategorie: 'Bildung',
    Energietraeger: 'Holz',
    Anzahl: 5,
  },
  {
    id: 125,
    Jahr: 2021,
    Kategorie: 'Bildung',
    Energietraeger: 'Photovoltaikanlagen',
    Anzahl: 27,
  },
  {
    id: 126,
    Jahr: 2021,
    Kategorie: 'Bildung',
    Energietraeger: 'Solaranlagen',
    Anzahl: 5,
  },
  {
    id: 127,
    Jahr: 2021,
    Kategorie: 'Sport und Freizeit',
    Energietraeger: 'Gas',
    Anzahl: 13,
  },
  {
    id: 128,
    Jahr: 2021,
    Kategorie: 'Sport und Freizeit',
    Energietraeger: 'Öl',
    Anzahl: 0,
  },
  {
    id: 129,
    Jahr: 2021,
    Kategorie: 'Sport und Freizeit',
    Energietraeger: 'Kombi Gas/Öl',
    Anzahl: 0,
  },
  {
    id: 130,
    Jahr: 2021,
    Kategorie: 'Sport und Freizeit',
    Energietraeger: 'Elektrizität',
    Anzahl: 4,
  },
  {
    id: 131,
    Jahr: 2021,
    Kategorie: 'Sport und Freizeit',
    Energietraeger: 'Fernwärme',
    Anzahl: 8,
  },
  {
    id: 132,
    Jahr: 2021,
    Kategorie: 'Sport und Freizeit',
    Energietraeger: 'Wärmepumpen',
    Anzahl: 2,
  },
  {
    id: 133,
    Jahr: 2021,
    Kategorie: 'Sport und Freizeit',
    Energietraeger: 'Holz',
    Anzahl: 0,
  },
  {
    id: 134,
    Jahr: 2021,
    Kategorie: 'Sport und Freizeit',
    Energietraeger: 'Photovoltaikanlagen',
    Anzahl: 2,
  },
  {
    id: 135,
    Jahr: 2021,
    Kategorie: 'Sport und Freizeit',
    Energietraeger: 'Solaranlagen',
    Anzahl: 1,
  },
  {
    id: 136,
    Jahr: 2021,
    Kategorie: 'Wohnen und Aufenthalt',
    Energietraeger: 'Gas',
    Anzahl: 5,
  },
  {
    id: 137,
    Jahr: 2021,
    Kategorie: 'Wohnen und Aufenthalt',
    Energietraeger: 'Öl',
    Anzahl: 3,
  },
  {
    id: 138,
    Jahr: 2021,
    Kategorie: 'Wohnen und Aufenthalt',
    Energietraeger: 'Kombi Gas/Öl',
    Anzahl: 0,
  },
  {
    id: 139,
    Jahr: 2021,
    Kategorie: 'Wohnen und Aufenthalt',
    Energietraeger: 'Elektrizität',
    Anzahl: 0,
  },
  {
    id: 140,
    Jahr: 2021,
    Kategorie: 'Wohnen und Aufenthalt',
    Energietraeger: 'Fernwärme',
    Anzahl: 1,
  },
  {
    id: 141,
    Jahr: 2021,
    Kategorie: 'Wohnen und Aufenthalt',
    Energietraeger: 'Wärmepumpen',
    Anzahl: 1,
  },
  {
    id: 142,
    Jahr: 2021,
    Kategorie: 'Wohnen und Aufenthalt',
    Energietraeger: 'Holz',
    Anzahl: 0,
  },
  {
    id: 143,
    Jahr: 2021,
    Kategorie: 'Wohnen und Aufenthalt',
    Energietraeger: 'Photovoltaikanlagen',
    Anzahl: 1,
  },
  {
    id: 144,
    Jahr: 2021,
    Kategorie: 'Wohnen und Aufenthalt',
    Energietraeger: 'Solaranlagen',
    Anzahl: 0,
  },
  {
    id: 145,
    Jahr: 2021,
    Kategorie: 'Kultur und Geselligkeit',
    Energietraeger: 'Gas',
    Anzahl: 7,
  },
  {
    id: 146,
    Jahr: 2021,
    Kategorie: 'Kultur und Geselligkeit',
    Energietraeger: 'Öl',
    Anzahl: 0,
  },
  {
    id: 147,
    Jahr: 2021,
    Kategorie: 'Kultur und Geselligkeit',
    Energietraeger: 'Kombi Gas/Öl',
    Anzahl: 0,
  },
  {
    id: 148,
    Jahr: 2021,
    Kategorie: 'Kultur und Geselligkeit',
    Energietraeger: 'Elektrizität',
    Anzahl: 3,
  },
  {
    id: 149,
    Jahr: 2021,
    Kategorie: 'Kultur und Geselligkeit',
    Energietraeger: 'Fernwärme',
    Anzahl: 5,
  },
  {
    id: 150,
    Jahr: 2021,
    Kategorie: 'Kultur und Geselligkeit',
    Energietraeger: 'Wärmepumpen',
    Anzahl: 0,
  },
  {
    id: 151,
    Jahr: 2021,
    Kategorie: 'Kultur und Geselligkeit',
    Energietraeger: 'Holz',
    Anzahl: 1,
  },
  {
    id: 152,
    Jahr: 2021,
    Kategorie: 'Kultur und Geselligkeit',
    Energietraeger: 'Photovoltaikanlagen',
    Anzahl: 0,
  },
  {
    id: 153,
    Jahr: 2021,
    Kategorie: 'Kultur und Geselligkeit',
    Energietraeger: 'Solaranlagen',
    Anzahl: 0,
  },
  {
    id: 154,
    Jahr: 2021,
    Kategorie: 'Infrastrukturbauten',
    Energietraeger: 'Gas',
    Anzahl: 8,
  },
  {
    id: 155,
    Jahr: 2021,
    Kategorie: 'Infrastrukturbauten',
    Energietraeger: 'Öl',
    Anzahl: 2,
  },
  {
    id: 156,
    Jahr: 2021,
    Kategorie: 'Infrastrukturbauten',
    Energietraeger: 'Kombi Gas/Öl',
    Anzahl: 0,
  },
  {
    id: 157,
    Jahr: 2021,
    Kategorie: 'Infrastrukturbauten',
    Energietraeger: 'Elektrizität',
    Anzahl: 13,
  },
  {
    id: 158,
    Jahr: 2021,
    Kategorie: 'Infrastrukturbauten',
    Energietraeger: 'Fernwärme',
    Anzahl: 2,
  },
  {
    id: 159,
    Jahr: 2021,
    Kategorie: 'Infrastrukturbauten',
    Energietraeger: 'Wärmepumpen',
    Anzahl: 3,
  },
  {
    id: 160,
    Jahr: 2021,
    Kategorie: 'Infrastrukturbauten',
    Energietraeger: 'Holz',
    Anzahl: 0,
  },
  {
    id: 161,
    Jahr: 2021,
    Kategorie: 'Infrastrukturbauten',
    Energietraeger: 'Photovoltaikanlagen',
    Anzahl: 3,
  },
  {
    id: 162,
    Jahr: 2021,
    Kategorie: 'Infrastrukturbauten',
    Energietraeger: 'Solaranlagen',
    Anzahl: 1,
  },
];
