export const vrsgFlaechenVvNeu = [
  {
    id: 1,
    Jahr: 2017,
    Kategorie: 'Verwaltungsgebäude',
    Anteil_nicht_versiegelt: 47,
  },
  {
    id: 2,
    Jahr: 2018,
    Kategorie: 'Verwaltungsgebäude',
    Anteil_nicht_versiegelt: 47,
  },
  {
    id: 3,
    Jahr: 2019,
    Kategorie: 'Verwaltungsgebäude',
    Anteil_nicht_versiegelt: 46,
  },
  {
    id: 4,
    Jahr: 2020,
    Kategorie: 'Verwaltungsgebäude',
    Anteil_nicht_versiegelt: 46,
  },
  {
    id: 5,
    Jahr: 2021,
    Kategorie: 'Verwaltungsgebäude',
    Anteil_nicht_versiegelt: 48,
  },
  {
    id: 6,
    Jahr: 2017,
    Kategorie: 'Bildung',
    Anteil_nicht_versiegelt: 69,
  },
  {
    id: 7,
    Jahr: 2018,
    Kategorie: 'Bildung',
    Anteil_nicht_versiegelt: 69,
  },
  {
    id: 8,
    Jahr: 2019,
    Kategorie: 'Bildung',
    Anteil_nicht_versiegelt: 68,
  },
  {
    id: 9,
    Jahr: 2020,
    Kategorie: 'Bildung',
    Anteil_nicht_versiegelt: 69,
  },
  {
    id: 10,
    Jahr: 2021,
    Kategorie: 'Bildung',
    Anteil_nicht_versiegelt: 69,
  },
  {
    id: 11,
    Jahr: 2017,
    Kategorie: 'Sport und Freizeit',
    Anteil_nicht_versiegelt: 69,
  },
  {
    id: 12,
    Jahr: 2018,
    Kategorie: 'Sport und Freizeit',
    Anteil_nicht_versiegelt: 69,
  },
  {
    id: 13,
    Jahr: 2019,
    Kategorie: 'Sport und Freizeit',
    Anteil_nicht_versiegelt: 69,
  },
  {
    id: 14,
    Jahr: 2020,
    Kategorie: 'Sport und Freizeit',
    Anteil_nicht_versiegelt: 69,
  },
  {
    id: 15,
    Jahr: 2021,
    Kategorie: 'Sport und Freizeit',
    Anteil_nicht_versiegelt: 69,
  },
  {
    id: 16,
    Jahr: 2018,
    Kategorie: 'Wohnen und Aufenthalt',
    Anteil_nicht_versiegelt: 79,
  },
  {
    id: 17,
    Jahr: 2019,
    Kategorie: 'Wohnen und Aufenthalt',
    Anteil_nicht_versiegelt: 79,
  },
  {
    id: 18,
    Jahr: 2020,
    Kategorie: 'Wohnen und Aufenthalt',
    Anteil_nicht_versiegelt: 79,
  },
  {
    id: 19,
    Jahr: 2021,
    Kategorie: 'Wohnen und Aufenthalt',
    Anteil_nicht_versiegelt: 77,
  },
  {
    id: 20,
    Jahr: 2017,
    Kategorie: 'Kultur und Geselligkeit',
    Anteil_nicht_versiegelt: 25,
  },
  {
    id: 21,
    Jahr: 2018,
    Kategorie: 'Kultur und Geselligkeit',
    Anteil_nicht_versiegelt: 68,
  },
  {
    id: 22,
    Jahr: 2019,
    Kategorie: 'Kultur und Geselligkeit',
    Anteil_nicht_versiegelt: 67,
  },
  {
    id: 23,
    Jahr: 2020,
    Kategorie: 'Kultur und Geselligkeit',
    Anteil_nicht_versiegelt: 67,
  },
  {
    id: 24,
    Jahr: 2021,
    Kategorie: 'Kultur und Geselligkeit',
    Anteil_nicht_versiegelt: 67,
  },
  {
    id: 25,
    Jahr: 2017,
    Kategorie: 'Infrastrukturbauten',
    Anteil_nicht_versiegelt: 58,
  },
  {
    id: 26,
    Jahr: 2018,
    Kategorie: 'Infrastrukturbauten',
    Anteil_nicht_versiegelt: 58,
  },
  {
    id: 27,
    Jahr: 2019,
    Kategorie: 'Infrastrukturbauten',
    Anteil_nicht_versiegelt: 58,
  },
  {
    id: 28,
    Jahr: 2020,
    Kategorie: 'Infrastrukturbauten',
    Anteil_nicht_versiegelt: 65,
  },
  {
    id: 29,
    Jahr: 2021,
    Kategorie: 'Infrastrukturbauten',
    Anteil_nicht_versiegelt: 65,
  },
];
