export const baujahrVv = [
  {
    id: 1,
    Baujahr: '1554',
    count: 1,
  },
  {
    id: 2,
    Baujahr: '1650',
    count: 1,
  },
  {
    id: 3,
    Baujahr: '1678',
    count: 1,
  },
  {
    id: 4,
    Baujahr: '1694',
    count: 2,
  },
  {
    id: 5,
    Baujahr: '1700',
    count: 1,
  },
  {
    id: 6,
    Baujahr: '1740',
    count: 2,
  },
  {
    id: 7,
    Baujahr: '1752',
    count: 1,
  },
  {
    id: 8,
    Baujahr: '1769',
    count: 1,
  },
  {
    id: 9,
    Baujahr: '1790',
    count: 1,
  },
  {
    id: 10,
    Baujahr: '1792',
    count: 1,
  },
  {
    id: 11,
    Baujahr: '1800',
    count: 3,
  },
  {
    id: 12,
    Baujahr: '1850',
    count: 2,
  },
  {
    id: 13,
    Baujahr: '1860',
    count: 1,
  },
  {
    id: 14,
    Baujahr: '1863',
    count: 2,
  },
  {
    id: 15,
    Baujahr: '1868',
    count: 2,
  },
  {
    id: 16,
    Baujahr: '1870',
    count: 2,
  },
  {
    id: 17,
    Baujahr: '1872',
    count: 1,
  },
  {
    id: 18,
    Baujahr: '1875',
    count: 1,
  },
  {
    id: 19,
    Baujahr: '1878',
    count: 1,
  },
  {
    id: 20,
    Baujahr: '1880',
    count: 1,
  },
  {
    id: 21,
    Baujahr: '1882',
    count: 2,
  },
  {
    id: 22,
    Baujahr: '1886',
    count: 1,
  },
  {
    id: 23,
    Baujahr: '1891',
    count: 1,
  },
  {
    id: 24,
    Baujahr: '1892',
    count: 1,
  },
  {
    id: 25,
    Baujahr: '1894',
    count: 1,
  },
  {
    id: 26,
    Baujahr: '1895',
    count: 1,
  },
  {
    id: 27,
    Baujahr: '1897',
    count: 5,
  },
  {
    id: 28,
    Baujahr: '1898',
    count: 1,
  },
  {
    id: 29,
    Baujahr: '1900',
    count: 20,
  },
  {
    id: 30,
    Baujahr: '1901',
    count: 1,
  },
  {
    id: 31,
    Baujahr: '1902',
    count: 1,
  },
  {
    id: 32,
    Baujahr: '1903',
    count: 2,
  },
  {
    id: 33,
    Baujahr: '1905',
    count: 3,
  },
  {
    id: 34,
    Baujahr: '1906',
    count: 1,
  },
  {
    id: 35,
    Baujahr: '1907',
    count: 4,
  },
  {
    id: 36,
    Baujahr: '1909',
    count: 3,
  },
  {
    id: 37,
    Baujahr: '1910',
    count: 3,
  },
  {
    id: 38,
    Baujahr: '1911',
    count: 2,
  },
  {
    id: 39,
    Baujahr: '1917',
    count: 2,
  },
  {
    id: 40,
    Baujahr: '1920',
    count: 2,
  },
  {
    id: 41,
    Baujahr: '1922',
    count: 3,
  },
  {
    id: 42,
    Baujahr: '1923',
    count: 3,
  },
  {
    id: 43,
    Baujahr: '1924',
    count: 1,
  },
  {
    id: 44,
    Baujahr: '1925',
    count: 2,
  },
  {
    id: 45,
    Baujahr: '1926',
    count: 2,
  },
  {
    id: 46,
    Baujahr: '1928',
    count: 2,
  },
  {
    id: 47,
    Baujahr: '1929',
    count: 2,
  },
  {
    id: 48,
    Baujahr: '1930',
    count: 1,
  },
  {
    id: 49,
    Baujahr: '1931',
    count: 2,
  },
  {
    id: 50,
    Baujahr: '1932',
    count: 1,
  },
  {
    id: 51,
    Baujahr: '1933',
    count: 2,
  },
  {
    id: 52,
    Baujahr: '1935',
    count: 2,
  },
  {
    id: 53,
    Baujahr: '1936',
    count: 2,
  },
  {
    id: 54,
    Baujahr: '1938',
    count: 2,
  },
  {
    id: 55,
    Baujahr: '1939',
    count: 1,
  },
  {
    id: 56,
    Baujahr: '1940',
    count: 2,
  },
  {
    id: 57,
    Baujahr: '1945',
    count: 1,
  },
  {
    id: 58,
    Baujahr: '1946',
    count: 2,
  },
  {
    id: 59,
    Baujahr: '1947',
    count: 6,
  },
  {
    id: 60,
    Baujahr: '1948',
    count: 7,
  },
  {
    id: 61,
    Baujahr: '1949',
    count: 6,
  },
  {
    id: 62,
    Baujahr: '1950',
    count: 7,
  },
  {
    id: 63,
    Baujahr: '1951',
    count: 3,
  },
  {
    id: 64,
    Baujahr: '1952',
    count: 5,
  },
  {
    id: 65,
    Baujahr: '1953',
    count: 14,
  },
  {
    id: 66,
    Baujahr: '1954',
    count: 8,
  },
  {
    id: 67,
    Baujahr: '1955',
    count: 7,
  },
  {
    id: 68,
    Baujahr: '1956',
    count: 1,
  },
  {
    id: 69,
    Baujahr: '1957',
    count: 8,
  },
  {
    id: 70,
    Baujahr: '1958',
    count: 5,
  },
  {
    id: 71,
    Baujahr: '1959',
    count: 1,
  },
  {
    id: 72,
    Baujahr: '1960',
    count: 2,
  },
  {
    id: 73,
    Baujahr: '1961',
    count: 8,
  },
  {
    id: 74,
    Baujahr: '1962',
    count: 8,
  },
  {
    id: 75,
    Baujahr: '1963',
    count: 3,
  },
  {
    id: 76,
    Baujahr: '1964',
    count: 3,
  },
  {
    id: 77,
    Baujahr: '1965',
    count: 2,
  },
  {
    id: 78,
    Baujahr: '1966',
    count: 5,
  },
  {
    id: 79,
    Baujahr: '1967',
    count: 1,
  },
  {
    id: 80,
    Baujahr: '1968',
    count: 1,
  },
  {
    id: 81,
    Baujahr: '1969',
    count: 1,
  },
  {
    id: 82,
    Baujahr: '1970',
    count: 6,
  },
  {
    id: 83,
    Baujahr: '1971',
    count: 8,
  },
  {
    id: 84,
    Baujahr: '1972',
    count: 7,
  },
  {
    id: 85,
    Baujahr: '1973',
    count: 5,
  },
  {
    id: 86,
    Baujahr: '1974',
    count: 4,
  },
  {
    id: 87,
    Baujahr: '1975',
    count: 4,
  },
  {
    id: 88,
    Baujahr: '1977',
    count: 2,
  },
  {
    id: 89,
    Baujahr: '1979',
    count: 2,
  },
  {
    id: 90,
    Baujahr: '1980',
    count: 2,
  },
  {
    id: 91,
    Baujahr: '1981',
    count: 1,
  },
  {
    id: 92,
    Baujahr: '1987',
    count: 1,
  },
  {
    id: 93,
    Baujahr: '1988',
    count: 1,
  },
  {
    id: 94,
    Baujahr: '1989',
    count: 1,
  },
  {
    id: 95,
    Baujahr: '1990',
    count: 1,
  },
  {
    id: 96,
    Baujahr: '1991',
    count: 1,
  },
  {
    id: 97,
    Baujahr: '1995',
    count: 3,
  },
  {
    id: 98,
    Baujahr: '1996',
    count: 1,
  },
  {
    id: 99,
    Baujahr: '1997',
    count: 4,
  },
  {
    id: 100,
    Baujahr: '2000',
    count: 1,
  },
  {
    id: 101,
    Baujahr: '2004',
    count: 1,
  },
  {
    id: 102,
    Baujahr: '2005',
    count: 2,
  },
  {
    id: 103,
    Baujahr: '2006',
    count: 1,
  },
  {
    id: 104,
    Baujahr: '2007',
    count: 2,
  },
  {
    id: 105,
    Baujahr: '2009',
    count: 1,
  },
  {
    id: 106,
    Baujahr: '2011',
    count: 1,
  },
  {
    id: 107,
    Baujahr: '2013',
    count: 1,
  },
  {
    id: 108,
    Baujahr: '2015',
    count: 2,
  },
  {
    id: 109,
    Baujahr: '2016',
    count: 2,
  },
  {
    id: 110,
    Baujahr: '2017',
    count: 1,
  },
  {
    id: 111,
    Baujahr: '2018',
    count: 2,
  },
  {
    id: 112,
    Baujahr: '2019',
    count: 3,
  },
  {
    id: 113,
    Baujahr: '2020',
    count: 3,
  },
  {
    id: 114,
    Baujahr: '2021',
    count: 1,
  },
];
