export const hindernisfreiFv = [
  {
    id: 1,
    Jahr: 2012,
    Kategorie: 'hindernisfreie Wohnungen',
    Anz_Hindernisfrei: 14,
  },
  {
    id: 2,
    Jahr: 2012,
    Kategorie: 'hindernisfreie Geschäftsräume',
    Anz_Hindernisfrei: 61,
  },
  {
    id: 3,
    Jahr: 2012,
    Kategorie: 'hindernisfreie Wohnungen (Landwirtschaft)',
    Anz_Hindernisfrei: 0,
  },
  {
    id: 4,
    Jahr: 2013,
    Kategorie: 'hindernisfreie Wohnungen',
    Anz_Hindernisfrei: 105,
  },
  {
    id: 5,
    Jahr: 2013,
    Kategorie: 'hindernisfreie Geschäftsräume',
    Anz_Hindernisfrei: 61,
  },
  {
    id: 6,
    Jahr: 2013,
    Kategorie: 'hindernisfreie Wohnungen (Landwirtschaft)',
    Anz_Hindernisfrei: 0,
  },
  {
    id: 7,
    Jahr: 2014,
    Kategorie: 'hindernisfreie Wohnungen',
    Anz_Hindernisfrei: 105,
  },
  {
    id: 8,
    Jahr: 2014,
    Kategorie: 'hindernisfreie Geschäftsräume',
    Anz_Hindernisfrei: 61,
  },
  {
    id: 9,
    Jahr: 2014,
    Kategorie: 'hindernisfreie Wohnungen (Landwirtschaft)',
    Anz_Hindernisfrei: 0,
  },
  {
    id: 10,
    Jahr: 2015,
    Kategorie: 'hindernisfreie Wohnungen',
    Anz_Hindernisfrei: 105,
  },
  {
    id: 11,
    Jahr: 2015,
    Kategorie: 'hindernisfreie Geschäftsräume',
    Anz_Hindernisfrei: 89,
  },
  {
    id: 12,
    Jahr: 2015,
    Kategorie: 'hindernisfreie Wohnungen (Landwirtschaft)',
    Anz_Hindernisfrei: 0,
  },
  {
    id: 13,
    Jahr: 2016,
    Kategorie: 'hindernisfreie Wohnungen',
    Anz_Hindernisfrei: 122,
  },
  {
    id: 14,
    Jahr: 2016,
    Kategorie: 'hindernisfreie Geschäftsräume',
    Anz_Hindernisfrei: 91,
  },
  {
    id: 15,
    Jahr: 2016,
    Kategorie: 'hindernisfreie Wohnungen (Landwirtschaft)',
    Anz_Hindernisfrei: 0,
  },
  {
    id: 16,
    Jahr: 2017,
    Kategorie: 'hindernisfreie Wohnungen',
    Anz_Hindernisfrei: 122,
  },
  {
    id: 17,
    Jahr: 2017,
    Kategorie: 'hindernisfreie Geschäftsräume',
    Anz_Hindernisfrei: 113,
  },
  {
    id: 18,
    Jahr: 2017,
    Kategorie: 'hindernisfreie Wohnungen (Landwirtschaft)',
    Anz_Hindernisfrei: 0,
  },
  {
    id: 19,
    Jahr: 2018,
    Kategorie: 'hindernisfreie Wohnungen',
    Anz_Hindernisfrei: 142,
  },
  {
    id: 20,
    Jahr: 2018,
    Kategorie: 'hindernisfreie Geschäftsräume',
    Anz_Hindernisfrei: 108,
  },
  {
    id: 21,
    Jahr: 2018,
    Kategorie: 'hindernisfreie Wohnungen (Landwirtschaft)',
    Anz_Hindernisfrei: 0,
  },
  {
    id: 22,
    Jahr: 2019,
    Kategorie: 'hindernisfreie Wohnungen',
    Anz_Hindernisfrei: 308,
  },
  {
    id: 23,
    Jahr: 2019,
    Kategorie: 'hindernisfreie Geschäftsräume',
    Anz_Hindernisfrei: 137,
  },
  {
    id: 24,
    Jahr: 2019,
    Kategorie: 'hindernisfreie Wohnungen (Landwirtschaft)',
    Anz_Hindernisfrei: 0,
  },
  {
    id: 25,
    Jahr: 2020,
    Kategorie: 'hindernisfreie Wohnungen',
    Anz_Hindernisfrei: 308,
  },
  {
    id: 26,
    Jahr: 2020,
    Kategorie: 'hindernisfreie Geschäftsräume',
    Anz_Hindernisfrei: 137,
  },
  {
    id: 27,
    Jahr: 2020,
    Kategorie: 'hindernisfreie Wohnungen (Landwirtschaft)',
    Anz_Hindernisfrei: 0,
  },
  {
    id: 28,
    Jahr: 2021,
    Kategorie: 'hindernisfreie Wohnungen',
    Anz_Hindernisfrei: 308,
  },
  {
    id: 29,
    Jahr: 2021,
    Kategorie: 'hindernisfreie Geschäftsräume',
    Anz_Hindernisfrei: 137,
  },
  {
    id: 30,
    Jahr: 2021,
    Kategorie: 'hindernisfreie Wohnungen (Landwirtschaft)',
    Anz_Hindernisfrei: 0,
  },
];
