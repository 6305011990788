export const autoplaetzeVvNeu = [
  {
    id: 1,
    Jahr: 2017,
    Kategorie: 'Verwaltungsgebäude',
    Autoplaetze: 5.88,
  },
  {
    id: 2,
    Jahr: 2018,
    Kategorie: 'Verwaltungsgebäude',
    Autoplaetze: 5.76,
  },
  {
    id: 3,
    Jahr: 2019,
    Kategorie: 'Verwaltungsgebäude',
    Autoplaetze: 6.13,
  },
  {
    id: 4,
    Jahr: 2020,
    Kategorie: 'Verwaltungsgebäude',
    Autoplaetze: 6.13,
  },
  {
    id: 5,
    Jahr: 2021,
    Kategorie: 'Verwaltungsgebäude',
    Autoplaetze: 9.59,
  },
  {
    id: 6,
    Jahr: 2017,
    Kategorie: 'Bildung',
    Autoplaetze: 1.91,
  },
  {
    id: 7,
    Jahr: 2018,
    Kategorie: 'Bildung',
    Autoplaetze: 1.88,
  },
  {
    id: 8,
    Jahr: 2019,
    Kategorie: 'Bildung',
    Autoplaetze: 1.93,
  },
  {
    id: 9,
    Jahr: 2020,
    Kategorie: 'Bildung',
    Autoplaetze: 2.27,
  },
  {
    id: 10,
    Jahr: 2021,
    Kategorie: 'Bildung',
    Autoplaetze: 2.35,
  },
  {
    id: 11,
    Jahr: 2017,
    Kategorie: 'Sport und Freizeit',
    Autoplaetze: 20.76,
  },
  {
    id: 12,
    Jahr: 2018,
    Kategorie: 'Sport und Freizeit',
    Autoplaetze: 20.76,
  },
  {
    id: 13,
    Jahr: 2019,
    Kategorie: 'Sport und Freizeit',
    Autoplaetze: 21.8,
  },
  {
    id: 14,
    Jahr: 2020,
    Kategorie: 'Sport und Freizeit',
    Autoplaetze: 21.8,
  },
  {
    id: 15,
    Jahr: 2021,
    Kategorie: 'Sport und Freizeit',
    Autoplaetze: 23.79,
  },
  {
    id: 16,
    Jahr: 2018,
    Kategorie: 'Wohnen und Aufenthalt',
    Autoplaetze: 1.11,
  },
  {
    id: 17,
    Jahr: 2019,
    Kategorie: 'Wohnen und Aufenthalt',
    Autoplaetze: 1,
  },
  {
    id: 18,
    Jahr: 2020,
    Kategorie: 'Wohnen und Aufenthalt',
    Autoplaetze: 1,
  },
  {
    id: 19,
    Jahr: 2021,
    Kategorie: 'Wohnen und Aufenthalt',
    Autoplaetze: 1.15,
  },
  {
    id: 20,
    Jahr: 2017,
    Kategorie: 'Kultur und Geselligkeit',
    Autoplaetze: 0,
  },
  {
    id: 21,
    Jahr: 2018,
    Kategorie: 'Kultur und Geselligkeit',
    Autoplaetze: 0,
  },
  {
    id: 22,
    Jahr: 2019,
    Kategorie: 'Kultur und Geselligkeit',
    Autoplaetze: 0,
  },
  {
    id: 23,
    Jahr: 2020,
    Kategorie: 'Kultur und Geselligkeit',
    Autoplaetze: 0,
  },
  {
    id: 24,
    Jahr: 2021,
    Kategorie: 'Kultur und Geselligkeit',
    Autoplaetze: 0,
  },
  {
    id: 25,
    Jahr: 2017,
    Kategorie: 'Infrastrukturbauten',
    Autoplaetze: 5,
  },
  {
    id: 26,
    Jahr: 2018,
    Kategorie: 'Infrastrukturbauten',
    Autoplaetze: 5,
  },
  {
    id: 27,
    Jahr: 2019,
    Kategorie: 'Infrastrukturbauten',
    Autoplaetze: 5.33,
  },
  {
    id: 28,
    Jahr: 2020,
    Kategorie: 'Infrastrukturbauten',
    Autoplaetze: 9.1,
  },
  {
    id: 29,
    Jahr: 2021,
    Kategorie: 'Infrastrukturbauten',
    Autoplaetze: 9.9,
  },
];
