export const handlungsbedarfFv = [
  {
    id: 1,
    Jahr: 2017,
    Kategorie: 'Ohne',
    Anz_Kein: 54,
    Anz_Absehbar: 155,
    Anz_Dringend: 53,
  },
  {
    id: 2,
    Jahr: 2018,
    Kategorie: 'Ohne',
    Anz_Kein: 59,
    Anz_Absehbar: 155,
    Anz_Dringend: 47,
  },
  {
    id: 3,
    Jahr: 2019,
    Kategorie: 'Ohne',
    Anz_Kein: 71,
    Anz_Absehbar: 169,
    Anz_Dringend: 48,
  },
  {
    id: 4,
    Jahr: 2020,
    Kategorie: 'Ohne',
    Anz_Kein: 70,
    Anz_Absehbar: 178,
    Anz_Dringend: 50,
  },
  {
    id: 5,
    Jahr: 2021,
    Kategorie: 'Ohne',
    Anz_Kein: 76,
    Anz_Absehbar: 178,
    Anz_Dringend: 49,
  },
];
