<mat-dialog-content>
  <app-filter
    [labels]="labels"
    [years]="years"
    [categories]="categories"
    [selectedBuildings]="categoriesFiltered"
    [selectedYears]="yearsFiltered"
    [selectedCategories]="labelsFiltered"
    [useNewSystem]="useNewSystem"
    [showThirdFilter]="showThirdFilter"
    [showNewSystemFilter]="showNewSystemFilter"
    [hideYearFilter]="hideYearFilter"
    (newItemEvent)="changeFilter($event)"
  ></app-filter>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-raised-button [mat-dialog-close]="changes">Anwenden</button>
  <button mat-raised-button mat-accent (click)="abort()">Abbrechen</button>
</mat-dialog-actions>
