<div class="header-logo-title">
  <img
    height="80%"
    class="logo"
    src="assets/Immobilien_Stadt_Bern.svg"
    alt="Logo Immobilien Stadt Bern"
  />
  <span
    ><div>Nachhaltiges Immobilienmanagement</div>
    <div class="spacer"></div>
    <button id="hamburgerMenu" mat-button [matMenuTriggerFor]="menu">
      <mat-icon>menu</mat-icon>
    </button></span
  >
</div>
<mat-toolbar id="secondToolbar" color="primary">
  <button
    class="home"
    mat-button
    routerLink="/home"
    routerLinkActive="is-active"
  >
    <mat-icon>home</mat-icon>
  </button>
  <button
    class="toolbar-item"
    [ngClass]="{
      'is-active':
        rlaNachhaltigkeit1.isActive ||
        rlaNachhaltigkeit2.isActive ||
        rlaNachhaltigkeit3.isActive
    }"
    mat-menu-item
    [matMenuTriggerFor]="mobileNachhaltigkeit"
  >
    NACHHALTIGKEIT
  </button>
  <button
    class="toolbar-item"
    [ngClass]="{
      'is-active': rlaGesellschaft1.isActive || rlaGesellschaft2.isActive
    }"
    mat-menu-item
    [matMenuTriggerFor]="mobileGesellschaft"
  >
    GESELLSCHAFT
  </button>
  <button
    class="toolbar-item"
    [ngClass]="{ 'is-active': rlaWirtschaft.isActive }"
    mat-menu-item
    [matMenuTriggerFor]="mobileWirtschaft"
  >
    WIRTSCHAFT
  </button>
  <button
    class="toolbar-item"
    mat-menu-item
    [matMenuTriggerFor]="mobileUmwelt"
    [ngClass]="{
      'is-active':
        rlaUmwelt.isActive ||
        rlaUmweltEnergie1.isActive ||
        rlaUmweltEnergie2.isActive ||
        rlaUmweltParkplatz1.isActive ||
        rlaUmweltParkplatz2.isActive
    }"
  >
    UMWELT
  </button>
  <button
    class="toolbar-item"
    mat-menu-item
    [matMenuTriggerFor]="mobileKontakt"
    [ngClass]="{
      'is-active':
        rlaKontaktImpressum.isActive || rlaKontaktDatenschutz.isActive
    }"
  >
    KONTAKT
  </button>

  <button id="hamburgerMenu" mat-button [matMenuTriggerFor]="menu">
    <mat-icon>menu</mat-icon>
  </button>

  <mat-menu #parking="matMenu"
    ><button
      mat-menu-item
      routerLink="/veloplaetze"
      routerLinkActive="is-active"
    >
      Veloparkplätze
    </button>
    <button
      mat-menu-item
      routerLink="/autoplaetze"
      routerLinkActive="is-active"
    >
      Autoparkplätze
    </button>
  </mat-menu>
</mat-toolbar>

<mat-menu #menu="matMenu">
  <button mat-menu-item routerLink="/home" routerLinkActive="is-active-menu">
    <mat-icon>home</mat-icon>HOME
  </button>
  <button
    mat-menu-item
    [ngClass]="{
      'is-active-menu':
        rlaNachhaltigkeit1.isActive || rlaNachhaltigkeit2.isActive
    }"
    [matMenuTriggerFor]="mobileNachhaltigkeit"
  >
    NACHHALTIGKEIT
  </button>
  <button
    mat-menu-item
    [matMenuTriggerFor]="mobileGesellschaft"
    [ngClass]="{
      'is-active-menu': rlaGesellschaft1.isActive || rlaGesellschaft2.isActive
    }"
  >
    GESELLSCHAFT
  </button>
  <button
    mat-menu-item
    [matMenuTriggerFor]="mobileWirtschaft"
    [ngClass]="{
      'is-active-menu': rlaWirtschaft.isActive
    }"
  >
    WIRTSCHAFT
  </button>
  <button
    mat-menu-item
    [matMenuTriggerFor]="mobileUmwelt"
    [ngClass]="{
      'is-active-menu':
        rlaUmwelt.isActive ||
        rlaUmweltEnergie1.isActive ||
        rlaUmweltEnergie2.isActive ||
        rlaUmweltParkplatz1.isActive ||
        rlaUmweltParkplatz2.isActive
    }"
  >
    UMWELT
  </button>
  <button
    mat-menu-item
    [matMenuTriggerFor]="mobileKontakt"
    [ngClass]="{
      'is-active-menu':
        rlaKontaktImpressum.isActive || rlaKontaktDatenschutz.isActive
    }"
  >
    KONTAKT
  </button>
</mat-menu>

<mat-menu #mobileNachhaltigkeit="matMenu">
  <button
    mat-menu-item
    routerLink="/nachhaltigkeitsrosette"
    routerLinkActive="is-active-menu"
    #rlaNachhaltigkeit1="routerLinkActive"
  >
    Nachhaltigkeitsrosette
  </button>
  <button
    mat-menu-item
    routerLink="/ausgewertete"
    routerLinkActive="is-active-menu"
    #rlaNachhaltigkeit2="routerLinkActive"
  >
    Ausgewertete Gebäude
  </button>
  <button
    mat-menu-item
    routerLink="/baujahre"
    routerLinkActive="is-active-menu"
    #rlaNachhaltigkeit3="routerLinkActive"
  >
    Baujahre
  </button>
</mat-menu>
<mat-menu #mobileGesellschaft="matMenu">
  <button
    mat-menu-item
    routerLink="/denkmalpflege"
    routerLinkActive="is-active-menu"
    #rlaGesellschaft1="routerLinkActive"
  >
    Denkmalpflege
  </button>
  <button
    mat-menu-item
    routerLink="/hindernisfrei"
    routerLinkActive="is-active-menu"
    #rlaGesellschaft2="routerLinkActive"
  >
    Hindernisfreier Zugang
  </button>
</mat-menu>
<mat-menu #mobileWirtschaft="matMenu">
  <button
    mat-menu-item
    routerLink="/handlungsbedarf"
    routerLinkActive="is-active-menu"
    #rlaWirtschaft="routerLinkActive"
  >
    Handlungsbedarf
  </button>
</mat-menu>
<mat-menu #mobileUmwelt="matMenu">
  <button
    mat-menu-item
    [matMenuTriggerFor]="mobileUmweltEnergie"
    [ngClass]="{
      'is-active-menu':
        rlaUmweltEnergie1.isActive ||
        rlaUmweltEnergie2.isActive 
   
    }"
  >
    Energie
  </button>
  <button
    mat-menu-item
    [matMenuTriggerFor]="mobileUmweltParkplaetze"
    [ngClass]="{
      'is-active-menu':
        rlaUmweltParkplatz1.isActive || rlaUmweltParkplatz2.isActive
    }"
  >
    Parkplätze
  </button>
  <button
    mat-menu-item
    routerLink="/versFlaechen"
    routerLinkActive="is-active-menu"
    #rlaUmwelt="routerLinkActive"
  >
    Unversiegelte Flächen
  </button>
</mat-menu>
<mat-menu #mobileUmweltEnergie="matMenu">
  <button
    mat-menu-item
    routerLink="/energietraeger"
    routerLinkActive="is-active-menu"
    #rlaUmweltEnergie1="routerLinkActive"
  >
    Energieträger
  </button>
  <button
    mat-menu-item
    routerLink="/erneuerbareEnergien"
    routerLinkActive="is-active-menu"
    #rlaUmweltEnergie2="routerLinkActive"
  >
    Erneuerbare Energie
  </button>
<!--   <button
    mat-menu-item
    routerLink="/prog-energietraeger"
    routerLinkActive="is-active-menu"
    #rlaUmweltEnergie3="routerLinkActive"
  >
    Energieträger Prognose 2035
  </button> -->
<!--   <button
    mat-menu-item
    routerLink="/prog-photovoltaik"
    routerLinkActive="is-active-menu"
    #rlaUmweltEnergie4="routerLinkActive"
  >
    Photovoltaik Prognose 2035
  </button> -->
</mat-menu>
<mat-menu #mobileUmweltParkplaetze="matMenu">
  <button
    mat-menu-item
    routerLink="/veloplaetze"
    routerLinkActive="is-active-menu"
    #rlaUmweltParkplatz1="routerLinkActive"
  >
    Veloparkplätze
  </button>
  <button
    mat-menu-item
    routerLink="/autoplaetze"
    routerLinkActive="is-active-menu"
    #rlaUmweltParkplatz2="routerLinkActive"
  >
    Autoparkplätze
  </button>
</mat-menu>
<mat-menu #mobileKontakt="matMenu">
  <button
    mat-menu-item
    routerLink="/impressum"
    routerLinkActive="is-active-menu"
    #rlaKontaktImpressum="routerLinkActive"
  >
    Impressum
  </button>
  <button
    mat-menu-item
    routerLink="/datenschutz"
    routerLinkActive="is-active-menu"
    #rlaKontaktDatenschutz="routerLinkActive"
  >
    Rechtliche Hinweise / Datenschutz
  </button>
</mat-menu>
