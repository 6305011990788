export const vrsgFlaechenFv = [
  {
    id: 1,
    Jahr: 2014,
    Kategorie: 'Wohnliegenschaften',
    Anteil_nicht_versiegelt: 90.88,
  },
  {
    id: 2,
    Jahr: 2014,
    Kategorie: 'Geschäftsliegenschaften',
    Anteil_nicht_versiegelt: 57.5,
  },
  {
    id: 3,
    Jahr: 2014,
    Kategorie: 'Landwirtschaft',
    Anteil_nicht_versiegelt: 95,
  },
  {
    id: 4,
    Jahr: 2015,
    Kategorie: 'Wohnliegenschaften',
    Anteil_nicht_versiegelt: 89.87,
  },
  {
    id: 5,
    Jahr: 2015,
    Kategorie: 'Geschäftsliegenschaften',
    Anteil_nicht_versiegelt: 59.65,
  },
  {
    id: 6,
    Jahr: 2015,
    Kategorie: 'Landwirtschaft',
    Anteil_nicht_versiegelt: 95,
  },
  {
    id: 7,
    Jahr: 2016,
    Kategorie: 'Wohnliegenschaften',
    Anteil_nicht_versiegelt: 88.98,
  },
  {
    id: 8,
    Jahr: 2016,
    Kategorie: 'Geschäftsliegenschaften',
    Anteil_nicht_versiegelt: 62.51,
  },
  {
    id: 9,
    Jahr: 2016,
    Kategorie: 'Landwirtschaft',
    Anteil_nicht_versiegelt: 95,
  },
  {
    id: 10,
    Jahr: 2017,
    Kategorie: 'Wohnliegenschaften',
    Anteil_nicht_versiegelt: 89.24,
  },
  {
    id: 11,
    Jahr: 2017,
    Kategorie: 'Geschäftsliegenschaften',
    Anteil_nicht_versiegelt: 59.13,
  },
  {
    id: 12,
    Jahr: 2017,
    Kategorie: 'Landwirtschaft',
    Anteil_nicht_versiegelt: 95,
  },
  {
    id: 13,
    Jahr: 2018,
    Kategorie: 'Wohnliegenschaften',
    Anteil_nicht_versiegelt: 88.65,
  },
  {
    id: 14,
    Jahr: 2018,
    Kategorie: 'Geschäftsliegenschaften',
    Anteil_nicht_versiegelt: 61.62,
  },
  {
    id: 15,
    Jahr: 2018,
    Kategorie: 'Landwirtschaft',
    Anteil_nicht_versiegelt: 95,
  },
  {
    id: 16,
    Jahr: 2019,
    Kategorie: 'Wohnliegenschaften',
    Anteil_nicht_versiegelt: 87.18,
  },
  {
    id: 17,
    Jahr: 2019,
    Kategorie: 'Geschäftsliegenschaften',
    Anteil_nicht_versiegelt: 58.43,
  },
  {
    id: 18,
    Jahr: 2019,
    Kategorie: 'Landwirtschaft',
    Anteil_nicht_versiegelt: 95,
  },
  {
    id: 19,
    Jahr: 2020,
    Kategorie: 'Wohnliegenschaften',
    Anteil_nicht_versiegelt: 86.19,
  },
  {
    id: 20,
    Jahr: 2020,
    Kategorie: 'Geschäftsliegenschaften',
    Anteil_nicht_versiegelt: 60.35,
  },
  {
    id: 21,
    Jahr: 2020,
    Kategorie: 'Landwirtschaft',
    Anteil_nicht_versiegelt: 95,
  },
  {
    id: 22,
    Jahr: 2021,
    Kategorie: 'Wohnliegenschaften',
    Anteil_nicht_versiegelt: 86,
  },
  {
    id: 23,
    Jahr: 2021,
    Kategorie: 'Geschäftsliegenschaften',
    Anteil_nicht_versiegelt: 59,
  },
  {
    id: 24,
    Jahr: 2021,
    Kategorie: 'Landwirtschaft',
    Anteil_nicht_versiegelt: 95,
  },
];
