export const ernEnergienVv = [
  {
    id: 1,
    Jahr: 2015,
    Kategorie: 'Verwaltungsgebäude',
    Anz_Erneuerbar: 16,
    Anz_Nicht_Erneuerbar: 4,
  },
  {
    id: 2,
    Jahr: 2015,
    Kategorie: 'Schulen',
    Anz_Erneuerbar: 65,
    Anz_Nicht_Erneuerbar: 91,
  },
  {
    id: 3,
    Jahr: 2016,
    Kategorie: 'Verwaltungsgebäude',
    Anz_Erneuerbar: 14,
    Anz_Nicht_Erneuerbar: 6,
  },
  {
    id: 4,
    Jahr: 2016,
    Kategorie: 'Schulen',
    Anz_Erneuerbar: 72,
    Anz_Nicht_Erneuerbar: 94,
  },
  {
    id: 5,
    Jahr: 2016,
    Kategorie: 'Sportanlagen',
    Anz_Erneuerbar: 6,
    Anz_Nicht_Erneuerbar: 10,
  },
  {
    id: 6,
    Jahr: 2016,
    Kategorie: 'Eis-Wasseranlagen',
    Anz_Erneuerbar: 5,
    Anz_Nicht_Erneuerbar: 6,
  },
  {
    id: 7,
    Jahr: 2017,
    Kategorie: 'Verwaltungsgebäude',
    Anz_Erneuerbar: 14,
    Anz_Nicht_Erneuerbar: 6,
  },
  {
    id: 8,
    Jahr: 2017,
    Kategorie: 'Schulen',
    Anz_Erneuerbar: 75,
    Anz_Nicht_Erneuerbar: 92,
  },
  {
    id: 9,
    Jahr: 2017,
    Kategorie: 'Sportanlagen',
    Anz_Erneuerbar: 7,
    Anz_Nicht_Erneuerbar: 12,
  },
  {
    id: 10,
    Jahr: 2017,
    Kategorie: 'Eis-Wasseranlagen',
    Anz_Erneuerbar: 5,
    Anz_Nicht_Erneuerbar: 6,
  },
  {
    id: 11,
    Jahr: 2017,
    Kategorie: 'Kulturgebäude',
    Anz_Erneuerbar: 3,
    Anz_Nicht_Erneuerbar: 1,
  },
  {
    id: 12,
    Jahr: 2017,
    Kategorie: 'Werkhöfe',
    Anz_Erneuerbar: 6,
    Anz_Nicht_Erneuerbar: 13,
  },
  {
    id: 13,
    Jahr: 2018,
    Kategorie: 'Verwaltungsgebäude',
    Anz_Erneuerbar: 14,
    Anz_Nicht_Erneuerbar: 6,
  },
  {
    id: 14,
    Jahr: 2018,
    Kategorie: 'Schulen',
    Anz_Erneuerbar: 85,
    Anz_Nicht_Erneuerbar: 90,
  },
  {
    id: 15,
    Jahr: 2018,
    Kategorie: 'Sportanlagen',
    Anz_Erneuerbar: 8,
    Anz_Nicht_Erneuerbar: 12,
  },
  {
    id: 16,
    Jahr: 2018,
    Kategorie: 'Eis-Wasseranlagen',
    Anz_Erneuerbar: 5,
    Anz_Nicht_Erneuerbar: 6,
  },
  {
    id: 17,
    Jahr: 2018,
    Kategorie: 'Kulturgebäude',
    Anz_Erneuerbar: 4,
    Anz_Nicht_Erneuerbar: 1,
  },
  {
    id: 18,
    Jahr: 2018,
    Kategorie: 'Werkhöfe',
    Anz_Erneuerbar: 6,
    Anz_Nicht_Erneuerbar: 13,
  },
  {
    id: 19,
    Jahr: 2018,
    Kategorie: 'Jugend-/Quartiertreffs',
    Anz_Erneuerbar: 2,
    Anz_Nicht_Erneuerbar: 6,
  },
  {
    id: 20,
    Jahr: 2018,
    Kategorie: 'Wohnhäuser/-heime',
    Anz_Erneuerbar: 1,
    Anz_Nicht_Erneuerbar: 6,
  },
  {
    id: 21,
    Jahr: 2019,
    Kategorie: 'Verwaltungsgebäude',
    Anz_Erneuerbar: 14,
    Anz_Nicht_Erneuerbar: 5,
  },
  {
    id: 22,
    Jahr: 2019,
    Kategorie: 'Schulen',
    Anz_Erneuerbar: 96,
    Anz_Nicht_Erneuerbar: 80,
  },
  {
    id: 23,
    Jahr: 2019,
    Kategorie: 'Sportanlagen',
    Anz_Erneuerbar: 7,
    Anz_Nicht_Erneuerbar: 12,
  },
  {
    id: 24,
    Jahr: 2019,
    Kategorie: 'Eis-Wasseranlagen',
    Anz_Erneuerbar: 5,
    Anz_Nicht_Erneuerbar: 6,
  },
  {
    id: 25,
    Jahr: 2019,
    Kategorie: 'Kulturgebäude',
    Anz_Erneuerbar: 4,
    Anz_Nicht_Erneuerbar: 1,
  },
  {
    id: 26,
    Jahr: 2019,
    Kategorie: 'Werkhöfe',
    Anz_Erneuerbar: 6,
    Anz_Nicht_Erneuerbar: 12,
  },
  {
    id: 27,
    Jahr: 2019,
    Kategorie: 'Jugend-/Quartiertreffs',
    Anz_Erneuerbar: 2,
    Anz_Nicht_Erneuerbar: 6,
  },
  {
    id: 28,
    Jahr: 2019,
    Kategorie: 'Wohnhäuser/-heime',
    Anz_Erneuerbar: 2,
    Anz_Nicht_Erneuerbar: 6,
  },
  {
    id: 29,
    Jahr: 2019,
    Kategorie: 'Restaurants',
    Anz_Erneuerbar: 0,
    Anz_Nicht_Erneuerbar: 4,
  },
  {
    id: 30,
    Jahr: 2020,
    Kategorie: 'Verwaltungsgebäude',
    Anz_Erneuerbar: 15,
    Anz_Nicht_Erneuerbar: 5,
  },
  {
    id: 31,
    Jahr: 2020,
    Kategorie: 'Schulen',
    Anz_Erneuerbar: 110,
    Anz_Nicht_Erneuerbar: 80,
  },
  {
    id: 32,
    Jahr: 2020,
    Kategorie: 'Sportanlagen',
    Anz_Erneuerbar: 7,
    Anz_Nicht_Erneuerbar: 12,
  },
  {
    id: 33,
    Jahr: 2020,
    Kategorie: 'Eis-Wasseranlagen',
    Anz_Erneuerbar: 5,
    Anz_Nicht_Erneuerbar: 6,
  },
  {
    id: 34,
    Jahr: 2020,
    Kategorie: 'Kulturgebäude',
    Anz_Erneuerbar: 4,
    Anz_Nicht_Erneuerbar: 1,
  },
  {
    id: 35,
    Jahr: 2020,
    Kategorie: 'Werkhöfe',
    Anz_Erneuerbar: 8,
    Anz_Nicht_Erneuerbar: 19,
  },
  {
    id: 36,
    Jahr: 2020,
    Kategorie: 'Jugend-/Quartiertreffs',
    Anz_Erneuerbar: 2,
    Anz_Nicht_Erneuerbar: 6,
  },
  {
    id: 37,
    Jahr: 2020,
    Kategorie: 'Wohnhäuser/-heime',
    Anz_Erneuerbar: 2,
    Anz_Nicht_Erneuerbar: 6,
  },
  {
    id: 38,
    Jahr: 2020,
    Kategorie: 'Restaurants',
    Anz_Erneuerbar: 0,
    Anz_Nicht_Erneuerbar: 4,
  },
  {
    id: 39,
    Jahr: 2021,
    Kategorie: 'Verwaltungsgebäude',
    Anz_Erneuerbar: 15,
    Anz_Nicht_Erneuerbar: 7,
  },
  {
    id: 40,
    Jahr: 2021,
    Kategorie: 'Schulen',
    Anz_Erneuerbar: 115,
    Anz_Nicht_Erneuerbar: 77,
  },
  {
    id: 41,
    Jahr: 2021,
    Kategorie: 'Sportanlagen',
    Anz_Erneuerbar: 8,
    Anz_Nicht_Erneuerbar: 11,
  },
  {
    id: 42,
    Jahr: 2021,
    Kategorie: 'Eis-Wasseranlagen',
    Anz_Erneuerbar: 5,
    Anz_Nicht_Erneuerbar: 6,
  },
  {
    id: 43,
    Jahr: 2021,
    Kategorie: 'Kulturgebäude',
    Anz_Erneuerbar: 4,
    Anz_Nicht_Erneuerbar: 1,
  },
  {
    id: 44,
    Jahr: 2021,
    Kategorie: 'Werkhöfe',
    Anz_Erneuerbar: 9,
    Anz_Nicht_Erneuerbar: 23,
  },
  {
    id: 45,
    Jahr: 2021,
    Kategorie: 'Jugend-/Quartiertreffs',
    Anz_Erneuerbar: 2,
    Anz_Nicht_Erneuerbar: 6,
  },
  {
    id: 46,
    Jahr: 2021,
    Kategorie: 'Wohnhäuser/-heime',
    Anz_Erneuerbar: 3,
    Anz_Nicht_Erneuerbar: 8,
  },
  {
    id: 47,
    Jahr: 2021,
    Kategorie: 'Restaurants',
    Anz_Erneuerbar: 0,
    Anz_Nicht_Erneuerbar: 3,
  },
];
