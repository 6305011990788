export const denkmalpflegeVvNeu = [
  {
    id: 1,
    Jahr: 2019,
    Kategorie: 'Verwaltungsgebäude',
    Beachtenswert: 0,
    Erhaltenswert: 2,
    Schuetzenswert: 3,
    Nicht_Inventarisiert: 3,
    WorldHeritage: 8,
    Gebaeude_Nach_1990: 0,
  },
  {
    id: 2,
    Jahr: 2019,
    Kategorie: 'Bildung',
    Beachtenswert: 37,
    Erhaltenswert: 33,
    Schuetzenswert: 74,
    Nicht_Inventarisiert: 33,
    WorldHeritage: 12,
    Gebaeude_Nach_1990: 6,
  },
  {
    id: 3,
    Jahr: 2019,
    Kategorie: 'Sport und Freizeit',
    Beachtenswert: 3,
    Erhaltenswert: 2,
    Schuetzenswert: 3,
    Nicht_Inventarisiert: 15,
    WorldHeritage: 0,
    Gebaeude_Nach_1990: 3,
  },
  {
    id: 4,
    Jahr: 2019,
    Kategorie: 'Wohnen und Aufenthalt',
    Beachtenswert: 1,
    Erhaltenswert: 1,
    Schuetzenswert: 1,
    Nicht_Inventarisiert: 6,
    WorldHeritage: 1,
    Gebaeude_Nach_1990: 0,
  },
  {
    id: 5,
    Jahr: 2019,
    Kategorie: 'Kultur und Geselligkeit',
    Beachtenswert: 2,
    Erhaltenswert: 1,
    Schuetzenswert: 9,
    Nicht_Inventarisiert: 4,
    WorldHeritage: 6,
    Gebaeude_Nach_1990: 0,
  },
  {
    id: 6,
    Jahr: 2019,
    Kategorie: 'Infrastrukturbauten',
    Beachtenswert: 3,
    Erhaltenswert: 0,
    Schuetzenswert: 0,
    Nicht_Inventarisiert: 17,
    WorldHeritage: 0,
    Gebaeude_Nach_1990: 0,
  },
  {
    id: 7,
    Jahr: 2020,
    Kategorie: 'Verwaltungsgebäude',
    Beachtenswert: 0,
    Erhaltenswert: 2,
    Schuetzenswert: 3,
    Nicht_Inventarisiert: 3,
    WorldHeritage: 8,
    Gebaeude_Nach_1990: 0,
  },
  {
    id: 8,
    Jahr: 2020,
    Kategorie: 'Bildung',
    Beachtenswert: 38,
    Erhaltenswert: 36,
    Schuetzenswert: 77,
    Nicht_Inventarisiert: 34,
    WorldHeritage: 12,
    Gebaeude_Nach_1990: 6,
  },
  {
    id: 9,
    Jahr: 2020,
    Kategorie: 'Sport und Freizeit',
    Beachtenswert: 3,
    Erhaltenswert: 2,
    Schuetzenswert: 3,
    Nicht_Inventarisiert: 15,
    WorldHeritage: 0,
    Gebaeude_Nach_1990: 3,
  },
  {
    id: 10,
    Jahr: 2020,
    Kategorie: 'Wohnen und Aufenthalt',
    Beachtenswert: 1,
    Erhaltenswert: 1,
    Schuetzenswert: 1,
    Nicht_Inventarisiert: 6,
    WorldHeritage: 1,
    Gebaeude_Nach_1990: 0,
  },
  {
    id: 11,
    Jahr: 2020,
    Kategorie: 'Kultur und Geselligkeit',
    Beachtenswert: 2,
    Erhaltenswert: 1,
    Schuetzenswert: 9,
    Nicht_Inventarisiert: 4,
    WorldHeritage: 6,
    Gebaeude_Nach_1990: 0,
  },
  {
    id: 12,
    Jahr: 2020,
    Kategorie: 'Infrastrukturbauten',
    Beachtenswert: 3,
    Erhaltenswert: 2,
    Schuetzenswert: 3,
    Nicht_Inventarisiert: 25,
    WorldHeritage: 0,
    Gebaeude_Nach_1990: 2,
  },
  {
    id: 13,
    Jahr: 2021,
    Kategorie: 'Verwaltungsgebäude',
    Beachtenswert: 0,
    Erhaltenswert: 2,
    Schuetzenswert: 3,
    Nicht_Inventarisiert: 4,
    WorldHeritage: 8,
    Gebaeude_Nach_1990: 0,
  },
  {
    id: 14,
    Jahr: 2021,
    Kategorie: 'Bildung',
    Beachtenswert: 37,
    Erhaltenswert: 36,
    Schuetzenswert: 67,
    Nicht_Inventarisiert: 36,
    WorldHeritage: 10,
    Gebaeude_Nach_1990: 7,
  },
  {
    id: 15,
    Jahr: 2021,
    Kategorie: 'Sport und Freizeit',
    Beachtenswert: 3,
    Erhaltenswert: 2,
    Schuetzenswert: 3,
    Nicht_Inventarisiert: 14,
    WorldHeritage: 0,
    Gebaeude_Nach_1990: 3,
  },
  {
    id: 16,
    Jahr: 2021,
    Kategorie: 'Wohnen und Aufenthalt',
    Beachtenswert: 3,
    Erhaltenswert: 1,
    Schuetzenswert: 1,
    Nicht_Inventarisiert: 6,
    WorldHeritage: 2,
    Gebaeude_Nach_1990: 0,
  },
  {
    id: 17,
    Jahr: 2021,
    Kategorie: 'Kultur und Geselligkeit',
    Beachtenswert: 2,
    Erhaltenswert: 1,
    Schuetzenswert: 9,
    Nicht_Inventarisiert: 4,
    WorldHeritage: 6,
    Gebaeude_Nach_1990: 0,
  },
  {
    id: 18,
    Jahr: 2021,
    Kategorie: 'Infrastrukturbauten',
    Beachtenswert: 4,
    Erhaltenswert: 3,
    Schuetzenswert: 3,
    Nicht_Inventarisiert: 26,
    WorldHeritage: 0,
    Gebaeude_Nach_1990: 2,
  },
];
