<div class="visualization">
  <h1>Nachhaltigkeitsrosette</h1>
  <mat-tab-group>
    <mat-tab label="Finanz-/Fondsvermögen">
      <button
        mat-icon-button
        aria-label="Informationen"
        (click)="onInfoButtonTap('nachhaltigkeitsrosette.fv')"
        class="info-button"
      >
        <mat-icon>info_button</mat-icon>
      </button>
      <div class="filter">
        <mat-form-field class="select-form">
          <mat-label>Gebäudetyp</mat-label>
          <mat-select
            [(ngModel)]="selectedBuildingFV"
            name="buildingTypeFV"
            class="filter-select"
            (selectionChange)="selectionChangeBuildingFV($event)"
          >
            <mat-option *ngFor="let type of buildingTypesFV" [value]="type">
              {{ type }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="select-form">
          <mat-label>Jahr</mat-label>
          <mat-select
            [(ngModel)]="selectedYearFV"
            name="yearsFv"
            multiple
            class="filter-select"
            (selectionChange)="selectionChangeYearFV($event)"
            #yearSelectFV="ngModel"
            ><mat-optgroup
              ><button
                mat-raised-button
                class="mat-accent fill text-sm"
                (click)="deselectAllFV(yearSelectFV)"
              >
                Zurücksetzen
              </button></mat-optgroup
            >
            <mat-option *ngFor="let year of yearsFv" [value]="year">
              {{ year }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <button
          mat-raised-button
          color="warn"
          style="margin-left: 1vw"
          (click)="resetFilterFV(yearSelectFV)"
        >
          zurücksetzen
        </button>
      </div>
      <div class="filterList">
        Ausgewählte Filter:
        <span *ngIf="selectedBuildingFV !== 'Gesamt'">
          {{ selectedBuildingFV }}
        </span>
        <span *ngFor="let year of selectedYearFV"> {{ year }} </span>
      </div>
      <app-data-legend-table
        [dataLoaded]="true"
        [labels]="labelsFvFiltered"
      ></app-data-legend-table>
      <div class="container">
        <div class="flex-20" flex-order="2">
          <div *ngFor="let label of labels">
            <p>{{ label }}<br /></p>
          </div>
          <div class="areaLegends">
            <div class="areaLegend">
              <div
                class="colorRectangleArea"
                [ngStyle]="getColor('Keine Nachhaltigkeit')"
              ></div>
              <div style="float: left">Keine Nachhaltigkeit</div>
            </div>
            <div class="areaLegend">
              <div
                class="colorRectangleArea"
                [ngStyle]="getColor('Starke bis geringe Nachhaltigkeit')"
              ></div>
              <div style="float: left">Starke bis geringe Nachhaltigkeit</div>
            </div>
          </div>
        </div>

        <div class="flex" flex-order="1">
          <div class="chart-container">
            <canvas
              id="chart"
              baseChart
              [ngStyle]="{ height: chartHeight }"
              [datasets]="radarChartDataFV"
              [options]="radarChartOptions"
              [labels]="radarChartLabels"
              [chartType]="radarChartType"
            >
            </canvas>
          </div>
        </div>
      </div>
    </mat-tab>
    <mat-tab label="Verwaltungsvermögen">
      <button
        mat-icon-button
        aria-label="Informationen"
        (click)="
          onInfoButtonTap(
            useNewSystem
              ? 'nachhaltigkeitsrosette.vv.neu'
              : 'nachhaltigkeitsrosette.vv.alt'
          )
        "
        class="info-button"
      >
        <mat-icon>info_button</mat-icon>
      </button>
      <div class="filter">
        <mat-form-field class="select-form">
          <mat-label>Gebäudetyp</mat-label>
          <mat-select
            [(ngModel)]="selectedBuildingVV"
            name="buildingTypeVV"
            class="filter-select"
            (selectionChange)="selectionChangeBuildingVV($event)"
          >
            <mat-option *ngFor="let type of buildingTypesVV" [value]="type">
              {{ type }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="select-form">
          <mat-label>Jahr</mat-label>
          <mat-select
            [(ngModel)]="selectedYearVV"
            name="yearsVv"
            multiple
            class="filter-select"
            (selectionChange)="selectionChangeYearVV($event)"
            #yearSelectVV="ngModel"
            ><mat-optgroup
              ><button
                mat-raised-button
                class="mat-accent fill text-sm"
                (click)="deselectAllVV(yearSelectVV)"
              >
                Zurücksetzen
              </button></mat-optgroup
            >
            <mat-option *ngFor="let year of yearsVv" [value]="year">
              {{ year }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="select-form">
          <mat-select
            placeholder="System"
            name="system"
            class="filter-select"
            [(ngModel)]="selectedSystem"
            #csystemSelect="ngModel"
            (selectionChange)="selectionChangeSystem($event)"
          >
            <mat-option *ngFor="let system of systems" [value]="system">{{
              system
            }}</mat-option>
          </mat-select>
        </mat-form-field>
        <button
          mat-raised-button
          color="warn"
          style="margin-left: 1vw"
          (click)="resetFilterVV(yearSelectVV)"
        >
          zurücksetzen
        </button>
      </div>
      <div class="filterList">
        Ausgewählte Filter:
        <span *ngIf="selectedBuildingVV !== 'Gesamt'">
          {{ selectedBuildingVV }}
        </span>
        <span *ngFor="let year of selectedYearVV"> {{ year }} </span>
      </div>
      <app-data-legend-table
        [dataLoaded]="true"
        [labels]="labelsVvFiltered"
      ></app-data-legend-table>
      <div class="container">
        <div class="flex-20">
          <div *ngFor="let label of labels">
            <p>{{ label }}</p>
          </div>
          <div class="areaLegends">
            <div class="areaLegend">
              <div
                class="colorRectangle"
                [ngStyle]="getColor('Keine Nachhaltigkeit')"
              ></div>
              <div style="float: left">Keine Nachhaltigkeit</div>
            </div>
            <div class="areaLegend">
              <div
                class="colorRectangle"
                [ngStyle]="getColor('Starke bis geringe Nachhaltigkeit')"
              ></div>
              <div style="float: left">Starke bis geringe Nachhaltigkeit</div>
            </div>
          </div>
        </div>
        <div class="flex" flex-order="1">
          <div class="chart-container">
            <canvas
              baseChart
              [ngStyle]="{ height: chartHeight }"
              [datasets]="radarChartDataVV"
              [options]="radarChartOptions"
              [labels]="radarChartLabels"
              [chartType]="radarChartType"
            >
            </canvas>
          </div>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>
