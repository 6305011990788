<h1>Impressum</h1>
<div class="text-content">
  <div class="col">
    <p style="font-weight: bold">Daten / Redaktion / Gesamtverantwortung</p>
    <p>Immobilien Stadt Bern</p>
    <p>Nachhaltiges Immobilienmanagement</p>
    <p>Bundesgasse 33</p>
    <p>3011 Bern</p>
    <p>Tel. 031 321 60 60</p>
    <a href="mailto:nim@bern.ch">nim@bern.ch</a>
  </div>
  <div class="col">
    <p style="font-weight: bold">Umsetzung</p>
    <p>Berner Fachhochschule</p>
    <p>Wirtschaft</p>
    <p>Institut Public Sector Transformation</p>
    <p>Digital Sustainability Lab</p>
    <p>Brückenstrasse 73</p>
    <p>3005 Bern</p>
  </div>
  <div class="col">
    <p style="font-weight: bold">Web-Hosting</p>
    <p>Berner Fachhochschule</p>
    <p>Wirtschaft</p>
    <p>Institut Public Sector Transformation</p>
    <p>Digital Sustainability Lab</p>
    <p>Brückenstrasse 73</p>
    <p>3005 Bern</p>
  </div>
</div>
<p class="copyright">©2021 Immobilien Stadt Bern (Idee Konzept NIM-Tool Elsi Hischier)</p>
