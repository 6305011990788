export const baujahrFv = [
  {
    id: 1,
    Baujahr: '1458',
    count: 1,
  },
  {
    id: 2,
    Baujahr: '1500',
    count: 1,
  },
  {
    id: 3,
    Baujahr: '1550',
    count: 6,
  },
  {
    id: 4,
    Baujahr: '1557',
    count: 1,
  },
  {
    id: 5,
    Baujahr: '1600',
    count: 1,
  },
  {
    id: 6,
    Baujahr: '1693',
    count: 1,
  },
  {
    id: 7,
    Baujahr: '1718',
    count: 1,
  },
  {
    id: 8,
    Baujahr: '1724',
    count: 1,
  },
  {
    id: 9,
    Baujahr: '1730',
    count: 2,
  },
  {
    id: 10,
    Baujahr: '1735',
    count: 1,
  },
  {
    id: 11,
    Baujahr: '1740',
    count: 1,
  },
  {
    id: 12,
    Baujahr: '1742',
    count: 1,
  },
  {
    id: 13,
    Baujahr: '1759',
    count: 1,
  },
  {
    id: 14,
    Baujahr: '1767',
    count: 1,
  },
  {
    id: 15,
    Baujahr: '1778',
    count: 1,
  },
  {
    id: 16,
    Baujahr: '1800',
    count: 5,
  },
  {
    id: 17,
    Baujahr: '1819',
    count: 1,
  },
  {
    id: 18,
    Baujahr: '1844',
    count: 4,
  },
  {
    id: 19,
    Baujahr: '1847',
    count: 2,
  },
  {
    id: 20,
    Baujahr: '1850',
    count: 2,
  },
  {
    id: 21,
    Baujahr: '1856',
    count: 2,
  },
  {
    id: 22,
    Baujahr: '1858',
    count: 2,
  },
  {
    id: 23,
    Baujahr: '1860',
    count: 1,
  },
  {
    id: 24,
    Baujahr: '1861',
    count: 1,
  },
  {
    id: 25,
    Baujahr: '1864',
    count: 1,
  },
  {
    id: 26,
    Baujahr: '1867',
    count: 1,
  },
  {
    id: 27,
    Baujahr: '1871',
    count: 1,
  },
  {
    id: 28,
    Baujahr: '1872',
    count: 3,
  },
  {
    id: 29,
    Baujahr: '1873',
    count: 1,
  },
  {
    id: 30,
    Baujahr: '1874',
    count: 2,
  },
  {
    id: 31,
    Baujahr: '1875',
    count: 1,
  },
  {
    id: 32,
    Baujahr: '1876',
    count: 1,
  },
  {
    id: 33,
    Baujahr: '1877',
    count: 4,
  },
  {
    id: 34,
    Baujahr: '1879',
    count: 2,
  },
  {
    id: 35,
    Baujahr: '1880',
    count: 1,
  },
  {
    id: 36,
    Baujahr: '1889',
    count: 1,
  },
  {
    id: 37,
    Baujahr: '1890',
    count: 3,
  },
  {
    id: 38,
    Baujahr: '1891',
    count: 1,
  },
  {
    id: 39,
    Baujahr: '1896',
    count: 3,
  },
  {
    id: 40,
    Baujahr: '1897',
    count: 3,
  },
  {
    id: 41,
    Baujahr: '1900',
    count: 8,
  },
  {
    id: 42,
    Baujahr: '1901',
    count: 1,
  },
  {
    id: 43,
    Baujahr: '1902',
    count: 3,
  },
  {
    id: 44,
    Baujahr: '1905',
    count: 1,
  },
  {
    id: 45,
    Baujahr: '1907',
    count: 1,
  },
  {
    id: 46,
    Baujahr: '1910',
    count: 1,
  },
  {
    id: 47,
    Baujahr: '1911',
    count: 1,
  },
  {
    id: 48,
    Baujahr: '1912',
    count: 4,
  },
  {
    id: 49,
    Baujahr: '1913',
    count: 2,
  },
  {
    id: 50,
    Baujahr: '1914',
    count: 1,
  },
  {
    id: 51,
    Baujahr: '1915',
    count: 1,
  },
  {
    id: 52,
    Baujahr: '1917',
    count: 2,
  },
  {
    id: 53,
    Baujahr: '1918',
    count: 1,
  },
  {
    id: 54,
    Baujahr: '1919',
    count: 16,
  },
  {
    id: 55,
    Baujahr: '1920',
    count: 22,
  },
  {
    id: 56,
    Baujahr: '1921',
    count: 4,
  },
  {
    id: 57,
    Baujahr: '1922',
    count: 5,
  },
  {
    id: 58,
    Baujahr: '1923',
    count: 2,
  },
  {
    id: 59,
    Baujahr: '1924',
    count: 6,
  },
  {
    id: 60,
    Baujahr: '1925',
    count: 1,
  },
  {
    id: 61,
    Baujahr: '1926',
    count: 14,
  },
  {
    id: 62,
    Baujahr: '1927',
    count: 7,
  },
  {
    id: 63,
    Baujahr: '1928',
    count: 1,
  },
  {
    id: 64,
    Baujahr: '1932',
    count: 1,
  },
  {
    id: 65,
    Baujahr: '1933',
    count: 1,
  },
  {
    id: 66,
    Baujahr: '1934',
    count: 2,
  },
  {
    id: 67,
    Baujahr: '1938',
    count: 1,
  },
  {
    id: 68,
    Baujahr: '1940',
    count: 2,
  },
  {
    id: 69,
    Baujahr: '1942',
    count: 1,
  },
  {
    id: 70,
    Baujahr: '1943',
    count: 12,
  },
  {
    id: 71,
    Baujahr: '1944',
    count: 16,
  },
  {
    id: 72,
    Baujahr: '1946',
    count: 24,
  },
  {
    id: 73,
    Baujahr: '1947',
    count: 2,
  },
  {
    id: 74,
    Baujahr: '1948',
    count: 3,
  },
  {
    id: 75,
    Baujahr: '1950',
    count: 1,
  },
  {
    id: 76,
    Baujahr: '1951',
    count: 1,
  },
  {
    id: 77,
    Baujahr: '1953',
    count: 1,
  },
  {
    id: 78,
    Baujahr: '1954',
    count: 5,
  },
  {
    id: 79,
    Baujahr: '1955',
    count: 1,
  },
  {
    id: 80,
    Baujahr: '1957',
    count: 6,
  },
  {
    id: 81,
    Baujahr: '1958',
    count: 1,
  },
  {
    id: 82,
    Baujahr: '1959',
    count: 4,
  },
  {
    id: 83,
    Baujahr: '1960',
    count: 1,
  },
  {
    id: 84,
    Baujahr: '1961',
    count: 8,
  },
  {
    id: 85,
    Baujahr: '1962',
    count: 3,
  },
  {
    id: 86,
    Baujahr: '1964',
    count: 2,
  },
  {
    id: 87,
    Baujahr: '1965',
    count: 2,
  },
  {
    id: 88,
    Baujahr: '1966',
    count: 1,
  },
  {
    id: 89,
    Baujahr: '1967',
    count: 1,
  },
  {
    id: 90,
    Baujahr: '1968',
    count: 2,
  },
  {
    id: 91,
    Baujahr: '1970',
    count: 1,
  },
  {
    id: 92,
    Baujahr: '1971',
    count: 2,
  },
  {
    id: 93,
    Baujahr: '1974',
    count: 1,
  },
  {
    id: 94,
    Baujahr: '1976',
    count: 1,
  },
  {
    id: 95,
    Baujahr: '1977',
    count: 1,
  },
  {
    id: 96,
    Baujahr: '1990',
    count: 1,
  },
  {
    id: 97,
    Baujahr: '1993',
    count: 1,
  },
  {
    id: 98,
    Baujahr: '1995',
    count: 1,
  },
  {
    id: 99,
    Baujahr: '2004',
    count: 2,
  },
  {
    id: 100,
    Baujahr: '2016',
    count: 1,
  },
  {
    id: 101,
    Baujahr: '2017',
    count: 2,
  },
  {
    id: 102,
    Baujahr: '2018',
    count: 4,
  },
  {
    id: 103,
    Baujahr: '2019',
    count: 1,
  },
  {
    id: 104,
    Baujahr: 'vor 1800',
    count: 1,
  },
  {
    id: 105,
    Baujahr: 'vor 1885',
    count: 1,
  },
  {
    id: 106,
    Baujahr: 'vor 1920',
    count: 2,
  },
];
