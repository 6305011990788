export const ausgewerteteFv = [
  {
    id: 1,
    Jahr: 2012,
    Kategorie: 'Wohnliegenschaften',
    Anzahl_Teilgebaeude: 416,
    Anteil_Teilgebaeude: 90.43,
    Anzahl_Gebaeude: 203,
    Anteil_Gebaeude: 83.4,
  },
  {
    id: 2,
    Jahr: 2012,
    Kategorie: 'Geschäftsliegenschaften',
    Anzahl_Teilgebaeude: 40,
    Anteil_Teilgebaeude: 8.91,
    Anzahl_Gebaeude: 37,
    Anteil_Gebaeude: 15.35,
  },
  {
    id: 3,
    Jahr: 2012,
    Kategorie: 'Landwirtschaft',
    Anzahl_Teilgebaeude: 3,
    Anteil_Teilgebaeude: 0.65,
    Anzahl_Gebaeude: 3,
    Anteil_Gebaeude: 1.24,
  },
  {
    id: 4,
    Jahr: 2012,
    Kategorie: 'Total',
    Anzahl_Teilgebaeude: 459,
    Anteil_Teilgebaeude: 100,
    Anzahl_Gebaeude: 243,
    Anteil_Gebaeude: 100,
  },
  {
    id: 5,
    Jahr: 2013,
    Kategorie: 'Wohnliegenschaften',
    Anzahl_Teilgebaeude: 423,
    Anteil_Teilgebaeude: 90.58,
    Anzahl_Gebaeude: 206,
    Anteil_Gebaeude: 83.61,
  },
  {
    id: 6,
    Jahr: 2013,
    Kategorie: 'Geschäftsliegenschaften',
    Anzahl_Teilgebaeude: 40,
    Anteil_Teilgebaeude: 8.78,
    Anzahl_Gebaeude: 37,
    Anteil_Gebaeude: 15.16,
  },
  {
    id: 7,
    Jahr: 2013,
    Kategorie: 'Landwirtschaft',
    Anzahl_Teilgebaeude: 3,
    Anteil_Teilgebaeude: 0.64,
    Anzahl_Gebaeude: 3,
    Anteil_Gebaeude: 1.23,
  },
  {
    id: 8,
    Jahr: 2013,
    Kategorie: 'Total',
    Anzahl_Teilgebaeude: 466,
    Anteil_Teilgebaeude: 100,
    Anzahl_Gebaeude: 246,
    Anteil_Gebaeude: 100,
  },
  {
    id: 9,
    Jahr: 2014,
    Kategorie: 'Wohnliegenschaften',
    Anzahl_Teilgebaeude: 431,
    Anteil_Teilgebaeude: 91.12,
    Anzahl_Gebaeude: 205,
    Anteil_Gebaeude: 84.08,
  },
  {
    id: 10,
    Jahr: 2014,
    Kategorie: 'Geschäftsliegenschaften',
    Anzahl_Teilgebaeude: 39,
    Anteil_Teilgebaeude: 8.25,
    Anzahl_Gebaeude: 36,
    Anteil_Gebaeude: 14.69,
  },
  {
    id: 11,
    Jahr: 2014,
    Kategorie: 'Landwirtschaft',
    Anzahl_Teilgebaeude: 3,
    Anteil_Teilgebaeude: 0.63,
    Anzahl_Gebaeude: 3,
    Anteil_Gebaeude: 1.22,
  },
  {
    id: 12,
    Jahr: 2014,
    Kategorie: 'Total',
    Anzahl_Teilgebaeude: 473,
    Anteil_Teilgebaeude: 100,
    Anzahl_Gebaeude: 244,
    Anteil_Gebaeude: 100,
  },
  {
    id: 13,
    Jahr: 2015,
    Kategorie: 'Wohnliegenschaften',
    Anzahl_Teilgebaeude: 433,
    Anteil_Teilgebaeude: 89.46,
    Anzahl_Gebaeude: 205,
    Anteil_Gebaeude: 81.67,
  },
  {
    id: 14,
    Jahr: 2015,
    Kategorie: 'Geschäftsliegenschaften',
    Anzahl_Teilgebaeude: 48,
    Anteil_Teilgebaeude: 9.92,
    Anzahl_Gebaeude: 43,
    Anteil_Gebaeude: 17.13,
  },
  {
    id: 15,
    Jahr: 2015,
    Kategorie: 'Landwirtschaft',
    Anzahl_Teilgebaeude: 3,
    Anteil_Teilgebaeude: 0.62,
    Anzahl_Gebaeude: 3,
    Anteil_Gebaeude: 1.2,
  },
  {
    id: 16,
    Jahr: 2015,
    Kategorie: 'Total',
    Anzahl_Teilgebaeude: 484,
    Anteil_Teilgebaeude: 100,
    Anzahl_Gebaeude: 251,
    Anteil_Gebaeude: 100,
  },
  {
    id: 17,
    Jahr: 2016,
    Kategorie: 'Wohnliegenschaften',
    Anzahl_Teilgebaeude: 438,
    Anteil_Teilgebaeude: 89.21,
    Anzahl_Gebaeude: 208,
    Anteil_Gebaeude: 81.25,
  },
  {
    id: 18,
    Jahr: 2016,
    Kategorie: 'Geschäftsliegenschaften',
    Anzahl_Teilgebaeude: 50,
    Anteil_Teilgebaeude: 10.18,
    Anzahl_Gebaeude: 45,
    Anteil_Gebaeude: 17.58,
  },
  {
    id: 19,
    Jahr: 2016,
    Kategorie: 'Landwirtschaft',
    Anzahl_Teilgebaeude: 3,
    Anteil_Teilgebaeude: 0.61,
    Anzahl_Gebaeude: 3,
    Anteil_Gebaeude: 1.17,
  },
  {
    id: 20,
    Jahr: 2016,
    Kategorie: 'Total',
    Anzahl_Teilgebaeude: 491,
    Anteil_Teilgebaeude: 100,
    Anzahl_Gebaeude: 256,
    Anteil_Gebaeude: 100,
  },
  {
    id: 21,
    Jahr: 2017,
    Kategorie: 'Wohnliegenschaften',
    Anzahl_Teilgebaeude: 440,
    Anteil_Teilgebaeude: 88.53,
    Anzahl_Gebaeude: 210,
    Anteil_Gebaeude: 80.46,
  },
  {
    id: 22,
    Jahr: 2017,
    Kategorie: 'Geschäftsliegenschaften',
    Anzahl_Teilgebaeude: 54,
    Anteil_Teilgebaeude: 10.87,
    Anzahl_Gebaeude: 48,
    Anteil_Gebaeude: 18.39,
  },
  {
    id: 23,
    Jahr: 2017,
    Kategorie: 'Landwirtschaft',
    Anzahl_Teilgebaeude: 3,
    Anteil_Teilgebaeude: 0.6,
    Anzahl_Gebaeude: 3,
    Anteil_Gebaeude: 1.15,
  },
  {
    id: 24,
    Jahr: 2017,
    Kategorie: 'Total',
    Anzahl_Teilgebaeude: 497,
    Anteil_Teilgebaeude: 100,
    Anzahl_Gebaeude: 261,
    Anteil_Gebaeude: 100,
  },
  {
    id: 25,
    Jahr: 2018,
    Kategorie: 'Wohnliegenschaften',
    Anzahl_Teilgebaeude: 445,
    Anteil_Teilgebaeude: 89.72,
    Anzahl_Gebaeude: 216,
    Anteil_Gebaeude: 82.82,
  },
  {
    id: 26,
    Jahr: 2018,
    Kategorie: 'Geschäftsliegenschaften',
    Anzahl_Teilgebaeude: 48,
    Anteil_Teilgebaeude: 9.68,
    Anzahl_Gebaeude: 42,
    Anteil_Gebaeude: 16.03,
  },
  {
    id: 27,
    Jahr: 2018,
    Kategorie: 'Landwirtschaft',
    Anzahl_Teilgebaeude: 3,
    Anteil_Teilgebaeude: 0.6,
    Anzahl_Gebaeude: 3,
    Anteil_Gebaeude: 1.15,
  },
  {
    id: 28,
    Jahr: 2018,
    Kategorie: 'Total',
    Anzahl_Teilgebaeude: 496,
    Anteil_Teilgebaeude: 100,
    Anzahl_Gebaeude: 261,
    Anteil_Gebaeude: 100,
  },
  {
    id: 29,
    Jahr: 2019,
    Kategorie: 'Wohnliegenschaften',
    Anzahl_Teilgebaeude: 479,
    Anteil_Teilgebaeude: 89.37,
    Anzahl_Gebaeude: 239,
    Anteil_Gebaeude: 82.99,
  },
  {
    id: 30,
    Jahr: 2019,
    Kategorie: 'Geschäftsliegenschaften',
    Anzahl_Teilgebaeude: 54,
    Anteil_Teilgebaeude: 10.07,
    Anzahl_Gebaeude: 46,
    Anteil_Gebaeude: 15.97,
  },
  {
    id: 31,
    Jahr: 2019,
    Kategorie: 'Landwirtschaft',
    Anzahl_Teilgebaeude: 3,
    Anteil_Teilgebaeude: 0.56,
    Anzahl_Gebaeude: 3,
    Anteil_Gebaeude: 1.04,
  },
  {
    id: 32,
    Jahr: 2019,
    Kategorie: 'Total',
    Anzahl_Teilgebaeude: 536,
    Anteil_Teilgebaeude: 100,
    Anzahl_Gebaeude: 288,
    Anteil_Gebaeude: 100,
  },
  {
    id: 33,
    Jahr: 2020,
    Kategorie: 'Wohnliegenschaften',
    Anzahl_Teilgebaeude: 487,
    Anteil_Teilgebaeude: 89.52,
    Anzahl_Gebaeude: 249,
    Anteil_Gebaeude: 83.56,
  },
  {
    id: 34,
    Jahr: 2020,
    Kategorie: 'Geschäftsliegenschaften',
    Anzahl_Teilgebaeude: 54,
    Anteil_Teilgebaeude: 9.93,
    Anzahl_Gebaeude: 46,
    Anteil_Gebaeude: 15.44,
  },
  {
    id: 35,
    Jahr: 2020,
    Kategorie: 'Landwirtschaft',
    Anzahl_Teilgebaeude: 3,
    Anteil_Teilgebaeude: 0.55,
    Anzahl_Gebaeude: 3,
    Anteil_Gebaeude: 1.01,
  },
  {
    id: 36,
    Jahr: 2020,
    Kategorie: 'Total',
    Anzahl_Teilgebaeude: 544,
    Anteil_Teilgebaeude: 100,
    Anzahl_Gebaeude: 298,
    Anteil_Gebaeude: 100,
  },
  {
    id: 37,
    Jahr: 2021,
    Kategorie: 'Wohnliegenschaften',
    Anzahl_Teilgebaeude: 491,
    Anteil_Teilgebaeude: '',
    Anzahl_Gebaeude: 253,
    Anteil_Gebaeude: '',
  },
  {
    id: 38,
    Jahr: 2021,
    Kategorie: 'Geschäftsliegenschaften',
    Anzahl_Teilgebaeude: 55,
    Anteil_Teilgebaeude: '',
    Anzahl_Gebaeude: 47,
    Anteil_Gebaeude: '',
  },
  {
    id: 39,
    Jahr: 2021,
    Kategorie: 'Landwirtschaft',
    Anzahl_Teilgebaeude: 3,
    Anteil_Teilgebaeude: '',
    Anzahl_Gebaeude: 3,
    Anteil_Gebaeude: '',
  },
  {
    id: 40,
    Jahr: 2021,
    Kategorie: 'Total',
    Anzahl_Teilgebaeude: 549,
    Anteil_Teilgebaeude: 100,
    Anzahl_Gebaeude: 303,
    Anteil_Gebaeude: 100,
  },
];
