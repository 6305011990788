export const hindernisfreiVvNeu = [
  {
    id: 1,
    Jahr: 2017,
    Kategorie: 'Verwaltungsgebäude',
    Anz_Hindernisfrei: 13,
    Anz_Nicht_Hindernisfrei: null,
  },
  {
    id: 2,
    Jahr: 2018,
    Kategorie: 'Verwaltungsgebäude',
    Anz_Hindernisfrei: 13,
    Anz_Nicht_Hindernisfrei: null,
  },
  {
    id: 3,
    Jahr: 2019,
    Kategorie: 'Verwaltungsgebäude',
    Anz_Hindernisfrei: 13,
    Anz_Nicht_Hindernisfrei: null,
  },
  {
    id: 4,
    Jahr: 2020,
    Kategorie: 'Verwaltungsgebäude',
    Anz_Hindernisfrei: 13,
    Anz_Nicht_Hindernisfrei: null,
  },
  {
    id: 5,
    Jahr: 2021,
    Kategorie: 'Verwaltungsgebäude',
    Anz_Hindernisfrei: 13,
    Anz_Nicht_Hindernisfrei: null,
  },
  {
    id: 6,
    Jahr: 2017,
    Kategorie: 'Bildung',
    Anz_Hindernisfrei: 57,
    Anz_Nicht_Hindernisfrei: null,
  },
  {
    id: 7,
    Jahr: 2018,
    Kategorie: 'Bildung',
    Anz_Hindernisfrei: 59,
    Anz_Nicht_Hindernisfrei: null,
  },
  {
    id: 8,
    Jahr: 2019,
    Kategorie: 'Bildung',
    Anz_Hindernisfrei: 60,
    Anz_Nicht_Hindernisfrei: null,
  },
  {
    id: 9,
    Jahr: 2020,
    Kategorie: 'Bildung',
    Anz_Hindernisfrei: 71,
    Anz_Nicht_Hindernisfrei: null,
  },
  {
    id: 10,
    Jahr: 2021,
    Kategorie: 'Bildung',
    Anz_Hindernisfrei: 72,
    Anz_Nicht_Hindernisfrei: null,
  },
  {
    id: 11,
    Jahr: 2017,
    Kategorie: 'Sport und Freizeit',
    Anz_Hindernisfrei: 7,
    Anz_Nicht_Hindernisfrei: null,
  },
  {
    id: 12,
    Jahr: 2018,
    Kategorie: 'Sport und Freizeit',
    Anz_Hindernisfrei: 7,
    Anz_Nicht_Hindernisfrei: null,
  },
  {
    id: 13,
    Jahr: 2019,
    Kategorie: 'Sport und Freizeit',
    Anz_Hindernisfrei: 7,
    Anz_Nicht_Hindernisfrei: null,
  },
  {
    id: 14,
    Jahr: 2020,
    Kategorie: 'Sport und Freizeit',
    Anz_Hindernisfrei: 7,
    Anz_Nicht_Hindernisfrei: null,
  },
  {
    id: 15,
    Jahr: 2021,
    Kategorie: 'Sport und Freizeit',
    Anz_Hindernisfrei: 9,
    Anz_Nicht_Hindernisfrei: null,
  },
  {
    id: 16,
    Jahr: 2018,
    Kategorie: 'Wohnen und Aufenthalt',
    Anz_Hindernisfrei: 1,
    Anz_Nicht_Hindernisfrei: null,
  },
  {
    id: 17,
    Jahr: 2019,
    Kategorie: 'Wohnen und Aufenthalt',
    Anz_Hindernisfrei: 1,
    Anz_Nicht_Hindernisfrei: null,
  },
  {
    id: 18,
    Jahr: 2020,
    Kategorie: 'Wohnen und Aufenthalt',
    Anz_Hindernisfrei: 1,
    Anz_Nicht_Hindernisfrei: null,
  },
  {
    id: 19,
    Jahr: 2021,
    Kategorie: 'Wohnen und Aufenthalt',
    Anz_Hindernisfrei: 1,
    Anz_Nicht_Hindernisfrei: null,
  },
  {
    id: 20,
    Jahr: 2017,
    Kategorie: 'Kultur und Geselligkeit',
    Anz_Hindernisfrei: 1,
    Anz_Nicht_Hindernisfrei: null,
  },
  {
    id: 21,
    Jahr: 2018,
    Kategorie: 'Kultur und Geselligkeit',
    Anz_Hindernisfrei: 4,
    Anz_Nicht_Hindernisfrei: null,
  },
  {
    id: 22,
    Jahr: 2019,
    Kategorie: 'Kultur und Geselligkeit',
    Anz_Hindernisfrei: 4,
    Anz_Nicht_Hindernisfrei: null,
  },
  {
    id: 23,
    Jahr: 2020,
    Kategorie: 'Kultur und Geselligkeit',
    Anz_Hindernisfrei: 4,
    Anz_Nicht_Hindernisfrei: null,
  },
  {
    id: 24,
    Jahr: 2021,
    Kategorie: 'Kultur und Geselligkeit',
    Anz_Hindernisfrei: 4,
    Anz_Nicht_Hindernisfrei: null,
  },
  {
    id: 25,
    Jahr: 2017,
    Kategorie: 'Infrastrukturbauten',
    Anz_Hindernisfrei: 2,
    Anz_Nicht_Hindernisfrei: null,
  },
  {
    id: 26,
    Jahr: 2018,
    Kategorie: 'Infrastrukturbauten',
    Anz_Hindernisfrei: 2,
    Anz_Nicht_Hindernisfrei: null,
  },
  {
    id: 27,
    Jahr: 2019,
    Kategorie: 'Infrastrukturbauten',
    Anz_Hindernisfrei: 2,
    Anz_Nicht_Hindernisfrei: null,
  },
  {
    id: 28,
    Jahr: 2020,
    Kategorie: 'Infrastrukturbauten',
    Anz_Hindernisfrei: 2,
    Anz_Nicht_Hindernisfrei: null,
  },
  {
    id: 29,
    Jahr: 2021,
    Kategorie: 'Infrastrukturbauten',
    Anz_Hindernisfrei: 9,
    Anz_Nicht_Hindernisfrei: null,
  },
];
