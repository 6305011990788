<div class="visualization">
  <div style="display: flex; flex-direction: row">
    <h1>Anzahl erbaute Gebäude</h1>
    <div style="flex-grow: 1"></div>
    <button
      *ngIf="selectedTabIndex == 0"
      mat-raised-button
      (click)="openProjectsDialog()"
    >
      2000-Watt-Areale
    </button>
  </div>

  <mat-tab-group [(selectedIndex)]="selectedTabIndex">
    <mat-tab label="Finanz-/Fondsvermögen">
      <button
        mat-icon-button
        aria-label="Informationen"
        (click)="onInfoButtonTap('baujahre.fv')"
        class="info-button"
      >
        <mat-icon>info_button</mat-icon>
      </button>

      <div class="chart-container" style="clear: both">
        <canvas
          baseChart
          [ngStyle]="{ height: chartHeight }"
          [datasets]="lineChartDataFV"
          [labels]="lineChartLabelsFV"
          [options]="lineChartOptions"
          [colors]="lineChartColors"
          [legend]="lineChartLegend"
          [chartType]="lineChartType"
          [plugins]="lineChartPlugins"
        >
        </canvas>
      </div>
    </mat-tab>
    <mat-tab label="Verwaltungsvermögen">
      <button
        mat-icon-button
        aria-label="Informationen"
        (click)="onInfoButtonTap('baujahre.vv')"
        class="info-button"
      >
        <mat-icon>info_button</mat-icon>
      </button>
      <div class="chart-container" style="clear: both">
        <canvas
          baseChart
          [ngStyle]="{ height: chartHeight }"
          [datasets]="lineChartDataVV"
          [labels]="lineChartLabelsVV"
          [options]="lineChartOptions"
          [colors]="lineChartColors"
          [legend]="lineChartLegend"
          [chartType]="lineChartType"
          [plugins]="lineChartPlugins"
        >
        </canvas>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>
