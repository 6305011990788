import { Component, OnInit } from '@angular/core';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { Color, Label } from 'ng2-charts';
import { ChartColorService } from '../shared/chart-color.service';
import { InfoModalComponent } from '../info-modal/info-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { DialogExampleProjetsComponent } from './dialog-example-projets/dialog-example-projets.component';
import { baujahrFv } from './baujahr-fv.data';
import { baujahrVv } from './baujahr-vv.data';

@Component({
  selector: 'nim-baujahre-linechart',
  templateUrl: './baujahre-linechart.component.html',
  styleUrls: ['./baujahre-linechart.component.css'],
})
export class BaujahreLinechartComponent implements OnInit {
  public selectedTabIndex = 0;

  public lineChartLabelsFV: Label[] = [];

  public lineChartLabelsVV: Label[] = [];

  public chartHeight = this.getChartHeight();

  public lineChartOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    legend: { display: false },
    scales: {
      xAxes: [
        {
          scaleLabel: {
            display: true,
            labelString: 'Jahre',
            fontSize: window.innerWidth < 700 ? 9 : 12,
          },
          ticks: {
            fontSize: window.innerWidth < 700 ? 9 : 12,
          },
        },
      ],
      yAxes: [
        {
          display: true,
          scaleLabel: {
            display: true,
            labelString: 'Anzahl erbaute Gebäude',
            fontSize: window.innerWidth < 700 ? 9 : 12,
          },
          ticks: {
            fontSize: window.innerWidth < 700 ? 9 : 12,
            beginAtZero: true,
          },
        },
      ],
    },
  };

  public lineChartColors: Color[];

  public lineChartLegend = true;

  public lineChartType: ChartType = 'line';

  public lineChartPlugins = [];

  public lineChartDataFV: ChartDataSets[] = [];

  public lineChartDataVV: ChartDataSets[] = [];

  public selectedYearsFV = 'pro Jahrzehnt';

  public selectedYearsVV = 'pro Jahrzehnt';

  public years = ['pro Jahrzehnt', 'pro Jahr'];

  dataFV;

  dataVV;

  beforeCountFV;

  beforeCountVV;

  constructor(
    private colorService: ChartColorService,
    public dialog: MatDialog,
  ) {}

  ngOnInit() {
    this.lineChartColors = this.colorService.getDefaultVVAltColors();
    this.getVvData();
    this.getFvData();
  }

  private getFvData() {
    const res = baujahrFv;
    this.dataFV = res;
    this.setBeforeCountFV();
    this.getDecadesFV(this.dataFV);
  }

  private getVvData() {
    const res = baujahrVv;
    this.dataVV = res;
    this.setBeforeCountVV();
    this.getDecadesVV(this.dataVV);
  }

  openProjectsDialog() {
    this.dialog.open(DialogExampleProjetsComponent, {
      width: window.innerWidth < 600 ? '70%' : '50%',
    });
  }

  private getDataFV(dataSet) {
    let data = dataSet.sort((a, b) => {
      return (
        Number(a.Baujahr.substr(a.Baujahr.length - 4, a.Baujahr.length)) -
        Number(b.Baujahr.substr(b.Baujahr.length - 4, b.Baujahr.length))
      );
    });

    data = data.filter(
      (d) => d.Baujahr.substr(d.Baujahr.length - 4, d.Baujahr.length) >= 1920,
    );
    const countArray = [this.beforeCountFV];
    data.map((o) => countArray.push(o.count));
    this.lineChartLabelsFV = ['vor 1920'];
    data.map((d) => this.lineChartLabelsFV.push(d.Baujahr));
    return countArray;
  }

  private getDataVV(dataSet) {
    let data = dataSet.sort((a, b) => {
      return (
        Number(a.Baujahr.substr(a.Baujahr.length - 4, a.Baujahr.length)) -
        Number(b.Baujahr.substr(b.Baujahr.length - 4, b.Baujahr.length))
      );
    });

    data = data.filter(
      (d) => d.Baujahr.substr(d.Baujahr.length - 4, d.Baujahr.length) >= 1920,
    );
    const countArray = [this.beforeCountVV];
    data.map((o) => countArray.push(o.count));
    this.lineChartLabelsVV = ['vor 1920'];
    data.map((d) => this.lineChartLabelsVV.push(d.Baujahr));
    return countArray;
  }

  private prepareDataFV(dataSet) {
    this.lineChartDataFV = [
      {
        fill: true,
        label: 'Anzahl erbaute Gebäude',
        data: dataSet,
      },
    ];
  }

  private prepareDataVV(dataSet) {
    this.lineChartDataVV = [
      {
        fill: true,
        label: 'Anzahl erbaute Gebäude',
        data: dataSet,
      },
    ];
  }

  private setBeforeCountFV() {
    this.beforeCountFV = this.dataFV
      .filter(
        (d) =>
          d.Baujahr.substr(d.Baujahr.length - 4, d.Baujahr.length) <= 1920 &&
          d.Baujahr != 1920,
      )
      .map((d) => d.count)
      .reduce((a, b) => a + b, 0);
  }

  private setBeforeCountVV() {
    this.beforeCountVV = this.dataVV
      .filter(
        (d) =>
          d.Baujahr.substr(d.Baujahr.length - 4, d.Baujahr.length) <= 1920 &&
          d.Baujahr != 1920,
      )
      .map((d) => d.count)
      .reduce((a, b) => a + b, 0);
  }

  private getDecadesFV(dataSet) {
    const filteredData = [this.beforeCountFV];
    const filteredLabels = ['vor 1920'];
    let data = dataSet;
    let yearCount = 1920;
    for (yearCount = 1920; yearCount <= 2020; yearCount += 5) {
      data = data.filter(
        (d) =>
          d.Baujahr.substr(d.Baujahr.length - 4, d.Baujahr.length) >=
            yearCount && d.Baujahr != 'vor 1920',
      );
      filteredData.push(
        data
          .filter(
            (d) =>
              d.Baujahr.substr(d.Baujahr.length - 4, d.Baujahr.length) <
              yearCount + 5,
          )
          .map((d) => d.count)
          .reduce((a, b) => a + b, 0),
      );
      filteredLabels.push(`${yearCount}-${yearCount + 4}`);
    }
    this.prepareDataFV(filteredData);
    this.lineChartLabelsFV = filteredLabels;
  }

  private getDecadesVV(dataSet) {
    const filteredData = [this.beforeCountVV];
    const filteredLabels = ['vor 1920'];
    let data = dataSet;
    let yearCount = 1920;
    for (yearCount = 1920; yearCount <= 2020; yearCount += 5) {
      data = data.filter(
        (d) =>
          d.Baujahr.substr(d.Baujahr.length - 4, d.Baujahr.length) >=
            yearCount && d.Baujahr != 'vor 1920',
      );
      filteredData.push(
        data
          .filter(
            (d) =>
              d.Baujahr.substr(d.Baujahr.length - 4, d.Baujahr.length) <
              yearCount + 5,
          )
          .map((d) => d.count)
          .reduce((a, b) => a + b, 0),
      );
      filteredLabels.push(`${yearCount}-${yearCount + 4}`);
    }
    this.prepareDataVV(filteredData);
    this.lineChartLabelsVV = filteredLabels;
  }

  onInfoButtonTap(content: string) {
    this.dialog.open(InfoModalComponent, {
      data: {
        content: content,
      },
    });
  }

  public getChartHeight() {
    return window.innerWidth < 700 ? '300px' : '480px';
  }
}
