export const nachhaltigkeitsrosetteVVNeu = [
  {
    id: 1,
    Jahr: 2019,
    Kategorie: 'Gesamt',
    Gemeinschaft: 4,
    Gestaltung: 3.6,
    Nutzung_Erschliessung: 3.4,
    Wohlbefinden_Gesundheit: 4.1,
    Gebaeude_Bausubstanz: 2.8,
    Anlagekosten: 2.1,
    Betriebs_Unterhaltskosten: 3.2,
    Wertschoepfung_Immobilienmarkt: 3.1,
    Baustoffe: 3.5,
    Betriebsenergie: 2.6,
    Boden_Natur_Landschaft: 2.8,
    Infrastruktur: 2.9,
  },
  {
    id: 2,
    Jahr: 2019,
    Kategorie: 'Verwaltungsgebäude',
    Gemeinschaft: 3.6,
    Gestaltung: 3.6,
    Nutzung_Erschliessung: 4.7,
    Wohlbefinden_Gesundheit: 3.7,
    Gebaeude_Bausubstanz: 3.5,
    Anlagekosten: 2.4,
    Betriebs_Unterhaltskosten: 4,
    Wertschoepfung_Immobilienmarkt: 3.3,
    Baustoffe: 3.8,
    Betriebsenergie: 3.3,
    Boden_Natur_Landschaft: 3.4,
    Infrastruktur: 3.3,
  },
  {
    id: 3,
    Jahr: 2019,
    Kategorie: 'Bildung',
    Gemeinschaft: 3.9,
    Gestaltung: 3.7,
    Nutzung_Erschliessung: 3.4,
    Wohlbefinden_Gesundheit: 4.2,
    Gebaeude_Bausubstanz: 2.8,
    Anlagekosten: 2.1,
    Betriebs_Unterhaltskosten: 3.2,
    Wertschoepfung_Immobilienmarkt: 3.2,
    Baustoffe: 3.6,
    Betriebsenergie: 2.5,
    Boden_Natur_Landschaft: 2.6,
    Infrastruktur: 3,
  },
  {
    id: 4,
    Jahr: 2019,
    Kategorie: 'Sport und Freizeit',
    Gemeinschaft: 4.2,
    Gestaltung: 2.4,
    Nutzung_Erschliessung: 2.8,
    Wohlbefinden_Gesundheit: 3.9,
    Gebaeude_Bausubstanz: 2.2,
    Anlagekosten: 2.5,
    Betriebs_Unterhaltskosten: 2.7,
    Wertschoepfung_Immobilienmarkt: 3.8,
    Baustoffe: 3.3,
    Betriebsenergie: 2.3,
    Boden_Natur_Landschaft: 3.4,
    Infrastruktur: 2.1,
  },
  {
    id: 5,
    Jahr: 2019,
    Kategorie: 'Wohnen und Aufenthalt',
    Gemeinschaft: 4.4,
    Gestaltung: 4.4,
    Nutzung_Erschliessung: 2.9,
    Wohlbefinden_Gesundheit: 4.3,
    Gebaeude_Bausubstanz: 2.7,
    Anlagekosten: 2.3,
    Betriebs_Unterhaltskosten: 2.9,
    Wertschoepfung_Immobilienmarkt: 2.8,
    Baustoffe: 4,
    Betriebsenergie: 2.7,
    Boden_Natur_Landschaft: 3.5,
    Infrastruktur: 3.5,
  },
  {
    id: 6,
    Jahr: 2019,
    Kategorie: 'Kultur und Geselligkeit',
    Gemeinschaft: 4.3,
    Gestaltung: 4.1,
    Nutzung_Erschliessung: 3.5,
    Wohlbefinden_Gesundheit: 3.5,
    Gebaeude_Bausubstanz: 2.9,
    Anlagekosten: 2.3,
    Betriebs_Unterhaltskosten: 3.2,
    Wertschoepfung_Immobilienmarkt: 2.9,
    Baustoffe: 3,
    Betriebsenergie: 2.8,
    Boden_Natur_Landschaft: 3,
    Infrastruktur: 2.6,
  },
  {
    id: 7,
    Jahr: 2019,
    Kategorie: 'Infrastrukturbauten',
    Gemeinschaft: 3.8,
    Gestaltung: 2.2,
    Nutzung_Erschliessung: 3.1,
    Wohlbefinden_Gesundheit: 3.6,
    Gebaeude_Bausubstanz: 2.5,
    Anlagekosten: 2.1,
    Betriebs_Unterhaltskosten: 3.2,
    Wertschoepfung_Immobilienmarkt: 2.3,
    Baustoffe: 3.4,
    Betriebsenergie: 2.3,
    Boden_Natur_Landschaft: 2.5,
    Infrastruktur: 3,
  },
  {
    id: 8,
    Jahr: 2020,
    Kategorie: 'Gesamt',
    Gemeinschaft: 4,
    Gestaltung: 3.6,
    Nutzung_Erschliessung: 3.4,
    Wohlbefinden_Gesundheit: 4.1,
    Gebaeude_Bausubstanz: 2.8,
    Anlagekosten: 2.2,
    Betriebs_Unterhaltskosten: 3.2,
    Wertschoepfung_Immobilienmarkt: 3.1,
    Baustoffe: 3.5,
    Betriebsenergie: 2.6,
    Boden_Natur_Landschaft: 2.8,
    Infrastruktur: 3.2,
  },
  {
    id: 9,
    Jahr: 2020,
    Kategorie: 'Verwaltungsgebäude',
    Gemeinschaft: 3.6,
    Gestaltung: 3.6,
    Nutzung_Erschliessung: 4.7,
    Wohlbefinden_Gesundheit: 3.7,
    Gebaeude_Bausubstanz: 3.5,
    Anlagekosten: 2.5,
    Betriebs_Unterhaltskosten: 3.9,
    Wertschoepfung_Immobilienmarkt: 3.3,
    Baustoffe: 3.8,
    Betriebsenergie: 3.3,
    Boden_Natur_Landschaft: 3.4,
    Infrastruktur: 3.3,
  },
  {
    id: 10,
    Jahr: 2020,
    Kategorie: 'Bildung',
    Gemeinschaft: 3.9,
    Gestaltung: 3.8,
    Nutzung_Erschliessung: 3.5,
    Wohlbefinden_Gesundheit: 4.2,
    Gebaeude_Bausubstanz: 2.9,
    Anlagekosten: 2.1,
    Betriebs_Unterhaltskosten: 3.3,
    Wertschoepfung_Immobilienmarkt: 3.2,
    Baustoffe: 3.6,
    Betriebsenergie: 2.7,
    Boden_Natur_Landschaft: 2.6,
    Infrastruktur: 3.3,
  },
  {
    id: 11,
    Jahr: 2020,
    Kategorie: 'Sport und Freizeit',
    Gemeinschaft: 4.2,
    Gestaltung: 2.4,
    Nutzung_Erschliessung: 2.8,
    Wohlbefinden_Gesundheit: 3.9,
    Gebaeude_Bausubstanz: 2.2,
    Anlagekosten: 2.5,
    Betriebs_Unterhaltskosten: 2.6,
    Wertschoepfung_Immobilienmarkt: 3.8,
    Baustoffe: 3.3,
    Betriebsenergie: 2.4,
    Boden_Natur_Landschaft: 3.4,
    Infrastruktur: 2.4,
  },
  {
    id: 12,
    Jahr: 2020,
    Kategorie: 'Wohnen und Aufenthalt',
    Gemeinschaft: 4.4,
    Gestaltung: 4.4,
    Nutzung_Erschliessung: 2.9,
    Wohlbefinden_Gesundheit: 4.3,
    Gebaeude_Bausubstanz: 2.8,
    Anlagekosten: 2.3,
    Betriebs_Unterhaltskosten: 3,
    Wertschoepfung_Immobilienmarkt: 2.9,
    Baustoffe: 4,
    Betriebsenergie: 2.7,
    Boden_Natur_Landschaft: 3.5,
    Infrastruktur: 3.5,
  },
  {
    id: 13,
    Jahr: 2020,
    Kategorie: 'Kultur und Geselligkeit',
    Gemeinschaft: 4.3,
    Gestaltung: 4.1,
    Nutzung_Erschliessung: 3.5,
    Wohlbefinden_Gesundheit: 3.5,
    Gebaeude_Bausubstanz: 2.9,
    Anlagekosten: 2.3,
    Betriebs_Unterhaltskosten: 2.7,
    Wertschoepfung_Immobilienmarkt: 2.9,
    Baustoffe: 3,
    Betriebsenergie: 2.8,
    Boden_Natur_Landschaft: 3,
    Infrastruktur: 2.6,
  },
  {
    id: 14,
    Jahr: 2020,
    Kategorie: 'Infrastrukturbauten',
    Gemeinschaft: 4.1,
    Gestaltung: 2.8,
    Nutzung_Erschliessung: 3.2,
    Wohlbefinden_Gesundheit: 4,
    Gebaeude_Bausubstanz: 2.7,
    Anlagekosten: 2.1,
    Betriebs_Unterhaltskosten: 3.2,
    Wertschoepfung_Immobilienmarkt: 2.5,
    Baustoffe: 3.1,
    Betriebsenergie: 2.2,
    Boden_Natur_Landschaft: 2.7,
    Infrastruktur: 3.3,
  },
  {
    id: 15,
    Jahr: 2021,
    Kategorie: 'Gesamt',
    Gemeinschaft: 4,
    Gestaltung: 3.6,
    Nutzung_Erschliessung: 3.4,
    Wohlbefinden_Gesundheit: 4.1,
    Gebaeude_Bausubstanz: 2.9,
    Anlagekosten: 2.2,
    Betriebs_Unterhaltskosten: 3.2,
    Wertschoepfung_Immobilienmarkt: 3.1,
    Baustoffe: 3.5,
    Betriebsenergie: 2.6,
    Boden_Natur_Landschaft: 2.8,
    Infrastruktur: 2.9,
  },
  {
    id: 16,
    Jahr: 2021,
    Kategorie: 'Verwaltungsgebäude',
    Gemeinschaft: 3.6,
    Gestaltung: 3.7,
    Nutzung_Erschliessung: 4.5,
    Wohlbefinden_Gesundheit: 3.6,
    Gebaeude_Bausubstanz: 3.5,
    Anlagekosten: 2.4,
    Betriebs_Unterhaltskosten: 3.8,
    Wertschoepfung_Immobilienmarkt: 3.3,
    Baustoffe: 3.7,
    Betriebsenergie: 3.2,
    Boden_Natur_Landschaft: 3.4,
    Infrastruktur: 2.6,
  },
  {
    id: 17,
    Jahr: 2021,
    Kategorie: 'Bildung',
    Gemeinschaft: 3.9,
    Gestaltung: 3.8,
    Nutzung_Erschliessung: 3.5,
    Wohlbefinden_Gesundheit: 4.3,
    Gebaeude_Bausubstanz: 2.9,
    Anlagekosten: 2.2,
    Betriebs_Unterhaltskosten: 3.4,
    Wertschoepfung_Immobilienmarkt: 3.2,
    Baustoffe: 3.7,
    Betriebsenergie: 2.7,
    Boden_Natur_Landschaft: 2.6,
    Infrastruktur: 2.9,
  },
  {
    id: 18,
    Jahr: 2021,
    Kategorie: 'Sport und Freizeit',
    Gemeinschaft: 4.2,
    Gestaltung: 2.5,
    Nutzung_Erschliessung: 2.8,
    Wohlbefinden_Gesundheit: 4,
    Gebaeude_Bausubstanz: 2.2,
    Anlagekosten: 2.5,
    Betriebs_Unterhaltskosten: 2.6,
    Wertschoepfung_Immobilienmarkt: 3.8,
    Baustoffe: 3.3,
    Betriebsenergie: 2.4,
    Boden_Natur_Landschaft: 3.4,
    Infrastruktur: 2.4,
  },
  {
    id: 19,
    Jahr: 2021,
    Kategorie: 'Wohnen und Aufenthalt',
    Gemeinschaft: 4.4,
    Gestaltung: 4.2,
    Nutzung_Erschliessung: 2.9,
    Wohlbefinden_Gesundheit: 4.3,
    Gebaeude_Bausubstanz: 2.6,
    Anlagekosten: 2.2,
    Betriebs_Unterhaltskosten: 2.5,
    Wertschoepfung_Immobilienmarkt: 2.8,
    Baustoffe: 3.8,
    Betriebsenergie: 2.5,
    Boden_Natur_Landschaft: 3.4,
    Infrastruktur: 3.4,
  },
  {
    id: 20,
    Jahr: 2021,
    Kategorie: 'Kultur und Geselligkeit',
    Gemeinschaft: 4.3,
    Gestaltung: 4.1,
    Nutzung_Erschliessung: 3.5,
    Wohlbefinden_Gesundheit: 3.6,
    Gebaeude_Bausubstanz: 2.9,
    Anlagekosten: 2.3,
    Betriebs_Unterhaltskosten: 2.7,
    Wertschoepfung_Immobilienmarkt: 2.9,
    Baustoffe: 3,
    Betriebsenergie: 2.8,
    Boden_Natur_Landschaft: 3,
    Infrastruktur: 2.6,
  },
  {
    id: 21,
    Jahr: 2021,
    Kategorie: 'Infrastrukturbauten',
    Gemeinschaft: 4,
    Gestaltung: 2.8,
    Nutzung_Erschliessung: 3,
    Wohlbefinden_Gesundheit: 3.9,
    Gebaeude_Bausubstanz: 2.6,
    Anlagekosten: 2.1,
    Betriebs_Unterhaltskosten: 3.1,
    Wertschoepfung_Immobilienmarkt: 2.5,
    Baustoffe: 3,
    Betriebsenergie: 2.2,
    Boden_Natur_Landschaft: 2.7,
    Infrastruktur: 3.2,
  },
];
