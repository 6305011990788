<div class="visualization">
  <h1>Handlungsbedarf</h1>
  <mat-tab-group>
    <mat-tab label="Finanz-/Fondsvermögen">
      <button
        mat-icon-button
        aria-label="Informationen"
        (click)="onInfoButtonTap('handlungsbedarf.fv')"
        class="info-button"
      >
        <mat-icon>info_button</mat-icon>
      </button>
      <div class="filter">
        <mat-form-field class="select-form">
          <mat-label>Jahr</mat-label>
          <mat-select
            [(ngModel)]="selectedYearsFV"
            name="yearsFv"
            multiple
            class="filter-select"
            (selectionChange)="selectionChangeFV($event)"
            #yearSelectFV="ngModel"
            ><mat-optgroup disabled="disabled" class="filter-option">
              <button
                mat-raised-button
                class="mat-accent fill text-sm"
                (click)="deselectAllYearsFV(yearSelectFV)"
              >
                Zurücksetzen
              </button>
            </mat-optgroup>
            <mat-option *ngFor="let year of yearsFV" [value]="year">
              {{ year }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <button
          mat-raised-button
          color="warn"
          style="margin-left: 1vw"
          (click)="deselectAllYearsFV(yearSelectFV)"
        >
          zurücksetzen
        </button>
      </div>
      <div class="filterList">
        Ausgewählte Filter:
        <span *ngFor="let year of selectedYearsFV"> {{ year }} </span>
      </div>
      <app-data-legend-table
        [dataLoaded]="true"
        [labels]="yearLabelsFV"
      ></app-data-legend-table>
      <div class="chart-container">
        <canvas
          baseChart
          [ngStyle]="{ height: chartHeight }"
          [datasets]="barChartDataFV"
          [labels]="barChartLabels"
          [options]="barChartOptions"
          [legend]="barChartLegend"
          [chartType]="barChartType"
        >
        </canvas>
      </div>
    </mat-tab>
    <mat-tab label="Verwaltungsvermögen">
      <button
        mat-icon-button
        aria-label="Informationen"
        (click)="onInfoButtonTap('handlungsbedarf.vv')"
        class="info-button"
      >
        <mat-icon>info_button</mat-icon>
      </button>
      <div class="filter">
        <mat-form-field class="select-form">
          <mat-label>Jahr</mat-label>
          <mat-select
            [(ngModel)]="selectedYearsVV"
            name="yearsVv"
            multiple
            class="filter-select"
            (selectionChange)="selectionChangeVV($event)"
            #yearSelectVV="ngModel"
          >
            <mat-optgroup disabled="disabled" class="filter-option">
              <button
                mat-raised-button
                class="mat-accent fill text-sm"
                (click)="deselectAllYearsVV(yearSelectVV)"
              >
                Zurücksetzen
              </button>
            </mat-optgroup>
            <mat-option *ngFor="let year of yearsVV" [value]="year">
              {{ year }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <button
          mat-raised-button
          color="warn"
          style="margin-left: 1vw"
          (click)="deselectAllYearsVV(yearSelectVV)"
        >
          zurücksetzen
        </button>
      </div>
      <div class="filterList">
        Ausgewählte Filter:
        <span *ngFor="let year of selectedYearsVV"> {{ year }} </span>
      </div>
      <app-data-legend-table
        [dataLoaded]="true"
        [labels]="yearLabelsVV"
      ></app-data-legend-table>
      <div class="chart-container">
        <canvas
          baseChart
          [ngStyle]="{ height: chartHeight }"
          [datasets]="barChartDataVV"
          [labels]="barChartLabels"
          [options]="barChartOptions"
          [legend]="barChartLegend"
          [chartType]="barChartType"
        >
        </canvas>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>
