import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { Color, Label } from 'ng2-charts';
import { InfoModalComponent } from '../info-modal/info-modal.component';
import { ChartColorService } from '../shared/chart-color.service';
import { SmallScreenFilterLegendComponent } from '../smallScreenFilterLegend/smallScreenFilterLegend.component';
import { veloplaetzeFv } from './veloplaetze-fv.data';
import { veloplaetzeVvNeu } from './veloplaetze-vv-neu.data';
import { veloplaetzeVv } from './veloplaetze-vv.data';

@Component({
  selector: 'nim-veloplaetze-linechart',
  templateUrl: './veloplaetze-linechart.component.html',
  styleUrls: ['./veloplaetze-linechart.component.css'],
})
export class VeloplaetzeLinechartComponent implements OnInit {
  public lineChartDataVV: ChartDataSets[] = [];

  public lineChartDataFV: ChartDataSets[] = [];

  public lineChartLabelsFV: Label[] = [];

  public lineChartLabelsVV: Label[] = [];

  public lineChartOptions: ChartOptions = {
    maintainAspectRatio: false,
    responsive: true,
    legend: { display: false },
    scales: {
      xAxes: [
        {
          scaleLabel: {
            display: true,
            labelString: 'Jahr',
            fontSize: window.innerWidth < 700 ? 9 : 12,
          },
          ticks: {
            fontSize: window.innerWidth < 700 ? 9 : 12,
          },
        },
      ],
      yAxes: [
        {
          display: true,
          scaleLabel: {
            display: true,
            labelString: 'Anzahl Veloparkplätze',
            fontSize: window.innerWidth < 700 ? 9 : 12,
          },
          ticks: {
            fontSize: window.innerWidth < 700 ? 9 : 12,
            beginAtZero: true,
          },
        },
      ],
    },
  };

  public fondsColors: Color[];

  public vvAltColors: Color[];

  public vvNeuColors: Color[];

  public lineChartLegend = true;

  public lineChartType: ChartType = 'line';

  public lineChartPlugins = [];

  public dataLoadedFV = false;

  public dataLoadedVV = false;

  categoriesVvAlt = [
    'Verwaltungsgebäude (pro Objekt)',
    'Schulanlage (pro Objekt)',
    'Sportanlage (pro Objekt)',
    'Eis-Wasseranlage (pro Objekt)',
    'Kulturgebäude (pro Objekt)',
    'Werkhöfe (pro Objekt)',
    'Jugend-/Quartiertreffs (pro Objekt)',
    'Wohnhäuser/-heime (pro Objekt)',
    'Restaurants (pro Objekt)',
  ];

  categoriesVvNeu = [
    'Verwaltungsgebäude',
    'Bildung',
    'Sport und Freizeit',
    'Wohnen und Aufenthalt',
    'Kultur und Geselligkeit',
    'Infrastrukturbauten',
  ];

  categoriesVv = this.categoriesVvNeu;

  categoriesFv = [
    'Wohnliegenschaften (pro Zimmer)',
    'Geschäftsliegenschaften (pro Objekt)',
  ];

  categoriesFvFiltered = [];

  categoriesVvFiltered = [];

  newSystemYears = [2019, 2020, 2021];

  yearsVv = [];

  yearsFv = [];

  yearsFvFiltered = [];

  yearsVvFiltered = [];

  dataFV;

  dataVV;

  dataVVneu;

  public useNewSystem: boolean;

  public labelsFilteredFV = this.categoriesFv;

  public labelsFilteredVV = this.categoriesVvNeu;

  allDataIsZeroFV = false;

  allDataIsZeroVV = false;

  onlyOneYearFV = false;

  onlyOneYearVV = false;

  chartHeight = this.getChartHeight();

  constructor(
    private colorService: ChartColorService,
    public dialog: MatDialog,
    private breakpointObserver: BreakpointObserver,
    private smallScreenFilterDialog: MatDialog,
  ) {}

  public smallScreen: boolean;

  ngOnInit() {
    this.fondsColors = this.colorService.getDefaultFondsColors();
    this.vvNeuColors = this.colorService.getDefaultVVNeuColors();
    this.vvAltColors = this.colorService.getDefaultVVAltColors();
    this.getVvData();
    this.getFvData();
    this.getVvNeuData();
    this.checkIfSmallScreen();
  }

  private getFvData() {
    const res = veloplaetzeFv;
    this.dataFV = res;
    this.getYearsFV();
    this.prepDataFv(this.categoriesFv, res, this.yearsFv);
    this.dataLoadedFV = true;
  }

  private getVvData() {
    const res = veloplaetzeVv;
    this.dataVV = res;
  }

  private getVvNeuData() {
    const res = veloplaetzeVvNeu;
    this.dataVVneu = res;
    this.useNewSystem = true;
    this.getYearsVV(this.dataVVneu);
    this.prepDataVv(this.categoriesVv, res, this.yearsVv);
    this.dataLoadedVV = true;
  }

  private checkIfSmallScreen() {
    this.breakpointObserver
      .observe(['(min-width: 900px)'])
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          this.smallScreen = false;
        } else {
          this.smallScreen = true;
        }
      });
  }

  openDialog(asset: string) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    if (asset === 'FV') {
      dialogConfig.data = {
        labels: this.lineChartLabelsFV,
        years: this.yearsFv,
        categories: this.categoriesFv,
        labelsFiltered: [],
        yearsFiltered: this.yearsFvFiltered,
        categoriesFiltered: this.categoriesFvFiltered,
        showNewSystemFilter: false,
        showThirdFilter: false,
        hideYearFilter: true,
      };

      this.smallScreenFilterDialog
        .open(SmallScreenFilterLegendComponent, dialogConfig)
        .afterClosed()
        .subscribe((changes) => {
          if (changes) this.changeFilterFV(changes);
        });
    } else {
      dialogConfig.data = {
        labels: this.lineChartLabelsVV,
        years: this.yearsVv,
        categories: this.categoriesVv,
        labelsFiltered: [],
        yearsFiltered: this.yearsVvFiltered,
        categoriesFiltered: this.categoriesVvFiltered,
        showThirdFilter: false,
        showNewSystemFilter: true,
        useNewSystem: this.useNewSystem,
        hideYearFilter: true,
      };

      this.smallScreenFilterDialog
        .open(SmallScreenFilterLegendComponent, dialogConfig)
        .afterClosed()
        .subscribe((changes) => {
          if (changes) this.changeFilterVV(changes);
        });
    }
  }

  private getYearsFV() {
    this.dataFV.forEach((element) => {
      if (!this.yearsFv.includes(element.Jahr)) {
        this.yearsFv.push(element.Jahr);
        this.lineChartLabelsFV.push(String(element.Jahr));
      }
    });
  }

  private getYearsVV(data: any[]) {
    this.yearsVv = [];
    data.forEach((element) => {
      if (!this.yearsVv.includes(element.Jahr)) {
        this.yearsVv.push(element.Jahr);
        this.lineChartLabelsVV.push(String(element.Jahr));
      }
    });
  }

  private prepDataFv(categories: string[], data, years) {
    this.allDataIsZeroFV = true;
    this.lineChartDataFV = [];
    categories.forEach((c) => {
      const dataSet = {
        fill: false,
        label: c,
        data: [],
        pointBackgroundColor: this.getColor(c).backgroundColor,
        borderColor: this.getColor(c).backgroundColor,
      };
      const dataFiltered = data.filter((o) => o.Kategorie === c);
      years.forEach((l) => {
        if (dataFiltered.find((o) => o.Jahr === l) !== undefined) {
          dataSet.data.push(dataFiltered.find((o) => o.Jahr === l).Veloplaetze);
        } else dataSet.data.push(null);
      });
      this.lineChartDataFV.push(dataSet);
    });
    this.checkIfFVDataCanBeVisualized();
  }

  private prepDataVv(categories: string[], data, years) {
    this.allDataIsZeroVV = true;
    this.lineChartDataVV = [];
    categories.forEach((c) => {
      const dataSet = {
        fill: false,
        label: c,
        data: [],
        pointBackgroundColor: this.getColor(c).backgroundColor,
        borderColor: this.getColor(c).backgroundColor,
      };
      const dataFiltered = data.filter((o) => o.Kategorie === c);
      years.forEach((l) => {
        if (dataFiltered.find((o) => o.Jahr === l) !== undefined) {
          dataSet.data.push(dataFiltered.find((o) => o.Jahr === l).Veloplaetze);
        } else dataSet.data.push(null);
      });

      this.lineChartDataVV.push(dataSet);
    });
    this.checkIfVVDataCanBeVisualized();
  }

  public getColor(category: string) {
    switch (category) {
      case 'Wohnliegenschaften (pro Zimmer)': {
        return this.fondsColors[0];
      }
      case 'Geschäftsliegenschaften (pro Objekt)': {
        return this.fondsColors[1];
      }
      case 'Verwaltungsgebäude (pro Objekt)': {
        return this.vvAltColors[0];
      }
      case 'Schulanlage (pro Objekt)': {
        return this.vvAltColors[1];
      }
      case 'Sportanlage (pro Objekt)': {
        return this.vvAltColors[2];
      }
      case 'Eis-Wasseranlage (pro Objekt)': {
        return this.vvAltColors[3];
      }
      case 'Kulturgebäude (pro Objekt)': {
        return this.vvAltColors[4];
      }
      case 'Werkhöfe (pro Objekt)': {
        return this.vvAltColors[5];
      }
      case 'Jugend-/Quartiertreffs (pro Objekt)': {
        return this.vvAltColors[6];
      }
      case 'Wohnhäuser/-heime (pro Objekt)': {
        return this.vvAltColors[7];
      }
      case 'Restaurants (pro Objekt)': {
        return this.vvAltColors[8];
      }

      case 'Bildung': {
        return this.vvNeuColors[1];
      }
      case 'Sport und Freizeit': {
        return this.vvNeuColors[2];
      }
      case 'Wohnen und Aufenthalt': {
        return this.vvNeuColors[3];
      }
      case 'Kultur und Geselligkeit': {
        return this.vvNeuColors[4];
      }
      case 'Infrastrukturbauten': {
        return this.vvNeuColors[5];
      }
      default:
        return this.vvNeuColors[0];
    }
  }

  private checkIfVVDataCanBeVisualized() {
    this.lineChartDataVV.forEach((dataSet) => {
      if (dataSet.data.length !== 0) {
        this.allDataIsZeroVV = false;
      }
    });
    if (this.allDataIsZero(this.lineChartDataVV)) this.allDataIsZeroVV = true;
  }

  private checkIfFVDataCanBeVisualized() {
    this.lineChartDataFV.forEach((dataSet) => {
      if (dataSet.data.length !== 0) {
        this.allDataIsZeroFV = false;
      }
    });
    if (this.allDataIsZero(this.lineChartDataFV)) this.allDataIsZeroFV = true;
  }

  private allDataIsZero(dataSet) {
    let allZero = true;
    dataSet.forEach((dataObject) => {
      dataObject.data.forEach((element) => {
        if (element !== 0 && element !== null) allZero = false;
      });
    });
    return allZero;
  }

  public changeFilterFV(changes) {
    let catFV: string[];
    let yearsFV: number[];
    if (changes.cat.length > 0) {
      catFV = changes.cat;
      this.labelsFilteredFV = changes.cat;
      this.categoriesFvFiltered = changes.cat;
    } else {
      catFV = this.categoriesFv;
      this.labelsFilteredFV = this.categoriesFv;
      this.categoriesFvFiltered = [];
    }
    if (changes.years.length > 0) {
      if (changes.years.length < 2) this.onlyOneYearFV = true;
      else this.onlyOneYearFV = false;
      yearsFV = changes.years;
      this.lineChartLabelsFV = changes.years;
      this.yearsFvFiltered = changes.years;
    } else {
      yearsFV = this.yearsFv;
      this.yearsFvFiltered = [];
      this.lineChartLabelsFV = this.yearsFv.map((y) => String(y));
      this.onlyOneYearFV = false;
    }
    this.prepDataFv(catFV, this.dataFV, yearsFV);
  }

  public changeFilterVV(changes) {
    let catVV: string[];
    let yearsVV: number[];
    let dataVV;
    if (changes.newSystem === this.useNewSystem) {
      if (changes.cat.length > 0) {
        catVV = changes.cat;
        this.labelsFilteredVV = changes.cat;
        this.categoriesVvFiltered = changes.cat;
      } else {
        catVV = this.categoriesVv;
        this.labelsFilteredVV = this.categoriesVv;
        this.categoriesVvFiltered = [];
      }
      if (changes.years && changes.years.length > 0) {
        if (changes.years.length < 2) this.onlyOneYearVV = true;
        else this.onlyOneYearVV = false;
        yearsVV = changes.years;
        this.lineChartLabelsVV = changes.years;
        this.yearsVvFiltered = changes.years;
      } else {
        yearsVV = this.yearsVv;
        //this.yearsVvFiltered = [];
        this.lineChartLabelsVV = this.yearsVv.map((y) => String(y));
        this.onlyOneYearVV = false;
      }
      this.useNewSystem ? (dataVV = this.dataVVneu) : (dataVV = this.dataVV);
    } else if (changes.newSystem) {
      this.getYearsVV(this.dataVVneu);
      this.useNewSystem = true;
      dataVV = this.dataVVneu;
      this.categoriesVv = this.categoriesVvNeu;
      catVV = this.categoriesVv;
      this.labelsFilteredVV = this.categoriesVv;
      this.categoriesVvFiltered = [];
      yearsVV = this.yearsVv;
      this.lineChartLabelsVV = this.yearsVv.map((y) => String(y));
      this.onlyOneYearVV = false;
    } else {
      this.useNewSystem = false;
      this.getYearsVV(this.dataVV);
      dataVV = this.dataVV;
      this.categoriesVv = this.categoriesVvAlt;
      yearsVV = this.yearsVv;
      this.yearsVvFiltered = [];
      this.lineChartLabelsVV = this.yearsVv.map((y) => String(y));
      this.onlyOneYearVV = false;
      catVV = this.categoriesVv;
      this.labelsFilteredVV = this.categoriesVv;
      this.categoriesVvFiltered = [];
    }
    this.prepDataVv(catVV, dataVV, yearsVV);
  }

  onInfoButtonTap(content: string) {
    this.dialog.open(InfoModalComponent, {
      data: {
        content: content,
      },
    });
  }

  public getChartHeight() {
    return window.innerWidth < 700 ? '300px' : '400px';
  }
}
