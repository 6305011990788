export const autoplaetzeVv = [
  {
    id: 1,
    Autoplaetze: 6.53,
    Jahr: 2015,
    Kategorie: 'Verwaltungsgebäude (pro Objekt)',
  },
  {
    id: 2,
    Autoplaetze: 5.76,
    Jahr: 2016,
    Kategorie: 'Verwaltungsgebäude (pro Objekt)',
  },
  {
    id: 3,
    Autoplaetze: 5.88,
    Jahr: 2017,
    Kategorie: 'Verwaltungsgebäude (pro Objekt)',
  },
  {
    id: 4,
    Autoplaetze: 5.76,
    Jahr: 2018,
    Kategorie: 'Verwaltungsgebäude (pro Objekt)',
  },
  {
    id: 5,
    Autoplaetze: 6.13,
    Jahr: 2019,
    Kategorie: 'Verwaltungsgebäude (pro Objekt)',
  },
  {
    id: 6,
    Autoplaetze: 6.13,
    Jahr: 2020,
    Kategorie: 'Verwaltungsgebäude (pro Objekt)',
  },
  {
    id: 7,
    Autoplaetze: 9.59,
    Jahr: 2021,
    Kategorie: 'Verwaltungsgebäude (pro Objekt)',
  },
  {
    id: 8,
    Autoplaetze: 2,
    Jahr: 2015,
    Kategorie: 'Schulanlage (pro Objekt)',
  },
  {
    id: 9,
    Autoplaetze: 2.23,
    Jahr: 2016,
    Kategorie: 'Schulanlage (pro Objekt)',
  },
  {
    id: 10,
    Autoplaetze: 1.91,
    Jahr: 2017,
    Kategorie: 'Schulanlage (pro Objekt)',
  },
  {
    id: 11,
    Autoplaetze: 1.88,
    Jahr: 2018,
    Kategorie: 'Schulanlage (pro Objekt)',
  },
  {
    id: 12,
    Autoplaetze: 1.93,
    Jahr: 2019,
    Kategorie: 'Schulanlage (pro Objekt)',
  },
  {
    id: 13,
    Autoplaetze: 2.27,
    Jahr: 2020,
    Kategorie: 'Schulanlage (pro Objekt)',
  },
  {
    id: 14,
    Autoplaetze: 2.35,
    Jahr: 2021,
    Kategorie: 'Schulanlage (pro Objekt)',
  },
  {
    id: 15,
    Autoplaetze: 11.29,
    Jahr: 2016,
    Kategorie: 'Sportanlage (pro Objekt)',
  },
  {
    id: 16,
    Autoplaetze: 14.47,
    Jahr: 2017,
    Kategorie: 'Sportanlage (pro Objekt)',
  },
  {
    id: 17,
    Autoplaetze: 14.47,
    Jahr: 2018,
    Kategorie: 'Sportanlage (pro Objekt)',
  },
  {
    id: 18,
    Autoplaetze: 15.5,
    Jahr: 2019,
    Kategorie: 'Sportanlage (pro Objekt)',
  },
  {
    id: 19,
    Autoplaetze: 15.5,
    Jahr: 2020,
    Kategorie: 'Sportanlage (pro Objekt)',
  },
  {
    id: 20,
    Autoplaetze: 17.92,
    Jahr: 2021,
    Kategorie: 'Sportanlage (pro Objekt)',
  },
  {
    id: 21,
    Autoplaetze: 36.5,
    Jahr: 2016,
    Kategorie: 'Eis-Wasseranlage (pro Objekt)',
  },
  {
    id: 22,
    Autoplaetze: 36.5,
    Jahr: 2017,
    Kategorie: 'Eis-Wasseranlage (pro Objekt)',
  },
  {
    id: 23,
    Autoplaetze: 36.5,
    Jahr: 2018,
    Kategorie: 'Eis-Wasseranlage (pro Objekt)',
  },
  {
    id: 24,
    Autoplaetze: 36.5,
    Jahr: 2019,
    Kategorie: 'Eis-Wasseranlage (pro Objekt)',
  },
  {
    id: 25,
    Autoplaetze: 36.5,
    Jahr: 2020,
    Kategorie: 'Eis-Wasseranlage (pro Objekt)',
  },
  {
    id: 26,
    Autoplaetze: 36.5,
    Jahr: 2021,
    Kategorie: 'Eis-Wasseranlage (pro Objekt)',
  },
  {
    id: 27,
    Autoplaetze: 0,
    Jahr: 2017,
    Kategorie: 'Kulturgebäude (pro Objekt)',
  },
  {
    id: 28,
    Autoplaetze: 0,
    Jahr: 2018,
    Kategorie: 'Kulturgebäude (pro Objekt)',
  },
  {
    id: 29,
    Autoplaetze: 0,
    Jahr: 2019,
    Kategorie: 'Kulturgebäude (pro Objekt)',
  },
  {
    id: 30,
    Autoplaetze: 0,
    Jahr: 2020,
    Kategorie: 'Kulturgebäude (pro Objekt)',
  },
  {
    id: 31,
    Autoplaetze: 0,
    Jahr: 2021,
    Kategorie: 'Kulturgebäude (pro Objekt)',
  },
  {
    id: 32,
    Autoplaetze: 5,
    Jahr: 2017,
    Kategorie: 'Werkhöfe (pro Objekt)',
  },
  {
    id: 33,
    Autoplaetze: 5,
    Jahr: 2018,
    Kategorie: 'Werkhöfe (pro Objekt)',
  },
  {
    id: 34,
    Autoplaetze: 5.33,
    Jahr: 2019,
    Kategorie: 'Werkhöfe (pro Objekt)',
  },
  {
    id: 35,
    Autoplaetze: 9.1,
    Jahr: 2020,
    Kategorie: 'Werkhöfe (pro Objekt)',
  },
  {
    id: 36,
    Autoplaetze: 9.9,
    Jahr: 2021,
    Kategorie: 'Werkhöfe (pro Objekt)',
  },
  {
    id: 37,
    Autoplaetze: 0,
    Jahr: 2018,
    Kategorie: 'Jugend-/Quartiertreffs (pro Objekt)',
  },
  {
    id: 38,
    Autoplaetze: 0,
    Jahr: 2019,
    Kategorie: 'Jugend-/Quartiertreffs (pro Objekt)',
  },
  {
    id: 39,
    Autoplaetze: 0,
    Jahr: 2020,
    Kategorie: 'Jugend-/Quartiertreffs (pro Objekt)',
  },
  {
    id: 40,
    Autoplaetze: 0,
    Jahr: 2021,
    Kategorie: 'Jugend-/Quartiertreffs (pro Objekt)',
  },
  {
    id: 41,
    Autoplaetze: 1.11,
    Jahr: 2018,
    Kategorie: 'Wohnhäuser/-heime (pro Objekt)',
  },
  {
    id: 42,
    Autoplaetze: 1,
    Jahr: 2019,
    Kategorie: 'Wohnhäuser/-heime (pro Objekt)',
  },
  {
    id: 43,
    Autoplaetze: 1,
    Jahr: 2020,
    Kategorie: 'Wohnhäuser/-heime (pro Objekt)',
  },
  {
    id: 44,
    Autoplaetze: 1.15,
    Jahr: 2021,
    Kategorie: 'Wohnhäuser/-heime (pro Objekt)',
  },
  {
    id: 45,
    Autoplaetze: 0,
    Jahr: 2019,
    Kategorie: 'Restaurants (pro Objekt)',
  },
  {
    id: 46,
    Autoplaetze: 0,
    Jahr: 2020,
    Kategorie: 'Restaurants (pro Objekt)',
  },
  {
    id: 47,
    Autoplaetze: 0,
    Jahr: 2021,
    Kategorie: 'Restaurants (pro Objekt)',
  },
];
