<div class="visualization">
  <h1>Energieträger</h1>
  <mat-tab-group>
    <mat-tab label="Finanz-/Fondsvermögen">
      <button
        mat-icon-button
        aria-label="Informationen"
        (click)="onInfoButtonTap('energietraeger.fv')"
        class="info-button"
      >
        <mat-icon>info_button</mat-icon>
      </button>
      <div *ngIf="smallScreen">
        <button
          class="dialogButton"
          mat-raised-button
          (click)="openDialog('FV')"
        >
          Filter
        </button>
      </div>
      <app-filter
        *ngIf="!smallScreen"
        [years]="yearsFv"
        [labels]="labelsFV"
        [categories]="categoriesFv"
        [selectedBuildings]="categoriesFvFiltered"
        [selectedYears]="yearsFvFiltered"
        [selectedCategories]="labelsFilteredFV"
        [showThirdFilter]="true"
        (newItemEvent)="changeFilterFV($event)"
      ></app-filter>
      <div >
        <app-data-legend-table
          [dataLoaded]="dataLoadedFV"
          [labels]="labelsForLegendFV"
        ></app-data-legend-table>
        <div class="chart-container">
          <canvas
            baseChart
            [ngStyle]="{ height: chartHeight }"
            [datasets]="barChartDataFV"
            [labels]="barChartLabelsFV"
            [options]="barChartOptions"
            [plugins]="barChartPlugins"
            [legend]="barChartLegend"
            [chartType]="barChartType"
          >
          </canvas>
        </div>
      </div>
      
    </mat-tab>
    <mat-tab label="Verwaltungsvermögen">
      <button
        mat-icon-button
        aria-label="Informationen"
        (click)="
          onInfoButtonTap(
            useNewSystem ? 'energietraeger.vv.neu' : 'energietraeger.vv.alt'
          )
        "
        class="info-button"
      >
        <mat-icon>info_button</mat-icon>
      </button>
      <div *ngIf="smallScreen">
        <button
          class="dialogButton"
          mat-raised-button
          (click)="openDialog('VV')"
        >
          Filter
        </button>
      </div>
      <app-filter
        *ngIf="!smallScreen"
        [years]="yearsVv"
        [labels]="labelsVV"
        [selectedBuildings]="categoriesVvFiltered"
        [selectedYears]="yearsVvFiltered"
        [selectedCategories]="labelsFilteredVV"
        [useNewSystem]="useNewSystem"
        [categories]="categoriesVv"
        [old_years]="oldSystemYears"
        [new_years]="newSystemYears"
        [showThirdFilter]="true"
        [showNewSystemFilter]="true"
        (newItemEvent)="changeFilterVV($event)"
      ></app-filter>
      <div>
        <app-data-legend-table
          [dataLoaded]="dataLoadedVV"
          [labels]="labelsForLegendVV"
        ></app-data-legend-table>
        <div class="chart-container">
          <canvas
            baseChart
            [ngStyle]="{ height: chartHeight }"
            [datasets]="barChartDataVV"
            [labels]="barChartLabelsVV"
            [options]="barChartOptions"
            [plugins]="barChartPlugins"
            [legend]="barChartLegend"
            [chartType]="barChartType"
          >
          </canvas>
        </div>
      </div>
  
    </mat-tab>
  </mat-tab-group>
</div>
