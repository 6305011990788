import { Injectable } from '@angular/core';

export interface InfoModalDataItem {
  title?: string;
  description?: string;
  bold?: boolean;
}
export interface InfoModalTableItem {
  aspect: string;
  topics: InfoModalTableItemTopic[];
}
export interface InfoModalTableItemTopic {
  name: string;
  criterias: string[];
}
export interface InfoModalLink {
  link: string;
  text: string;
}
export interface InfoModalTable {
  table: InfoModalTableItem[];
}

export interface InfoModalData {
  title: string;
  description?: string;
  items: InfoModalDataItem[];
  tables: InfoModalTable[];
  link?: InfoModalLink;
}

@Injectable({
  providedIn: 'root',
})
export class InfoModalService {
  data = {
    home: {
      title: 'Home',
      items: [
        {
          description:
            'Das Nachhaltige Immobilienmanagement wurde 2011 im Fonds für Boden- und Wohbaupolitik (Fonds) und 2014 im Verwaltungsvermögen durch die entsprechenden Organe genehmigt und eingeführt.',
        },
        {
          description:
            'Die ausformulierten Ziele des Nachhaltigen Immobilienmanagements stehen im Einklang zu den bestehenden Vorgaben des Bundes, des Kantons und der Stadt Bern (Legislaturrichtlinien etc.). Das Leitbild Nachhaltige Entwicklung im Finanz-/Fondsvermögen und die Strategie Nachhaltige Entwicklung Immobilien Verwaltungsvermögen Stadt Bern fliessen bei den jeweiligen Bestellungen an die Baufachorgane ein. Über eine eigens für den Zweck der Nachhaltigkeitsbeurteilung programmierte Datenbank-Software können alle Gebäude in den drei Nachhaltigkeitsdimensionen Gesellschaft, Wirtschaft und Umwelt beurteilt werden.',
        },
        {
          description:
            'Immobilien Stadt Bern hat sich mit dem Instrument des Nachhaltigen Immobilienmanagements in den letzten Jahren einen wertvollen Überblick über das Gesamtportfolio verschaffen. Die  Portfoliios des Fonds und des Verwaltungsvermögens werden nach den Grundsätzen der Nachhaltigen Entwicklung anhand der drei Dimensionen Gesellschaft, Wirtschaft und Umwelt überprüft.',
        },
        {
          description:
            'Das Nachhaltige Immobilienmanagement gibt folgende Abgrenzungen für eine Auswertung vor:\r\n- nur beheizte Liegenschaften\n- nur  Liegenschaften, welche ihren Standort in der Stadt Bern und Agglomeration haben\n- keine kurzfristig im Einsatz befindliche Modulbauten\n- keine Zumieten',
        },
        {
          description:
            'Mit der Publikation des Nachhaltigkeitsberichts 2020 werden zugleich auch die Daten der früheren Erhebungen in interaktiver Form veröffentlicht. Alle Datensätze der letzten Jahre wurden in Zusammenarbeit mit der Universität Bern erfasst, visualisiert und können nun individuell je nach Interesse miteinander verglichen werden.',
          bold: true,
        },
        {
          description:
            'Zusätzlich zur Visualisierungs-Homepage erscheint der Nachhaltigkeitsbericht 2020 zum letzten Mal in digitaler Berichtform: Ab 2021 beschränkt sich die jährliche Berichterstattung neben der interaktiven Form jeweils auf ein kurzes Faktenblatt mit zentralen Beobachtungen zur Nachhaltigen Entwicklung des städtischen Immobilienportfolios.',
          bold: true,
        },
        {
          description:
            'Nach 9 Jahren Berichterstattung im Finanz-/Fondsvermögen und 6 Jahren im Verwaltungsvermögen (siehe Nachhaltigkeitsberichte unten) kann festgehalten werden, dass im Bereich der Nachhaltigen Entwicklung einige Fortschritte erzielt wurden. Es bleibt aber noch viel zu tun, um in allen Teilportfolios die hochgesteckten Ziele zu erreichen.',
          bold: true,
        },
      ],
    },
    'nachhaltigkeitsrosette.fv': {
      title: 'Nachhaltigkeitsrosette',
      description:
        'Wie aus der Nachhaltigkeitsrosette des Gesamtportfolios hervorgeht, liegen die grössten "Hausaufgaben" des Finanz-/Fondsvermögen seit Anbeginn der Auswertungen in den Handlungsfeldern Nutzung/Erschliessung, Gebäude- und Bausubstanz, Anlagekosten und Betriebsenergie (Bewertung unter 3.0). Die tiefen Kennzahlen in diesen Handlungsfeldern können nur durch gesamtheitliche Bauprojekte optimiert werden.',
      items: [
        {
          title: 'Fonds Wohnliegenschaften',

          description:
            'Der grösste Schwachpunkt bei den Wohnliegenschaften ist die Betriebsenergie. Ein geringer Heizwärmebedarf durch bauliche und haustechnische Vorkehrungen sowie der Einsatz von mehr erneuerbaren Energien sind umzusetzen.',
        },
        {
          title: 'Fonds Geschäftsliegenschaften',
          description:
            'Die Anlagekosten und die Betriebsenergie sind zu optimieren. Investitionen sind unter Berücksichtigung der Lebenszykluskosten zu tätigen und externe Kosten durch den Einsatz erneuerbarer Energien zu verringern.',
        },
        {
          title: 'Fonds Landwirtschaft',
          description:
            'Der bei den landwirtschaftlichen Betrieben untergeordnete Punkt der Nutzung, Erschliessung schneidet unterdurchschnittlich ab. Alle anderen Handlungsfelder schliessen sehr positiv ab.',
        },
      ],
      tables: [
        {
          table: [
            {
              aspect: 'Aspekte',
              topics: [
                {
                  name: 'Themen',
                  criterias: ['Kriterien'],
                },
              ],
            },
            {
              aspect: 'Gesellschaft',
              topics: [
                {
                  name: 'Gemeinschaft',
                  criterias: [
                    'Integration, Durchmischung',
                    'Soziale Kontakte',
                    'Solidarität, Gerechtigkeit',
                    'Partizipation',
                  ],
                },
                {
                  name: 'Gestaltung',
                  criterias: [
                    'Räumliche Identität, Wiedererkennung',
                    'Individuelle Gestaltung, Personalisierung',
                  ],
                },
                {
                  name: 'Nutzung, Erschliessung',
                  criterias: [
                    'Grundversorgung, Nutzungsmischung',
                    'Langsamverkehr und öffentlicher Verkehr',
                    'Zugänglichkeit und Nutzbarkeit für alle',
                  ],
                },
                {
                  name: 'Wohlbefinden, Gesundheit',
                  criterias: [
                    'Sicherheit',
                    'Licht',
                    'Raumluft',
                    'Strahlung',
                    'Sommerlicher Wärmeschutz',
                    'Lärm, Erschütterungen',
                  ],
                },
              ],
            },
          ],
        },
        {
          table: [
            {
              aspect: 'Aspekte',
              topics: [
                {
                  name: 'Themen',
                  criterias: ['Kriterien'],
                },
              ],
            },
            {
              aspect: 'Wirtschaft',
              topics: [
                {
                  name: 'Gebäude-Bausubstanz',
                  criterias: [
                    'Standort',
                    'Bausubstanz',
                    'Gebäudestruktur, Ausbau',
                  ],
                },
                {
                  name: 'Anlagekosten',
                  criterias: [
                    'Lebenszykluskosten',
                    'Finanzierung',
                    'Externe Kosten',
                  ],
                },
                {
                  name: 'Betirebs- und Unterhaltskosten',
                  criterias: ['Betrieb und Instandhaltung', 'Instandsetzung'],
                },
                {
                  name: 'Wertschöpfung, Immobilienmarkt',
                  criterias: ['Mehrwert für Gebäude', 'Erträge'],
                },
              ],
            },
          ],
        },
        {
          table: [
            {
              aspect: 'Aspekte',
              topics: [
                {
                  name: 'Themen',
                  criterias: ['Kriterien'],
                },
              ],
            },
            {
              aspect: 'Umwelt',
              topics: [
                {
                  name: 'Baustoffe',
                  criterias: [
                    'Rohstoffe: Verfügbarkeit',
                    'Umweltbelastung',
                    'Schadstoffe',
                    'Rückbau',
                  ],
                },
                {
                  name: 'Betriebsenergie',
                  criterias: [
                    'Wärme für Raumklima',
                    'Wärme für Warmwasser',
                    'Elektrizität',
                    'Deckung Energiebedarf',
                  ],
                },
                {
                  name: 'Boden, Landschaft',
                  criterias: ['Grundstückfläche', 'Freianlagen'],
                },
                {
                  name: 'Infrastruktur',
                  criterias: [
                    'Mobilität',
                    'Abfälle aus Betrieb und Nutzung',
                    'Wasser',
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
    'nachhaltigkeitsrosette.vv.alt': {
      title: 'Nachhaltigkeitsrosette',
      description:
        'Das Jahr 2021 ist das letzte Berichtsjahr, in welchem die alte Systematik des Verwaltungsvermögens visualisiert wird. Aktuell wird ein neues webbasiertes Datenbanktool programmiert, bei welchem nur noch die neue Systematik abgebildet wird. \n Wie aus der Nachhaltigkeitsrosette des Gesamtportfolios hervorgeht, liegen die grössten "Hausaufgaben" des Verwaltungsvermögens seit Anbeginn der Auswertungen in den Handlungsfeldern Gebäude- und Bausubstanz, Anlagekosten, Betriebsenergie und Boden/Landschaft (Bewertung unter 3.0). Die tiefen Kennzahlen in diesen Handlungsfeldern können nur durch gesamtheitliche Bauprojekte optimiert werden.',
      items: [
        {
          title: 'Verwaltungsgebäude',
          description:
            'Die Anlagekosten müssen optimiert werden. Das bedeutet, dass Investitionen unter Berücksichtigung der Lebenszykluskosten zu tätigen und externe Kosten durch den Einsatz erneuerbarer Energien zu verringern sind.',
        },
        {
          title: 'Schulanlagen',
          description:
            'Die Anlagekosten müssen optimiert werden. Das bedeutet, dass Investitionen unter Berücksichtigung der Lebenszykluskosten zu tätigen und externe Kosten durch den Einsatz erneuerbarer Energien zu verringern sind.',
        },
        {
          title: 'Sportanlagen',
          description:
            'Die Handlungsfelder Gebäude-Bausubstanz und Infrastruktur sind zu optimieren. Diverse Sportanlagen stehen in den nächsten Jahren weit oben auf der Investitionsplanung.',
        },
        {
          title: 'Eis-Wasseranlagen',
          description:
            'Die Handlungsfelder Gebäude-Bausubstanz und Infrastruktur sind zu optimieren. Diverse Eis- und Wasseranlagen stehen in den nächsten Jahren weit oben auf der Investitionsplanung.',
        },
        {
          title: 'Kulturgebäude',
          description:
            'Das Handlungsfeld Infrastruktur hat Nachholbedarf. Für die Optimierung der Mobilität fehlt jedoch der Platz auf den jeweiligen Parzellen (fehlende Veloparkplätze).',
        },
        {
          title: 'Werkhöfe',
          description:
            'Die Handlungsfelder Gestaltung und Anlagekosten sind zu optimieren. Die Orientierung und räumliche Identität durch Wiedererkennung ist aktuell etwas eingeschränkt.',
        },
        {
          title: 'Jugend-/Quartiertreffs',
          description:
            'Die Anlagekosten müssen optimiert werden. Das bedeutet, dass Investitionen unter Berücksichtigung der Lebenszykluskosten zu tätigen und externe Kosten durch den Einsatz erneuerbarer Energien zu verringern sind.',
        },
        {
          title: 'Wohnhäuser/-heime',
          description:
            'Die Anlagekosten müssen optimiert werden. Das bedeutet, dass Investitionen unter Berücksichtigung der Lebenszykluskosten zu tätigen und externe Kosten durch den Einsatz erneuerbarer Energien zu verringern sind.',
        },
        {
          title: 'Restaurants',
          description:
            'Die Anlagekosten sowie die Betriebs- und Unterhaltskosten sind zu optimieren. Die Unterhaltskosten steigen, weil die jeweiligen Bausubstanzen älteren Datums sind und die letzten baulichen Eingriffe bereits längere Zeit zurückliegen.',
        },
      ],
      tables: [
        {
          table: [
            {
              aspect: 'Aspekte',
              topics: [
                {
                  name: 'Themen',
                  criterias: ['Kriterien'],
                },
              ],
            },
            {
              aspect: 'Gesellschaft',
              topics: [
                {
                  name: 'Gemeinschaft',
                  criterias: [
                    'Integration, Durchmischung',
                    'Soziale Kontakte',
                    'Solidarität, Gerechtigkeit',
                    'Partizipation',
                  ],
                },
                {
                  name: 'Gestaltung',
                  criterias: [
                    'Räumliche Identität, Wiedererkennung',
                    'Individuelle Gestaltung, Personalisierung',
                  ],
                },
                {
                  name: 'Nutzung, Erschliessung',
                  criterias: [
                    'Grundversorgung, Nutzungsmischung',
                    'Langsamverkehr und öffentlicher Verkehr',
                    'Zugänglichkeit und Nutzbarkeit für alle',
                  ],
                },
                {
                  name: 'Wohlbefinden, Gesundheit',
                  criterias: [
                    'Sicherheit',
                    'Licht',
                    'Raumluft',
                    'Strahlung',
                    'Sommerlicher Wärmeschutz',
                    'Lärm, Erschütterungen',
                  ],
                },
              ],
            },
          ],
        },
        {
          table: [
            {
              aspect: 'Aspekte',
              topics: [
                {
                  name: 'Themen',
                  criterias: ['Kriterien'],
                },
              ],
            },
            {
              aspect: 'Wirtschaft',
              topics: [
                {
                  name: 'Gebäude-Bausubstanz',
                  criterias: [
                    'Standort',
                    'Bausubstanz',
                    'Gebäudestruktur, Ausbau',
                  ],
                },
                {
                  name: 'Anlagekosten',
                  criterias: [
                    'Lebenszykluskosten',
                    'Finanzierung',
                    'Externe Kosten',
                  ],
                },
                {
                  name: 'Betirebs- und Unterhaltskosten',
                  criterias: ['Betrieb und Instandhaltung', 'Instandsetzung'],
                },
                {
                  name: 'Wertschöpfung, Immobilienmarkt',
                  criterias: ['Mehrwert für Gebäude', 'Erträge'],
                },
              ],
            },
          ],
        },
        {
          table: [
            {
              aspect: 'Aspekte',
              topics: [
                {
                  name: 'Themen',
                  criterias: ['Kriterien'],
                },
              ],
            },
            {
              aspect: 'Umwelt',
              topics: [
                {
                  name: 'Baustoffe',
                  criterias: [
                    'Rohstoffe: Verfügbarkeit',
                    'Umweltbelastung',
                    'Schadstoffe',
                    'Rückbau',
                  ],
                },
                {
                  name: 'Betriebsenergie',
                  criterias: [
                    'Wärme für Raumklima',
                    'Wärme für Warmwasser',
                    'Elektrizität',
                    'Deckung Energiebedarf',
                  ],
                },
                {
                  name: 'Boden, Landschaft',
                  criterias: ['Grundstückfläche', 'Freianlagen'],
                },
                {
                  name: 'Infrastruktur',
                  criterias: [
                    'Mobilität',
                    'Abfälle aus Betrieb und Nutzung',
                    'Wasser',
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
    'nachhaltigkeitsrosette.vv.neu': {
      title: 'Nachhaltigkeitsrosette',
      description:
        '2019 wurden die bisher verwendeten Gebäudekategorien im Verwaltungsvermögen in übergeordneten Bereichen zusammengefasst. Das bedeutet, dass beispielsweise die Sport- und Eis-/Wasseranlagen neu im Bereich Sport und Freizeit subsumiert werden. Weiter besteht der Bereich Kultur und Geselligkeit nach der Umstellung aus den drei Gebäudekategorien Kulturgebäude, Jugendtreffs/Quartierzentren und Restaurants.',
      items: [
        {
          title: 'Verwaltungsgebäude',
          description:
            'Die Anlagekosten müssen optimiert werden. Das bedeutet, dass Investitionen unter Berücksichtigung der Lebenszykluskosten zu tätigen sind und dass externe Kosten durch den Einsatz erneuerbarer Energien zu verringern sind.',
        },
        {
          title: 'Bildung',
          description:
            'Die Anlagekosten müssen optimiert werden. Das bedeutet, dass Investitionen unter Berücksichtigung der Lebenszykluskosten zu tätigen und externe Kosten durch den Einsatz erneuerbarer Energien zu verringern sind.',
        },
        {
          title: 'Sport und Freizeit',
          description:
            'Die Handlungsfelder Gebäude-Bausubstanz und Infrastruktur sind zu optimieren. Diverse Sportanlagen sowie Eis- und Wasseranlagen stehen in den nächsten Jahren weit oben auf der Investitionsplanung.',
        },
        {
          title: 'Wohnen und Aufenthalt',
          description:
            'Die Anlagekosten müssen optimiert werden. Das bedeutet, dass Investitionen unter Berücksichtigung der Lebenszykluskosten zu tätigen und externe Kosten durch den Einsatz erneuerbarer Energien zu verringern sind.',
        },
        {
          title: 'Kultur und Geselligkeit',
          description:
            'Die Anlagekosten müssen noch optimiert werden. Das bedeutet, dass Investitionen unter Berücksichtigung der Lebenszykluskosten zu tätigen und externe Kosten durch den Einsatz erneuerbarer Energien zu verringern sind.',
        },
        {
          title: 'Infrastrukturbauten',
          description:
            'Die Handlungsfelder Gestaltung und Anlagekosten sind zu optimieren. Die Orientierung und räumliche Identität durch Wiedererkennung ist aktuell etwas eingeschränkt.',
        },
      ],
      tables: [
        {
          table: [
            {
              aspect: 'Aspekte',
              topics: [
                {
                  name: 'Themen',
                  criterias: ['Kriterien'],
                },
              ],
            },
            {
              aspect: 'Gesellschaft',
              topics: [
                {
                  name: 'Gemeinschaft',
                  criterias: [
                    'Integration, Durchmischung',
                    'Soziale Kontakte',
                    'Solidarität, Gerechtigkeit',
                    'Partizipation',
                  ],
                },
                {
                  name: 'Gestaltung',
                  criterias: [
                    'Räumliche Identität, Wiedererkennung',
                    'Individuelle Gestaltung, Personalisierung',
                  ],
                },
                {
                  name: 'Nutzung, Erschliessung',
                  criterias: [
                    'Grundversorgung, Nutzungsmischung',
                    'Langsamverkehr und öffentlicher Verkehr',
                    'Zugänglichkeit und Nutzbarkeit für alle',
                  ],
                },
                {
                  name: 'Wohlbefinden, Gesundheit',
                  criterias: [
                    'Sicherheit',
                    'Licht',
                    'Raumluft',
                    'Strahlung',
                    'Sommerlicher Wärmeschutz',
                    'Lärm, Erschütterungen',
                  ],
                },
              ],
            },
          ],
        },
        {
          table: [
            {
              aspect: 'Aspekte',
              topics: [
                {
                  name: 'Themen',
                  criterias: ['Kriterien'],
                },
              ],
            },
            {
              aspect: 'Wirtschaft',
              topics: [
                {
                  name: 'Gebäude-Bausubstanz',
                  criterias: [
                    'Standort',
                    'Bausubstanz',
                    'Gebäudestruktur, Ausbau',
                  ],
                },
                {
                  name: 'Anlagekosten',
                  criterias: [
                    'Lebenszykluskosten',
                    'Finanzierung',
                    'Externe Kosten',
                  ],
                },
                {
                  name: 'Betirebs- und Unterhaltskosten',
                  criterias: ['Betrieb und Instandhaltung', 'Instandsetzung'],
                },
                {
                  name: 'Wertschöpfung, Immobilienmarkt',
                  criterias: ['Mehrwert für Gebäude', 'Erträge'],
                },
              ],
            },
          ],
        },
        {
          table: [
            {
              aspect: 'Aspekte',
              topics: [
                {
                  name: 'Themen',
                  criterias: ['Kriterien'],
                },
              ],
            },
            {
              aspect: 'Umwelt',
              topics: [
                {
                  name: 'Baustoffe',
                  criterias: [
                    'Rohstoffe: Verfügbarkeit',
                    'Umweltbelastung',
                    'Schadstoffe',
                    'Rückbau',
                  ],
                },
                {
                  name: 'Betriebsenergie',
                  criterias: [
                    'Wärme für Raumklima',
                    'Wärme für Warmwasser',
                    'Elektrizität',
                    'Deckung Energiebedarf',
                  ],
                },
                {
                  name: 'Boden, Landschaft',
                  criterias: ['Grundstückfläche', 'Freianlagen'],
                },
                {
                  name: 'Infrastruktur',
                  criterias: [
                    'Mobilität',
                    'Abfälle aus Betrieb und Nutzung',
                    'Wasser',
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
    'ausgewertete.fv': {
      title: 'Ausgewertete Gebäude',
      description:
        'Die Anzahl beheizter und somit bewerteter Gebäude im Portfolio des Fonds für Boden- und Wohnbaupolitik nimmt kontinuierlich zu.',
      items: [
        {
          title: 'Fonds Wohnliegenschaften',
          description:
            'Die Anzahl der ausgewerteten Wohngebäude nimmt wegen den regelmässigen Zukäufen zu.',
        },
        {
          title: 'Fonds Geschäftsliegenschaften',
          description:
            'Das Teilportfolio Geschäftsliegenschaften hat sich in den letzten Jahren am meisten verändert, da regelmässig Rückbauten und Transfers in oder von anderen Teilportfolios stattfinden.',
        },
        {
          title: 'Fonds Landwirtschaft',
          description:
            'Seit Anbeginn der Auswertungen kam es zu keinen Veränderungen, was dazu führt, dass seit Beginn immer dieselben drei landwirtschaftlichen Betriebe bewertet wurden.',
        },
      ],
    },
    'ausgewertete.vv.alt': {
      title: 'Ausgewertete Gebäude - alte Systematik',
      description:
        'Das Jahr 2021 ist das letzte Berichtsjahr, in welchem die alte Systematik des Verwaltungsvermögens visualisiert wird. Aktuell wird ein neues webbasiertes Datenbanktool programmiert, bei welchem nur noch die neue Systematik abgebildet wird.',
    },
    'ausgewertete.vv.neu': {
      title: 'Ausgewertete Gebäude - neue Systematik',
      description:
        'Die Anzahl beheizter und somit bewerteter Gebäude im Portfolio des Verwaltungsvermögens hat in den vergangenen Jahren zugenommen. Die Gebäude wurden gemäss der Zugehörigkeit zur Gebäudekategorie besichtigt und beurteilt.',
      items: [
        {
          title: 'Verwaltungsgebäude',
          description:
            'Im Auswertungsjahr 2021 wurde ein Bestandsgebäude zusätzlich erfasst.',
        },
        {
          title: 'Bildung',
          description:
            'Im Auswertungsjahr 2021 wurden infolge Sanierungsarbeiten und Rückbauten 10 Gebäude weniger bewertet.',
        },
        {
          title: 'Sport und Freizeit',
          description:
            'Wegen einem Rückbau wurde im Jahr 2021 ein Gebäude weniger ausgewertet.',
        },
        {
          title: 'Wohnen und Aufenthalt',
          description: '2021 wurden 3 Bestandsgebäude zusätzlich ausgewertet.',
        },
        {
          title: 'Kultur und Geselligkeit',
          description:
            'Die Anzahl der ausgewerteten Kulturgebäude, Jugend-/Quartiertreffs und Restaurants bleibt stabil.',
        },
        {
          title: 'Infrastrukturbauten',
          description: '2021 wurden 3 Bestandsgebäude zusätzlich ausgewertet.',
        },
      ],
    },
    'handlungsbedarf.fv': {
      title: 'Handlungsbedarf',
      description:
        'Bei der Analyse der Fondsliegenschaften weisen die meisten Liegenschaften einen absehbaren Handlungsbedarf auf. Weil in den letzten Jahren permanent Liegenschaften zugekauft wurden - oft ältere bzw. sanierungsbedürftige Liegenschaften - stehen diese bei den Nachhaltigkeitsbeurteilungen den erfolgreich realisierten Sanierungsprojekten gegenüber.',
    },
    'handlungsbedarf.vv': {
      title: 'Handlungsbedarf',
      description:
        'Bei der Analyse der Liegenschaften des Verwaltungsvermögens weisen die meisten Liegenschaften einen absehbaren Handlungsbedarf auf. Weil in den letzten Jahren regelmässig viele Liegenschaften saniert wurden - nimmt die Gebäudeanzahl bei den Liegenschaften ohne Handlungsbedarf stetig zu. 2021 ist die Anzahl Gebäude infolge laufender Sanierungsprojekte beim dringenden Handlungsbedarf gesunken.',
    },
    'baujahre.fv': {
      title: 'Baujahre',
      description:
        'Beim Fonds für Boden- und Wohnbaupolitik wurde der grösste Teil der beheizten Liegenschaften vor 1970 erbaut. Von 1970 bis heute wurden nur ca. 6% der Gebäude, welche aktuell in Fondsbesitz sind, neu erstellt.',
    },
    'baujahre.vv': {
      title: 'Baujahre',
      description:
        'Beim Verwaltungsvermögen wurde ein grosser Teil der beheizten Liegenschaften vor 1980 erbaut. Allerdings wurden auch von 1980 bis heute regelmässig einige Dutzend Gebäude pro Jahrzehnt neu erstellt.',
    },
    'denkmalpflege.fv': {
      title: 'Denkmalpflege',
      description:
        'Die Darstellung erfolgt bei der Denkmalpflege auf Teilgebäudeebene. Bei der Revision des Bauinventars im Jahr 2018 wurden im Portfolio des Finanz-/Fondsvermögen viele ehemals erhaltenswerte Gebäude als beachtenswert eingestuft.',
      items: [
        {
          title: 'Fonds Wohnliegenschaften',
          description:
            'Aktuell ist der grösste Teil der Wohnliegenschaften im denkmalpflegerischen Inventar als beachtenswert eingestuft.',
        },
        {
          title: 'Fonds Geschäftsliegenschaften',
          description:
            'Aktuell ist der grösste Teil der Geschäftsliegenschaften im denkmalpflegerischen Inventar als schützens- und erhaltenswert eingestuft.',
        },
        {
          title: 'Fonds Landwirtschaft',
          description:
            'Aktuell sind 2 von 3 Gebäuden des Teilportfolio Landwirtschaft nicht inventarisiert.',
        },
      ],
      link: {
        link: 'https://bauinventar.bern.ch/infos/inventarkategorien.pdf',
        text: 'Erklärungen zum Bauinventar',
      },
    },
    'denkmalpflege.vv.alt': {
      title: 'Denkmalpflege',
      description:
        'Das Jahr 2021 ist das letzte Berichtsjahr, in welchem die alte Systematik des Verwaltungsvermögens visualisiert wird. Aktuell wird ein neues webbasiertes Datenbanktool programmiert, bei welchem nur noch die neue Systematik abgebildet wird.',
    },
    'denkmalpflege.vv.neu': {
      title: 'Denkmalpflege',
      description:
        'Die Angaben zum denkmalpflegerischen Inventar im Verwaltungsvermögen werden erst seit der Revision des Bauinventars im Jahr 2018 erfasst.',
      items: [
        {
          title: 'Verwaltungsgebäude',
          description:
            'Die Hälfte der Verwaltungsgebäude befindet sich im Perimeter des UNESCO Weltkulturerbe.',
        },
        {
          title: 'Bildung',
          description:
            'Der mit Abstand grösste Teil der Bildungsgebäude ist als schützenswert inventarisiert.',
        },
        {
          title: 'Sport und Freizeit',
          description:
            '5 von 25 Anlagen des Teilportfolios Sport und Freizeit befinden sich im denkmalpflegerischen Inventar.',
        },
        {
          title: 'Wohnen und Aufenthalt',
          description:
            'Es befinden sich 4 von 13 Wohnhäusern/-heime im denkmalpflegerischen Inventar.',
        },
        {
          title: 'Kultur und Geselligkeit',
          description:
            '16 von 22 Gebäuden des Teilportfolio Kultur und Geselligkeit befinden sich im denkmalpflegerischen Inventar.',
        },
        {
          title: 'Infrastrukturbauten',
          description:
            'Es befinden sich 6 von 38 Infrastrukturbauten im denkmalpflegerischen Inventar.',
        },
      ],
      link: {
        link: 'https://bauinventar.bern.ch/infos/inventarkategorien.pdf',
        text: 'Erklärungen zum Bauinventar',
      },
    },
    'hindernisfrei.fv': {
      title: 'Hindernisfreier Zugang',
      description:
        'Seit Anbeginn der Auswertung wurde im Finanz-/Fondsvermögen bei diesem Thema der grösste Erfolg verbucht.',
      items: [
        {
          title: 'Fonds Wohnliegenschaften',
          description:
            'Bei der ersten Auswertung im Jahr 2012 wurden nur 14 hindernisfreie Wohnungen gezählt. 2021 konnte ISB bereits 308 hindernisfreie Wohnungen anbieten.',
        },
        {
          title: 'Fonds Geschäftsliegenschaften',
          description:
            'Auch bei den hindernisfreien Geschäftsliegenschaften hat mehr als eine Verdoppelung bei den angebotenen Flächen stattgefunden (Steigerung von 61 auf 137 hindernisfreie Geschäftsräume).',
        },
        {
          title: 'Fonds Landwirtschaft',
          description:
            'Da nur 3 landwirtschaftliche Betriebe ausgewertet werden und das Führen eines landwirtschaftlichen Betriebs mit (körperlichen) Einschränkungen nur in seltenen Ausnahmefällen in Frage kommen dürfte, wird der Hindernisfreiheit eine unbedeutende Rolle eingeräumt. Alle Pächterwohnungen sind aktuell nicht hindernisfrei zugänglich.',
        },
      ],
    },
    'hindernisfrei.vv.alt': {
      title: 'Hindernisfreier Zugang',
      description:
        'Das Jahr 2021 ist das letzte Berichtsjahr, in welchem die alte Systematik des Verwaltungsvermögens visualisiert wird. Aktuell wird ein neues webbasiertes Datenbanktool programmiert, bei welchem nur noch die neue Systematik abgebildet wird.',
    },
    'hindernisfrei.vv.neu': {
      title: 'Hindernisfreier Zugang',
      description:
        'Bei den beiden Teilportfolios Verwaltungsgebäude und Bildung sind prozentual am meisten Gebäude hindernisfrei zugänglich.',
      items: [
        {
          title: 'Verwaltungsgebäude',
          description:
            '13 der 17 Verwaltungsgebäude sind komplett hindernisfrei zugänglich. Die anderen 4 Verwaltungsgebäude sind im Erdgeschoss hindernisfrei zugänglich. ',
        },
        {
          title: 'Bildung',
          description:
            'In den letzten Jahren konnte wegen den vielen Sanierungen und Neubauten der Wert von 50 auf 72 Schulanlagen mit hindernisfreiem Zugang erhöht werden.',
        },
        {
          title: 'Sport und Freizeit',
          description:
            'Es sind 9 von 25 Sportanlagen komplett hindernisfrei zugänglich.',
        },
        {
          title: 'Wohnen und Aufenthalt',
          description:
            'Lediglich 1 von 13 Wohnhäuser/-heimen ist komplett hindernisfrei zugänglich.',
        },
        {
          title: 'Kultur und Geselligkeit',
          description:
            'Lediglich 4 von 22 Gebäuden des Teilportfolio Kultur und Geselligkeit sind komplett hindernisfrei zugänglich.',
        },
        {
          title: 'Infrastrukturbauten',
          description:
            'Lediglich 9 von 38 Infrastrukturbauten sind komplett hindernisfrei zugänglich.',
        },
      ],
    },
    'energietraeger.fv': {
      title: 'Energieträger',
      description:
        'Gemäss der Strategie Substitution 2021-2025 von ISB werden bis 2025 alle Öl- und Elektroheizungen ersetzt. Es wird angestrebt, alle Gasheizungen bis 2035 durch erneuerbare Energien zu ersetzen.',
      items: [
        {
          title: 'Fonds Wohnliegenschaften',
          description:
            'Die 3 wichtigsten Ziele im Energiebereich im Teilportfolio Wohnliegenschaften sind die Anschlüsse an die Fernwärme ewb, der Einsatz von Wärmepumpen und der Zubau bei den Photovoltaikanlagen.',
        },
        {
          title: 'Fonds Geschäftsliegenschaften',
          description:
            'Die 3 wichtigsten Ziele im Energiebereich im Teilportfolio Geschäftsliegenschaften sind die Anschlüsse an die Fernwärme ewb, der Einsatz von Wärmepumpen und der Zubau bei den Photovoltaikanlagen.',
        },
        {
          title: 'Fonds Landwirtschaft',
          description:
            'Von den drei landwirtschaftlichen Betrieben wird aktuell nur noch eine Liegenschaft fossil beheizt. Diese Ölheizung wird bis 2025 ersetzt.',
        },
      ],
    },
    'energietraeger.vv.alt': {
      title: 'Energieträger',
      description:
        'Das Jahr 2021 ist das letzte Berichtsjahr, in welchem die alte Systematik des Verwaltungsvermögens visualisiert wird. Aktuell wird ein neues webbasiertes Datenbanktool programmiert, bei welchem nur noch die neue Systematik abgebildet wird.',
    },
    'energietraeger.vv.neu': {
      title: 'Energieträger',
      description:
        'Gemäss der Strategie Substitution 2021-2025 von ISB werden bis 2025 alle Öl- und Elektroheizungen ersetzt. Es wird angestrebt, alle Gasheizungen bis 2035 durch erneuerbare Energien zu ersetzen.',
      items: [
        {
          title: 'Verwaltungsgebäude',
          description:
            'Es gibt nur noch wenige Verwaltungsgebäude, welche durch fossile Energieträger beheizt werden. Es wird angestrebt, die letzten Gasheizungen bis 2035 mit erneuerbaren Energieträgern zu ersetzen.',
        },
        {
          title: 'Bildung',
          description:
            'Demnächst werden viele Schulanlagen an die Fernwärme von ewb oder an Nahverbundprojekte angeschlossen. Auch durch den grossen geplanten Zubau an Photovoltaikanlagen wird das Teilportfolio schrittweise fossilfrei werden.',
        },
        {
          title: 'Sport und Freizeit',
          description:
            'Die 3 wichtigsten Ziele im Energiebereich von ISB sind die Anschlüsse an die Fernwärme ewb, der Einsatz von Wärmepumpen und der Zubau bei den Photovoltaikanlagen.',
        },
        {
          title: 'Wohnen und Aufenthalt',
          description:
            'Die 3 wichtigsten Ziele im Energiebereich von ISB sind die Anschlüsse an die Fernwärme ewb, der Einsatz von Wärmepumpen und der Zubau bei den Photovoltaikanlagen.',
        },
        {
          title: 'Kultur und Geselligkeit',
          description:
            'Es gibt nur noch ein Kulturgebäude und 4 Restaurants, welche fossil oder elektrisch beheizt werden. Die Öl- und Elektroheizungen müssen bis 2025 durch erneuerbare Energieträger ersetzt werden. Der Ersatz der Gasheizungen wird bis 2035 angestrebt.',
        },
        {
          title: 'Infrastrukturbauten',
          description:
            'Die 3 wichtigsten Ziele im Energiebereich von ISB sind die Anschlüsse an die Fernwärme ewb, der Einsatz von Wärmepumpen und der Zubau bei den Photovoltaikanlagen.',
        },
      ],
    },
    'erneuerbareenergien.fv': {
      title: 'Erneuerbare Energien',
      description:
        'Der behördenverbindliche Richtplan Energie Stadt Bern wurde per 1. November 2014 durch den Gemeinderat in Kraft gesetzt. Die darin formulierten Hauptziele bis 2035 umfassen die Umstellung der Wärmeversorgung auf 70% und die der Stromversorgung auf 80% erneuerbare Energieträger. ISB strebt ein fossilfreies Portfolio bis 2035 an.',
      items: [
        {
          title: 'Fonds Wohnliegenschaften',
          description:
            'Die drei wichtigsten Ziele im Energiebereich im Teilportfolio Wohnliegenschaften sind die Anschlüsse an die Fernwärme ewb, der Einsatz von Wärmepumpen und der Zubau bei den Photovoltaikanlagen.',
        },
        {
          title: 'Fonds Geschäftsliegenschaften',
          description:
            'Die drei wichtigsten Ziele im Energiebereich im Teilportfolio Geschäftsliegenschaften sind die Anschlüsse an die Fernwärme ewb, der Einsatz von Wärmepumpen und der Zubau bei den Photovoltaikanlagen.',
        },
        {
          title: 'Fonds Landwirtschaft',
          description:
            'Von den drei landwirtschaftlichen Betrieben wird aktuell nur noch eine Liegenschaft fossil beheizt. Diese Ölheizung wird bis 2025 ersetzt.',
        },
      ],
    },
    'erneuerbareenergien.vv.alt': {
      title: 'Erneuerbare Energien',
      description:
        'Das Jahr 2021 ist das letzte Berichtsjahr, in welchem die alte Systematik des Verwaltungsvermögens visualisiert wird. Aktuell wird ein neues webbasiertes Datenbanktool programmiert, bei welchem nur noch die neue Systematik abgebildet wird.',
    },
    'erneuerbareenergien.vv.neu': {
      title: 'Erneuerbare Energien',
      description:
        'Der behördenverbindliche Richtplan Energie Stadt Bern wurde per 1. November 2014 durch den Gemeinderat in Kraft gesetzt. Die darin formulierten Hauptziele bis 2035 umfassen die Umstellung der Wärmeversorgung auf 70% und die der Stromversorgung auf 80% erneuerbare Energieträger. ISB strebt ein fossilfreies Portfolio bis 2035 an.',
      items: [
        {
          title: 'Verwaltungsgebäude',
          description:
            'Es gibt nur noch wenige Verwaltungsgebäude, welche durch fossile Energieträger beheizt werden. Es wird angestrebt, dass die letzten Gasheizungen bis 2035 mit erneuerbaren Energieträgern ersetzt werden.',
        },
        {
          title: 'Bildung',
          description:
            'Demnächst werden viele Schulanlagen an die Fernwärme von ewb oder an Nahverbundprojekte angeschlossen. Auch durch den grossen geplanten Zubau an Photovoltaikanlagen wird das Teilportfolio schrittweise fossilfrei werden.',
        },
        {
          title: 'Sport und Freizeit',
          description:
            'Die drei wichtigsten Ziele im Energiebereich von ISB sind die Anschlüsse an die Fernwärme ewb, der Einsatz von Wärmepumpen und der Zubau bei den Photovoltaikanlagen.',
        },
        {
          title: 'Wohnen und Aufenthalt',
          description:
            'Die drei wichtigsten Ziele im Energiebereich von ISB sind die Anschlüsse an die Fernwärme ewb, der Einsatz von Wärmepumpen und der Zubau bei den Photovoltaikanlagen.',
        },
        {
          title: 'Kultur und Geselligkeit',
          description:
            'Es gibt nur noch ein Kulturgebäude und 4 Restaurants, welche fossil oder elektrisch beheizt werden. Die Öl- und Elektroheizungen müssen bis 2025 durch erneuerbare Energieträger ersetzt werden. Der Ersatz der Gasheizungen wird bis 2035 angestrebt.',
        },
        {
          title: 'Infrastrukturbauten',
          description:
            'Die drei wichtigsten Ziele im Energiebereich von ISB sind die Anschlüsse an die Fernwärme ewb, der Einsatz von Wärmepumpen und der Zubau bei den Photovoltaikanlagen.',
        },
      ],
    },
    'prog-energietraeger.fv': {
      title: 'Prognose Energieträger',
      description:
        'ISB hat die Strategie Substitution 2021-2025 für sämtliche Öl- und Elektroheizungen mit einer Umsetzung bis 2025 verabschiedet. Zudem wird angestrebt bis 2035 alle Gasheizungen durch erneuerbare Energieträger zu ersetzen.',
    },
    'prog-energietraeger.vv': {
      title: 'Prognose Energieträger',
      description:
        'ISB hat die Strategie Substitution 2021-2025 für sämtliche Öl- und Elektroheizungen mit einer Umsetzung bis 2025 verabschiedet. Zudem wird angestrebt bis 2035 alle Gasheizungen durch erneuerbare Energieträger zu ersetzen.',
    },
    'prog-photovoltaik.fv': {
      title: 'Prognose Photovoltaikanlagen',
      description:
        'Gemäss der Roadmap Photovoltaikanlagen von ISB werden Photovoltaikanlagen bei den meisten Neubauten und Gesamtsanierungen realisiert.',
    },
    'prog-photovoltaik.vv': {
      title: 'Prognose Photovoltaikanlagen',
      description:
        'Gemäss der Roadmap von ISB werden Photovoltaikanlagen bei den meisten Neubauten und Gesamtsanierungen realisiert.',
    },
    'vers-flaechen.fv': {
      title: 'Unversiegelte Flächen',
      description:
        'Ein haushälterischer Umgang mit Grundstückflächen ist anzustreben und der grösste Teil der nicht überbauten Parzellenfläche soll unversiegelt bleiben.',
      items: [
        {
          title: 'Fonds Wohnliegenschaften',
          description:
            'Es gibt bei den Wohnliegenschaften einen sehr grossen Anteil an unversiegelter Fläche. Der Anteil an naturnaher Umgebung ist aber noch ausbaufähig.',
        },
        {
          title: 'Fonds Geschäftsliegenschaften',
          description:
            'Es gibt bei den Geschäftsliegenschaften einen mittleren Anteil an unversiegelter Fläche. Der Anteil an naturnaher Umgebung ist aber noch ausbaufähig.',
        },
        {
          title: 'Fonds Landwirtschaft',
          description:
            'Der grösste Teil der landwirtschaftlichen Betriebe sind Agrarflächen. Das Thema Versiegelung ist hier somit bedeutungslos.',
        },
      ],
    },
    'vers-flaechen.vv.alt': {
      title: 'Unversiegelte Flächen',
      description:
        'Das Jahr 2021 ist das letzte Berichtsjahr, in welchem die alte Systematik des Verwaltungsvermögens visualisiert wird. Aktuell wird ein neues webbasiertes Datenbanktool programmiert, bei welchem nur noch die neue Systematik abgebildet wird.',
    },
    'vers-flaechen.vv.neu': {
      title: 'Unversiegelte Flächen',
      description:
        'Ein haushälterischer Umgang mit Grundstückflächen ist anzustreben und der grösste Teil der nicht überbauten Parzellenfläche soll unversiegelt sein.',
      items: [
        {
          title: 'Verwaltungsgebäude',
          description:
            'Es gibt bei den Verwaltungsgebäuden einen mittleren Anteil an unversiegelter Fläche. Der Anteil an naturnaher Umgebung ist aber noch ausbaufähig.',
        },
        {
          title: 'Bildung',
          description:
            'Es gibt bei den Bildungsgebäuden einen mittleren bis grossen Anteil an unversiegelter Fläche. Der Anteil an naturnaher Umgebung ist aber noch ausbaufähig.',
        },
        {
          title: 'Sport und Freizeit',
          description:
            'Es gibt im Teilportfolio Sport und Freizeit einen mittleren Anteil an unversiegelter Fläche. Der Anteil an naturnaher Umgebung ist aber noch ausbaufähig.',
        },
        {
          title: 'Wohnen und Aufenthalt',
          description:
            'Es gibt im Teilportfolio Wohnen und Aufenthalt einen grossen Anteil an unversiegelter Fläche. Der Anteil an naturnaher Umgebung ist aber noch ausbaufähig.',
        },
        {
          title: 'Kultur und Geselligkeit',
          description:
            'Es gibt im Teilportfolio Kultur und Geselligkeit einen mittleren Anteil an unversiegelter Fläche. Der Anteil an naturnaher Umgebung ist aber noch ausbaufähig.',
        },
        {
          title: 'Infrastrukturbauten',
          description:
            'Es gibt bei den Infrastrukturbauten einen mittleren Anteil an unversiegelter Fläche. Der Anteil an naturnaher Umgebung ist aber noch ausbaufähig.',
        },
      ],
    },
    'veloplaetze.fv': {
      title: 'Veloparkplätze',
      description:
        'Es sollen genügend Einrichtungen für die Veloparkierung in unmittelbarer Nähe der Nutzenden bereitstehen.',
      items: [
        {
          title: 'Fonds Wohnliegenschaften',
          description:
            'Im Idealfall wäre pro Zimmer ein Veloparkplatz vorhanden. Ein Zubau von Veloparkplätzen wird angestrebt.',
        },
        {
          title: 'Fonds Geschäftsliegenschaften',
          description:
            'Der Zubau von Veloparkplätzen wird in der Velostadt Bern fortgesetzt.',
        },
        {
          title: 'Fonds Landwirtschaft',
          description:
            'Es gibt bei landwirtschaftlichen Betrieben beinahe grenzenlos viele Veloabstellmöglichkeiten. Diese sind für die Auswertung jedoch unbedeutend.',
        },
      ],
    },
    'veloplaetze.vv.alt': {
      title: 'Veloparkplätze',
      description:
        'Das Jahr 2021 ist das letzte Berichtsjahr, in welchem die alte Systematik des Verwaltungsvermögens visualisiert wird. Aktuell wird ein neues webbasiertes Datenbanktool programmiert, bei welchem nur noch die neue Systematik abgebildet wird.',
    },
    'veloplaetze.vv.neu': {
      title: 'Veloparkplätze',
      description:
        'Es sollen genügend Einrichtungen für die Veloparkierung in unmittelbarer Nähe der Nutzenden bereitstehen.',
      items: [
        {
          title: 'Verwaltungsgebäude',
          description:
            'Mitarbeitenden und Nutzenden werden Veloparkplätze zur Verfügung gestellt.',
        },
        {
          title: 'Bildung',
          description:
            'Mitarbeitenden und Nutzenden werden Veloparkplätze zur Verfügung gestellt.',
        },
        {
          title: 'Sport und Freizeit',
          description:
            'Mitarbeitenden und Nutzenden werden Veloparkplätze zur Verfügung gestellt.',
        },
        {
          title: 'Wohnen und Aufenthalt',
          description:
            'Mitarbeitenden und Nutzenden werden Veloparkplätze zur Verfügung gestellt.',
        },
        {
          title: 'Kultur und Geselligkeit',
          description:
            'Mitarbeitenden und Nutzenden werden Veloparkplätze zur Verfügung gestellt.',
        },
        {
          title: 'Infrastrukturbauten',
          description:
            'Mitarbeitenden und Nutzenden werden Veloparkplätze zur Verfügung gestellt.',
        },
      ],
    },
    'autoplaetze.fv': {
      title: 'Autoparkplätze',
      description:
        'Es ist im Sinne der Nachhaltigen Entwicklung, wenn die Anzahl Autoparkplätze verringert werden kann, zwecks Verkehrsumlagerung auf den öffentlichen Verkehr.',
      items: [
        {
          title: 'Fonds Wohnliegenschaften',
          description:
            'Das Teilportfolio Wohnen ist in der Gesamtsicht autoarm. Es wird darauf geachtet, die Anzahl Autoparkplätze beizubehalten oder zu verringern.',
        },
        {
          title: 'Fonds Geschäftsliegenschaften',
          description:
            'Das Teilportfolio Geschäftsliegenschaften benötigt eine minimale Anzahl an Autoparkplätzen für Anlieferungen.',
        },
        {
          title: 'Fonds Landwirtschaft',
          description:
            'Beim Teilportfolio Landwirtschaft erübrigt sich eine Aussage zu den Autoparkplätzen, da auf einem grossen landwirtschaftlichen Betrieb die Parkbedürfnisse des Fuhrparks gegeben sind.',
        },
      ],
    },
    'autoplaetze.vv.alt': {
      title: 'Autoparkplätze',
      description:
        'Das Jahr 2021 ist das letzte Berichtsjahr, in welchem die alte Systematik des Verwaltungsvermögens visualisiert wird. Aktuell wird ein neues webbasiertes Datenbanktool programmiert, bei welchem nur noch die neue Systematik abgebildet wird.',
    },
    'autoplaetze.vv.neu': {
      title: 'Autoparkplätze',
      description:
        'Es ist im Sinne der Nachhaltigen Entwicklung, wenn die Anzahl Autoparkplätze verringert werden kann, zwecks Verkehrsumlagerung auf den öffentlichen Verkehr.',
      items: [
        {
          title: 'Verwaltungsgebäude',
          description:
            'Alle städtischen Verwaltungsgebäude sind mit dem öffentlichen Verkehr erreichbar. Es sind bei einigen Verwaltungsgebäuden eine minimale Anzahl an Autoparkplätzen vorhanden (Menschen mit Behinderung, Anlieferung etc.).',
        },
        {
          title: 'Bildung',
          description:
            'Es sind bei grösserem Schularealen eine minimale Anzahl an Autoparkplätzen vorhanden (Menschen mit Behinderung, Anlieferung etc.).',
        },
        {
          title: 'Sport und Freizeit',
          description:
            'Es sind Autoparkplätze für Mitarbeitende und Nutzende vorhanden.',
        },
        {
          title: 'Wohnen und Aufenthalt',
          description:
            'Es ist eine Minimalanzahl an Autoparkplätzen vorhanden (Menschen mit Behinderung, Anlieferung).',
        },
        {
          title: 'Kultur und Geselligkeit',
          description:
            'Es sind bei den Kulturgebäuden und Restaurants keine gekennzeichneten Autoparkplätze vorhanden. Eine Minimalanzahl an Autoparkplätzen gibt es bei Jugend-/Quartertreffs.',
        },
        {
          title: 'Infrastrukturbauten',
          description:
            'Es sind Autoparkplätze für den notwendigen Fuhrpark und für Mitarbeitende vorhanden.',
        },
      ],
    },
    empty: {
      title: '',
      description: '',
      items: [{ title: '', description: '', bold: true }],
    },
  };

  constructor() {}

  getInfoModalData(key: string): InfoModalData {
    return this.data[key];
  }
}
