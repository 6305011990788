export const veloplaetzeVv = [
  {
    id: 1,
    Veloplaetze: 23.4,
    Jahr: 2015,
    Kategorie: 'Verwaltungsgebäude (pro Objekt)',
  },
  {
    id: 2,
    Veloplaetze: 25.53,
    Jahr: 2016,
    Kategorie: 'Verwaltungsgebäude (pro Objekt)',
  },
  {
    id: 3,
    Veloplaetze: 26.12,
    Jahr: 2017,
    Kategorie: 'Verwaltungsgebäude (pro Objekt)',
  },
  {
    id: 4,
    Veloplaetze: 26.12,
    Jahr: 2018,
    Kategorie: 'Verwaltungsgebäude (pro Objekt)',
  },
  {
    id: 5,
    Veloplaetze: 27.56,
    Jahr: 2019,
    Kategorie: 'Verwaltungsgebäude (pro Objekt)',
  },
  {
    id: 6,
    Veloplaetze: 27.56,
    Jahr: 2020,
    Kategorie: 'Verwaltungsgebäude (pro Objekt)',
  },
  {
    id: 7,
    Veloplaetze: 27.12,
    Jahr: 2021,
    Kategorie: 'Verwaltungsgebäude (pro Objekt)',
  },
  {
    id: 8,
    Veloplaetze: 19.79,
    Jahr: 2015,
    Kategorie: 'Schulanlage (pro Objekt)',
  },
  {
    id: 9,
    Veloplaetze: 19.05,
    Jahr: 2016,
    Kategorie: 'Schulanlage (pro Objekt)',
  },
  {
    id: 10,
    Veloplaetze: 23.47,
    Jahr: 2017,
    Kategorie: 'Schulanlage (pro Objekt)',
  },
  {
    id: 11,
    Veloplaetze: 23.08,
    Jahr: 2018,
    Kategorie: 'Schulanlage (pro Objekt)',
  },
  {
    id: 12,
    Veloplaetze: 23.6,
    Jahr: 2019,
    Kategorie: 'Schulanlage (pro Objekt)',
  },
  {
    id: 13,
    Veloplaetze: 23.21,
    Jahr: 2020,
    Kategorie: 'Schulanlage (pro Objekt)',
  },
  {
    id: 14,
    Veloplaetze: 23.23,
    Jahr: 2021,
    Kategorie: 'Schulanlage (pro Objekt)',
  },
  {
    id: 15,
    Veloplaetze: 5.14,
    Jahr: 2016,
    Kategorie: 'Sportanlage (pro Objekt)',
  },
  {
    id: 16,
    Veloplaetze: 6.6,
    Jahr: 2017,
    Kategorie: 'Sportanlage (pro Objekt)',
  },
  {
    id: 17,
    Veloplaetze: 6.6,
    Jahr: 2018,
    Kategorie: 'Sportanlage (pro Objekt)',
  },
  {
    id: 18,
    Veloplaetze: 9,
    Jahr: 2019,
    Kategorie: 'Sportanlage (pro Objekt)',
  },
  {
    id: 19,
    Veloplaetze: 9,
    Jahr: 2020,
    Kategorie: 'Sportanlage (pro Objekt)',
  },
  {
    id: 20,
    Veloplaetze: 9.69,
    Jahr: 2021,
    Kategorie: 'Sportanlage (pro Objekt)',
  },
  {
    id: 21,
    Veloplaetze: 77.83,
    Jahr: 2016,
    Kategorie: 'Eis-Wasseranlage (pro Objekt)',
  },
  {
    id: 22,
    Veloplaetze: 77.83,
    Jahr: 2017,
    Kategorie: 'Eis-Wasseranlage (pro Objekt)',
  },
  {
    id: 23,
    Veloplaetze: 77.83,
    Jahr: 2018,
    Kategorie: 'Eis-Wasseranlage (pro Objekt)',
  },
  {
    id: 24,
    Veloplaetze: 77.83,
    Jahr: 2019,
    Kategorie: 'Eis-Wasseranlage (pro Objekt)',
  },
  {
    id: 25,
    Veloplaetze: 77.83,
    Jahr: 2020,
    Kategorie: 'Eis-Wasseranlage (pro Objekt)',
  },
  {
    id: 26,
    Veloplaetze: 77.83,
    Jahr: 2021,
    Kategorie: 'Eis-Wasseranlage (pro Objekt)',
  },
  {
    id: 27,
    Veloplaetze: 4,
    Jahr: 2017,
    Kategorie: 'Kulturgebäude (pro Objekt)',
  },
  {
    id: 28,
    Veloplaetze: 3.33,
    Jahr: 2018,
    Kategorie: 'Kulturgebäude (pro Objekt)',
  },
  {
    id: 29,
    Veloplaetze: 3.33,
    Jahr: 2019,
    Kategorie: 'Kulturgebäude (pro Objekt)',
  },
  {
    id: 30,
    Veloplaetze: 3.33,
    Jahr: 2020,
    Kategorie: 'Kulturgebäude (pro Objekt)',
  },
  {
    id: 31,
    Veloplaetze: 3.33,
    Jahr: 2021,
    Kategorie: 'Kulturgebäude (pro Objekt)',
  },
  {
    id: 32,
    Veloplaetze: 1.81,
    Jahr: 2017,
    Kategorie: 'Werkhöfe (pro Objekt)',
  },
  {
    id: 33,
    Veloplaetze: 1.81,
    Jahr: 2018,
    Kategorie: 'Werkhöfe (pro Objekt)',
  },
  {
    id: 34,
    Veloplaetze: 1.93,
    Jahr: 2019,
    Kategorie: 'Werkhöfe (pro Objekt)',
  },
  {
    id: 35,
    Veloplaetze: 6,
    Jahr: 2020,
    Kategorie: 'Werkhöfe (pro Objekt)',
  },
  {
    id: 36,
    Veloplaetze: 5.71,
    Jahr: 2021,
    Kategorie: 'Werkhöfe (pro Objekt)',
  },
  {
    id: 37,
    Veloplaetze: 1.25,
    Jahr: 2018,
    Kategorie: 'Jugend-/Quartiertreffs (pro Objekt)',
  },
  {
    id: 38,
    Veloplaetze: 1.25,
    Jahr: 2019,
    Kategorie: 'Jugend-/Quartiertreffs (pro Objekt)',
  },
  {
    id: 39,
    Veloplaetze: 1.25,
    Jahr: 2020,
    Kategorie: 'Jugend-/Quartiertreffs (pro Objekt)',
  },
  {
    id: 40,
    Veloplaetze: 1.25,
    Jahr: 2021,
    Kategorie: 'Jugend-/Quartiertreffs (pro Objekt)',
  },
  {
    id: 41,
    Veloplaetze: 4.56,
    Jahr: 2018,
    Kategorie: 'Wohnhäuser/-heime (pro Objekt)',
  },
  {
    id: 42,
    Veloplaetze: 4.1,
    Jahr: 2019,
    Kategorie: 'Wohnhäuser/-heime (pro Objekt)',
  },
  {
    id: 43,
    Veloplaetze: 4.1,
    Jahr: 2020,
    Kategorie: 'Wohnhäuser/-heime (pro Objekt)',
  },
  {
    id: 44,
    Veloplaetze: 3.77,
    Jahr: 2021,
    Kategorie: 'Wohnhäuser/-heime (pro Objekt)',
  },
  {
    id: 45,
    Veloplaetze: 0,
    Jahr: 2019,
    Kategorie: 'Restaurants (pro Objekt)',
  },
  {
    id: 46,
    Veloplaetze: 0,
    Jahr: 2020,
    Kategorie: 'Restaurants (pro Objekt)',
  },
  {
    id: 47,
    Veloplaetze: 0,
    Jahr: 2021,
    Kategorie: 'Restaurants (pro Objekt)',
  },
];
