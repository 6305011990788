<h1>Rechtliche Hinweise / Datenschutz</h1>
<h2>Urheberrechte</h2>

<p>
  Sämtliche Online-Dokumente und Webseiten sowie ihre Teile sind
  urheberrechtlich geschützt und dürfen nur zum privaten, wissenschaftlichen und
  nichtgewerblichen Gebrauch kopiert und ausgedruckt werden. Jegliche
  Wiedergabe, Weiterübertragung oder sonstiger Gebrauch der Information auf
  dieser Homepage für kommerzielle Zwecke ist untersagt.
</p>

<p>
  Ohne vorgängige schriftliche Genehmigung des Digital Sustainability Lab dürfen
  die Dokumente und Webseiten sowie ihre Teile weder vervielfältigt noch auf
  anderen Servern gespeichert, in Newsgroups, Blogs oder Online-Diensten
  eingespeist oder auf Datenträgern gespeichert werden. Dies gilt insbesondere
  für das Logo der Fachhochschule. Für die Verwendung von Bildern und dem Logo
  wenden Sie sich bitte an die Abteilung Kommunikation und Marketing.
</p>

<p>
  Das Zitieren der Dokumente und Webseiten unter korrekter Quellenangabe sowie
  das Setzen von Links auf unsere Website sind ausdrücklich erlaubt.
</p>
<h2>Gewährleistungs- und Haftungsausschluss</h2>
<p>
  Das Digital Sustainability Lab behandelt die Richtigkeit und Aktualisierung
  der Informationen auf seinen Seiten gemäss gesetzlichen Vorgaben prioritär. Es
  übernimmt jedoch keine Gewähr für Aktualität, Korrektheit, Vollständigkeit und
  Qualität der bereitgestellten Informationen. Es behält sich vor, die
  Informationen jederzeit und ohne Ankündigung anzupassen oder zu entfernen. Das
  Digital Sustainability Lab haftet nicht bei Schäden materieller oder
  immaterieller Art, welche aus dem Zugriff oder der Nutzung bzw. Nichtnutzung
  der veröffentlichten Informationen, durch Missbrauch der Verbindung oder durch
  technische Störungen entstanden sind. Das Digital Sustainability Lab hat
  fremde, d.h. nicht auf seinen Servern oder in seinem Einflussbereich liegende
  Websites, die mit dieser Website über Verknüpfungen (Hyperlinks) allenfalls
  verbunden sind, nicht überprüft und übernimmt keine Verantwortung für deren
  Inhalte.
</p>
<h2>Datenschutz</h2>
<p>
  Die Webseite verwendet keine Tracker und erfasst keine persönlichen Daten.
</p>
