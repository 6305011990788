export const nachhaltigkeitsrosetteVV = [
  {
    id: 1,
    Jahr: 2015,
    Kategorie: 'Gesamt',
    Gemeinschaft: 3.8,
    Gestaltung: 3.6,
    Nutzung_Erschliessung: 3.4,
    Wohlbefinden_Gesundheit: 4,
    Gebaeude_Bausubstanz: 2.7,
    Anlagekosten: 2,
    Betriebs_Unterhaltskosten: 3.3,
    Wertschoepfung_Immobilienmarkt: 3.1,
    Baustoffe: 3.5,
    Betriebsenergie: 2.5,
    Boden_Natur_Landschaft: 2.6,
    Infrastruktur: 2.1,
  },
  {
    id: 2,
    Jahr: 2015,
    Kategorie: 'Verwaltungsgebäude',
    Gemeinschaft: 3.5,
    Gestaltung: 3.6,
    Nutzung_Erschliessung: 4.7,
    Wohlbefinden_Gesundheit: 3.8,
    Gebaeude_Bausubstanz: 3.5,
    Anlagekosten: 2.7,
    Betriebs_Unterhaltskosten: 3.9,
    Wertschoepfung_Immobilienmarkt: 3,
    Baustoffe: 3.8,
    Betriebsenergie: 3.2,
    Boden_Natur_Landschaft: 3.5,
    Infrastruktur: 2.7,
  },
  {
    id: 3,
    Jahr: 2015,
    Kategorie: 'Schulen',
    Gemeinschaft: 3.8,
    Gestaltung: 3.6,
    Nutzung_Erschliessung: 3.3,
    Wohlbefinden_Gesundheit: 4,
    Gebaeude_Bausubstanz: 2.6,
    Anlagekosten: 2,
    Betriebs_Unterhaltskosten: 3.2,
    Wertschoepfung_Immobilienmarkt: 3.1,
    Baustoffe: 3.5,
    Betriebsenergie: 2.4,
    Boden_Natur_Landschaft: 2.5,
    Infrastruktur: 2.1,
  },
  {
    id: 4,
    Jahr: 2016,
    Kategorie: 'Gesamt',
    Gemeinschaft: 3.9,
    Gestaltung: 3.5,
    Nutzung_Erschliessung: 3.4,
    Wohlbefinden_Gesundheit: 3.9,
    Gebaeude_Bausubstanz: 2.7,
    Anlagekosten: 2,
    Betriebs_Unterhaltskosten: 3.2,
    Wertschoepfung_Immobilienmarkt: 3.3,
    Baustoffe: 3.6,
    Betriebsenergie: 2.5,
    Boden_Natur_Landschaft: 2.7,
    Infrastruktur: 2,
  },
  {
    id: 5,
    Jahr: 2016,
    Kategorie: 'Verwaltungsgebäude',
    Gemeinschaft: 3.5,
    Gestaltung: 3.5,
    Nutzung_Erschliessung: 4.6,
    Wohlbefinden_Gesundheit: 3.7,
    Gebaeude_Bausubstanz: 3.4,
    Anlagekosten: 2.3,
    Betriebs_Unterhaltskosten: 3.8,
    Wertschoepfung_Immobilienmarkt: 3.2,
    Baustoffe: 3.8,
    Betriebsenergie: 3.2,
    Boden_Natur_Landschaft: 3.4,
    Infrastruktur: 2.5,
  },
  {
    id: 6,
    Jahr: 2016,
    Kategorie: 'Schulen',
    Gemeinschaft: 3.8,
    Gestaltung: 3.6,
    Nutzung_Erschliessung: 3.3,
    Wohlbefinden_Gesundheit: 4,
    Gebaeude_Bausubstanz: 2.7,
    Anlagekosten: 2,
    Betriebs_Unterhaltskosten: 3.2,
    Wertschoepfung_Immobilienmarkt: 3.2,
    Baustoffe: 3.5,
    Betriebsenergie: 2.4,
    Boden_Natur_Landschaft: 2.5,
    Infrastruktur: 2,
  },
  {
    id: 7,
    Jahr: 2016,
    Kategorie: 'Sportanlagen',
    Gemeinschaft: 4.5,
    Gestaltung: 2.6,
    Nutzung_Erschliessung: 3.2,
    Wohlbefinden_Gesundheit: 4,
    Gebaeude_Bausubstanz: 2.5,
    Anlagekosten: 2.2,
    Betriebs_Unterhaltskosten: 3,
    Wertschoepfung_Immobilienmarkt: 4,
    Baustoffe: 3.7,
    Betriebsenergie: 2.3,
    Boden_Natur_Landschaft: 3.5,
    Infrastruktur: 1.3,
  },
  {
    id: 8,
    Jahr: 2016,
    Kategorie: 'Eis-Wasseranlagen',
    Gemeinschaft: 3.8,
    Gestaltung: 2.2,
    Nutzung_Erschliessung: 2.3,
    Wohlbefinden_Gesundheit: 3.4,
    Gebaeude_Bausubstanz: 1.9,
    Anlagekosten: 2.8,
    Betriebs_Unterhaltskosten: 1.6,
    Wertschoepfung_Immobilienmarkt: 4,
    Baustoffe: 3.1,
    Betriebsenergie: 2.4,
    Boden_Natur_Landschaft: 2.9,
    Infrastruktur: 1.5,
  },
  {
    id: 9,
    Jahr: 2017,
    Kategorie: 'Gesamt',
    Gemeinschaft: 3.9,
    Gestaltung: 3.5,
    Nutzung_Erschliessung: 3.4,
    Wohlbefinden_Gesundheit: 4,
    Gebaeude_Bausubstanz: 2.7,
    Anlagekosten: 2,
    Betriebs_Unterhaltskosten: 3.2,
    Wertschoepfung_Immobilienmarkt: 3.2,
    Baustoffe: 3.5,
    Betriebsenergie: 2.5,
    Boden_Natur_Landschaft: 2.7,
    Infrastruktur: 2.3,
  },
  {
    id: 10,
    Jahr: 2017,
    Kategorie: 'Verwaltungsgebäude',
    Gemeinschaft: 3.5,
    Gestaltung: 3.5,
    Nutzung_Erschliessung: 4.6,
    Wohlbefinden_Gesundheit: 3.7,
    Gebaeude_Bausubstanz: 3.4,
    Anlagekosten: 2.3,
    Betriebs_Unterhaltskosten: 3.9,
    Wertschoepfung_Immobilienmarkt: 3.2,
    Baustoffe: 3.8,
    Betriebsenergie: 3.2,
    Boden_Natur_Landschaft: 3.4,
    Infrastruktur: 2.6,
  },
  {
    id: 11,
    Jahr: 2017,
    Kategorie: 'Schulen',
    Gemeinschaft: 3.9,
    Gestaltung: 3.7,
    Nutzung_Erschliessung: 3.4,
    Wohlbefinden_Gesundheit: 4.1,
    Gebaeude_Bausubstanz: 2.7,
    Anlagekosten: 1.9,
    Betriebs_Unterhaltskosten: 3.2,
    Wertschoepfung_Immobilienmarkt: 3.2,
    Baustoffe: 3.6,
    Betriebsenergie: 2.5,
    Boden_Natur_Landschaft: 2.5,
    Infrastruktur: 2.4,
  },
  {
    id: 12,
    Jahr: 2017,
    Kategorie: 'Sportanlagen',
    Gemeinschaft: 4.5,
    Gestaltung: 2.6,
    Nutzung_Erschliessung: 3.2,
    Wohlbefinden_Gesundheit: 3.9,
    Gebaeude_Bausubstanz: 2.4,
    Anlagekosten: 2.3,
    Betriebs_Unterhaltskosten: 2.8,
    Wertschoepfung_Immobilienmarkt: 3.7,
    Baustoffe: 3.6,
    Betriebsenergie: 2.2,
    Boden_Natur_Landschaft: 3.7,
    Infrastruktur: 1.4,
  },
  {
    id: 13,
    Jahr: 2017,
    Kategorie: 'Eis-Wasseranlagen',
    Gemeinschaft: 3.8,
    Gestaltung: 2.2,
    Nutzung_Erschliessung: 2.3,
    Wohlbefinden_Gesundheit: 3.6,
    Gebaeude_Bausubstanz: 1.9,
    Anlagekosten: 2.8,
    Betriebs_Unterhaltskosten: 1.9,
    Wertschoepfung_Immobilienmarkt: 4,
    Baustoffe: 3.1,
    Betriebsenergie: 2.4,
    Boden_Natur_Landschaft: 2.9,
    Infrastruktur: 1.5,
  },
  {
    id: 14,
    Jahr: 2017,
    Kategorie: 'Kulturgebäude',
    Gemeinschaft: 3.9,
    Gestaltung: 4.6,
    Nutzung_Erschliessung: 3.9,
    Wohlbefinden_Gesundheit: 4,
    Gebaeude_Bausubstanz: 3.4,
    Anlagekosten: 2.6,
    Betriebs_Unterhaltskosten: 3.6,
    Wertschoepfung_Immobilienmarkt: 2.8,
    Baustoffe: 3.1,
    Betriebsenergie: 3,
    Boden_Natur_Landschaft: 4.2,
    Infrastruktur: 2.1,
  },
  {
    id: 15,
    Jahr: 2017,
    Kategorie: 'Werkhöfe',
    Gemeinschaft: 3.8,
    Gestaltung: 2.2,
    Nutzung_Erschliessung: 3.2,
    Wohlbefinden_Gesundheit: 3.7,
    Gebaeude_Bausubstanz: 2.5,
    Anlagekosten: 2.1,
    Betriebs_Unterhaltskosten: 3,
    Wertschoepfung_Immobilienmarkt: 2.2,
    Baustoffe: 3.4,
    Betriebsenergie: 2.5,
    Boden_Natur_Landschaft: 2.4,
    Infrastruktur: 2.9,
  },
  {
    id: 16,
    Jahr: 2018,
    Kategorie: 'Gesamt',
    Gemeinschaft: 3.9,
    Gestaltung: 3.5,
    Nutzung_Erschliessung: 3.4,
    Wohlbefinden_Gesundheit: 4,
    Gebaeude_Bausubstanz: 2.7,
    Anlagekosten: 2,
    Betriebs_Unterhaltskosten: 3.1,
    Wertschoepfung_Immobilienmarkt: 3.1,
    Baustoffe: 3.5,
    Betriebsenergie: 2.6,
    Boden_Natur_Landschaft: 2.8,
    Infrastruktur: 2.7,
  },
  {
    id: 17,
    Jahr: 2018,
    Kategorie: 'Verwaltungsgebäude',
    Gemeinschaft: 3.5,
    Gestaltung: 3.5,
    Nutzung_Erschliessung: 4.6,
    Wohlbefinden_Gesundheit: 3.5,
    Gebaeude_Bausubstanz: 3.4,
    Anlagekosten: 2.3,
    Betriebs_Unterhaltskosten: 3.7,
    Wertschoepfung_Immobilienmarkt: 3.2,
    Baustoffe: 3.8,
    Betriebsenergie: 3.2,
    Boden_Natur_Landschaft: 3.4,
    Infrastruktur: 2.9,
  },
  {
    id: 18,
    Jahr: 2018,
    Kategorie: 'Schulen',
    Gemeinschaft: 3.9,
    Gestaltung: 3.7,
    Nutzung_Erschliessung: 3.4,
    Wohlbefinden_Gesundheit: 4.1,
    Gebaeude_Bausubstanz: 2.7,
    Anlagekosten: 1.9,
    Betriebs_Unterhaltskosten: 3.2,
    Wertschoepfung_Immobilienmarkt: 3.1,
    Baustoffe: 3.6,
    Betriebsenergie: 2.5,
    Boden_Natur_Landschaft: 2.5,
    Infrastruktur: 2.7,
  },
  {
    id: 19,
    Jahr: 2018,
    Kategorie: 'Sportanlagen',
    Gemeinschaft: 4.5,
    Gestaltung: 2.6,
    Nutzung_Erschliessung: 3.2,
    Wohlbefinden_Gesundheit: 4,
    Gebaeude_Bausubstanz: 2.4,
    Anlagekosten: 2.3,
    Betriebs_Unterhaltskosten: 2.7,
    Wertschoepfung_Immobilienmarkt: 3.7,
    Baustoffe: 3.6,
    Betriebsenergie: 2.3,
    Boden_Natur_Landschaft: 3.7,
    Infrastruktur: 1.9,
  },
  {
    id: 20,
    Jahr: 2018,
    Kategorie: 'Eis-Wasseranlagen',
    Gemeinschaft: 3.8,
    Gestaltung: 2.2,
    Nutzung_Erschliessung: 2.3,
    Wohlbefinden_Gesundheit: 3.6,
    Gebaeude_Bausubstanz: 1.9,
    Anlagekosten: 2.8,
    Betriebs_Unterhaltskosten: 2,
    Wertschoepfung_Immobilienmarkt: 4,
    Baustoffe: 3.1,
    Betriebsenergie: 2.4,
    Boden_Natur_Landschaft: 2.9,
    Infrastruktur: 1.9,
  },
  {
    id: 21,
    Jahr: 2018,
    Kategorie: 'Kulturgebäude',
    Gemeinschaft: 3.9,
    Gestaltung: 4.7,
    Nutzung_Erschliessung: 4.1,
    Wohlbefinden_Gesundheit: 3.9,
    Gebaeude_Bausubstanz: 3.4,
    Anlagekosten: 2.7,
    Betriebs_Unterhaltskosten: 3.2,
    Wertschoepfung_Immobilienmarkt: 2.8,
    Baustoffe: 3.3,
    Betriebsenergie: 3.4,
    Boden_Natur_Landschaft: 4.3,
    Infrastruktur: 1.9,
  },
  {
    id: 22,
    Jahr: 2018,
    Kategorie: 'Werkhöfe',
    Gemeinschaft: 3.8,
    Gestaltung: 2.2,
    Nutzung_Erschliessung: 3.2,
    Wohlbefinden_Gesundheit: 3.7,
    Gebaeude_Bausubstanz: 2.5,
    Anlagekosten: 2.1,
    Betriebs_Unterhaltskosten: 3.4,
    Wertschoepfung_Immobilienmarkt: 2.2,
    Baustoffe: 3.4,
    Betriebsenergie: 2.5,
    Boden_Natur_Landschaft: 2.4,
    Infrastruktur: 2.9,
  },
  {
    id: 23,
    Jahr: 2018,
    Kategorie: 'Jugend-/Quartiertreffs',
    Gemeinschaft: 4.8,
    Gestaltung: 4.3,
    Nutzung_Erschliessung: 3.5,
    Wohlbefinden_Gesundheit: 3.3,
    Gebaeude_Bausubstanz: 2.7,
    Anlagekosten: 2.2,
    Betriebs_Unterhaltskosten: 2.7,
    Wertschoepfung_Immobilienmarkt: 2.9,
    Baustoffe: 3,
    Betriebsenergie: 2.8,
    Boden_Natur_Landschaft: 2.6,
    Infrastruktur: 3,
  },
  {
    id: 24,
    Jahr: 2018,
    Kategorie: 'Wohnhäuser/-heime',
    Gemeinschaft: 4.4,
    Gestaltung: 4.6,
    Nutzung_Erschliessung: 2.9,
    Wohlbefinden_Gesundheit: 4.3,
    Gebaeude_Bausubstanz: 2.7,
    Anlagekosten: 2.3,
    Betriebs_Unterhaltskosten: 3,
    Wertschoepfung_Immobilienmarkt: 2.7,
    Baustoffe: 3.9,
    Betriebsenergie: 2.6,
    Boden_Natur_Landschaft: 3.6,
    Infrastruktur: 3.8,
  },
  {
    id: 25,
    Jahr: 2019,
    Kategorie: 'Gesamt',
    Gemeinschaft: 4,
    Gestaltung: 3.6,
    Nutzung_Erschliessung: 3.4,
    Wohlbefinden_Gesundheit: 4.1,
    Gebaeude_Bausubstanz: 2.8,
    Anlagekosten: 2.1,
    Betriebs_Unterhaltskosten: 3.2,
    Wertschoepfung_Immobilienmarkt: 3.1,
    Baustoffe: 3.5,
    Betriebsenergie: 2.6,
    Boden_Natur_Landschaft: 2.8,
    Infrastruktur: 2.9,
  },
  {
    id: 26,
    Jahr: 2019,
    Kategorie: 'Verwaltungsgebäude',
    Gemeinschaft: 3.6,
    Gestaltung: 3.6,
    Nutzung_Erschliessung: 4.7,
    Wohlbefinden_Gesundheit: 3.7,
    Gebaeude_Bausubstanz: 3.5,
    Anlagekosten: 2.4,
    Betriebs_Unterhaltskosten: 4,
    Wertschoepfung_Immobilienmarkt: 3.3,
    Baustoffe: 3.8,
    Betriebsenergie: 3.3,
    Boden_Natur_Landschaft: 3.4,
    Infrastruktur: 3.3,
  },
  {
    id: 27,
    Jahr: 2019,
    Kategorie: 'Schulen',
    Gemeinschaft: 3.9,
    Gestaltung: 3.7,
    Nutzung_Erschliessung: 3.4,
    Wohlbefinden_Gesundheit: 4.2,
    Gebaeude_Bausubstanz: 2.8,
    Anlagekosten: 2.1,
    Betriebs_Unterhaltskosten: 3.2,
    Wertschoepfung_Immobilienmarkt: 3.2,
    Baustoffe: 3.6,
    Betriebsenergie: 2.5,
    Boden_Natur_Landschaft: 2.6,
    Infrastruktur: 3,
  },
  {
    id: 28,
    Jahr: 2019,
    Kategorie: 'Sportanlagen',
    Gemeinschaft: 4.5,
    Gestaltung: 2.6,
    Nutzung_Erschliessung: 3.2,
    Wohlbefinden_Gesundheit: 4.1,
    Gebaeude_Bausubstanz: 2.4,
    Anlagekosten: 2.3,
    Betriebs_Unterhaltskosten: 2.9,
    Wertschoepfung_Immobilienmarkt: 3.7,
    Baustoffe: 3.5,
    Betriebsenergie: 2.3,
    Boden_Natur_Landschaft: 3.8,
    Infrastruktur: 2,
  },
  {
    id: 29,
    Jahr: 2019,
    Kategorie: 'Eis-Wasseranlagen',
    Gemeinschaft: 3.8,
    Gestaltung: 2.2,
    Nutzung_Erschliessung: 2.3,
    Wohlbefinden_Gesundheit: 3.6,
    Gebaeude_Bausubstanz: 1.9,
    Anlagekosten: 2.9,
    Betriebs_Unterhaltskosten: 2.4,
    Wertschoepfung_Immobilienmarkt: 4,
    Baustoffe: 3,
    Betriebsenergie: 2.4,
    Boden_Natur_Landschaft: 2.9,
    Infrastruktur: 2.2,
  },
  {
    id: 30,
    Jahr: 2019,
    Kategorie: 'Kulturgebäude',
    Gemeinschaft: 3.9,
    Gestaltung: 4.7,
    Nutzung_Erschliessung: 4.1,
    Wohlbefinden_Gesundheit: 3.9,
    Gebaeude_Bausubstanz: 3.4,
    Anlagekosten: 2.7,
    Betriebs_Unterhaltskosten: 3.8,
    Wertschoepfung_Immobilienmarkt: 2.8,
    Baustoffe: 3.3,
    Betriebsenergie: 3,
    Boden_Natur_Landschaft: 4.3,
    Infrastruktur: 1.9,
  },
  {
    id: 31,
    Jahr: 2019,
    Kategorie: 'Werkhöfe',
    Gemeinschaft: 3.8,
    Gestaltung: 2.2,
    Nutzung_Erschliessung: 3.1,
    Wohlbefinden_Gesundheit: 3.6,
    Gebaeude_Bausubstanz: 2.5,
    Anlagekosten: 2.1,
    Betriebs_Unterhaltskosten: 3.2,
    Wertschoepfung_Immobilienmarkt: 2.3,
    Baustoffe: 3.4,
    Betriebsenergie: 2.3,
    Boden_Natur_Landschaft: 2.5,
    Infrastruktur: 3,
  },
  {
    id: 32,
    Jahr: 2019,
    Kategorie: 'Jugend-/Quartiertreffs',
    Gemeinschaft: 4.8,
    Gestaltung: 4.3,
    Nutzung_Erschliessung: 3.5,
    Wohlbefinden_Gesundheit: 3.3,
    Gebaeude_Bausubstanz: 2.7,
    Anlagekosten: 2.2,
    Betriebs_Unterhaltskosten: 2.8,
    Wertschoepfung_Immobilienmarkt: 2.9,
    Baustoffe: 3,
    Betriebsenergie: 2.6,
    Boden_Natur_Landschaft: 2.6,
    Infrastruktur: 3,
  },
  {
    id: 33,
    Jahr: 2019,
    Kategorie: 'Wohnhäuser/-heime',
    Gemeinschaft: 4.4,
    Gestaltung: 4.4,
    Nutzung_Erschliessung: 2.9,
    Wohlbefinden_Gesundheit: 4.3,
    Gebaeude_Bausubstanz: 2.7,
    Anlagekosten: 2.3,
    Betriebs_Unterhaltskosten: 2.9,
    Wertschoepfung_Immobilienmarkt: 2.8,
    Baustoffe: 4,
    Betriebsenergie: 2.7,
    Boden_Natur_Landschaft: 3.5,
    Infrastruktur: 3.5,
  },
  {
    id: 34,
    Jahr: 2019,
    Kategorie: 'Restaurants',
    Gemeinschaft: 3.5,
    Gestaltung: 3,
    Nutzung_Erschliessung: 2.3,
    Wohlbefinden_Gesundheit: 3.7,
    Gebaeude_Bausubstanz: 2.5,
    Anlagekosten: 1.8,
    Betriebs_Unterhaltskosten: 3.3,
    Wertschoepfung_Immobilienmarkt: 2.8,
    Baustoffe: 3,
    Betriebsenergie: 2.8,
    Boden_Natur_Landschaft: 2.5,
    Infrastruktur: 2.3,
  },
  {
    id: 35,
    Jahr: 2020,
    Kategorie: 'Gesamt',
    Gemeinschaft: 4,
    Gestaltung: 3.6,
    Nutzung_Erschliessung: 3.4,
    Wohlbefinden_Gesundheit: 4.1,
    Gebaeude_Bausubstanz: 2.8,
    Anlagekosten: 2.2,
    Betriebs_Unterhaltskosten: 3.2,
    Wertschoepfung_Immobilienmarkt: 3.1,
    Baustoffe: 3.5,
    Betriebsenergie: 2.6,
    Boden_Natur_Landschaft: 2.8,
    Infrastruktur: 3.2,
  },
  {
    id: 36,
    Jahr: 2020,
    Kategorie: 'Verwaltungsgebäude',
    Gemeinschaft: 3.6,
    Gestaltung: 3.6,
    Nutzung_Erschliessung: 4.7,
    Wohlbefinden_Gesundheit: 3.7,
    Gebaeude_Bausubstanz: 3.5,
    Anlagekosten: 2.5,
    Betriebs_Unterhaltskosten: 3.9,
    Wertschoepfung_Immobilienmarkt: 3.3,
    Baustoffe: 3.8,
    Betriebsenergie: 3.3,
    Boden_Natur_Landschaft: 3.4,
    Infrastruktur: 3.3,
  },
  {
    id: 37,
    Jahr: 2020,
    Kategorie: 'Schulen',
    Gemeinschaft: 3.9,
    Gestaltung: 3.8,
    Nutzung_Erschliessung: 3.5,
    Wohlbefinden_Gesundheit: 4.2,
    Gebaeude_Bausubstanz: 2.9,
    Anlagekosten: 2.1,
    Betriebs_Unterhaltskosten: 3.3,
    Wertschoepfung_Immobilienmarkt: 3.2,
    Baustoffe: 3.6,
    Betriebsenergie: 2.7,
    Boden_Natur_Landschaft: 2.6,
    Infrastruktur: 3.3,
  },
  {
    id: 38,
    Jahr: 2020,
    Kategorie: 'Sportanlagen',
    Gemeinschaft: 4.5,
    Gestaltung: 2.6,
    Nutzung_Erschliessung: 3.2,
    Wohlbefinden_Gesundheit: 4.1,
    Gebaeude_Bausubstanz: 2.4,
    Anlagekosten: 2.3,
    Betriebs_Unterhaltskosten: 2.8,
    Wertschoepfung_Immobilienmarkt: 3.7,
    Baustoffe: 3.5,
    Betriebsenergie: 2.4,
    Boden_Natur_Landschaft: 3.8,
    Infrastruktur: 2.6,
  },
  {
    id: 39,
    Jahr: 2020,
    Kategorie: 'Eis-Wasseranlagen',
    Gemeinschaft: 3.8,
    Gestaltung: 2.2,
    Nutzung_Erschliessung: 2.3,
    Wohlbefinden_Gesundheit: 3.7,
    Gebaeude_Bausubstanz: 1.9,
    Anlagekosten: 2.9,
    Betriebs_Unterhaltskosten: 2.2,
    Wertschoepfung_Immobilienmarkt: 4,
    Baustoffe: 3,
    Betriebsenergie: 2.4,
    Boden_Natur_Landschaft: 2.9,
    Infrastruktur: 2.1,
  },
  {
    id: 40,
    Jahr: 2020,
    Kategorie: 'Kulturgebäude',
    Gemeinschaft: 3.9,
    Gestaltung: 4.7,
    Nutzung_Erschliessung: 4.1,
    Wohlbefinden_Gesundheit: 3.9,
    Gebaeude_Bausubstanz: 3.4,
    Anlagekosten: 2.7,
    Betriebs_Unterhaltskosten: 3.3,
    Wertschoepfung_Immobilienmarkt: 2.8,
    Baustoffe: 3.3,
    Betriebsenergie: 3,
    Boden_Natur_Landschaft: 4.3,
    Infrastruktur: 1.9,
  },
  {
    id: 41,
    Jahr: 2020,
    Kategorie: 'Werkhöfe',
    Gemeinschaft: 4.1,
    Gestaltung: 2.8,
    Nutzung_Erschliessung: 3.2,
    Wohlbefinden_Gesundheit: 4,
    Gebaeude_Bausubstanz: 2.7,
    Anlagekosten: 2.1,
    Betriebs_Unterhaltskosten: 3.2,
    Wertschoepfung_Immobilienmarkt: 2.5,
    Baustoffe: 3.1,
    Betriebsenergie: 2.2,
    Boden_Natur_Landschaft: 2.7,
    Infrastruktur: 3.3,
  },
  {
    id: 42,
    Jahr: 2020,
    Kategorie: 'Jugend-/Quartiertreffs',
    Gemeinschaft: 4.8,
    Gestaltung: 4.3,
    Nutzung_Erschliessung: 3.5,
    Wohlbefinden_Gesundheit: 3.3,
    Gebaeude_Bausubstanz: 2.7,
    Anlagekosten: 2.2,
    Betriebs_Unterhaltskosten: 2.7,
    Wertschoepfung_Immobilienmarkt: 2.9,
    Baustoffe: 3,
    Betriebsenergie: 2.7,
    Boden_Natur_Landschaft: 2.6,
    Infrastruktur: 3,
  },
  {
    id: 43,
    Jahr: 2020,
    Kategorie: 'Wohnhäuser/-heime',
    Gemeinschaft: 4.4,
    Gestaltung: 4.4,
    Nutzung_Erschliessung: 2.9,
    Wohlbefinden_Gesundheit: 4.3,
    Gebaeude_Bausubstanz: 2.8,
    Anlagekosten: 2.3,
    Betriebs_Unterhaltskosten: 3,
    Wertschoepfung_Immobilienmarkt: 2.9,
    Baustoffe: 4,
    Betriebsenergie: 2.7,
    Boden_Natur_Landschaft: 3.5,
    Infrastruktur: 3.5,
  },
  {
    id: 44,
    Jahr: 2020,
    Kategorie: 'Restaurants',
    Gemeinschaft: 3.5,
    Gestaltung: 3,
    Nutzung_Erschliessung: 2.3,
    Wohlbefinden_Gesundheit: 3.7,
    Gebaeude_Bausubstanz: 2.5,
    Anlagekosten: 1.8,
    Betriebs_Unterhaltskosten: 1.8,
    Wertschoepfung_Immobilienmarkt: 2.8,
    Baustoffe: 3,
    Betriebsenergie: 2.8,
    Boden_Natur_Landschaft: 2.5,
    Infrastruktur: 2.3,
  },
  {
    id: 45,
    Jahr: 2021,
    Kategorie: 'Gesamt',
    Gemeinschaft: 4,
    Gestaltung: 3.6,
    Nutzung_Erschliessung: 3.4,
    Wohlbefinden_Gesundheit: 4.1,
    Gebaeude_Bausubstanz: 2.9,
    Anlagekosten: 2.2,
    Betriebs_Unterhaltskosten: 3.2,
    Wertschoepfung_Immobilienmarkt: 3.1,
    Baustoffe: 3.5,
    Betriebsenergie: 2.6,
    Boden_Natur_Landschaft: 2.8,
    Infrastruktur: 2.9,
  },
  {
    id: 46,
    Jahr: 2021,
    Kategorie: 'Verwaltungsgebäude',
    Gemeinschaft: 3.6,
    Gestaltung: 3.7,
    Nutzung_Erschliessung: 4.5,
    Wohlbefinden_Gesundheit: 3.6,
    Gebaeude_Bausubstanz: 3.5,
    Anlagekosten: 2.4,
    Betriebs_Unterhaltskosten: 3.8,
    Wertschoepfung_Immobilienmarkt: 3.3,
    Baustoffe: 3.7,
    Betriebsenergie: 3.2,
    Boden_Natur_Landschaft: 3.4,
    Infrastruktur: 2.6,
  },
  {
    id: 47,
    Jahr: 2021,
    Kategorie: 'Schulen',
    Gemeinschaft: 3.9,
    Gestaltung: 3.8,
    Nutzung_Erschliessung: 3.5,
    Wohlbefinden_Gesundheit: 4.3,
    Gebaeude_Bausubstanz: 2.9,
    Anlagekosten: 2.2,
    Betriebs_Unterhaltskosten: 3.4,
    Wertschoepfung_Immobilienmarkt: 3.2,
    Baustoffe: 3.7,
    Betriebsenergie: 2.7,
    Boden_Natur_Landschaft: 2.6,
    Infrastruktur: 2.9,
  },
  {
    id: 48,
    Jahr: 2021,
    Kategorie: 'Sportanlagen',
    Gemeinschaft: 4.5,
    Gestaltung: 2.7,
    Nutzung_Erschliessung: 3.2,
    Wohlbefinden_Gesundheit: 4.1,
    Gebaeude_Bausubstanz: 2.4,
    Anlagekosten: 2.3,
    Betriebs_Unterhaltskosten: 2.8,
    Wertschoepfung_Immobilienmarkt: 3.7,
    Baustoffe: 3.6,
    Betriebsenergie: 2.4,
    Boden_Natur_Landschaft: 3.8,
    Infrastruktur: 2.6,
  },
  {
    id: 49,
    Jahr: 2021,
    Kategorie: 'Eis-Wasseranlagen',
    Gemeinschaft: 3.8,
    Gestaltung: 2.2,
    Nutzung_Erschliessung: 2.3,
    Wohlbefinden_Gesundheit: 3.8,
    Gebaeude_Bausubstanz: 1.9,
    Anlagekosten: 2.9,
    Betriebs_Unterhaltskosten: 2.3,
    Wertschoepfung_Immobilienmarkt: 4,
    Baustoffe: 3,
    Betriebsenergie: 2.4,
    Boden_Natur_Landschaft: 2.9,
    Infrastruktur: 2.1,
  },
  {
    id: 50,
    Jahr: 2021,
    Kategorie: 'Kulturgebäude',
    Gemeinschaft: 3.9,
    Gestaltung: 4.7,
    Nutzung_Erschliessung: 4.1,
    Wohlbefinden_Gesundheit: 3.9,
    Gebaeude_Bausubstanz: 3.4,
    Anlagekosten: 2.7,
    Betriebs_Unterhaltskosten: 3.3,
    Wertschoepfung_Immobilienmarkt: 2.8,
    Baustoffe: 3.3,
    Betriebsenergie: 2.8,
    Boden_Natur_Landschaft: 4.3,
    Infrastruktur: 1.9,
  },
  {
    id: 51,
    Jahr: 2021,
    Kategorie: 'Werkhöfe',
    Gemeinschaft: 4,
    Gestaltung: 2.8,
    Nutzung_Erschliessung: 3,
    Wohlbefinden_Gesundheit: 3.9,
    Gebaeude_Bausubstanz: 2.6,
    Anlagekosten: 2.1,
    Betriebs_Unterhaltskosten: 3.1,
    Wertschoepfung_Immobilienmarkt: 2.5,
    Baustoffe: 3,
    Betriebsenergie: 2.2,
    Boden_Natur_Landschaft: 2.7,
    Infrastruktur: 3.2,
  },
  {
    id: 52,
    Jahr: 2021,
    Kategorie: 'Jugend-/Quartiertreffs',
    Gemeinschaft: 4.8,
    Gestaltung: 4.3,
    Nutzung_Erschliessung: 3.5,
    Wohlbefinden_Gesundheit: 3.3,
    Gebaeude_Bausubstanz: 2.7,
    Anlagekosten: 2.2,
    Betriebs_Unterhaltskosten: 2.3,
    Wertschoepfung_Immobilienmarkt: 2.9,
    Baustoffe: 3,
    Betriebsenergie: 2.8,
    Boden_Natur_Landschaft: 2.6,
    Infrastruktur: 3,
  },
  {
    id: 53,
    Jahr: 2021,
    Kategorie: 'Wohnhäuser/-heime',
    Gemeinschaft: 4.4,
    Gestaltung: 4.2,
    Nutzung_Erschliessung: 2.9,
    Wohlbefinden_Gesundheit: 4.3,
    Gebaeude_Bausubstanz: 2.6,
    Anlagekosten: 2.2,
    Betriebs_Unterhaltskosten: 2.5,
    Wertschoepfung_Immobilienmarkt: 2.8,
    Baustoffe: 3.8,
    Betriebsenergie: 2.5,
    Boden_Natur_Landschaft: 3.4,
    Infrastruktur: 3.4,
  },
  {
    id: 54,
    Jahr: 2021,
    Kategorie: 'Restaurants',
    Gemeinschaft: 3.5,
    Gestaltung: 3,
    Nutzung_Erschliessung: 2.3,
    Wohlbefinden_Gesundheit: 3.7,
    Gebaeude_Bausubstanz: 2.5,
    Anlagekosten: 1.8,
    Betriebs_Unterhaltskosten: 2.8,
    Wertschoepfung_Immobilienmarkt: 2.8,
    Baustoffe: 3,
    Betriebsenergie: 2.8,
    Boden_Natur_Landschaft: 2.5,
    Infrastruktur: 2.3,
  },
];
