<div class="visualization">
  <h1>Hindernisfreier Zugang zu Gebäuden</h1>
  <mat-tab-group>
    <mat-tab label="Finanz-/Fondsvermögen">
      <button
        mat-icon-button
        aria-label="Informationen"
        (click)="onInfoButtonTap('hindernisfrei.fv')"
        class="info-button"
      >
        <mat-icon>info_button</mat-icon>
      </button>

      <div *ngIf="smallScreen">
        <button
          class="dialogButton"
          mat-raised-button
          (click)="openDialog('FV')"
        >
          Filter
        </button>
      </div>
      <app-filter
        *ngIf="!smallScreen"
        [years]="yearsFv"
        [categories]="categoriesFv"
        [selectedBuildings]="categoriesFvFiltered"
        [selectedYears]="yearsFvFiltered"
        [selectedCategories]="labelsFvFiltered"
        [showThirdFilter]="false"
        [hideYearFilter]="true"
        (newItemEvent)="changeFilterFV($event)"
      ></app-filter>
      <div>
        <app-data-legend-table
          [dataLoaded]="dataLoadedFV"
          [labels]="labelsFilteredFV"
        ></app-data-legend-table>
        <div class="chart-container">
          <canvas
            baseChart
            [ngStyle]="{ height: chartHeight }"
            [datasets]="lineChartDataFV"
            [labels]="lineChartLabelsFV"
            [options]="lineChartOptions"
            [legend]="lineChartLegend"
            [chartType]="lineChartType"
            [plugins]="lineChartPlugins"
          >
          </canvas>
        </div>
      </div>
    </mat-tab>
    <mat-tab label="Verwaltungsvermögen">
      <button
        mat-icon-button
        aria-label="Informationen"
        (click)="
          onInfoButtonTap(
            useNewSystem ? 'hindernisfrei.vv.neu' : 'hindernisfrei.vv.alt'
          )
        "
        class="info-button"
      >
        <mat-icon>info_button</mat-icon>
      </button>
      <div *ngIf="smallScreen">
        <button
          class="dialogButton"
          mat-raised-button
          (click)="openDialog('VV')"
        >
          Filter
        </button>
      </div>
      <app-filter
        *ngIf="!smallScreen"
        [years]="yearsVv"
        [selectedBuildings]="categoriesVvFiltered"
        [selectedYears]="yearsVvFiltered"
        [selectedCategories]="labelsVvFiltered"
        [useNewSystem]="useNewSystem"
        [categories]="categoriesVv"
        [showThirdFilter]="false"
        [showNewSystemFilter]="true"
        [hideYearFilter]="true"
        [old_years]="yearsVv"
        [new_years]=""
        (newItemEvent)="changeFilterVV($event)"
      ></app-filter>
      <div>
        <app-data-legend-table
          [dataLoaded]="dataLoadedVV"
          [labels]="labelsFilteredVV"
        ></app-data-legend-table>
        <div class="chart-container">
          <canvas
            baseChart
            [ngStyle]="{ height: chartHeight }"
            [datasets]="lineChartDataVV"
            [labels]="lineChartLabelsVV"
            [options]="lineChartOptions"
            [legend]="lineChartLegend"
            [chartType]="lineChartType"
            [plugins]="lineChartPlugins"
          >
          </canvas>
        </div>
      </div>

      </mat-tab
    >
  </mat-tab-group>
</div>
