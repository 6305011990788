export const nachhaltigkeitsrosetteFV = [
  {
    id: 1,
    Jahr: 2012,
    Kategorie: 'Gesamt',
    Gemeinschaft: 3.4,
    Gestaltung: 3.7,
    Nutzung_Erschliessung: 2.9,
    Wohlbefinden_Gesundheit: 4.2,
    Gebaeude_Bausubstanz: 2.6,
    Anlagekosten: 2.5,
    Betriebs_Unterhaltskosten: 4,
    Wertschoepfung_Immobilienmarkt: 2.9,
    Baustoffe: 3.8,
    Betriebsenergie: 1.9,
    Boden_Natur_Landschaft: 3.2,
    Infrastruktur: 3.5,
  },
  {
    id: 2,
    Jahr: 2012,
    Kategorie: 'Wohnliegenschaften',
    Gemeinschaft: 3.3,
    Gestaltung: 3.7,
    Nutzung_Erschliessung: 2.8,
    Wohlbefinden_Gesundheit: 4.2,
    Gebaeude_Bausubstanz: 2.6,
    Anlagekosten: 2.5,
    Betriebs_Unterhaltskosten: 4,
    Wertschoepfung_Immobilienmarkt: 2.9,
    Baustoffe: 3.8,
    Betriebsenergie: 1.8,
    Boden_Natur_Landschaft: 3.2,
    Infrastruktur: 3.6,
  },
  {
    id: 3,
    Jahr: 2012,
    Kategorie: 'Geschäftsliegenschaften',
    Gemeinschaft: 3.8,
    Gestaltung: 4.2,
    Nutzung_Erschliessung: 3.3,
    Wohlbefinden_Gesundheit: 4.1,
    Gebaeude_Bausubstanz: 2.6,
    Anlagekosten: 2.4,
    Betriebs_Unterhaltskosten: 3.8,
    Wertschoepfung_Immobilienmarkt: 2.8,
    Baustoffe: 3.5,
    Betriebsenergie: 1.9,
    Boden_Natur_Landschaft: 2.6,
    Infrastruktur: 3,
  },
  {
    id: 4,
    Jahr: 2012,
    Kategorie: 'Landwirtschaft',
    Gemeinschaft: 4.2,
    Gestaltung: 4.7,
    Nutzung_Erschliessung: 1.2,
    Wohlbefinden_Gesundheit: 4.4,
    Gebaeude_Bausubstanz: 2.3,
    Anlagekosten: 3.2,
    Betriebs_Unterhaltskosten: 4,
    Wertschoepfung_Immobilienmarkt: 3,
    Baustoffe: 4,
    Betriebsenergie: 2.5,
    Boden_Natur_Landschaft: 3.7,
    Infrastruktur: 3,
  },
  {
    id: 5,
    Jahr: 2013,
    Kategorie: 'Gesamt',
    Gemeinschaft: 3.3,
    Gestaltung: 3.8,
    Nutzung_Erschliessung: 2.9,
    Wohlbefinden_Gesundheit: 4.2,
    Gebaeude_Bausubstanz: 2.7,
    Anlagekosten: 2.6,
    Betriebs_Unterhaltskosten: 4.1,
    Wertschoepfung_Immobilienmarkt: 2.9,
    Baustoffe: 3.9,
    Betriebsenergie: 2.1,
    Boden_Natur_Landschaft: 3.3,
    Infrastruktur: 3.6,
  },
  {
    id: 6,
    Jahr: 2013,
    Kategorie: 'Wohnliegenschaften',
    Gemeinschaft: 3.3,
    Gestaltung: 3.7,
    Nutzung_Erschliessung: 2.8,
    Wohlbefinden_Gesundheit: 4.2,
    Gebaeude_Bausubstanz: 2.7,
    Anlagekosten: 2.6,
    Betriebs_Unterhaltskosten: 4.1,
    Wertschoepfung_Immobilienmarkt: 2.9,
    Baustoffe: 3.9,
    Betriebsenergie: 2.1,
    Boden_Natur_Landschaft: 3.3,
    Infrastruktur: 3.7,
  },
  {
    id: 7,
    Jahr: 2013,
    Kategorie: 'Geschäftsliegenschaften',
    Gemeinschaft: 3.8,
    Gestaltung: 4.1,
    Nutzung_Erschliessung: 3.3,
    Wohlbefinden_Gesundheit: 4,
    Gebaeude_Bausubstanz: 2.9,
    Anlagekosten: 2.6,
    Betriebs_Unterhaltskosten: 4.2,
    Wertschoepfung_Immobilienmarkt: 3.1,
    Baustoffe: 3.9,
    Betriebsenergie: 2.2,
    Boden_Natur_Landschaft: 2.9,
    Infrastruktur: 3.3,
  },
  {
    id: 8,
    Jahr: 2013,
    Kategorie: 'Landwirtschaft',
    Gemeinschaft: 4.2,
    Gestaltung: 4.7,
    Nutzung_Erschliessung: 1.2,
    Wohlbefinden_Gesundheit: 4.8,
    Gebaeude_Bausubstanz: 3.2,
    Anlagekosten: 3.2,
    Betriebs_Unterhaltskosten: 4.7,
    Wertschoepfung_Immobilienmarkt: 3,
    Baustoffe: 4.5,
    Betriebsenergie: 2.5,
    Boden_Natur_Landschaft: 3.7,
    Infrastruktur: 3,
  },
  {
    id: 9,
    Jahr: 2014,
    Kategorie: 'Gesamt',
    Gemeinschaft: 3.3,
    Gestaltung: 3.8,
    Nutzung_Erschliessung: 2.7,
    Wohlbefinden_Gesundheit: 4.2,
    Gebaeude_Bausubstanz: 2.7,
    Anlagekosten: 2.6,
    Betriebs_Unterhaltskosten: 4.1,
    Wertschoepfung_Immobilienmarkt: 2.9,
    Baustoffe: 3.9,
    Betriebsenergie: 2.1,
    Boden_Natur_Landschaft: 3.3,
    Infrastruktur: 3.6,
  },
  {
    id: 10,
    Jahr: 2014,
    Kategorie: 'Wohnliegenschaften',
    Gemeinschaft: 3.3,
    Gestaltung: 3.8,
    Nutzung_Erschliessung: 2.7,
    Wohlbefinden_Gesundheit: 4.2,
    Gebaeude_Bausubstanz: 2.7,
    Anlagekosten: 2.6,
    Betriebs_Unterhaltskosten: 4.2,
    Wertschoepfung_Immobilienmarkt: 2.9,
    Baustoffe: 3.9,
    Betriebsenergie: 2.1,
    Boden_Natur_Landschaft: 3.3,
    Infrastruktur: 3.7,
  },
  {
    id: 11,
    Jahr: 2014,
    Kategorie: 'Geschäftsliegenschaften',
    Gemeinschaft: 3.8,
    Gestaltung: 4.2,
    Nutzung_Erschliessung: 3.3,
    Wohlbefinden_Gesundheit: 3.9,
    Gebaeude_Bausubstanz: 2.9,
    Anlagekosten: 2.6,
    Betriebs_Unterhaltskosten: 4,
    Wertschoepfung_Immobilienmarkt: 3.1,
    Baustoffe: 3.9,
    Betriebsenergie: 2.3,
    Boden_Natur_Landschaft: 2.9,
    Infrastruktur: 3.3,
  },
  {
    id: 12,
    Jahr: 2014,
    Kategorie: 'Landwirtschaft',
    Gemeinschaft: 4.2,
    Gestaltung: 4.7,
    Nutzung_Erschliessung: 1.2,
    Wohlbefinden_Gesundheit: 4.8,
    Gebaeude_Bausubstanz: 3.2,
    Anlagekosten: 3.2,
    Betriebs_Unterhaltskosten: 4.7,
    Wertschoepfung_Immobilienmarkt: 3,
    Baustoffe: 4.5,
    Betriebsenergie: 2.5,
    Boden_Natur_Landschaft: 3.7,
    Infrastruktur: 3.2,
  },
  {
    id: 13,
    Jahr: 2015,
    Kategorie: 'Gesamt',
    Gemeinschaft: 3.3,
    Gestaltung: 3.9,
    Nutzung_Erschliessung: 2.8,
    Wohlbefinden_Gesundheit: 4.2,
    Gebaeude_Bausubstanz: 2.7,
    Anlagekosten: 2.6,
    Betriebs_Unterhaltskosten: 4.2,
    Wertschoepfung_Immobilienmarkt: 3,
    Baustoffe: 3.9,
    Betriebsenergie: 2.2,
    Boden_Natur_Landschaft: 3.3,
    Infrastruktur: 3.5,
  },
  {
    id: 14,
    Jahr: 2015,
    Kategorie: 'Wohnliegenschaften',
    Gemeinschaft: 3.3,
    Gestaltung: 3.8,
    Nutzung_Erschliessung: 2.7,
    Wohlbefinden_Gesundheit: 4.2,
    Gebaeude_Bausubstanz: 2.7,
    Anlagekosten: 2.6,
    Betriebs_Unterhaltskosten: 4.2,
    Wertschoepfung_Immobilienmarkt: 3,
    Baustoffe: 3.9,
    Betriebsenergie: 2.2,
    Boden_Natur_Landschaft: 3.3,
    Infrastruktur: 3.7,
  },
  {
    id: 15,
    Jahr: 2015,
    Kategorie: 'Geschäftsliegenschaften',
    Gemeinschaft: 3.8,
    Gestaltung: 4.2,
    Nutzung_Erschliessung: 3.3,
    Wohlbefinden_Gesundheit: 3.9,
    Gebaeude_Bausubstanz: 2.8,
    Anlagekosten: 2.6,
    Betriebs_Unterhaltskosten: 3.9,
    Wertschoepfung_Immobilienmarkt: 3.2,
    Baustoffe: 3.8,
    Betriebsenergie: 2.3,
    Boden_Natur_Landschaft: 3.1,
    Infrastruktur: 3.4,
  },
  {
    id: 16,
    Jahr: 2015,
    Kategorie: 'Landwirtschaft',
    Gemeinschaft: 4.3,
    Gestaltung: 4.7,
    Nutzung_Erschliessung: 1.2,
    Wohlbefinden_Gesundheit: 4.8,
    Gebaeude_Bausubstanz: 3.2,
    Anlagekosten: 3.2,
    Betriebs_Unterhaltskosten: 4.7,
    Wertschoepfung_Immobilienmarkt: 3,
    Baustoffe: 4.5,
    Betriebsenergie: 2.5,
    Boden_Natur_Landschaft: 3.7,
    Infrastruktur: 3.2,
  },
  {
    id: 17,
    Jahr: 2016,
    Kategorie: 'Gesamt',
    Gemeinschaft: 3.3,
    Gestaltung: 4.1,
    Nutzung_Erschliessung: 2.8,
    Wohlbefinden_Gesundheit: 4.4,
    Gebaeude_Bausubstanz: 2.7,
    Anlagekosten: 2.6,
    Betriebs_Unterhaltskosten: 4.2,
    Wertschoepfung_Immobilienmarkt: 3,
    Baustoffe: 3.8,
    Betriebsenergie: 2.3,
    Boden_Natur_Landschaft: 3.3,
    Infrastruktur: 3.6,
  },
  {
    id: 18,
    Jahr: 2016,
    Kategorie: 'Wohnliegenschaften',
    Gemeinschaft: 3.2,
    Gestaltung: 4,
    Nutzung_Erschliessung: 2.7,
    Wohlbefinden_Gesundheit: 4.4,
    Gebaeude_Bausubstanz: 2.6,
    Anlagekosten: 2.6,
    Betriebs_Unterhaltskosten: 4.2,
    Wertschoepfung_Immobilienmarkt: 3,
    Baustoffe: 3.8,
    Betriebsenergie: 2.3,
    Boden_Natur_Landschaft: 3.3,
    Infrastruktur: 3.6,
  },
  {
    id: 19,
    Jahr: 2016,
    Kategorie: 'Geschäftsliegenschaften',
    Gemeinschaft: 3.9,
    Gestaltung: 4.3,
    Nutzung_Erschliessung: 3.3,
    Wohlbefinden_Gesundheit: 4,
    Gebaeude_Bausubstanz: 2.9,
    Anlagekosten: 2.6,
    Betriebs_Unterhaltskosten: 4,
    Wertschoepfung_Immobilienmarkt: 3.2,
    Baustoffe: 3.8,
    Betriebsenergie: 2.4,
    Boden_Natur_Landschaft: 3.1,
    Infrastruktur: 3.4,
  },
  {
    id: 20,
    Jahr: 2016,
    Kategorie: 'Landwirtschaft',
    Gemeinschaft: 4.3,
    Gestaltung: 4.7,
    Nutzung_Erschliessung: 1.2,
    Wohlbefinden_Gesundheit: 4.8,
    Gebaeude_Bausubstanz: 3.2,
    Anlagekosten: 3.2,
    Betriebs_Unterhaltskosten: 4.7,
    Wertschoepfung_Immobilienmarkt: 3,
    Baustoffe: 4.5,
    Betriebsenergie: 2.8,
    Boden_Natur_Landschaft: 3.7,
    Infrastruktur: 3.2,
  },
  {
    id: 21,
    Jahr: 2017,
    Kategorie: 'Gesamt',
    Gemeinschaft: 3.3,
    Gestaltung: 4.1,
    Nutzung_Erschliessung: 2.8,
    Wohlbefinden_Gesundheit: 4.4,
    Gebaeude_Bausubstanz: 2.7,
    Anlagekosten: 2.6,
    Betriebs_Unterhaltskosten: 4.2,
    Wertschoepfung_Immobilienmarkt: 3,
    Baustoffe: 3.8,
    Betriebsenergie: 2.3,
    Boden_Natur_Landschaft: 3.3,
    Infrastruktur: 3.6,
  },
  {
    id: 22,
    Jahr: 2017,
    Kategorie: 'Wohnliegenschaften',
    Gemeinschaft: 3.2,
    Gestaltung: 4,
    Nutzung_Erschliessung: 2.7,
    Wohlbefinden_Gesundheit: 4.4,
    Gebaeude_Bausubstanz: 2.6,
    Anlagekosten: 2.6,
    Betriebs_Unterhaltskosten: 4.2,
    Wertschoepfung_Immobilienmarkt: 3,
    Baustoffe: 3.8,
    Betriebsenergie: 2.3,
    Boden_Natur_Landschaft: 3.3,
    Infrastruktur: 3.6,
  },
  {
    id: 23,
    Jahr: 2017,
    Kategorie: 'Geschäftsliegenschaften',
    Gemeinschaft: 3.9,
    Gestaltung: 4.3,
    Nutzung_Erschliessung: 3.4,
    Wohlbefinden_Gesundheit: 4,
    Gebaeude_Bausubstanz: 2.9,
    Anlagekosten: 2.6,
    Betriebs_Unterhaltskosten: 4,
    Wertschoepfung_Immobilienmarkt: 3.2,
    Baustoffe: 3.9,
    Betriebsenergie: 2.5,
    Boden_Natur_Landschaft: 3.1,
    Infrastruktur: 3.4,
  },
  {
    id: 24,
    Jahr: 2017,
    Kategorie: 'Landwirtschaft',
    Gemeinschaft: 4.3,
    Gestaltung: 4.7,
    Nutzung_Erschliessung: 1.2,
    Wohlbefinden_Gesundheit: 4.8,
    Gebaeude_Bausubstanz: 3,
    Anlagekosten: 3.2,
    Betriebs_Unterhaltskosten: 4.7,
    Wertschoepfung_Immobilienmarkt: 3,
    Baustoffe: 4.5,
    Betriebsenergie: 2.8,
    Boden_Natur_Landschaft: 3.7,
    Infrastruktur: 3.2,
  },
  {
    id: 25,
    Jahr: 2018,
    Kategorie: 'Gesamt',
    Gemeinschaft: 3.3,
    Gestaltung: 4.1,
    Nutzung_Erschliessung: 2.8,
    Wohlbefinden_Gesundheit: 4.4,
    Gebaeude_Bausubstanz: 2.7,
    Anlagekosten: 2.7,
    Betriebs_Unterhaltskosten: 4.2,
    Wertschoepfung_Immobilienmarkt: 3.1,
    Baustoffe: 3.8,
    Betriebsenergie: 2.4,
    Boden_Natur_Landschaft: 3.3,
    Infrastruktur: 3.6,
  },
  {
    id: 26,
    Jahr: 2018,
    Kategorie: 'Wohnliegenschaften',
    Gemeinschaft: 3.2,
    Gestaltung: 4.1,
    Nutzung_Erschliessung: 2.8,
    Wohlbefinden_Gesundheit: 4.4,
    Gebaeude_Bausubstanz: 2.7,
    Anlagekosten: 2.7,
    Betriebs_Unterhaltskosten: 4.2,
    Wertschoepfung_Immobilienmarkt: 3,
    Baustoffe: 3.8,
    Betriebsenergie: 2.3,
    Boden_Natur_Landschaft: 3.3,
    Infrastruktur: 3.6,
  },
  {
    id: 27,
    Jahr: 2018,
    Kategorie: 'Geschäftsliegenschaften',
    Gemeinschaft: 3.9,
    Gestaltung: 4.4,
    Nutzung_Erschliessung: 3.5,
    Wohlbefinden_Gesundheit: 4.1,
    Gebaeude_Bausubstanz: 3,
    Anlagekosten: 2.6,
    Betriebs_Unterhaltskosten: 3.9,
    Wertschoepfung_Immobilienmarkt: 3.3,
    Baustoffe: 4,
    Betriebsenergie: 2.6,
    Boden_Natur_Landschaft: 3.1,
    Infrastruktur: 3.5,
  },
  {
    id: 28,
    Jahr: 2018,
    Kategorie: 'Landwirtschaft',
    Gemeinschaft: 4.3,
    Gestaltung: 4.7,
    Nutzung_Erschliessung: 1.2,
    Wohlbefinden_Gesundheit: 4.8,
    Gebaeude_Bausubstanz: 3,
    Anlagekosten: 3.2,
    Betriebs_Unterhaltskosten: 4.7,
    Wertschoepfung_Immobilienmarkt: 3,
    Baustoffe: 4.5,
    Betriebsenergie: 2.8,
    Boden_Natur_Landschaft: 3.7,
    Infrastruktur: 3.2,
  },
  {
    id: 29,
    Jahr: 2019,
    Kategorie: 'Gesamt',
    Gemeinschaft: 3.3,
    Gestaltung: 4,
    Nutzung_Erschliessung: 2.9,
    Wohlbefinden_Gesundheit: 4.4,
    Gebaeude_Bausubstanz: 2.7,
    Anlagekosten: 2.6,
    Betriebs_Unterhaltskosten: 4.1,
    Wertschoepfung_Immobilienmarkt: 3,
    Baustoffe: 3.7,
    Betriebsenergie: 2.4,
    Boden_Natur_Landschaft: 3.2,
    Infrastruktur: 3.5,
  },
  {
    id: 30,
    Jahr: 2019,
    Kategorie: 'Wohnliegenschaften',
    Gemeinschaft: 3.2,
    Gestaltung: 4,
    Nutzung_Erschliessung: 2.8,
    Wohlbefinden_Gesundheit: 4.4,
    Gebaeude_Bausubstanz: 2.6,
    Anlagekosten: 2.6,
    Betriebs_Unterhaltskosten: 4.1,
    Wertschoepfung_Immobilienmarkt: 3,
    Baustoffe: 3.7,
    Betriebsenergie: 2.4,
    Boden_Natur_Landschaft: 3.2,
    Infrastruktur: 3.5,
  },
  {
    id: 31,
    Jahr: 2019,
    Kategorie: 'Geschäftsliegenschaften',
    Gemeinschaft: 4,
    Gestaltung: 4.5,
    Nutzung_Erschliessung: 3.6,
    Wohlbefinden_Gesundheit: 4.2,
    Gebaeude_Bausubstanz: 3.1,
    Anlagekosten: 2.7,
    Betriebs_Unterhaltskosten: 4,
    Wertschoepfung_Immobilienmarkt: 3.3,
    Baustoffe: 4,
    Betriebsenergie: 2.7,
    Boden_Natur_Landschaft: 3,
    Infrastruktur: 3.7,
  },
  {
    id: 32,
    Jahr: 2019,
    Kategorie: 'Landwirtschaft',
    Gemeinschaft: 4.3,
    Gestaltung: 4.7,
    Nutzung_Erschliessung: 1.2,
    Wohlbefinden_Gesundheit: 4.8,
    Gebaeude_Bausubstanz: 3,
    Anlagekosten: 3.2,
    Betriebs_Unterhaltskosten: 4.3,
    Wertschoepfung_Immobilienmarkt: 3,
    Baustoffe: 4.5,
    Betriebsenergie: 3,
    Boden_Natur_Landschaft: 3.7,
    Infrastruktur: 3.2,
  },
  {
    id: 33,
    Jahr: 2020,
    Kategorie: 'Gesamt',
    Gemeinschaft: 3.3,
    Gestaltung: 4,
    Nutzung_Erschliessung: 2.9,
    Wohlbefinden_Gesundheit: 4.4,
    Gebaeude_Bausubstanz: 2.7,
    Anlagekosten: 2.6,
    Betriebs_Unterhaltskosten: 4.1,
    Wertschoepfung_Immobilienmarkt: 3,
    Baustoffe: 3.7,
    Betriebsenergie: 2.4,
    Boden_Natur_Landschaft: 3.2,
    Infrastruktur: 3.6,
  },
  {
    id: 34,
    Jahr: 2020,
    Kategorie: 'Wohnliegenschaften',
    Gemeinschaft: 3.3,
    Gestaltung: 3.9,
    Nutzung_Erschliessung: 2.8,
    Wohlbefinden_Gesundheit: 4.4,
    Gebaeude_Bausubstanz: 2.6,
    Anlagekosten: 2.6,
    Betriebs_Unterhaltskosten: 4.1,
    Wertschoepfung_Immobilienmarkt: 3,
    Baustoffe: 3.7,
    Betriebsenergie: 2.4,
    Boden_Natur_Landschaft: 3.2,
    Infrastruktur: 3.5,
  },
  {
    id: 35,
    Jahr: 2020,
    Kategorie: 'Geschäftsliegenschaften',
    Gemeinschaft: 4,
    Gestaltung: 4.5,
    Nutzung_Erschliessung: 3.6,
    Wohlbefinden_Gesundheit: 4.2,
    Gebaeude_Bausubstanz: 3.1,
    Anlagekosten: 2.7,
    Betriebs_Unterhaltskosten: 4.1,
    Wertschoepfung_Immobilienmarkt: 3.4,
    Baustoffe: 4,
    Betriebsenergie: 2.7,
    Boden_Natur_Landschaft: 3,
    Infrastruktur: 3.7,
  },
  {
    id: 36,
    Jahr: 2020,
    Kategorie: 'Landwirtschaft',
    Gemeinschaft: 4.3,
    Gestaltung: 4.7,
    Nutzung_Erschliessung: 1.2,
    Wohlbefinden_Gesundheit: 4.8,
    Gebaeude_Bausubstanz: 3,
    Anlagekosten: 3.2,
    Betriebs_Unterhaltskosten: 4.3,
    Wertschoepfung_Immobilienmarkt: 3,
    Baustoffe: 4.5,
    Betriebsenergie: 3,
    Boden_Natur_Landschaft: 3.7,
    Infrastruktur: 3.2,
  },
  {
    id: 37,
    Jahr: 2021,
    Kategorie: 'Gesamt',
    Gemeinschaft: 3.3,
    Gestaltung: 4,
    Nutzung_Erschliessung: 2.9,
    Wohlbefinden_Gesundheit: 4.4,
    Gebaeude_Bausubstanz: 2.8,
    Anlagekosten: 2.7,
    Betriebs_Unterhaltskosten: 4.2,
    Wertschoepfung_Immobilienmarkt: 3.1,
    Baustoffe: 3.8,
    Betriebsenergie: 2.5,
    Boden_Natur_Landschaft: 3.3,
    Infrastruktur: 3.7,
  },
  {
    id: 38,
    Jahr: 2021,
    Kategorie: 'Wohnliegenschaften',
    Gemeinschaft: 3.3,
    Gestaltung: 4,
    Nutzung_Erschliessung: 2.8,
    Wohlbefinden_Gesundheit: 4.4,
    Gebaeude_Bausubstanz: 2.7,
    Anlagekosten: 2.7,
    Betriebs_Unterhaltskosten: 4.2,
    Wertschoepfung_Immobilienmarkt: 3.1,
    Baustoffe: 3.8,
    Betriebsenergie: 2.5,
    Boden_Natur_Landschaft: 3.3,
    Infrastruktur: 3.7,
  },
  {
    id: 39,
    Jahr: 2021,
    Kategorie: 'Geschäftsliegenschaften',
    Gemeinschaft: 3.9,
    Gestaltung: 4.5,
    Nutzung_Erschliessung: 3.6,
    Wohlbefinden_Gesundheit: 4.2,
    Gebaeude_Bausubstanz: 3.2,
    Anlagekosten: 2.7,
    Betriebs_Unterhaltskosten: 4.1,
    Wertschoepfung_Immobilienmarkt: 3.4,
    Baustoffe: 4,
    Betriebsenergie: 2.9,
    Boden_Natur_Landschaft: 3,
    Infrastruktur: 3.7,
  },
  {
    id: 40,
    Jahr: 2021,
    Kategorie: 'Landwirtschaft',
    Gemeinschaft: 4.3,
    Gestaltung: 4.7,
    Nutzung_Erschliessung: 1.2,
    Wohlbefinden_Gesundheit: 4.8,
    Gebaeude_Bausubstanz: 3,
    Anlagekosten: 3.2,
    Betriebs_Unterhaltskosten: 4.3,
    Wertschoepfung_Immobilienmarkt: 3,
    Baustoffe: 4.5,
    Betriebsenergie: 3,
    Boden_Natur_Landschaft: 3.7,
    Infrastruktur: 3.2,
  },
];
