import { Component, ElementRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'nim-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
})
export class HomeComponent {
  @ViewChild('slide', { read: ElementRef }) slide;

  constructor(public dialog: MatDialog) {}

  ngOnInit() {}

  scroll(dir: string) {
    const px = this.getScrollPX();
    let scrollspeed = 12;
    switch (dir) {
      case 'left':
        this.slowSlide(px, scrollspeed, dir);
        break;
      case 'right':
        this.slowSlide(px, scrollspeed, dir);
        break;
    }
  }

  getScrollPX() {
    return window.innerWidth < 800 ? 215 : 304;
  }

  slowSlide(amount: number, speed: number, direction: string) {
    let scrollAmount = 0;
    let container = this.slide.nativeElement;
    switch (direction) {
      case 'left':
        let slideTimerLeft = setInterval(() => {
          container.scrollLeft += 10;
          scrollAmount += 10;
          if (scrollAmount >= amount) {
            window.clearInterval(slideTimerLeft);
          }
        }, speed);
        break;
      case 'right':
        scrollAmount = amount;
        let slideTimerRight = setInterval(() => {
          container.scrollLeft -= 10;
          scrollAmount -= 10;
          if (scrollAmount <= 0) {
            window.clearInterval(slideTimerRight);
          }
        }, speed);
    }
  }
}
