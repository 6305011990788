import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import { InfoModalComponent } from '../info-modal/info-modal.component';
import { ChartColorService } from '../shared/chart-color.service';
import { SmallScreenFilterLegendComponent } from '../smallScreenFilterLegend/smallScreenFilterLegend.component';
import { energietraegerFv } from './energietraeger-fv.data';
import { energietraegerVvNeu } from './energietraeger-vv-neu.data';
import { energietraegerVv } from './energietraeger-vv.data';

@Component({
  selector: 'nim-energietraeger-barchart',
  templateUrl: './energietraeger-barchart.component.html',
  styleUrls: ['./energietraeger-barchart.component.css'],
})
export class EnergietraegerBarchartComponent implements OnInit {
  ngOnInit(): void {
    this.barChartColors = this.colorService.getEnergyChartColors();
    this.getFvData();
    this.getVvData();
    this.getVvNeuData();
    this.checkIfSmallScreen();
  }

  private getFvData() {
    const res = energietraegerFv;
    this.dataFV = res;
    this.getYearsFV();
    this.prepDataFv(this.categoriesFv, this.labelsFV, res, this.yearsFv);
    setTimeout(() => {
      this.dataLoadedFV = true;
    }, 500);
  }

  private getVvData() {
    const res = energietraegerVv;
    this.dataVV = res;
    this.getYearsVV();
  }

  private getVvNeuData() {
    const res = energietraegerVvNeu;
    this.dataVVneu = res;
    this.useNewSystem = true;
    this.categoriesVv = this.categoriesVvNeu;
    this.yearsVv = this.newSystemYears;
    console.log(this.categoriesVv, this.labelsVV, this.yearsVv);
    this.prepDataVv(this.categoriesVv, this.labelsVV, res, this.yearsVv);
    this.dataLoadedVV = true;
  }

  public barChartLabelsFV = [
    'Wohnliegenschaften',
    'Geschäftsliegenschaften',
    'Landwirtschaft',
  ];

  public barChartLabelsVV = [
    'Verwaltungsgebäude',
    'Bildung',
    'Sport und Freizeit',
    'Wohnen und Aufenthalt',
    'Kultur und Geselligkeit',
    'Infrastrukturbauten',
  ];

  // eslint-disable-next-line class-methods-use-this
  createHoverInfoLabel(labelsFV) {
    return (tooltipItem, data) => {
      const currentDatasetIndex = tooltipItem.datasetIndex;
      const currentDataset = data.datasets[currentDatasetIndex];

      if (this.dataFV && data.datasets[currentDatasetIndex].order !== 2) {
        if (
          (currentDataset.label === 'Öl' &&
            labelsFV[tooltipItem.index] === 'Wohnliegenschaften') ||
          (currentDataset.label === 'Öl' &&
            labelsFV[tooltipItem.index] === 'Geschäftsliegenschaften') ||
          (currentDataset.label === 'Öl' &&
            labelsFV[tooltipItem.index] === 'Landwirtschaft')
        ) {
          let categoryLabels =
            this.categoriesFvFiltered.length >= 1
              ? this.categoriesFvFiltered
              : this.categoriesFv;

          if (this.categoriesFvFiltered.length == 1) {
            let categories =
              this.categoriesFvFiltered.length == 1
                ? this.categoriesFvFiltered
                : this.categoriesFv;
            return `${currentDataset.label}: ${
              tooltipItem.value
            } (Einzelöfen: ${this.dataFV
              .filter(
                (d) =>
                  String(d.Jahr) === currentDataset.stack &&
                  d.Energietraeger === 'Öl_einzel' &&
                  categories.includes(d.Kategorie),
              )
              .map((d) => d.Anzahl)
              .reduce((a, b) => a + b, 0)}, zentrale Heizungen: ${this.dataFV
              .filter(
                (d) =>
                  String(d.Jahr) === currentDataset.stack &&
                  d.Energietraeger === 'Öl_zentral' &&
                  categories.includes(d.Kategorie),
              )
              .map((d) => d.Anzahl)
              .reduce((a, b) => a + b, 0)})`;
          } else
            return `${currentDataset.label}: ${
              tooltipItem.value
            } (Einzelöfen: ${
              this.dataFV.find(
                (d) =>
                  String(d.Jahr) === currentDataset.stack &&
                  d.Energietraeger === 'Öl_einzel' &&
                  d.Kategorie === categoryLabels[tooltipItem.index],
              )?.Anzahl
            }, zentrale Heizungen: ${
              this.dataFV.find(
                (d) =>
                  String(d.Jahr) === currentDataset.stack &&
                  d.Energietraeger === 'Öl_zentral' &&
                  d.Kategorie === categoryLabels[tooltipItem.index],
              )?.Anzahl
            })`;
        }
        if (
          (currentDataset.label === 'Gas' &&
            labelsFV[tooltipItem.index] === 'Wohnliegenschaften') ||
          (currentDataset.label === 'Gas' &&
            labelsFV[tooltipItem.index] === 'Geschäftsliegenschaften') ||
          (currentDataset.label === 'Gas' &&
            labelsFV[tooltipItem.index] === 'Landwirtschaft')
        ) {
          let categoryLabels =
            this.categoriesFvFiltered.length >= 1
              ? this.categoriesFvFiltered
              : this.categoriesFv;

          if (this.categoriesFvFiltered.length == 1) {
            let categories =
              this.categoriesFvFiltered.length == 1
                ? this.categoriesFvFiltered
                : this.categoriesFv;
            return `${currentDataset.label}: ${
              tooltipItem.value
            } (Einzelöfen: ${this.dataFV
              .filter(
                (d) =>
                  String(d.Jahr) === currentDataset.stack &&
                  d.Energietraeger === 'Gas_einzel' &&
                  categories.includes(d.Kategorie),
              )
              .map((d) => d.Anzahl)
              .reduce((a, b) => a + b, 0)}, zentrale Heizungen: ${this.dataFV
              .filter(
                (d) =>
                  String(d.Jahr) === currentDataset.stack &&
                  d.Energietraeger === 'Gas_zentral' &&
                  categories.includes(d.Kategorie),
              )
              .map((d) => d.Anzahl)
              .reduce((a, b) => a + b, 0)})`;
          } else
            return `${currentDataset.label}: ${
              tooltipItem.value
            } (Einzelöfen: ${
              this.dataFV.find(
                (d) =>
                  String(d.Jahr) === currentDataset.stack &&
                  d.Energietraeger === 'Gas_einzel' &&
                  d.Kategorie === categoryLabels[tooltipItem.index],
              )?.Anzahl
            }, zentrale Heizungen: ${
              this.dataFV.find(
                (d) =>
                  String(d.Jahr) === currentDataset.stack &&
                  d.Energietraeger === 'Gas_zentral' &&
                  d.Kategorie === categoryLabels[tooltipItem.index],
              )?.Anzahl
            })`;
        }
        if (
          (currentDataset.label === 'Holz' &&
            labelsFV[tooltipItem.index] === 'Wohnliegenschaften') ||
          (currentDataset.label === 'Holz' &&
            labelsFV[tooltipItem.index] === 'Geschäftsliegenschaften') ||
          (currentDataset.label === 'Holz' &&
            labelsFV[tooltipItem.index] === 'Landwirtschaft')
        ) {
          let categoryLabels =
            this.categoriesFvFiltered.length >= 1
              ? this.categoriesFvFiltered
              : this.categoriesFv;

          if (this.categoriesFvFiltered.length == 1) {
            let categories =
              this.categoriesFvFiltered.length == 1
                ? this.categoriesFvFiltered
                : this.categoriesFv;

            return `${currentDataset.label}: ${
              tooltipItem.value
            } (Einzelöfen: ${this.dataFV
              .filter(
                (d) =>
                  String(d.Jahr) === currentDataset.stack &&
                  d.Energietraeger === 'Holz_einzel' &&
                  categories.includes(d.Kategorie),
              )
              .map((d) => d.Anzahl)
              .reduce((a, b) => a + b, 0)}, zentrale Heizungen: ${this.dataFV
              .filter(
                (d) =>
                  String(d.Jahr) === currentDataset.stack &&
                  d.Energietraeger === 'Holz_zentral' &&
                  categories.includes(d.Kategorie),
              )
              .map((d) => d.Anzahl)
              .reduce((a, b) => a + b, 0)})`;
          } else
            return `${currentDataset.label}: ${
              tooltipItem.value
            } (Einzelöfen: ${
              this.dataFV.find(
                (d) =>
                  String(d.Jahr) === currentDataset.stack &&
                  d.Energietraeger === 'Holz_einzel' &&
                  d.Kategorie === categoryLabels[tooltipItem.index],
              ).Anzahl
            }, zentrale Heizungen: ${
              this.dataFV.find(
                (d) =>
                  String(d.Jahr) === currentDataset.stack &&
                  d.Energietraeger === 'Holz_zentral' &&
                  d.Kategorie === categoryLabels[tooltipItem.index],
              ).Anzahl
            })`;
        }
      }
      return `${data.datasets[tooltipItem.datasetIndex].label}: ${
        tooltipItem.value
      }`;
    };
  }

  dataFV = [];

  dataVV = [];

  public barChartOptions: ChartOptions = {
    maintainAspectRatio: false,
    layout: { padding: { bottom: 30 } },
    responsive: true,
    legend: {
      display: false,
    },
    hover: { mode: null },
    scales: {
      xAxes: [
        {
          stacked: true,
          ticks: {
            maxRotation: 0,
            autoSkip: false,
            padding: 30,
          },
        },
      ],
      yAxes: [
        {
          stacked: true,
          scaleLabel: {
            display: true,
            labelString: 'Anzahl Energieträger pro Gebäudekategorie',
          },
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
    plugins: {
      datalabels: {
        anchor: 'start',
        align: 'start',
        rotation: 45,
        clip: false,
        formatter(value, context) {
          if (context.datasetIndex % context.dataset.hitRadius[0] === 0)
            return context.dataset.stack;
          return '';
        },
      },
    },
    tooltips: {
      mode: 'x',
      callbacks: {
        label: this.createHoverInfoLabel(this.barChartLabelsFV),
      },
    },
  };

  public barChartPlugins = [pluginDataLabels];

  public barChartType: ChartType = 'bar';

  public barChartLegend = true;

  public barChartColors;

  public barChartDataFV: ChartDataSets[] = [];

  public barChartDataVV: ChartDataSets[] = [];

  public dataLoadedFV = false;

  public dataLoadedVV = false;

  public allDataIsZeroVV = false;

  public allDataIsZeroFV = false;

  public useNewSystem: boolean;

  labelsVV = [
    'Gas',
    'Öl',
    'Kombi Gas/Öl',
    'Elektrizität',
    'Fernwärme',
    'Wärmepumpen',
    'Holz',
    'Photovoltaikanlagen',
    'Solaranlagen',
  ];

  labelsFV = [
    'Gas',
    'Öl',
    'BHKW (=Blockheizkraftwerk)',
    'Elektrizität',
    'Fernwärme',
    'Wärmepumpen',
    'Holz',
    'Photovoltaikanlagen',
    'Solaranlagen',
  ];

  public labelsFilteredFV = [];
  public labelsFilteredVV = [];
  categoriesFv = [
    'Wohnliegenschaften',
    'Geschäftsliegenschaften',
    'Landwirtschaft',
  ];

  categoriesVv = [
    'Verwaltungsgebäude',
    'Schulanlagen',
    'Sportanlagen',
    'Eis- und Wasseranlagen',
    'Kulturgebäude',
    'Werkhöfe',
    'Jugend-/Quartiertreffs',
    'Wohnhäuser/-heime',
    'Restaurants',
  ];

  categoriesVvAlt = [
    'Verwaltungsgebäude',
    'Schulanlagen',
    'Sportanlagen',
    'Eis- und Wasseranlagen',
    'Kulturgebäude',
    'Werkhöfe',
    'Jugend-/Quartiertreffs',
    'Wohnhäuser/-heime',
    'Restaurants',
  ];

  categoriesVvNeu = [
    'Verwaltungsgebäude',
    'Bildung',
    'Sport und Freizeit',
    'Wohnen und Aufenthalt',
    'Kultur und Geselligkeit',
    'Infrastrukturbauten',
  ];

  categoriesFvFiltered = [];

  categoriesVvFiltered = [];

  yearsFv = [];

  newSystemYears = [2019, 2020, 2021];
  oldSystemYears = [];

  yearsVv = this.newSystemYears;

  yearsFvFiltered = [];

  yearsVvFiltered = [];

  public labelsForLegendFV = this.labelsFV;

  public labelsForLegendVV = this.labelsVV;

  dataVVneu;

  public smallScreen: boolean;

  public chartHeight = this.getChartHeight();

  constructor(
    private colorService: ChartColorService,
    public dialog: MatDialog,
    private breakpointObserver: BreakpointObserver,
    private smallScreenFilterDialog: MatDialog,
  ) {}

  private checkIfSmallScreen() {
    this.breakpointObserver
      .observe(['(min-width: 900px)'])
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          this.smallScreen = false;
        } else {
          this.smallScreen = true;
        }
      });
  }

  openDialog(asset: string) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    if (asset === 'FV') {
      dialogConfig.data = {
        labels: this.labelsFV,
        years: this.yearsFv,
        categories: this.categoriesFv,
        labelsFiltered: this.labelsFilteredFV,
        yearsFiltered: this.yearsFvFiltered,
        categoriesFiltered: this.categoriesFvFiltered,
        showNewSystemFilter: false,
        showThirdFilter: true,
      };

      this.smallScreenFilterDialog
        .open(SmallScreenFilterLegendComponent, dialogConfig)
        .afterClosed()
        .subscribe((changes) => {
          if (changes) this.changeFilterFV(changes);
        });
    } else {
      dialogConfig.data = {
        labels: this.labelsVV,
        years: this.yearsVv,
        categories: this.categoriesVv,
        labelsFiltered: this.labelsFilteredVV,
        yearsFiltered: this.yearsVvFiltered,
        categoriesFiltered: this.categoriesVvFiltered,
        showThirdFilter: true,
        showNewSystemFilter: true,
        useNewSystem: this.useNewSystem,
      };

      this.smallScreenFilterDialog
        .open(SmallScreenFilterLegendComponent, dialogConfig)
        .afterClosed()
        .subscribe((changes) => {
          if (changes) this.changeFilterVV(changes);
        });
    }
  }

  private getYearsFV() {
    this.dataFV.forEach((element) => {
      if (!this.yearsFv.includes(element.Jahr)) this.yearsFv.push(element.Jahr);
    });
  }

  private getYearsVV() {
    this.dataVV.forEach((element) => {
      if (!this.oldSystemYears.includes(element.Jahr))
        this.oldSystemYears.push(element.Jahr);
    });
  }

  private prepDataFv(categories: string[], labels: string[], data, years) {
    this.allDataIsZeroFV = true;
    this.barChartDataFV = [];
    years.forEach((j) => {
      labels.forEach((l) => {
        let labelsLength =
          this.labelsFilteredFV.length > 0
            ? this.labelsFilteredFV.length
            : this.labelsFV.length;
        const dataSet = {
          label: l,
          data: [],
          stack: String(j),
          backgroundColor: this.getColor(l).backgroundColor,
          borderColor: 'rgba(0, 0, 0, 0)',
          hitRadius: [labelsLength],
        };
        const dataFiltered = data
          .filter((o) => o.Jahr === j)
          .filter((o) => o.Energietraeger === l);
        categories.forEach((c) => {
          if (dataFiltered.find((o) => o.Kategorie === c) !== undefined) {
            dataSet.data.push(
              dataFiltered.find((o) => o.Kategorie === c).Anzahl,
            );
          }
        });

        this.barChartDataFV.push(dataSet);
      });
    });
    this.checkIfFVDataCanBeVisualized();
  }

  private prepDataVv(categories: string[], labels: string[], data, years) {
    this.allDataIsZeroVV = true;
    this.barChartDataVV = [];
    years.forEach((j) => {
      labels.forEach((l) => {
        let labelsLength =
          this.labelsFilteredVV.length > 0
            ? this.labelsFilteredVV.length
            : this.labelsVV.length;
        const dataSet = {
          label: l,
          data: [],
          stack: String(j),
          backgroundColor: this.getColor(l).backgroundColor,
          borderColor: 'rgba(0, 0, 0, 0)',
          hitRadius: [labelsLength],
          // helps distinguish between FV and VV in tooltip
          order: 2,
        };
        const dataFiltered = data
          .filter((o) => o.Jahr === j)
          .filter((o) => o.Energietraeger === l);
        categories.forEach((c) => {
          if (dataFiltered.find((o) => o.Kategorie === c) !== undefined) {
            dataSet.data.push(
              dataFiltered.find((o) => o.Kategorie === c).Anzahl,
            );
          }
        });
        this.barChartDataVV.push(dataSet);
      });
    });
    this.checkIfVVDataCanBeVisualized();
  }

  private checkIfVVDataCanBeVisualized() {
    this.barChartDataVV.forEach((dataSet) => {
      if (dataSet.data.length !== 0) {
        this.allDataIsZeroVV = false;
      }
    });
    if (this.allDataIsZero(this.barChartDataVV)) this.allDataIsZeroVV = true;
  }

  private checkIfFVDataCanBeVisualized() {
    this.barChartDataFV.forEach((dataSet) => {
      if (dataSet.data.length !== 0) {
        this.allDataIsZeroFV = false;
      }
    });
    if (this.allDataIsZero(this.barChartDataFV)) this.allDataIsZeroFV = true;
  }

  private allDataIsZero(dataSet) {
    let allZero = true;
    dataSet.forEach((dataObject) => {
      dataObject.data.forEach((element) => {
        if (element !== 0) allZero = false;
      });
    });
    return allZero;
  }

  private getColor(label: string) {
    switch (label) {
      case 'Solaranlagen': {
        return this.barChartColors[0];
      }
      case 'Photovoltaikanlagen': {
        return this.barChartColors[1];
      }
      case 'Holz': {
        return this.barChartColors[2];
      }

      case 'Fernwärme': {
        return this.barChartColors[5];
      }
      case 'Wärmepumpen': {
        return this.barChartColors[4];
      }
      case 'Gas': {
        return this.barChartColors[7];
      }
      case 'Kombi Gas/Öl': {
        return this.barChartColors[8];
      }
      case 'BHKW (=Blockheizkraftwerk)': {
        return this.barChartColors[9];
      }
      case 'Öl': {
        return this.barChartColors[10];
      }
      case 'Elektrizität': {
        return this.barChartColors[11];
      }
      default: {
        return this.barChartColors[0];
      }
    }
  }

  public changeFilterFV(changes) {
    let catFV: string[];
    let yearsFV: number[];
    if (changes.cat.length > 0) {
      catFV = changes.cat;
      this.barChartLabelsFV = changes.cat;
      this.categoriesFvFiltered = changes.cat;
    } else {
      catFV = this.categoriesFv;
      this.barChartLabelsFV = this.categoriesFv;
      this.categoriesFvFiltered = [];
    }
    if (changes.years.length > 0) {
      yearsFV = changes.years;
      this.yearsFvFiltered = changes.years;
    } else {
      this.yearsFvFiltered = [];
      yearsFV = this.yearsFv;
    }
    if (changes.labels.length > 0) {
      this.labelsForLegendFV = changes.labels;
      this.labelsFilteredFV = changes.labels;
    } else {
      this.labelsForLegendFV = this.labelsFV;
      this.labelsFilteredFV = [];
    }
    this.prepDataFv(catFV, this.labelsForLegendFV, this.dataFV, yearsFV);
  }

  public changeFilterVV(changes) {
    let catVV: string[];
    let labelsVV: string[];
    let yearsVV: number[];
    let dataVV;
    if (changes.newSystem === this.useNewSystem) {
      if (changes.cat.length > 0) {
        catVV = changes.cat;
        this.barChartLabelsVV = changes.cat;
        this.categoriesVvFiltered = changes.cat;
      } else {
        catVV = this.categoriesVv;
        this.barChartLabelsVV = this.categoriesVv;
        this.categoriesVvFiltered = [];
      }
      if (changes.years.length > 0) yearsVV = changes.years;
      else yearsVV = this.yearsVv;
      if (changes.labels.length > 0) {
        labelsVV = changes.labels;
        this.labelsFilteredVV = changes.labels;
      } else {
        labelsVV = this.labelsVV;
        this.labelsFilteredVV = [];
      }
    } else if (changes.newSystem) {
      this.useNewSystem = true;
      dataVV = this.dataVVneu;
      this.categoriesVv = this.categoriesVvNeu;
      catVV = this.categoriesVv;
      this.barChartLabelsVV = this.categoriesVvNeu;
      this.categoriesVvFiltered = [];
      labelsVV = this.labelsVV;
      this.labelsFilteredVV = [];
      yearsVV = this.newSystemYears;
      this.yearsVv = this.newSystemYears;
    } else {
      this.useNewSystem = false;
      dataVV = this.dataVV;
      this.categoriesVv = this.categoriesVvAlt;
      catVV = this.categoriesVv;
      this.barChartLabelsVV = this.categoriesVvAlt;
      this.categoriesVvFiltered = [];
      labelsVV = this.labelsVV;
      this.labelsFilteredVV = [];
      yearsVV = this.oldSystemYears;
      this.yearsVv = this.oldSystemYears;
    }
    this.useNewSystem ? (dataVV = this.dataVVneu) : (dataVV = this.dataVV);
    this.prepDataVv(catVV, labelsVV, dataVV, yearsVV);
  }

  onInfoButtonTap(content: string) {
    this.dialog.open(InfoModalComponent, {
      data: {
        content: content,
      },
    });
  }

  public getChartHeight() {
    return window.innerWidth < 700 ? '300px' : '440px';
  }
}
