export const ernEnergienFv = [
  {
    id: 1,
    Jahr: 2012,
    Kategorie: 'Wohnliegenschaften',
    Anz_Erneuerbar: 221,
    Anz_Nicht_Erneuerbar: 395,
  },
  {
    id: 2,
    Jahr: 2012,
    Kategorie: 'Geschäftsliegenschaften',
    Anz_Erneuerbar: 14,
    Anz_Nicht_Erneuerbar: 25,
  },
  {
    id: 3,
    Jahr: 2012,
    Kategorie: 'Landwirtschaft',
    Anz_Erneuerbar: 2,
    Anz_Nicht_Erneuerbar: 1,
  },
  {
    id: 4,
    Jahr: 2013,
    Kategorie: 'Wohnliegenschaften',
    Anz_Erneuerbar: 220,
    Anz_Nicht_Erneuerbar: 389,
  },
  {
    id: 5,
    Jahr: 2013,
    Kategorie: 'Geschäftsliegenschaften',
    Anz_Erneuerbar: 10,
    Anz_Nicht_Erneuerbar: 26,
  },
  {
    id: 6,
    Jahr: 2013,
    Kategorie: 'Landwirtschaft',
    Anz_Erneuerbar: 2,
    Anz_Nicht_Erneuerbar: 1,
  },
  {
    id: 7,
    Jahr: 2014,
    Kategorie: 'Wohnliegenschaften',
    Anz_Erneuerbar: 230,
    Anz_Nicht_Erneuerbar: 392,
  },
  {
    id: 8,
    Jahr: 2014,
    Kategorie: 'Geschäftsliegenschaften',
    Anz_Erneuerbar: 11,
    Anz_Nicht_Erneuerbar: 25,
  },
  {
    id: 9,
    Jahr: 2014,
    Kategorie: 'Landwirtschaft',
    Anz_Erneuerbar: 2,
    Anz_Nicht_Erneuerbar: 1,
  },
  {
    id: 10,
    Jahr: 2015,
    Kategorie: 'Wohnliegenschaften',
    Anz_Erneuerbar: 231,
    Anz_Nicht_Erneuerbar: 389,
  },
  {
    id: 11,
    Jahr: 2015,
    Kategorie: 'Geschäftsliegenschaften',
    Anz_Erneuerbar: 12,
    Anz_Nicht_Erneuerbar: 32,
  },
  {
    id: 12,
    Jahr: 2015,
    Kategorie: 'Landwirtschaft',
    Anz_Erneuerbar: 2,
    Anz_Nicht_Erneuerbar: 1,
  },
  {
    id: 13,
    Jahr: 2016,
    Kategorie: 'Wohnliegenschaften',
    Anz_Erneuerbar: 236,
    Anz_Nicht_Erneuerbar: 391,
  },
  {
    id: 14,
    Jahr: 2016,
    Kategorie: 'Geschäftsliegenschaften',
    Anz_Erneuerbar: 15,
    Anz_Nicht_Erneuerbar: 34,
  },
  {
    id: 15,
    Jahr: 2016,
    Kategorie: 'Landwirtschaft',
    Anz_Erneuerbar: 2,
    Anz_Nicht_Erneuerbar: 1,
  },
  {
    id: 16,
    Jahr: 2017,
    Kategorie: 'Wohnliegenschaften',
    Anz_Erneuerbar: 238,
    Anz_Nicht_Erneuerbar: 389,
  },
  {
    id: 17,
    Jahr: 2017,
    Kategorie: 'Geschäftsliegenschaften',
    Anz_Erneuerbar: 17,
    Anz_Nicht_Erneuerbar: 34,
  },
  {
    id: 18,
    Jahr: 2017,
    Kategorie: 'Landwirtschaft',
    Anz_Erneuerbar: 2,
    Anz_Nicht_Erneuerbar: 1,
  },
  {
    id: 19,
    Jahr: 2018,
    Kategorie: 'Wohnliegenschaften',
    Anz_Erneuerbar: 245,
    Anz_Nicht_Erneuerbar: 387,
  },
  {
    id: 20,
    Jahr: 2018,
    Kategorie: 'Geschäftsliegenschaften',
    Anz_Erneuerbar: 17,
    Anz_Nicht_Erneuerbar: 33,
  },
  {
    id: 21,
    Jahr: 2018,
    Kategorie: 'Landwirtschaft',
    Anz_Erneuerbar: 2,
    Anz_Nicht_Erneuerbar: 1,
  },
  {
    id: 22,
    Jahr: 2019,
    Kategorie: 'Wohnliegenschaften',
    Anz_Erneuerbar: 273,
    Anz_Nicht_Erneuerbar: 392,
  },
  {
    id: 23,
    Jahr: 2019,
    Kategorie: 'Geschäftsliegenschaften',
    Anz_Erneuerbar: 19,
    Anz_Nicht_Erneuerbar: 30,
  },
  {
    id: 24,
    Jahr: 2019,
    Kategorie: 'Landwirtschaft',
    Anz_Erneuerbar: 3,
    Anz_Nicht_Erneuerbar: 1,
  },
  {
    id: 25,
    Jahr: 2020,
    Kategorie: 'Wohnliegenschaften',
    Anz_Erneuerbar: 272,
    Anz_Nicht_Erneuerbar: 391,
  },
  {
    id: 26,
    Jahr: 2020,
    Kategorie: 'Geschäftsliegenschaften',
    Anz_Erneuerbar: 19,
    Anz_Nicht_Erneuerbar: 30,
  },
  {
    id: 27,
    Jahr: 2020,
    Kategorie: 'Landwirtschaft',
    Anz_Erneuerbar: 3,
    Anz_Nicht_Erneuerbar: 1,
  },
  {
    id: 28,
    Jahr: 2021,
    Kategorie: 'Wohnliegenschaften',
    Anz_Erneuerbar: 274,
    Anz_Nicht_Erneuerbar: 391,
  },
  {
    id: 29,
    Jahr: 2021,
    Kategorie: 'Geschäftsliegenschaften',
    Anz_Erneuerbar: 19,
    Anz_Nicht_Erneuerbar: 30,
  },
  {
    id: 30,
    Jahr: 2021,
    Kategorie: 'Landwirtschaft',
    Anz_Erneuerbar: 3,
    Anz_Nicht_Erneuerbar: 1,
  },
];
