<div class="visualization">
  <h1>Photovoltaikanlagen Prognose bis 2035</h1>
  <mat-tab-group>
    <mat-tab label="Finanz-/Fondsvermögen">
      <button
        mat-icon-button
        aria-label="Informationen"
        (click)="onInfoButtonTap('prog-photovoltaik.fv')"
        class="info-button"
      >
        <mat-icon>info_button</mat-icon>
      </button>
      <app-data-legend-table
        [dataLoaded]="dataLoadedFV"
        [labels]="labels"
      ></app-data-legend-table>
      <div class="chart-container" style="clear: both">
        <canvas
          baseChart
          [ngStyle]="{ height: chartHeight }"
          [datasets]="lineChartDataFV"
          [labels]="years"
          [options]="lineChartOptions"
          [legend]="lineChartLegend"
          [chartType]="lineChartType"
          [plugins]="lineChartPlugins"
        >
        </canvas>
      </div>
    </mat-tab>
    <mat-tab label="Verwaltungsvermögen">
      <button
        mat-icon-button
        aria-label="Informationen"
        (click)="onInfoButtonTap('prog-photovoltaik.vv')"
        class="info-button"
      >
        <mat-icon>info_button</mat-icon>
      </button>
      <app-data-legend-table
        [dataLoaded]="dataLoadedVV"
        [labels]="labels"
      ></app-data-legend-table>
      <div class="chart-container" style="clear: both">
        <canvas
          baseChart
          [ngStyle]="{ height: chartHeight }"
          [datasets]="lineChartDataVV"
          [labels]="years"
          [options]="lineChartOptions"
          [legend]="lineChartLegend"
          [chartType]="lineChartType"
          [plugins]="lineChartPlugins"
        >
        </canvas>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>
