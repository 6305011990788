export const ausgewerteteVv = [
  {
    id: 1,
    Jahr: 2015,
    Kategorie: 'Verwaltungsgebäude',
    Anzahl_Gebaeude: 15,
    Anteil_Gebaeude: 7.11,
  },
  {
    id: 2,
    Jahr: 2015,
    Kategorie: 'Schulanlagen',
    Anzahl_Gebaeude: 196,
    Anteil_Gebaeude: 92.89,
  },
  {
    id: 3,
    Jahr: 2015,
    Kategorie: 'Total',
    Anzahl_Gebaeude: 211,
    Anteil_Gebaeude: 100,
  },
  {
    id: 4,
    Jahr: 2016,
    Kategorie: 'Verwaltungsgebäude',
    Anzahl_Gebaeude: 17,
    Anteil_Gebaeude: 6.91,
  },
  {
    id: 5,
    Jahr: 2016,
    Kategorie: 'Schulanlagen',
    Anzahl_Gebaeude: 204,
    Anteil_Gebaeude: 82.93,
  },
  {
    id: 6,
    Jahr: 2016,
    Kategorie: 'Sportanlagen',
    Anzahl_Gebaeude: 15,
    Anteil_Gebaeude: 6.1,
  },
  {
    id: 7,
    Jahr: 2016,
    Kategorie: 'Eis- und Wasseranlagen',
    Anzahl_Gebaeude: 10,
    Anteil_Gebaeude: 4.07,
  },
  {
    id: 8,
    Jahr: 2016,
    Kategorie: 'Total',
    Anzahl_Gebaeude: 246,
    Anteil_Gebaeude: 100,
  },
  {
    id: 9,
    Jahr: 2017,
    Kategorie: 'Verwaltungsgebäude',
    Anzahl_Gebaeude: 17,
    Anteil_Gebaeude: 6.23,
  },
  {
    id: 10,
    Jahr: 2017,
    Kategorie: 'Schulanlagen',
    Anzahl_Gebaeude: 203,
    Anteil_Gebaeude: 74.36,
  },
  {
    id: 11,
    Jahr: 2017,
    Kategorie: 'Sportanlagen',
    Anzahl_Gebaeude: 17,
    Anteil_Gebaeude: 6.23,
  },
  {
    id: 12,
    Jahr: 2017,
    Kategorie: 'Eis- und Wasseranlagen',
    Anzahl_Gebaeude: 10,
    Anteil_Gebaeude: 3.66,
  },
  {
    id: 13,
    Jahr: 2017,
    Kategorie: 'Kulturgebäude',
    Anzahl_Gebaeude: 5,
    Anteil_Gebaeude: 1.83,
  },
  {
    id: 14,
    Jahr: 2017,
    Kategorie: 'Werkhöfe',
    Anzahl_Gebaeude: 21,
    Anteil_Gebaeude: 7.69,
  },
  {
    id: 15,
    Jahr: 2017,
    Kategorie: 'Total',
    Anzahl_Gebaeude: 273,
    Anteil_Gebaeude: 100,
  },
  {
    id: 16,
    Jahr: 2018,
    Kategorie: 'Verwaltungsgebäude',
    Anzahl_Gebaeude: 17,
    Anteil_Gebaeude: 5.74,
  },
  {
    id: 17,
    Jahr: 2018,
    Kategorie: 'Schulanlagen',
    Anzahl_Gebaeude: 204,
    Anteil_Gebaeude: 68.92,
  },
  {
    id: 18,
    Jahr: 2018,
    Kategorie: 'Sportanlagen',
    Anzahl_Gebaeude: 17,
    Anteil_Gebaeude: 5.74,
  },
  {
    id: 19,
    Jahr: 2018,
    Kategorie: 'Eis- und Wasseranlagen',
    Anzahl_Gebaeude: 10,
    Anteil_Gebaeude: 3.38,
  },
  {
    id: 20,
    Jahr: 2018,
    Kategorie: 'Kulturgebäude',
    Anzahl_Gebaeude: 6,
    Anteil_Gebaeude: 2.03,
  },
  {
    id: 21,
    Jahr: 2018,
    Kategorie: 'Werkhöfe',
    Anzahl_Gebaeude: 21,
    Anteil_Gebaeude: 7.08,
  },
  {
    id: 22,
    Jahr: 2018,
    Kategorie: 'Jugend-/Quartiertreffs',
    Anzahl_Gebaeude: 12,
    Anteil_Gebaeude: 4.05,
  },
  {
    id: 23,
    Jahr: 2018,
    Kategorie: 'Wohnhäuser/-heime',
    Anzahl_Gebaeude: 9,
    Anteil_Gebaeude: 3.04,
  },
  {
    id: 24,
    Jahr: 2018,
    Kategorie: 'Total',
    Anzahl_Gebaeude: 296,
    Anteil_Gebaeude: 100,
  },
  {
    id: 25,
    Jahr: 2019,
    Kategorie: 'Verwaltungsgebäude',
    Anzahl_Gebaeude: 16,
    Anteil_Gebaeude: 5.54,
  },
  {
    id: 26,
    Jahr: 2019,
    Kategorie: 'Schulanlagen',
    Anzahl_Gebaeude: 195,
    Anteil_Gebaeude: 67.47,
  },
  {
    id: 27,
    Jahr: 2019,
    Kategorie: 'Sportanlagen',
    Anzahl_Gebaeude: 16,
    Anteil_Gebaeude: 5.54,
  },
  {
    id: 28,
    Jahr: 2019,
    Kategorie: 'Eis- und Wasseranlagen',
    Anzahl_Gebaeude: 10,
    Anteil_Gebaeude: 3.46,
  },
  {
    id: 29,
    Jahr: 2019,
    Kategorie: 'Kulturgebäude',
    Anzahl_Gebaeude: 6,
    Anteil_Gebaeude: 2.08,
  },
  {
    id: 30,
    Jahr: 2019,
    Kategorie: 'Werkhöfe',
    Anzahl_Gebaeude: 20,
    Anteil_Gebaeude: 6.92,
  },
  {
    id: 31,
    Jahr: 2019,
    Kategorie: 'Jugend-/Quartiertreffs',
    Anzahl_Gebaeude: 12,
    Anteil_Gebaeude: 4.15,
  },
  {
    id: 32,
    Jahr: 2019,
    Kategorie: 'Wohnhäuser/-heime',
    Anzahl_Gebaeude: 10,
    Anteil_Gebaeude: 3.46,
  },
  {
    id: 33,
    Jahr: 2019,
    Kategorie: 'Restaurants',
    Anzahl_Gebaeude: 4,
    Anteil_Gebaeude: 1.38,
  },
  {
    id: 34,
    Jahr: 2019,
    Kategorie: 'Total',
    Anzahl_Gebaeude: 289,
    Anteil_Gebaeude: 100,
  },
  {
    id: 35,
    Jahr: 2020,
    Kategorie: 'Verwaltungsgebäude',
    Anzahl_Gebaeude: 16,
    Anteil_Gebaeude: 5.13,
  },
  {
    id: 36,
    Jahr: 2020,
    Kategorie: 'Schulanlagen',
    Anzahl_Gebaeude: 203,
    Anteil_Gebaeude: 65.06,
  },
  {
    id: 37,
    Jahr: 2020,
    Kategorie: 'Sportanlagen',
    Anzahl_Gebaeude: 16,
    Anteil_Gebaeude: 5.13,
  },
  {
    id: 38,
    Jahr: 2020,
    Kategorie: 'Eis- und Wasseranlagen',
    Anzahl_Gebaeude: 10,
    Anteil_Gebaeude: 3.21,
  },
  {
    id: 39,
    Jahr: 2020,
    Kategorie: 'Kulturgebäude',
    Anzahl_Gebaeude: 6,
    Anteil_Gebaeude: 1.92,
  },
  {
    id: 40,
    Jahr: 2020,
    Kategorie: 'Werkhöfe',
    Anzahl_Gebaeude: 35,
    Anteil_Gebaeude: 11.22,
  },
  {
    id: 41,
    Jahr: 2020,
    Kategorie: 'Jugend-/Quartiertreffs',
    Anzahl_Gebaeude: 12,
    Anteil_Gebaeude: 3.85,
  },
  {
    id: 42,
    Jahr: 2020,
    Kategorie: 'Wohnhäuser/-heime',
    Anzahl_Gebaeude: 10,
    Anteil_Gebaeude: 3.21,
  },
  {
    id: 43,
    Jahr: 2020,
    Kategorie: 'Restaurants',
    Anzahl_Gebaeude: 4,
    Anteil_Gebaeude: 1.28,
  },
  {
    id: 44,
    Jahr: 2020,
    Kategorie: 'Total',
    Anzahl_Gebaeude: 312,
    Anteil_Gebaeude: 100,
  },
  {
    id: 45,
    Jahr: 2021,
    Kategorie: 'Verwaltungsgebäude',
    Anzahl_Gebaeude: 17,
    Anteil_Gebaeude: '',
  },
  {
    id: 46,
    Jahr: 2021,
    Kategorie: 'Schulanlagen',
    Anzahl_Gebaeude: 193,
    Anteil_Gebaeude: '',
  },
  {
    id: 47,
    Jahr: 2021,
    Kategorie: 'Sportanlagen',
    Anzahl_Gebaeude: 15,
    Anteil_Gebaeude: '',
  },
  {
    id: 48,
    Jahr: 2021,
    Kategorie: 'Eis- und Wasseranlagen',
    Anzahl_Gebaeude: 10,
    Anteil_Gebaeude: '',
  },
  {
    id: 49,
    Jahr: 2021,
    Kategorie: 'Kulturgebäude',
    Anzahl_Gebaeude: 6,
    Anteil_Gebaeude: '',
  },
  {
    id: 50,
    Jahr: 2021,
    Kategorie: 'Werkhöfe',
    Anzahl_Gebaeude: 38,
    Anteil_Gebaeude: '',
  },
  {
    id: 51,
    Jahr: 2021,
    Kategorie: 'Jugend-/Quartiertreffs',
    Anzahl_Gebaeude: 12,
    Anteil_Gebaeude: '',
  },
  {
    id: 52,
    Jahr: 2021,
    Kategorie: 'Wohnhäuser/-heime',
    Anzahl_Gebaeude: 13,
    Anteil_Gebaeude: '',
  },
  {
    id: 53,
    Jahr: 2021,
    Kategorie: 'Restaurants',
    Anzahl_Gebaeude: 4,
    Anteil_Gebaeude: '',
  },
  {
    id: 54,
    Jahr: 2021,
    Kategorie: 'Total',
    Anzahl_Gebaeude: 308,
    Anteil_Gebaeude: 100,
  },
];
