export const progEnergienVv = [
  {
    id: 1,
    Jahr: 2018,
    Energietraeger: 'Gas',
    Anzahl: 99,
  },
  {
    id: 2,
    Jahr: 2018,
    Energietraeger: 'Öl',
    Anzahl: 24,
  },
  {
    id: 3,
    Jahr: 2018,
    Energietraeger: 'Kombi Gas/Öl',
    Anzahl: 1,
  },
  {
    id: 4,
    Jahr: 2018,
    Energietraeger: 'Elektro-Direktheizungen',
    Anzahl: 16,
  },
  {
    id: 5,
    Jahr: 2018,
    Energietraeger: 'Fernwärme',
    Anzahl: 83,
  },
  {
    id: 6,
    Jahr: 2018,
    Energietraeger: 'Wärmepumpen',
    Anzahl: 18,
  },
  {
    id: 7,
    Jahr: 2018,
    Energietraeger: 'Holz',
    Anzahl: 4,
  },
  {
    id: 8,
    Jahr: 2018,
    Energietraeger: 'Photovoltaikanlagen',
    Anzahl: 15,
  },
  {
    id: 9,
    Jahr: 2018,
    Energietraeger: 'Solarthermieanlagen',
    Anzahl: 7,
  },
  {
    id: 10,
    Jahr: 2018,
    Energietraeger: 'Total',
    Anzahl: 267,
  },
  {
    id: 11,
    Jahr: 2019,
    Energietraeger: 'Gas',
    Anzahl: 92,
  },
  {
    id: 12,
    Jahr: 2019,
    Energietraeger: 'Öl',
    Anzahl: 21,
  },
  {
    id: 13,
    Jahr: 2019,
    Energietraeger: 'Kombi Gas/Öl',
    Anzahl: 1,
  },
  {
    id: 14,
    Jahr: 2019,
    Energietraeger: 'Elektro-Direktheizungen',
    Anzahl: 18,
  },
  {
    id: 15,
    Jahr: 2019,
    Energietraeger: 'Fernwärme',
    Anzahl: 84,
  },
  {
    id: 16,
    Jahr: 2019,
    Energietraeger: 'Wärmepumpen',
    Anzahl: 21,
  },
  {
    id: 17,
    Jahr: 2019,
    Energietraeger: 'Holz',
    Anzahl: 4,
  },
  {
    id: 18,
    Jahr: 2019,
    Energietraeger: 'Photovoltaikanlagen',
    Anzahl: 22,
  },
  {
    id: 19,
    Jahr: 2019,
    Energietraeger: 'Solarthermieanlagen',
    Anzahl: 7,
  },
  {
    id: 20,
    Jahr: 2019,
    Energietraeger: 'Total',
    Anzahl: 270,
  },
  {
    id: 21,
    Jahr: 2020,
    Energietraeger: 'Gas',
    Anzahl: 96,
  },
  {
    id: 22,
    Jahr: 2020,
    Energietraeger: 'Öl',
    Anzahl: 20,
  },
  {
    id: 23,
    Jahr: 2020,
    Energietraeger: 'Kombi Gas/Öl',
    Anzahl: 1,
  },
  {
    id: 24,
    Jahr: 2020,
    Energietraeger: 'Elektro-Direktheizungen',
    Anzahl: 22,
  },
  {
    id: 25,
    Jahr: 2020,
    Energietraeger: 'Fernwärme',
    Anzahl: 85,
  },
  {
    id: 26,
    Jahr: 2020,
    Energietraeger: 'Wärmepumpen',
    Anzahl: 26,
  },
  {
    id: 27,
    Jahr: 2020,
    Energietraeger: 'Holz',
    Anzahl: 4,
  },
  {
    id: 28,
    Jahr: 2020,
    Energietraeger: 'Photovoltaikanlagen',
    Anzahl: 31,
  },
  {
    id: 29,
    Jahr: 2020,
    Energietraeger: 'Solarthermieanlagen',
    Anzahl: 8,
  },
  {
    id: 30,
    Jahr: 2020,
    Energietraeger: 'Total',
    Anzahl: 293,
  },
  {
    id: 31,
    Jahr: 2021,
    Energietraeger: 'Gas',
    Anzahl: 100,
  },
  {
    id: 32,
    Jahr: 2021,
    Energietraeger: 'Öl',
    Anzahl: 20,
  },
  {
    id: 33,
    Jahr: 2021,
    Energietraeger: 'Kombi Gas/Öl',
    Anzahl: 1,
  },
  {
    id: 34,
    Jahr: 2021,
    Energietraeger: 'Elektro-Direktheizungen',
    Anzahl: 21,
  },
  {
    id: 35,
    Jahr: 2021,
    Energietraeger: 'Fernwärme',
    Anzahl: 86,
  },
  {
    id: 36,
    Jahr: 2021,
    Energietraeger: 'Wärmepumpen',
    Anzahl: 27,
  },
  {
    id: 37,
    Jahr: 2021,
    Energietraeger: 'Holz',
    Anzahl: 6,
  },
  {
    id: 38,
    Jahr: 2021,
    Energietraeger: 'Photovoltaikanlagen',
    Anzahl: 34,
  },
  {
    id: 39,
    Jahr: 2021,
    Energietraeger: 'Solarthermieanlagen',
    Anzahl: 8,
  },
  {
    id: 40,
    Jahr: 2021,
    Energietraeger: 'Total',
    Anzahl: 303,
  },
  {
    id: 41,
    Jahr: 2022,
    Energietraeger: 'Gas',
    Anzahl: 90,
  },
  {
    id: 42,
    Jahr: 2022,
    Energietraeger: 'Öl',
    Anzahl: 15,
  },
  {
    id: 43,
    Jahr: 2022,
    Energietraeger: 'Kombi Gas/Öl',
    Anzahl: 1,
  },
  {
    id: 44,
    Jahr: 2022,
    Energietraeger: 'Elektro-Direktheizungen',
    Anzahl: 14,
  },
  {
    id: 45,
    Jahr: 2022,
    Energietraeger: 'Fernwärme',
    Anzahl: 95,
  },
  {
    id: 46,
    Jahr: 2022,
    Energietraeger: 'Wärmepumpen',
    Anzahl: 33,
  },
  {
    id: 47,
    Jahr: 2022,
    Energietraeger: 'Holz',
    Anzahl: 4,
  },
  {
    id: 48,
    Jahr: 2022,
    Energietraeger: 'Photovoltaikanlagen',
    Anzahl: 40,
  },
  {
    id: 49,
    Jahr: 2022,
    Energietraeger: 'Solarthermieanlagen',
    Anzahl: 9,
  },
  {
    id: 50,
    Jahr: 2022,
    Energietraeger: 'Total',
    Anzahl: 301,
  },
  {
    id: 51,
    Jahr: 2023,
    Energietraeger: 'Gas',
    Anzahl: 87,
  },
  {
    id: 52,
    Jahr: 2023,
    Energietraeger: 'Öl',
    Anzahl: 10,
  },
  {
    id: 53,
    Jahr: 2023,
    Energietraeger: 'Kombi Gas/Öl',
    Anzahl: 1,
  },
  {
    id: 54,
    Jahr: 2023,
    Energietraeger: 'Elektro-Direktheizungen',
    Anzahl: 10,
  },
  {
    id: 55,
    Jahr: 2023,
    Energietraeger: 'Fernwärme',
    Anzahl: 100,
  },
  {
    id: 56,
    Jahr: 2023,
    Energietraeger: 'Wärmepumpen',
    Anzahl: 36,
  },
  {
    id: 57,
    Jahr: 2023,
    Energietraeger: 'Holz',
    Anzahl: 4,
  },
  {
    id: 58,
    Jahr: 2023,
    Energietraeger: 'Photovoltaikanlagen',
    Anzahl: 45,
  },
  {
    id: 59,
    Jahr: 2023,
    Energietraeger: 'Solarthermieanlagen',
    Anzahl: 9,
  },
  {
    id: 60,
    Jahr: 2023,
    Energietraeger: 'Total',
    Anzahl: 302,
  },
  {
    id: 61,
    Jahr: 2024,
    Energietraeger: 'Gas',
    Anzahl: 80,
  },
  {
    id: 62,
    Jahr: 2024,
    Energietraeger: 'Öl',
    Anzahl: 5,
  },
  {
    id: 63,
    Jahr: 2024,
    Energietraeger: 'Kombi Gas/Öl',
    Anzahl: 1,
  },
  {
    id: 64,
    Jahr: 2024,
    Energietraeger: 'Elektro-Direktheizungen',
    Anzahl: 5,
  },
  {
    id: 65,
    Jahr: 2024,
    Energietraeger: 'Fernwärme',
    Anzahl: 110,
  },
  {
    id: 66,
    Jahr: 2024,
    Energietraeger: 'Wärmepumpen',
    Anzahl: 40,
  },
  {
    id: 67,
    Jahr: 2024,
    Energietraeger: 'Holz',
    Anzahl: 4,
  },
  {
    id: 68,
    Jahr: 2024,
    Energietraeger: 'Photovoltaikanlagen',
    Anzahl: 55,
  },
  {
    id: 69,
    Jahr: 2024,
    Energietraeger: 'Solarthermieanlagen',
    Anzahl: 10,
  },
  {
    id: 70,
    Jahr: 2024,
    Energietraeger: 'Total',
    Anzahl: 310,
  },
  {
    id: 71,
    Jahr: 2025,
    Energietraeger: 'Gas',
    Anzahl: 70,
  },
  {
    id: 72,
    Jahr: 2025,
    Energietraeger: 'Öl',
    Anzahl: 0,
  },
  {
    id: 73,
    Jahr: 2025,
    Energietraeger: 'Kombi Gas/Öl',
    Anzahl: 0,
  },
  {
    id: 74,
    Jahr: 2025,
    Energietraeger: 'Elektro-Direktheizungen',
    Anzahl: 0,
  },
  {
    id: 75,
    Jahr: 2025,
    Energietraeger: 'Fernwärme',
    Anzahl: 115,
  },
  {
    id: 76,
    Jahr: 2025,
    Energietraeger: 'Wärmepumpen',
    Anzahl: 43,
  },
  {
    id: 77,
    Jahr: 2025,
    Energietraeger: 'Holz',
    Anzahl: 5,
  },
  {
    id: 78,
    Jahr: 2025,
    Energietraeger: 'Photovoltaikanlagen',
    Anzahl: 60,
  },
  {
    id: 79,
    Jahr: 2025,
    Energietraeger: 'Solarthermieanlagen',
    Anzahl: 10,
  },
  {
    id: 80,
    Jahr: 2025,
    Energietraeger: 'Total',
    Anzahl: 303,
  },
  {
    id: 81,
    Jahr: 2026,
    Energietraeger: 'Gas',
    Anzahl: 60,
  },
  {
    id: 82,
    Jahr: 2026,
    Energietraeger: 'Öl',
    Anzahl: 0,
  },
  {
    id: 83,
    Jahr: 2026,
    Energietraeger: 'Kombi Gas/Öl',
    Anzahl: 0,
  },
  {
    id: 84,
    Jahr: 2026,
    Energietraeger: 'Elektro-Direktheizungen',
    Anzahl: 0,
  },
  {
    id: 85,
    Jahr: 2026,
    Energietraeger: 'Fernwärme',
    Anzahl: 120,
  },
  {
    id: 86,
    Jahr: 2026,
    Energietraeger: 'Wärmepumpen',
    Anzahl: 46,
  },
  {
    id: 87,
    Jahr: 2026,
    Energietraeger: 'Holz',
    Anzahl: 5,
  },
  {
    id: 88,
    Jahr: 2026,
    Energietraeger: 'Photovoltaikanlagen',
    Anzahl: 70,
  },
  {
    id: 89,
    Jahr: 2026,
    Energietraeger: 'Solarthermieanlagen',
    Anzahl: 11,
  },
  {
    id: 90,
    Jahr: 2026,
    Energietraeger: 'Total',
    Anzahl: 312,
  },
  {
    id: 91,
    Jahr: 2027,
    Energietraeger: 'Gas',
    Anzahl: 50,
  },
  {
    id: 92,
    Jahr: 2027,
    Energietraeger: 'Öl',
    Anzahl: 0,
  },
  {
    id: 93,
    Jahr: 2027,
    Energietraeger: 'Kombi Gas/Öl',
    Anzahl: 0,
  },
  {
    id: 94,
    Jahr: 2027,
    Energietraeger: 'Elektro-Direktheizungen',
    Anzahl: 0,
  },
  {
    id: 95,
    Jahr: 2027,
    Energietraeger: 'Fernwärme',
    Anzahl: 125,
  },
  {
    id: 96,
    Jahr: 2027,
    Energietraeger: 'Wärmepumpen',
    Anzahl: 50,
  },
  {
    id: 97,
    Jahr: 2027,
    Energietraeger: 'Holz',
    Anzahl: 5,
  },
  {
    id: 98,
    Jahr: 2027,
    Energietraeger: 'Photovoltaikanlagen',
    Anzahl: 80,
  },
  {
    id: 99,
    Jahr: 2027,
    Energietraeger: 'Solarthermieanlagen',
    Anzahl: 11,
  },
  {
    id: 100,
    Jahr: 2027,
    Energietraeger: 'Total',
    Anzahl: 321,
  },
  {
    id: 101,
    Jahr: 2028,
    Energietraeger: 'Gas',
    Anzahl: 40,
  },
  {
    id: 102,
    Jahr: 2028,
    Energietraeger: 'Öl',
    Anzahl: 0,
  },
  {
    id: 103,
    Jahr: 2028,
    Energietraeger: 'Kombi Gas/Öl',
    Anzahl: 0,
  },
  {
    id: 104,
    Jahr: 2028,
    Energietraeger: 'Elektro-Direktheizungen',
    Anzahl: 0,
  },
  {
    id: 105,
    Jahr: 2028,
    Energietraeger: 'Fernwärme',
    Anzahl: 130,
  },
  {
    id: 106,
    Jahr: 2028,
    Energietraeger: 'Wärmepumpen',
    Anzahl: 60,
  },
  {
    id: 107,
    Jahr: 2028,
    Energietraeger: 'Holz',
    Anzahl: 5,
  },
  {
    id: 108,
    Jahr: 2028,
    Energietraeger: 'Photovoltaikanlagen',
    Anzahl: 90,
  },
  {
    id: 109,
    Jahr: 2028,
    Energietraeger: 'Solarthermieanlagen',
    Anzahl: 12,
  },
  {
    id: 110,
    Jahr: 2028,
    Energietraeger: 'Total',
    Anzahl: 337,
  },
  {
    id: 111,
    Jahr: 2029,
    Energietraeger: 'Gas',
    Anzahl: 30,
  },
  {
    id: 112,
    Jahr: 2029,
    Energietraeger: 'Öl',
    Anzahl: 0,
  },
  {
    id: 113,
    Jahr: 2029,
    Energietraeger: 'Kombi Gas/Öl',
    Anzahl: 0,
  },
  {
    id: 114,
    Jahr: 2029,
    Energietraeger: 'Elektro-Direktheizungen',
    Anzahl: 0,
  },
  {
    id: 115,
    Jahr: 2029,
    Energietraeger: 'Fernwärme',
    Anzahl: 135,
  },
  {
    id: 116,
    Jahr: 2029,
    Energietraeger: 'Wärmepumpen',
    Anzahl: 70,
  },
  {
    id: 117,
    Jahr: 2029,
    Energietraeger: 'Holz',
    Anzahl: 5,
  },
  {
    id: 118,
    Jahr: 2029,
    Energietraeger: 'Photovoltaikanlagen',
    Anzahl: 95,
  },
  {
    id: 119,
    Jahr: 2029,
    Energietraeger: 'Solarthermieanlagen',
    Anzahl: 12,
  },
  {
    id: 120,
    Jahr: 2029,
    Energietraeger: 'Total',
    Anzahl: 347,
  },
  {
    id: 121,
    Jahr: 2030,
    Energietraeger: 'Gas',
    Anzahl: 25,
  },
  {
    id: 122,
    Jahr: 2030,
    Energietraeger: 'Öl',
    Anzahl: 0,
  },
  {
    id: 123,
    Jahr: 2030,
    Energietraeger: 'Kombi Gas/Öl',
    Anzahl: 0,
  },
  {
    id: 124,
    Jahr: 2030,
    Energietraeger: 'Elektro-Direktheizungen',
    Anzahl: 0,
  },
  {
    id: 125,
    Jahr: 2030,
    Energietraeger: 'Fernwärme',
    Anzahl: 140,
  },
  {
    id: 126,
    Jahr: 2030,
    Energietraeger: 'Wärmepumpen',
    Anzahl: 75,
  },
  {
    id: 127,
    Jahr: 2030,
    Energietraeger: 'Holz',
    Anzahl: 5,
  },
  {
    id: 128,
    Jahr: 2030,
    Energietraeger: 'Photovoltaikanlagen',
    Anzahl: 100,
  },
  {
    id: 129,
    Jahr: 2030,
    Energietraeger: 'Solarthermieanlagen',
    Anzahl: 13,
  },
  {
    id: 130,
    Jahr: 2030,
    Energietraeger: 'Total',
    Anzahl: 358,
  },
  {
    id: 131,
    Jahr: 2031,
    Energietraeger: 'Gas',
    Anzahl: 20,
  },
  {
    id: 132,
    Jahr: 2031,
    Energietraeger: 'Öl',
    Anzahl: 0,
  },
  {
    id: 133,
    Jahr: 2031,
    Energietraeger: 'Kombi Gas/Öl',
    Anzahl: 0,
  },
  {
    id: 134,
    Jahr: 2031,
    Energietraeger: 'Elektro-Direktheizungen',
    Anzahl: 0,
  },
  {
    id: 135,
    Jahr: 2031,
    Energietraeger: 'Fernwärme',
    Anzahl: 145,
  },
  {
    id: 136,
    Jahr: 2031,
    Energietraeger: 'Wärmepumpen',
    Anzahl: 80,
  },
  {
    id: 137,
    Jahr: 2031,
    Energietraeger: 'Holz',
    Anzahl: 6,
  },
  {
    id: 138,
    Jahr: 2031,
    Energietraeger: 'Photovoltaikanlagen',
    Anzahl: 110,
  },
  {
    id: 139,
    Jahr: 2031,
    Energietraeger: 'Solarthermieanlagen',
    Anzahl: 13,
  },
  {
    id: 140,
    Jahr: 2031,
    Energietraeger: 'Total',
    Anzahl: 374,
  },
  {
    id: 141,
    Jahr: 2032,
    Energietraeger: 'Gas',
    Anzahl: 15,
  },
  {
    id: 142,
    Jahr: 2032,
    Energietraeger: 'Öl',
    Anzahl: 0,
  },
  {
    id: 143,
    Jahr: 2032,
    Energietraeger: 'Kombi Gas/Öl',
    Anzahl: 0,
  },
  {
    id: 144,
    Jahr: 2032,
    Energietraeger: 'Elektro-Direktheizungen',
    Anzahl: 0,
  },
  {
    id: 145,
    Jahr: 2032,
    Energietraeger: 'Fernwärme',
    Anzahl: 150,
  },
  {
    id: 146,
    Jahr: 2032,
    Energietraeger: 'Wärmepumpen',
    Anzahl: 85,
  },
  {
    id: 147,
    Jahr: 2032,
    Energietraeger: 'Holz',
    Anzahl: 6,
  },
  {
    id: 148,
    Jahr: 2032,
    Energietraeger: 'Photovoltaikanlagen',
    Anzahl: 115,
  },
  {
    id: 149,
    Jahr: 2032,
    Energietraeger: 'Solarthermieanlagen',
    Anzahl: 14,
  },
  {
    id: 150,
    Jahr: 2032,
    Energietraeger: 'Total',
    Anzahl: 385,
  },
  {
    id: 151,
    Jahr: 2033,
    Energietraeger: 'Gas',
    Anzahl: 10,
  },
  {
    id: 152,
    Jahr: 2033,
    Energietraeger: 'Öl',
    Anzahl: 0,
  },
  {
    id: 153,
    Jahr: 2033,
    Energietraeger: 'Kombi Gas/Öl',
    Anzahl: 0,
  },
  {
    id: 154,
    Jahr: 2033,
    Energietraeger: 'Elektro-Direktheizungen',
    Anzahl: 0,
  },
  {
    id: 155,
    Jahr: 2033,
    Energietraeger: 'Fernwärme',
    Anzahl: 155,
  },
  {
    id: 156,
    Jahr: 2033,
    Energietraeger: 'Wärmepumpen',
    Anzahl: 90,
  },
  {
    id: 157,
    Jahr: 2033,
    Energietraeger: 'Holz',
    Anzahl: 6,
  },
  {
    id: 158,
    Jahr: 2033,
    Energietraeger: 'Photovoltaikanlagen',
    Anzahl: 120,
  },
  {
    id: 159,
    Jahr: 2033,
    Energietraeger: 'Solarthermieanlagen',
    Anzahl: 14,
  },
  {
    id: 160,
    Jahr: 2033,
    Energietraeger: 'Total',
    Anzahl: 395,
  },
  {
    id: 161,
    Jahr: 2034,
    Energietraeger: 'Gas',
    Anzahl: 5,
  },
  {
    id: 162,
    Jahr: 2034,
    Energietraeger: 'Öl',
    Anzahl: 0,
  },
  {
    id: 163,
    Jahr: 2034,
    Energietraeger: 'Kombi Gas/Öl',
    Anzahl: 0,
  },
  {
    id: 164,
    Jahr: 2034,
    Energietraeger: 'Elektro-Direktheizungen',
    Anzahl: 0,
  },
  {
    id: 165,
    Jahr: 2034,
    Energietraeger: 'Fernwärme',
    Anzahl: 160,
  },
  {
    id: 166,
    Jahr: 2034,
    Energietraeger: 'Wärmepumpen',
    Anzahl: 95,
  },
  {
    id: 167,
    Jahr: 2034,
    Energietraeger: 'Holz',
    Anzahl: 6,
  },
  {
    id: 168,
    Jahr: 2034,
    Energietraeger: 'Photovoltaikanlagen',
    Anzahl: 125,
  },
  {
    id: 169,
    Jahr: 2034,
    Energietraeger: 'Solarthermieanlagen',
    Anzahl: 15,
  },
  {
    id: 170,
    Jahr: 2034,
    Energietraeger: 'Total',
    Anzahl: 406,
  },
  {
    id: 171,
    Jahr: 2035,
    Energietraeger: 'Gas',
    Anzahl: 0,
  },
  {
    id: 172,
    Jahr: 2035,
    Energietraeger: 'Öl',
    Anzahl: 0,
  },
  {
    id: 173,
    Jahr: 2035,
    Energietraeger: 'Kombi Gas/Öl',
    Anzahl: 0,
  },
  {
    id: 174,
    Jahr: 2035,
    Energietraeger: 'Elektro-Direktheizungen',
    Anzahl: 0,
  },
  {
    id: 175,
    Jahr: 2035,
    Energietraeger: 'Fernwärme',
    Anzahl: 165,
  },
  {
    id: 176,
    Jahr: 2035,
    Energietraeger: 'Wärmepumpen',
    Anzahl: 100,
  },
  {
    id: 177,
    Jahr: 2035,
    Energietraeger: 'Holz',
    Anzahl: 6,
  },
  {
    id: 178,
    Jahr: 2035,
    Energietraeger: 'Photovoltaikanlagen',
    Anzahl: 130,
  },
  {
    id: 179,
    Jahr: 2035,
    Energietraeger: 'Solarthermieanlagen',
    Anzahl: 15,
  },
  {
    id: 180,
    Jahr: 2035,
    Energietraeger: 'Total',
    Anzahl: 416,
  },
];
