export const veloplaetzeFv = [
  {
    id: 1,
    Veloplaetze: 0.18,
    Jahr: 2012,
    Kategorie: 'Wohnliegenschaften (pro Zimmer)',
  },
  {
    id: 2,
    Veloplaetze: 0.2,
    Jahr: 2013,
    Kategorie: 'Wohnliegenschaften (pro Zimmer)',
  },
  {
    id: 3,
    Veloplaetze: 0.21,
    Jahr: 2014,
    Kategorie: 'Wohnliegenschaften (pro Zimmer)',
  },
  {
    id: 4,
    Veloplaetze: 0.22,
    Jahr: 2015,
    Kategorie: 'Wohnliegenschaften (pro Zimmer)',
  },
  {
    id: 5,
    Veloplaetze: 0.24,
    Jahr: 2016,
    Kategorie: 'Wohnliegenschaften (pro Zimmer)',
  },
  {
    id: 6,
    Veloplaetze: 0.25,
    Jahr: 2017,
    Kategorie: 'Wohnliegenschaften (pro Zimmer)',
  },
  {
    id: 7,
    Veloplaetze: 0.26,
    Jahr: 2018,
    Kategorie: 'Wohnliegenschaften (pro Zimmer)',
  },
  {
    id: 8,
    Veloplaetze: 0.29,
    Jahr: 2019,
    Kategorie: 'Wohnliegenschaften (pro Zimmer)',
  },
  {
    id: 9,
    Veloplaetze: 0.3,
    Jahr: 2020,
    Kategorie: 'Wohnliegenschaften (pro Zimmer)',
  },
  {
    id: 10,
    Veloplaetze: 0.3,
    Jahr: 2021,
    Kategorie: 'Wohnliegenschaften (pro Zimmer)',
  },
  {
    id: 11,
    Veloplaetze: 0.68,
    Jahr: 2012,
    Kategorie: 'Geschäftsliegenschaften (pro Objekt)',
  },
  {
    id: 12,
    Veloplaetze: 1.01,
    Jahr: 2013,
    Kategorie: 'Geschäftsliegenschaften (pro Objekt)',
  },
  {
    id: 13,
    Veloplaetze: 1.06,
    Jahr: 2014,
    Kategorie: 'Geschäftsliegenschaften (pro Objekt)',
  },
  {
    id: 14,
    Veloplaetze: 0.81,
    Jahr: 2015,
    Kategorie: 'Geschäftsliegenschaften (pro Objekt)',
  },
  {
    id: 15,
    Veloplaetze: 1.01,
    Jahr: 2016,
    Kategorie: 'Geschäftsliegenschaften (pro Objekt)',
  },
  {
    id: 16,
    Veloplaetze: 1.2,
    Jahr: 2017,
    Kategorie: 'Geschäftsliegenschaften (pro Objekt)',
  },
  {
    id: 17,
    Veloplaetze: 1.37,
    Jahr: 2018,
    Kategorie: 'Geschäftsliegenschaften (pro Objekt)',
  },
  {
    id: 18,
    Veloplaetze: 1.34,
    Jahr: 2019,
    Kategorie: 'Geschäftsliegenschaften (pro Objekt)',
  },
  {
    id: 19,
    Veloplaetze: 1.66,
    Jahr: 2020,
    Kategorie: 'Geschäftsliegenschaften (pro Objekt)',
  },
  {
    id: 20,
    Veloplaetze: 1.63,
    Jahr: 2021,
    Kategorie: 'Geschäftsliegenschaften (pro Objekt)',
  },
];
