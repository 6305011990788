import { Component, OnInit } from '@angular/core';
import { ChartDataSets, ChartType, RadialChartOptions } from 'chart.js';
import { Label } from 'ng2-charts';
import { FormControl, NgModel, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ChartColorService } from '../shared/chart-color.service';
import { InfoModalComponent } from '../info-modal/info-modal.component';
import { nachhaltigkeitsrosetteVV } from './nachhaltigkeitsrosette-vv.data';
import { nachhaltigkeitsrosetteVVNeu } from './nachhaltigkeitsrosette-vv-neu.data';
import { nachhaltigkeitsrosetteFV } from './nachhaltigkeitsrosette-fv.data';

@Component({
  selector: 'nim-nachhaltigkeits-radialchart',
  templateUrl: './nachhaltigkeits-radialchart.component.html',
  styleUrls: ['./nachhaltigkeits-radialchart.component.css'],
})
export class NachhaltigkeitsRadialchartComponent implements OnInit {
  public radarChartOptions: RadialChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    legend: {
      display: false,
    },
    scale: {
      ticks: {
        maxTicksLimit: 8,
        min: 0,
        max: 5,
      },
      pointLabels: { fontSize: window.innerWidth < 900 ? 7 : 12 },
    },
    tooltips: {
      callbacks: {
        title: () => {
          return '';
        },
      },
    },
  };

  public useNewSystem: boolean = true;

  public chartHeight = this.getChartHeight();

  buildingsControl = new FormControl('', Validators.required);

  public radarChartLabels: Label[] = [
    'Gemeinschaft',
    'Gestaltung',
    'Nutzung, Erschliessung',
    'Wohlbefinden, Gesundheit',
    'Gebäude-Bausubstanz',
    'Anlagekosten',
    'Betriebs- und Unterhaltskosten',
    'Wertschöpfung, Immobilienmarkt',
    'Baustoffe',
    'Betriebsenergie',
    'Boden, Landschaft',
    'Infrastruktur',
  ];

  public buildingTypesFV = [
    'Gesamt',
    'Wohnliegenschaften',
    'Geschäftsliegenschaften',
    'Landwirtschaft',
  ];

  public buildingTypesVVneu = [
    'Gesamt',
    'Verwaltungsgebäude',
    'Bildung',
    'Sport und Freizeit',
    'Wohnen und Aufenthalt',
    'Kultur und Geselligkeit',
    'Infrastrukturbauten',
  ];

  public buildingTypesVV = this.buildingTypesVVneu;

  public buildingTypesVValt = [
    'Gesamt',
    'Verwaltungsgebäude',
    'Schulen',
    'Sportanlagen',
    'Eis-Wasseranlagen',
    'Kulturgebäude',
    'Werkhöfe',
    'Jugend-/Quartiertreffs',
    'Wohnhäuser/-heime',
    'Restaurants',
  ];

  selectedBuildingFV: string = this.buildingTypesFV[0];

  selectedBuildingVV: string = this.buildingTypesVV[0];

  selectedYearFV;

  selectedYearVV;

  public selectedSystem;

  public radarChartDataFV: ChartDataSets[] = [];

  public radarChartDataVV: ChartDataSets[] = [];

  public radarChartType: ChartType = 'radar';

  chartColors;

  constructor(
    private chartColorService: ChartColorService,
    public dialog: MatDialog,
  ) {}

  labels = [
    'Nachhaltigkeitsbewertung:',
    '5 stark',
    '4 gut',
    '3 gering',
    '<2 keine',
  ];

  dataFV;

  dataVV;

  dataVValt;

  dataVVneu;

  yearsVv = [];

  yearsFv = [];

  yearsVvAlt = [
    [2015, 2016, 2017, 2018, 2019, 2020, 2021],
    [2015, 2016, 2017, 2018, 2019, 2020, 2021],
    [2015, 2016, 2017, 2018, 2019, 2020, 2021],
    [2016, 2017, 2018, 2019, 2020, 2021],
    [2016, 2017, 2018, 2019, 2020, 2021],
    [2017, 2018, 2019, 2020, 2021],
    [2017, 2018, 2019, 2020, 2021],
    [2018, 2019, 2020, 2021],
    [2018, 2019, 2020, 2021],
    [2019, 2020, 2021],
  ];

  newSystemYears = [2019, 2020, 2021];

  public systems = ['Altes System', 'Neues System'];

  labelsFv = [];

  labelsVv = [];

  labelsFvFiltered = [];

  labelsVvFiltered = [];

  areaLabels = ['Keine Nachhaltigkeit', 'Starke bis geringe Nachhaltigkeit'];

  ngOnInit() {
    this.chartColors = this.chartColorService.getRadialChartColors();
    this.getVvData();
    this.getFvData();
    this.getVvNeuData();
 
  }

  private getVvData() {
    this.dataVValt = nachhaltigkeitsrosetteVV;
  }

  private getFvData() {
    const res = nachhaltigkeitsrosetteFV;
    this.dataFV = res;
    this.getYearsFV();
    this.labelsFvFiltered = this.labelsFv;
    this.prepareDataFV(res, this.buildingTypesFV[0], this.yearsFv);
  }

  private getVvNeuData() {
    this.dataVVneu = nachhaltigkeitsrosetteVVNeu;
    this.dataVV = this.dataVVneu;
    this.yearsVv = this.newSystemYears;
    this.selectedSystem = 'Neues System';
    this.labelsVvFiltered = this.labelsVv;
    this.getYearLabelsVV();
    this.prepareDataVV(
      nachhaltigkeitsrosetteVV,
      this.buildingTypesVV[0],
      this.yearsVv,
    );
  }

  private getYearsFV() {
    this.dataFV.forEach((element) => {
      if (!this.yearsFv.includes(element.Jahr)) {
        this.yearsFv.push(element.Jahr);
        this.labelsFv.push(String(element.Jahr));
      }
    });
  }

  private getYearLabelsVV() {
    this.labelsVv = [];
    this.yearsVv.forEach((year) => this.labelsVv.push(String(year)));
  }

  selectionChangeBuildingFV(selectedBuilding) {
    this.selectedBuildingFV = selectedBuilding.value;
    this.prepareDataFV(this.dataFV, selectedBuilding.value, this.yearsFv);
  }

  selectionChangeYearFV(selectedYears) {
    this.labelsFvFiltered = selectedYears.value.map((year) => String(year));
    this.prepareDataFV(
      this.dataFV,
      this.selectedBuildingFV,
      selectedYears.value,
    );
  }

  selectionChangeBuildingVV(selectedBuilding) {
    this.selectedBuildingVV = selectedBuilding.value;
    if (!this.useNewSystem) {
      this.yearsVv = this.yearsVvAlt[
        this.buildingTypesVValt.indexOf(this.selectedBuildingVV)
      ];
      this.labelsVvFiltered = [];
      this.yearsVv.forEach((year) => this.labelsVvFiltered.push(String(year)));
    }

    this.prepareDataVV(this.dataVV, selectedBuilding.value, this.yearsVv);
  }

  selectionChangeYearVV(selectedYears) {
    this.labelsVvFiltered = selectedYears.value.map((year) => String(year));
    this.prepareDataVV(
      this.dataVV,
      this.selectedBuildingVV,
      selectedYears.value,
    );
  }

  deselectAllVV(select: NgModel) {
    select.update.emit([]);
    this.yearsVv = this.newSystemYears;
    this.prepareDataVV(this.dataVV, this.buildingTypesVV[0], this.yearsVv);
  }

  deselectAllFV(select: NgModel) {
    select.update.emit([]);
    this.prepareDataFV(this.dataFV, this.buildingTypesFV[0], this.yearsFv);
  }

  private prepareDataFV(dataSet, category, years) {
    this.radarChartDataFV = [];
    /// add dummy data to make visual difference between values under and over 2
    this.radarChartDataFV.push(
      {
        data: [5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5],
        fill: true,
        borderWidth: -1,
        pointRadius: 0,
        pointHoverRadius: 0,
        label: 'Starke bis geringe Nachhaltigkeit',
        backgroundColor: this.chartColors[0].backgroundColor,
        borderColor: this.chartColors[0].backgroundColor,
      },
      {
        data: [2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2],
        fill: true,
        borderWidth: -1,
        pointRadius: 0,
        pointHoverRadius: 0,
        label: 'Keine Nachhaltigkeit',
        backgroundColor: this.chartColors[1].backgroundColor,
        borderColor: this.chartColors[1].backgroundColor,
      },
    );
    years.forEach((year) => {
      if (
        dataSet
          .filter((o) => o.Jahr === year)
          .filter((o) => o.Kategorie === category).length !== 0
      )
        this.radarChartDataFV.push({
          label: String(year),
          fill: false,
          borderWidth: window.innerWidth < 900 ? 1 : 3,
          pointRadius: window.innerWidth < 900 ? 1 : 3,
          backgroundColor: this.getColor(String(year)).borderColor,
          borderColor: this.getColor(String(year)).borderColor,
          pointBackgroundColor: this.getColor(String(year))
            .pointBackgroundColor,
          pointBorderColor: this.getColor(String(year)).pointBorderColor,
          pointHoverBackgroundColor: this.getColor(String(year)).borderColor,
          pointHoverBorderColor: this.getColor(String(year)).borderColor,
          data: dataSet
            .filter((o) => o.Jahr === year)
            .filter((o) => o.Kategorie === category)
            .map((o) => [
              o.Gemeinschaft,
              o.Gestaltung,
              o.Nutzung_Erschliessung,
              o.Wohlbefinden_Gesundheit,
              o.Gebaeude_Bausubstanz,
              o.Anlagekosten,
              o.Betriebs_Unterhaltskosten,
              o.Wertschoepfung_Immobilienmarkt,
              o.Baustoffe,
              o.Betriebsenergie,
              o.Boden_Natur_Landschaft,
              o.Infrastruktur,
            ])[0],
        });
    });
  }

  private prepareDataVV(dataSet, category, years) {
    this.radarChartDataVV = [];
    /// add dummy data to make visual difference between values under and over 2
    this.radarChartDataVV.push(
      {
        data: [5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5],
        fill: true,
        borderWidth: -1,
        pointRadius: 0,
        pointHoverRadius: 0,
        label: 'Starke bis geringe Nachhaltigkeit',
        backgroundColor: this.chartColors[0].backgroundColor,
        borderColor: this.chartColors[0].backgroundColor,
      },
      {
        data: [2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2],
        fill: true,
        borderWidth: -1,
        pointRadius: 0,
        pointHoverRadius: 0,
        label: 'Keine Nachhaltigkeit',
        backgroundColor: this.chartColors[1].backgroundColor,
        borderColor: this.chartColors[1].backgroundColor,
      },
    );
    years.forEach((year) => {
      if (
        dataSet
          .filter((o) => o.Jahr === year)
          .filter((o) => o.Kategorie === category).length !== 0
      )
        this.radarChartDataVV.push({
          fill: false,
          borderWidth: window.innerWidth < 900 ? 1 : 3,
          pointRadius: window.innerWidth < 900 ? 1 : 3,
          label: String(year),
          backgroundColor: this.getColor(String(year)).borderColor,
          borderColor: this.getColor(String(year)).borderColor,
          pointBackgroundColor: this.getColor(String(year))
            .pointBackgroundColor,
          pointBorderColor: this.getColor(String(year)).pointBorderColor,
          pointHoverBackgroundColor: this.getColor(String(year)).borderColor,
          pointHoverBorderColor: this.getColor(String(year)).borderColor,
          data: dataSet
            .filter((o) => o.Jahr === year)
            .filter((o) => o.Kategorie === category)
            .map((o) => [
              o.Gemeinschaft,
              o.Gestaltung,
              o.Nutzung_Erschliessung,
              o.Wohlbefinden_Gesundheit,
              o.Gebaeude_Bausubstanz,
              o.Anlagekosten,
              o.Betriebs_Unterhaltskosten,
              o.Wertschoepfung_Immobilienmarkt,
              o.Baustoffe,
              o.Betriebsenergie,
              o.Boden_Natur_Landschaft,
              o.Infrastruktur,
            ])[0],
        });
    });
  }

  public resetFilterFV(select: NgModel) {
    this.ngOnInit();
    this.selectedBuildingFV = this.buildingTypesFV[0];
    select.update.emit([]);
  }

  public resetFilterVV(select: NgModel) {
    this.ngOnInit();
    this.selectedSystem = 'Neues System';
    this.buildingTypesVV = this.buildingTypesVVneu;
    this.selectedBuildingVV = this.buildingTypesVV[0];
    select.update.emit([]);
  }

  onInfoButtonTap(content: string) {
    this.dialog.open(InfoModalComponent, {
      data: {
        content,
      },
    });
  }

  public selectionChangeSystem(ob) {
    this.selectedSystem === ob.value;
    if (this.selectedSystem === 'Altes System') {
      this.yearsVv = this.yearsVvAlt[0];
      this.labelsFvFiltered = this.yearsVvAlt.map((year) => String(year));
      this.buildingTypesVV = this.buildingTypesVValt;
      this.selectedBuildingVV = 'Gesamt';
      this.dataVV = this.dataVValt;
      this.useNewSystem = false;
    } else {
      this.selectedYearVV = this.newSystemYears;
      this.yearsVv = this.newSystemYears;
      this.labelsVvFiltered = this.newSystemYears.map((year) => String(year));
      this.buildingTypesVV = this.buildingTypesVVneu;
      this.dataVV = this.dataVVneu;
      this.selectedBuildingVV = 'Gesamt';
      this.useNewSystem = true;
    }
    this.prepareDataVV(this.dataVV, this.selectedBuildingVV, this.yearsVv);
  }

  public getColor(label: string) {
    switch (label) {
      case 'Keine Nachhaltigkeit': {
        return this.chartColors[1];
      }
      case 'Starke bis geringe Nachhaltigkeit': {
        return this.chartColors[0];
      }
      case '2012': {
        return this.chartColors[8];
      }
      case '2013': {
        return this.chartColors[9];
      }
      case '2014': {
        return this.chartColors[10];
      }
      case '2015': {
        return this.chartColors[2];
      }
      case '2016': {
        return this.chartColors[3];
      }
      case '2017': {
        return this.chartColors[4];
      }
      case '2018': {
        return this.chartColors[5];
      }
      case '2019': {
        return this.chartColors[6];
      }
      case '2020': {
        return this.chartColors[7];
      }
      case '2021': {
        return this.chartColors[11];
      }
      default:
        return '#ffffff';
    }
  }

  public getChartHeight() {
    if (window.innerWidth < window.innerHeight) {
      return window.innerWidth - 5;
    } else {
      return window.innerWidth < 1200 ? '300px' : '400px';
    }
  }
}
