import { Component, OnInit } from '@angular/core';
import { ChartOptions, ChartType } from 'chart.js';
import { Color, Label } from 'ng2-charts';
import { NgModel } from '@angular/forms';
import { ChartColorService } from '../shared/chart-color.service';
import { MatDialog } from '@angular/material/dialog';
import { InfoModalComponent } from '../info-modal/info-modal.component';
import { handlungsBedarfVv } from './handlungsbedarf-vv.data';
import { handlungsbedarfFv } from './handlungsberdarf-fv.data';

@Component({
  selector: 'nim-handlungsbedarf-table',
  templateUrl: './handlungsbedarf-table.component.html',
  styleUrls: ['./handlungsbedarf-table.component.css'],
})
export class HandlungsbedarfTableComponent implements OnInit {
  constructor(
    private chartColorService: ChartColorService,
    public dialog: MatDialog,
  ) {}

  public barChartOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    legend: { display: false },
    scales: {
      xAxes: [
        {
          scaleLabel: {
            display: true,
          },
        },
      ],
      yAxes: [
        {
          display: true,
          scaleLabel: {
            display: true,
            labelString: 'Anzahl Gebäude',
          },
          ticks: { beginAtZero: true },
        },
      ],
    },
  };

  public barChartType: ChartType = 'bar';

  public barChartColors: Color[];

  public barChartLegend = true;

  public barChartLabels: Label[] = ['kein', 'absehbar', 'dringend'];

  yearsFV = [];

  selectedYearsFV;

  yearsVV = [];

  selectedYearsVV;

  yearLabelsFV;

  yearLabelsVV;

  dataFV;

  dataVV;

  barChartDataFV = [];

  barChartDataVV = [];

  public chartHeight = this.getChartHeight();

  ngOnInit() {
    this.barChartColors = this.chartColorService.getRadialChartColors();
    this.getVvData();
    this.getFvData();
  }

  private getFvData() {
    const res = handlungsbedarfFv;
    this.dataFV = res;
    this.getYearsFV();
    this.yearLabelsFV = this.yearsFV;
    this.prepareDataFV(this.dataFV);
  }

  private getVvData() {
    const res = handlungsBedarfVv;
    this.dataVV = res;
    this.getYearsVV();
    this.yearLabelsVV = this.yearsVV;
    this.prepareDataVV(this.dataVV);
  }

  private getYearsFV() {
    this.dataFV.forEach((element) => {
      if (!this.yearsFV.includes(element.Jahr)) {
        this.yearsFV.push(`${String(element.Jahr)} `);
      }
    });
  }

  private getYearsVV() {
    this.dataVV.forEach((element) => {
      if (!this.yearsVV.includes(element.Jahr)) {
        this.yearsVV.push(`${String(element.Jahr)} `);
      }
    });
  }

  private prepareDataFV(data) {
    this.barChartDataFV = [];
    data.forEach((element) => {
      const newDataSet = {
        label: element.Jahr,
        data: [element.Anz_Kein, element.Anz_Absehbar, element.Anz_Dringend],
        backgroundColor: this.getColor(String(element.Jahr)).backgroundColor,
        hoverBackgroundColor: this.getColor(String(element.Jahr))
          .hoverBackgroundColor,
        borderColor: this.getColor(String(element.Jahr)).borderColor,
        hoverBorderColor: this.getColor(String(element.Jahr)).hoverBorderColor,
      };
      this.barChartDataFV.push(newDataSet);
    });
  }

  private prepareDataVV(data) {
    this.barChartDataVV = [];

    data.forEach((element) => {
      const newDataSet = {
        label: element.Jahr,
        data: [element.Anz_Kein, element.Anz_Absehbar, element.Anz_Dringend],
        backgroundColor: this.getColor(String(element.Jahr)).backgroundColor,
        hoverBackgroundColor: this.getColor(String(element.Jahr))
          .hoverBackgroundColor,
        borderColor: this.getColor(String(element.Jahr)).borderColor,
        hoverBorderColor: this.getColor(String(element.Jahr)).hoverBorderColor,
      };
      this.barChartDataVV.push(newDataSet);
    });
  }

  selectionChangeFV(selected) {
    this.updateDataFV(selected.value);
    this.prepareDataFV(this.barChartDataFV);
  }

  selectionChangeVV(selected) {
    this.updateDataVV(selected.value);
    this.prepareDataVV(this.barChartDataVV);
  }

  private updateDataVV(selectedYears) {
    this.yearLabelsVV = this.selectedYearsVV;
    this.barChartDataVV = [];
    selectedYears.forEach((year) => {
      this.barChartDataVV.push(
        this.dataVV.filter((d) => `${d.Jahr} ` === year)[0],
      );
    });
  }

  private updateDataFV(selectedYears) {
    this.yearLabelsFV = this.selectedYearsFV;
    this.barChartDataFV = [];
    selectedYears.forEach((year) => {
      this.barChartDataFV.push(
        this.dataFV.filter((d) => `${d.Jahr} ` === year)[0],
      );
    });
  }

  public deselectAllYearsFV(select: NgModel) {
    select.update.emit([]);
    this.selectedYearsFV = [];
    this.yearLabelsFV = this.yearsFV;
    this.prepareDataFV(this.dataFV);
  }

  public deselectAllYearsVV(select: NgModel) {
    select.update.emit([]);
    this.selectedYearsVV = [];
    this.yearLabelsVV = this.yearsVV;
    this.prepareDataVV(this.dataVV);
  }

  onInfoButtonTap(content: string) {
    this.dialog.open(InfoModalComponent, {
      data: {
        content: content,
      },
    });
  }

  public getChartHeight() {
    return window.innerWidth < 700 ? '300px' : '440px';
  }

  public getColor(label: string) {
    switch (label) {
      case 'Keine Nachhaltigkeit': {
        return this.barChartColors[1];
      }
      case 'Starke bis geringe Nachhaltigkeit': {
        return this.barChartColors[0];
      }
      case '2012': {
        return this.barChartColors[8];
      }
      case '2013': {
        return this.barChartColors[9];
      }
      case '2014': {
        return this.barChartColors[10];
      }
      case '2015': {
        return this.barChartColors[2];
      }
      case '2016': {
        return this.barChartColors[3];
      }
      case '2017': {
        return this.barChartColors[4];
      }
      case '2018': {
        return this.barChartColors[5];
      }
      case '2019': {
        return this.barChartColors[6];
      }
      case '2020': {
        return this.barChartColors[7];
      }
      case '2021': {
        return this.barChartColors[11];
      }
      default:
        return {
          backgroundColor: '#ffffff',
          borderColor: '#ffffff',
          pointBackgroundColor: '#ffffff',
          pointBorderColor: '#ffffff',
          pointHoverBackgroundColor: '#ffffff',
          pointHoverBorderColor: '#ffffff',
        };
    }
  }
}
