import { Component, OnInit } from '@angular/core';
import { Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { InfoModalData, InfoModalService } from '../shared/info-modal.service';

export interface InfoModalDialogData {
  content: 'test' | 'home';
}

@Component({
  selector: 'nim-info-modal',
  templateUrl: './info-modal.component.html',
  styleUrls: ['./info-modal.component.css'],
})
export class InfoModalComponent implements OnInit {
  modalData: InfoModalData;

  constructor(
    public dialogRef: MatDialogRef<InfoModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: InfoModalDialogData,
    private modalService: InfoModalService,
  ) {}

  ngOnInit(): void {
    this.modalData = this.modalService.getInfoModalData(this.data.content);
  }

  getColor(aspect: string) {
    switch (aspect) {
      case 'Gesellschaft':
        return '#8FA6DB';
      case 'Wirtschaft':
        return '#F3CA8E';
      case 'Umwelt':
        return '#D8EBB9';
      default:
        return '#D7DBE7';
    }
  }
}
