import { Component, OnInit } from '@angular/core';
import { NgModel } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
import { Label, Color } from 'ng2-charts';
import { InfoModalComponent } from '../info-modal/info-modal.component';
import { ChartColorService } from '../shared/chart-color.service';
import { progEnergienFv } from './prog-energien-fv.data';
import { progEnergienVv } from './prog-energien-vv.data';

@Component({
  selector: 'nim-prog-energietraeger-linechart',
  templateUrl: './prog-energietraeger-linechart.component.html',
  styleUrls: ['./prog-energietraeger-linechart.component.css'],
})
export class ProgEnergietraegerLinechartComponent implements OnInit {
  public lineChartLabelsFV: Label[] = [];

  public lineChartLabelsVV: Label[] = [];

  public chartHeight = this.getChartHeight();

  public lineChartOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    legend: { display: false },
    scales: {
      xAxes: [
        {
          stacked: true,
          scaleLabel: {
            display: true,
            labelString: 'Jahre',
            fontSize: window.innerWidth < 700 ? 9 : 12,
          },
          ticks: { fontSize: window.innerWidth < 700 ? 9 : 12 },
        },
      ],
      yAxes: [
        {
          stacked: true,
          display: true,
          scaleLabel: {
            display: true,
            labelString: 'Anzahl Energieträger pro Jahr',
            fontSize: window.innerWidth < 700 ? 9 : 12,
          },
          ticks: {
            fontSize: window.innerWidth < 700 ? 9 : 12,
            beginAtZero: true,
          },
        },
      ],
    },
    tooltips: {
      mode: 'x',
      callbacks: {
        label: (tooltipItem, data) => {
          const currentDatasetIndex = tooltipItem.datasetIndex;
          const currentDataset = data.datasets[currentDatasetIndex];

          return `${data.datasets[tooltipItem.datasetIndex].label}: ${
            tooltipItem.value
          }`;
        },
      },
    },
  };

  public lineChartColors: Color[];

  public lineChartLegend = true;

  public lineChartType: ChartType = 'line';

  public lineChartPlugins = [];

  public lineChartDataFV: ChartDataSets[] = [];

  public lineChartDataVV: ChartDataSets[] = [];

  dataLoadedFV = false;

  dataLoadedVV = false;

  dataFV;

  dataVV;

  selectedCategoriesFV;

  selectedCategoriesVV;

  categoriesVV = [
    'Gas',
    'Öl',
    'Kombi Gas/Öl',
    'Elektro-Direktheizungen',
    'Fernwärme',
    'Wärmepumpen',
    'Holz',
    'Photovoltaikanlagen',
    'Solarthermieanlagen',
  ];

  categoriesFV = [
    'Gas',
    'Öl',
    'BHKW (=Blockheizkraftwerk)',
    'Elektro-Direktheizungen',
    'Fernwärme',
    'Wärmepumpen',
    'Holz',
    'Photovoltaikanlagen',
    'Solarthermieanlagen',
  ];

  yearsFV = [
    2012,
    2013,
    2014,
    2015,
    2016,
    2017,
    2018,
    2019,
    2020,
    2021,
    2022,
    2023,
    2024,
    2025,
    2026,
    2027,
    2028,
    2029,
    2030,
    2031,
    2032,
    2033,
    2034,
    2035,
  ];

  yearsVV = [
    2018,
    2019,
    2020,
    2021,
    2022,
    2023,
    2024,
    2025,
    2026,
    2027,
    2028,
    2029,
    2030,
    2031,
    2032,
    2033,
    2034,
    2035,
  ];

  constructor(
    private colorService: ChartColorService,
    public dialog: MatDialog,
  ) {}

  ngOnInit() {
    this.lineChartColors = this.colorService.getEnergyChartColors();
    this.getFvData();
    this.getVvData();
  }

  private getFvData() {
    const res = progEnergienFv;
    this.dataFV = res;
    this.prepareDataFV(this.categoriesFV, this.dataFV, this.yearsFV);
    this.dataLoadedFV = true;
  }

  private getVvData() {
    const res = progEnergienVv;
    this.dataVV = res;
    this.prepareDataVV(this.categoriesVV, this.dataVV, this.yearsVV);
    this.dataLoadedVV = true;
  }

  private prepareDataFV(categories: string[], data, years) {
    this.lineChartDataFV = [];
    categories.forEach((c) => {
      const dataSet = {
        fill: true,
        label: c,
        data: [],
        backgroundColor: this.getColor(c).backgroundColor,
        borderColor: this.getColor(c).backgroundColor,
        pointBackgroundColor: this.getColor(c).backgroundColor,
        pointBorderColor: this.getColor(c).backgroundColor,
        pointHoverBorderColor: this.getColor(c).backgroundColor,
      };
      const dataFiltered = data.filter((o) => o.Energietraeger === c);
      years.forEach((l) => {
        dataSet.data.push(dataFiltered.find((o) => o.Jahr === l).Anzahl);
      });
      this.lineChartDataFV.push(dataSet);
    });
  }

  private prepareDataVV(categories: string[], data, years) {
    this.lineChartDataVV = [];
    categories.forEach((c) => {
      const dataSet = {
        fill: true,
        label: c,
        data: [],
        backgroundColor: this.getColor(c).backgroundColor,
        borderColor: this.getColor(c).backgroundColor,
        pointBackgroundColor: this.getColor(c).backgroundColor,
        pointBorderColor: this.getColor(c).backgroundColor,
        pointHoverBorderColor: this.getColor(c).backgroundColor,
      };
      const dataFiltered = data.filter((o) => o.Energietraeger === c);
      years.forEach((l) => {
        dataSet.data.push(dataFiltered.find((o) => o.Jahr === l).Anzahl);
      });
      this.lineChartDataVV.push(dataSet);
    });
  }

  private getColor(label: string) {
    switch (label) {
      case 'Solarthermieanlagen': {
        return this.lineChartColors[0];
      }
      case 'Photovoltaikanlagen': {
        return this.lineChartColors[1];
      }
      case 'Holz': {
        return this.lineChartColors[2];
      }

      case 'Fernwärme': {
        return this.lineChartColors[5];
      }
      case 'Wärmepumpen': {
        return this.lineChartColors[4];
      }
      case 'Gas': {
        return this.lineChartColors[7];
      }
      case 'Kombi Gas/Öl': {
        return this.lineChartColors[8];
      }
      case 'BHKW (=Blockheizkraftwerk)': {
        return this.lineChartColors[9];
      }
      case 'Öl': {
        return this.lineChartColors[10];
      }
      case 'Elektro-Direktheizungen': {
        return this.lineChartColors[11];
      }
      default: {
        return this.lineChartColors[0];
      }
    }
  }

  selectionChangeFV(selected) {
    this.selectedCategoriesFV = selected.value;
    this.prepareDataFV(this.selectedCategoriesFV, this.dataFV, this.yearsFV);
  }

  selectionChangeVV(selected) {
    this.selectedCategoriesVV = selected.value;
    this.prepareDataVV(this.selectedCategoriesVV, this.dataVV, this.yearsVV);
  }

  public deselectAllCategoriesFV(select: NgModel) {
    select.update.emit([]);
    this.selectedCategoriesFV = [];
    this.prepareDataFV(this.categoriesFV, this.dataFV, this.yearsFV);
  }

  public deselectAllCategoriesVV(select: NgModel) {
    select.update.emit([]);
    this.selectedCategoriesVV = [];
    this.prepareDataVV(this.categoriesVV, this.dataVV, this.yearsVV);
  }

  onInfoButtonTap(content: string) {
    this.dialog.open(InfoModalComponent, {
      data: {
        content: content,
      },
    });
  }

  public getChartHeight() {
    return window.innerWidth < 700 ? '300px' : '400px';
  }
}
