export const autoplaetzeFv = [
  {
    id: 1,
    Autoplaetze: 0.09,
    Jahr: 2012,
    Kategorie: 'Wohnliegenschaften (pro Wohnung)',
  },
  {
    id: 2,
    Autoplaetze: 0.09,
    Jahr: 2013,
    Kategorie: 'Wohnliegenschaften (pro Wohnung)',
  },
  {
    id: 3,
    Autoplaetze: 0.11,
    Jahr: 2014,
    Kategorie: 'Wohnliegenschaften (pro Wohnung)',
  },
  {
    id: 4,
    Autoplaetze: 0.12,
    Jahr: 2015,
    Kategorie: 'Wohnliegenschaften (pro Wohnung)',
  },
  {
    id: 5,
    Autoplaetze: 0.16,
    Jahr: 2016,
    Kategorie: 'Wohnliegenschaften (pro Wohnung)',
  },
  {
    id: 6,
    Autoplaetze: 0.17,
    Jahr: 2017,
    Kategorie: 'Wohnliegenschaften (pro Wohnung)',
  },
  {
    id: 7,
    Autoplaetze: 0.17,
    Jahr: 2018,
    Kategorie: 'Wohnliegenschaften (pro Wohnung)',
  },
  {
    id: 8,
    Autoplaetze: 0.18,
    Jahr: 2019,
    Kategorie: 'Wohnliegenschaften (pro Wohnung)',
  },
  {
    id: 9,
    Autoplaetze: 0.15,
    Jahr: 2020,
    Kategorie: 'Wohnliegenschaften (pro Wohnung)',
  },
  {
    id: 10,
    Autoplaetze: 0.15,
    Jahr: 2021,
    Kategorie: 'Wohnliegenschaften (pro Wohnung)',
  },
  {
    id: 11,
    Autoplaetze: 1.72,
    Jahr: 2012,
    Kategorie: 'Geschäftsliegenschaften (pro Objekt)',
  },
  {
    id: 12,
    Autoplaetze: 1.85,
    Jahr: 2013,
    Kategorie: 'Geschäftsliegenschaften (pro Objekt)',
  },
  {
    id: 13,
    Autoplaetze: 1.86,
    Jahr: 2014,
    Kategorie: 'Geschäftsliegenschaften (pro Objekt)',
  },
  {
    id: 14,
    Autoplaetze: 1.3,
    Jahr: 2015,
    Kategorie: 'Geschäftsliegenschaften (pro Objekt)',
  },
  {
    id: 15,
    Autoplaetze: 1.33,
    Jahr: 2016,
    Kategorie: 'Geschäftsliegenschaften (pro Objekt)',
  },
  {
    id: 16,
    Autoplaetze: 1.23,
    Jahr: 2017,
    Kategorie: 'Geschäftsliegenschaften (pro Objekt)',
  },
  {
    id: 17,
    Autoplaetze: 1.41,
    Jahr: 2018,
    Kategorie: 'Geschäftsliegenschaften (pro Objekt)',
  },
  {
    id: 18,
    Autoplaetze: 1.77,
    Jahr: 2019,
    Kategorie: 'Geschäftsliegenschaften (pro Objekt)',
  },
  {
    id: 19,
    Autoplaetze: 1.96,
    Jahr: 2020,
    Kategorie: 'Geschäftsliegenschaften (pro Objekt)',
  },
  {
    id: 20,
    Autoplaetze: 1.94,
    Jahr: 2021,
    Kategorie: 'Geschäftsliegenschaften (pro Objekt)',
  },
];
