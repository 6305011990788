export const progEnergienFv = [
  {
    id: 1,
    Jahr: 2012,
    Energietraeger: 'Gas',
    Anzahl: 268,
  },
  {
    id: 2,
    Jahr: 2012,
    Energietraeger: 'Öl',
    Anzahl: 148,
  },
  {
    id: 3,
    Jahr: 2012,
    Energietraeger: 'BHKW (=Blockheizkraftwerk)',
    Anzahl: 1,
  },
  {
    id: 4,
    Jahr: 2012,
    Energietraeger: 'Elektro-Direktheizungen',
    Anzahl: 4,
  },
  {
    id: 5,
    Jahr: 2012,
    Energietraeger: 'Fernwärme',
    Anzahl: 87,
  },
  {
    id: 6,
    Jahr: 2012,
    Energietraeger: 'Wärmepumpen',
    Anzahl: 4,
  },
  {
    id: 7,
    Jahr: 2012,
    Energietraeger: 'Holz',
    Anzahl: 138,
  },
  {
    id: 8,
    Jahr: 2012,
    Energietraeger: 'Photovoltaikanlagen',
    Anzahl: 1,
  },
  {
    id: 9,
    Jahr: 2012,
    Energietraeger: 'Solarthermieanlagen',
    Anzahl: 9,
  },
  {
    id: 10,
    Jahr: 2012,
    Energietraeger: 'Total',
    Anzahl: 660,
  },
  {
    id: 11,
    Jahr: 2013,
    Energietraeger: 'Gas',
    Anzahl: 265,
  },
  {
    id: 12,
    Jahr: 2013,
    Energietraeger: 'Öl',
    Anzahl: 146,
  },
  {
    id: 13,
    Jahr: 2013,
    Energietraeger: 'BHKW (=Blockheizkraftwerk)',
    Anzahl: 1,
  },
  {
    id: 14,
    Jahr: 2013,
    Energietraeger: 'Elektro-Direktheizungen',
    Anzahl: 4,
  },
  {
    id: 15,
    Jahr: 2013,
    Energietraeger: 'Fernwärme',
    Anzahl: 85,
  },
  {
    id: 16,
    Jahr: 2013,
    Energietraeger: 'Wärmepumpen',
    Anzahl: 7,
  },
  {
    id: 17,
    Jahr: 2013,
    Energietraeger: 'Holz',
    Anzahl: 132,
  },
  {
    id: 18,
    Jahr: 2013,
    Energietraeger: 'Photovoltaikanlagen',
    Anzahl: 1,
  },
  {
    id: 19,
    Jahr: 2013,
    Energietraeger: 'Solarthermieanlagen',
    Anzahl: 9,
  },
  {
    id: 20,
    Jahr: 2013,
    Energietraeger: 'Total',
    Anzahl: 650,
  },
  {
    id: 21,
    Jahr: 2014,
    Energietraeger: 'Gas',
    Anzahl: 270,
  },
  {
    id: 22,
    Jahr: 2014,
    Energietraeger: 'Öl',
    Anzahl: 143,
  },
  {
    id: 23,
    Jahr: 2014,
    Energietraeger: 'BHKW (=Blockheizkraftwerk)',
    Anzahl: 1,
  },
  {
    id: 24,
    Jahr: 2014,
    Energietraeger: 'Elektro-Direktheizungen',
    Anzahl: 4,
  },
  {
    id: 25,
    Jahr: 2014,
    Energietraeger: 'Fernwärme',
    Anzahl: 87,
  },
  {
    id: 26,
    Jahr: 2014,
    Energietraeger: 'Wärmepumpen',
    Anzahl: 8,
  },
  {
    id: 27,
    Jahr: 2014,
    Energietraeger: 'Holz',
    Anzahl: 139,
  },
  {
    id: 28,
    Jahr: 2014,
    Energietraeger: 'Photovoltaikanlagen',
    Anzahl: 2,
  },
  {
    id: 29,
    Jahr: 2014,
    Energietraeger: 'Solarthermieanlagen',
    Anzahl: 9,
  },
  {
    id: 30,
    Jahr: 2014,
    Energietraeger: 'Total',
    Anzahl: 663,
  },
  {
    id: 31,
    Jahr: 2015,
    Energietraeger: 'Gas',
    Anzahl: 275,
  },
  {
    id: 32,
    Jahr: 2015,
    Energietraeger: 'Öl',
    Anzahl: 143,
  },
  {
    id: 33,
    Jahr: 2015,
    Energietraeger: 'BHKW (=Blockheizkraftwerk)',
    Anzahl: 1,
  },
  {
    id: 34,
    Jahr: 2015,
    Energietraeger: 'Elektro-Direktheizungen',
    Anzahl: 3,
  },
  {
    id: 35,
    Jahr: 2015,
    Energietraeger: 'Fernwärme',
    Anzahl: 88,
  },
  {
    id: 36,
    Jahr: 2015,
    Energietraeger: 'Wärmepumpen',
    Anzahl: 9,
  },
  {
    id: 37,
    Jahr: 2015,
    Energietraeger: 'Holz',
    Anzahl: 139,
  },
  {
    id: 38,
    Jahr: 2015,
    Energietraeger: 'Photovoltaikanlagen',
    Anzahl: 2,
  },
  {
    id: 39,
    Jahr: 2015,
    Energietraeger: 'Solarthermieanlagen',
    Anzahl: 9,
  },
  {
    id: 40,
    Jahr: 2015,
    Energietraeger: 'Total',
    Anzahl: 669,
  },
  {
    id: 41,
    Jahr: 2016,
    Energietraeger: 'Gas',
    Anzahl: 278,
  },
  {
    id: 42,
    Jahr: 2016,
    Energietraeger: 'Öl',
    Anzahl: 141,
  },
  {
    id: 43,
    Jahr: 2016,
    Energietraeger: 'BHKW (=Blockheizkraftwerk)',
    Anzahl: 1,
  },
  {
    id: 44,
    Jahr: 2016,
    Energietraeger: 'Elektro-Direktheizungen',
    Anzahl: 6,
  },
  {
    id: 45,
    Jahr: 2016,
    Energietraeger: 'Fernwärme',
    Anzahl: 89,
  },
  {
    id: 46,
    Jahr: 2016,
    Energietraeger: 'Wärmepumpen',
    Anzahl: 14,
  },
  {
    id: 47,
    Jahr: 2016,
    Energietraeger: 'Holz',
    Anzahl: 135,
  },
  {
    id: 48,
    Jahr: 2016,
    Energietraeger: 'Photovoltaikanlagen',
    Anzahl: 6,
  },
  {
    id: 49,
    Jahr: 2016,
    Energietraeger: 'Solarthermieanlagen',
    Anzahl: 11,
  },
  {
    id: 50,
    Jahr: 2016,
    Energietraeger: 'Total',
    Anzahl: 681,
  },
  {
    id: 51,
    Jahr: 2017,
    Energietraeger: 'Gas',
    Anzahl: 277,
  },
  {
    id: 52,
    Jahr: 2017,
    Energietraeger: 'Öl',
    Anzahl: 140,
  },
  {
    id: 53,
    Jahr: 2017,
    Energietraeger: 'BHKW (=Blockheizkraftwerk)',
    Anzahl: 1,
  },
  {
    id: 54,
    Jahr: 2017,
    Energietraeger: 'Elektro-Direktheizungen',
    Anzahl: 6,
  },
  {
    id: 55,
    Jahr: 2017,
    Energietraeger: 'Fernwärme',
    Anzahl: 90,
  },
  {
    id: 56,
    Jahr: 2017,
    Energietraeger: 'Wärmepumpen',
    Anzahl: 15,
  },
  {
    id: 57,
    Jahr: 2017,
    Energietraeger: 'Holz',
    Anzahl: 135,
  },
  {
    id: 58,
    Jahr: 2017,
    Energietraeger: 'Photovoltaikanlagen',
    Anzahl: 8,
  },
  {
    id: 59,
    Jahr: 2017,
    Energietraeger: 'Solarthermieanlagen',
    Anzahl: 11,
  },
  {
    id: 60,
    Jahr: 2017,
    Energietraeger: 'Total',
    Anzahl: 683,
  },
  {
    id: 61,
    Jahr: 2018,
    Energietraeger: 'Gas',
    Anzahl: 272,
  },
  {
    id: 62,
    Jahr: 2018,
    Energietraeger: 'Öl',
    Anzahl: 141,
  },
  {
    id: 63,
    Jahr: 2018,
    Energietraeger: 'BHKW (=Blockheizkraftwerk)',
    Anzahl: 1,
  },
  {
    id: 64,
    Jahr: 2018,
    Energietraeger: 'Elektro-Direktheizungen',
    Anzahl: 7,
  },
  {
    id: 65,
    Jahr: 2018,
    Energietraeger: 'Fernwärme',
    Anzahl: 90,
  },
  {
    id: 66,
    Jahr: 2018,
    Energietraeger: 'Wärmepumpen',
    Anzahl: 19,
  },
  {
    id: 67,
    Jahr: 2018,
    Energietraeger: 'Holz',
    Anzahl: 133,
  },
  {
    id: 68,
    Jahr: 2018,
    Energietraeger: 'Photovoltaikanlagen',
    Anzahl: 13,
  },
  {
    id: 69,
    Jahr: 2018,
    Energietraeger: 'Solarthermieanlagen',
    Anzahl: 11,
  },
  {
    id: 70,
    Jahr: 2018,
    Energietraeger: 'Total',
    Anzahl: 687,
  },
  {
    id: 71,
    Jahr: 2019,
    Energietraeger: 'Gas',
    Anzahl: 269,
  },
  {
    id: 72,
    Jahr: 2019,
    Energietraeger: 'Öl',
    Anzahl: 146,
  },
  {
    id: 73,
    Jahr: 2019,
    Energietraeger: 'BHKW (=Blockheizkraftwerk)',
    Anzahl: 1,
  },
  {
    id: 74,
    Jahr: 2019,
    Energietraeger: 'Elektro-Direktheizungen',
    Anzahl: 7,
  },
  {
    id: 75,
    Jahr: 2019,
    Energietraeger: 'Fernwärme',
    Anzahl: 97,
  },
  {
    id: 76,
    Jahr: 2019,
    Energietraeger: 'Wärmepumpen',
    Anzahl: 24,
  },
  {
    id: 77,
    Jahr: 2019,
    Energietraeger: 'Holz',
    Anzahl: 140,
  },
  {
    id: 78,
    Jahr: 2019,
    Energietraeger: 'Photovoltaikanlagen',
    Anzahl: 21,
  },
  {
    id: 79,
    Jahr: 2019,
    Energietraeger: 'Solarthermieanlagen',
    Anzahl: 12,
  },
  {
    id: 80,
    Jahr: 2019,
    Energietraeger: 'Total',
    Anzahl: 717,
  },
  {
    id: 81,
    Jahr: 2020,
    Energietraeger: 'Gas',
    Anzahl: 265,
  },
  {
    id: 82,
    Jahr: 2020,
    Energietraeger: 'Öl',
    Anzahl: 150,
  },
  {
    id: 83,
    Jahr: 2020,
    Energietraeger: 'BHKW (=Blockheizkraftwerk)',
    Anzahl: 1,
  },
  {
    id: 84,
    Jahr: 2020,
    Energietraeger: 'Elektro-Direktheizungen',
    Anzahl: 6,
  },
  {
    id: 85,
    Jahr: 2020,
    Energietraeger: 'Fernwärme',
    Anzahl: 97,
  },
  {
    id: 86,
    Jahr: 2020,
    Energietraeger: 'Wärmepumpen',
    Anzahl: 27,
  },
  {
    id: 87,
    Jahr: 2020,
    Energietraeger: 'Holz',
    Anzahl: 134,
  },
  {
    id: 88,
    Jahr: 2020,
    Energietraeger: 'Photovoltaikanlagen',
    Anzahl: 23,
  },
  {
    id: 89,
    Jahr: 2020,
    Energietraeger: 'Solarthermieanlagen',
    Anzahl: 12,
  },
  {
    id: 90,
    Jahr: 2020,
    Energietraeger: 'Total',
    Anzahl: 715,
  },
  {
    id: 91,
    Jahr: 2021,
    Energietraeger: 'Gas',
    Anzahl: 265,
  },
  {
    id: 92,
    Jahr: 2021,
    Energietraeger: 'Öl',
    Anzahl: 150,
  },
  {
    id: 93,
    Jahr: 2021,
    Energietraeger: 'BHKW (=Blockheizkraftwerk)',
    Anzahl: 1,
  },
  {
    id: 94,
    Jahr: 2021,
    Energietraeger: 'Elektro-Direktheizungen',
    Anzahl: 6,
  },
  {
    id: 95,
    Jahr: 2021,
    Energietraeger: 'Fernwärme',
    Anzahl: 97,
  },
  {
    id: 96,
    Jahr: 2021,
    Energietraeger: 'Wärmepumpen',
    Anzahl: 27,
  },
  {
    id: 97,
    Jahr: 2021,
    Energietraeger: 'Holz',
    Anzahl: 134,
  },
  {
    id: 98,
    Jahr: 2021,
    Energietraeger: 'Photovoltaikanlagen',
    Anzahl: 26,
  },
  {
    id: 99,
    Jahr: 2021,
    Energietraeger: 'Solarthermieanlagen',
    Anzahl: 12,
  },
  {
    id: 100,
    Jahr: 2021,
    Energietraeger: 'Total',
    Anzahl: 718,
  },
  {
    id: 101,
    Jahr: 2022,
    Energietraeger: 'Gas',
    Anzahl: 255,
  },
  {
    id: 102,
    Jahr: 2022,
    Energietraeger: 'Öl',
    Anzahl: 70,
  },
  {
    id: 103,
    Jahr: 2022,
    Energietraeger: 'BHKW (=Blockheizkraftwerk)',
    Anzahl: 1,
  },
  {
    id: 104,
    Jahr: 2022,
    Energietraeger: 'Elektro-Direktheizungen',
    Anzahl: 5,
  },
  {
    id: 105,
    Jahr: 2022,
    Energietraeger: 'Fernwärme',
    Anzahl: 115,
  },
  {
    id: 106,
    Jahr: 2022,
    Energietraeger: 'Wärmepumpen',
    Anzahl: 33,
  },
  {
    id: 107,
    Jahr: 2022,
    Energietraeger: 'Holz',
    Anzahl: 130,
  },
  {
    id: 108,
    Jahr: 2022,
    Energietraeger: 'Photovoltaikanlagen',
    Anzahl: 33,
  },
  {
    id: 109,
    Jahr: 2022,
    Energietraeger: 'Solarthermieanlagen',
    Anzahl: 13,
  },
  {
    id: 110,
    Jahr: 2022,
    Energietraeger: 'Total',
    Anzahl: 655,
  },
  {
    id: 111,
    Jahr: 2023,
    Energietraeger: 'Gas',
    Anzahl: 230,
  },
  {
    id: 112,
    Jahr: 2023,
    Energietraeger: 'Öl',
    Anzahl: 50,
  },
  {
    id: 113,
    Jahr: 2023,
    Energietraeger: 'BHKW (=Blockheizkraftwerk)',
    Anzahl: 1,
  },
  {
    id: 114,
    Jahr: 2023,
    Energietraeger: 'Elektro-Direktheizungen',
    Anzahl: 4,
  },
  {
    id: 115,
    Jahr: 2023,
    Energietraeger: 'Fernwärme',
    Anzahl: 135,
  },
  {
    id: 116,
    Jahr: 2023,
    Energietraeger: 'Wärmepumpen',
    Anzahl: 36,
  },
  {
    id: 117,
    Jahr: 2023,
    Energietraeger: 'Holz',
    Anzahl: 120,
  },
  {
    id: 118,
    Jahr: 2023,
    Energietraeger: 'Photovoltaikanlagen',
    Anzahl: 34,
  },
  {
    id: 119,
    Jahr: 2023,
    Energietraeger: 'Solarthermieanlagen',
    Anzahl: 14,
  },
  {
    id: 120,
    Jahr: 2023,
    Energietraeger: 'Total',
    Anzahl: 624,
  },
  {
    id: 121,
    Jahr: 2024,
    Energietraeger: 'Gas',
    Anzahl: 215,
  },
  {
    id: 122,
    Jahr: 2024,
    Energietraeger: 'Öl',
    Anzahl: 35,
  },
  {
    id: 123,
    Jahr: 2024,
    Energietraeger: 'BHKW (=Blockheizkraftwerk)',
    Anzahl: 1,
  },
  {
    id: 124,
    Jahr: 2024,
    Energietraeger: 'Elektro-Direktheizungen',
    Anzahl: 2,
  },
  {
    id: 125,
    Jahr: 2024,
    Energietraeger: 'Fernwärme',
    Anzahl: 145,
  },
  {
    id: 126,
    Jahr: 2024,
    Energietraeger: 'Wärmepumpen',
    Anzahl: 40,
  },
  {
    id: 127,
    Jahr: 2024,
    Energietraeger: 'Holz',
    Anzahl: 110,
  },
  {
    id: 128,
    Jahr: 2024,
    Energietraeger: 'Photovoltaikanlagen',
    Anzahl: 36,
  },
  {
    id: 129,
    Jahr: 2024,
    Energietraeger: 'Solarthermieanlagen',
    Anzahl: 14,
  },
  {
    id: 130,
    Jahr: 2024,
    Energietraeger: 'Total',
    Anzahl: 598,
  },
  {
    id: 131,
    Jahr: 2025,
    Energietraeger: 'Gas',
    Anzahl: 200,
  },
  {
    id: 132,
    Jahr: 2025,
    Energietraeger: 'Öl',
    Anzahl: 0,
  },
  {
    id: 133,
    Jahr: 2025,
    Energietraeger: 'BHKW (=Blockheizkraftwerk)',
    Anzahl: 1,
  },
  {
    id: 134,
    Jahr: 2025,
    Energietraeger: 'Elektro-Direktheizungen',
    Anzahl: 0,
  },
  {
    id: 135,
    Jahr: 2025,
    Energietraeger: 'Fernwärme',
    Anzahl: 160,
  },
  {
    id: 136,
    Jahr: 2025,
    Energietraeger: 'Wärmepumpen',
    Anzahl: 43,
  },
  {
    id: 137,
    Jahr: 2025,
    Energietraeger: 'Holz',
    Anzahl: 100,
  },
  {
    id: 138,
    Jahr: 2025,
    Energietraeger: 'Photovoltaikanlagen',
    Anzahl: 44,
  },
  {
    id: 139,
    Jahr: 2025,
    Energietraeger: 'Solarthermieanlagen',
    Anzahl: 15,
  },
  {
    id: 140,
    Jahr: 2025,
    Energietraeger: 'Total',
    Anzahl: 563,
  },
  {
    id: 141,
    Jahr: 2026,
    Energietraeger: 'Gas',
    Anzahl: 185,
  },
  {
    id: 142,
    Jahr: 2026,
    Energietraeger: 'Öl',
    Anzahl: 0,
  },
  {
    id: 143,
    Jahr: 2026,
    Energietraeger: 'BHKW (=Blockheizkraftwerk)',
    Anzahl: 1,
  },
  {
    id: 144,
    Jahr: 2026,
    Energietraeger: 'Elektro-Direktheizungen',
    Anzahl: 0,
  },
  {
    id: 145,
    Jahr: 2026,
    Energietraeger: 'Fernwärme',
    Anzahl: 175,
  },
  {
    id: 146,
    Jahr: 2026,
    Energietraeger: 'Wärmepumpen',
    Anzahl: 46,
  },
  {
    id: 147,
    Jahr: 2026,
    Energietraeger: 'Holz',
    Anzahl: 90,
  },
  {
    id: 148,
    Jahr: 2026,
    Energietraeger: 'Photovoltaikanlagen',
    Anzahl: 47,
  },
  {
    id: 149,
    Jahr: 2026,
    Energietraeger: 'Solarthermieanlagen',
    Anzahl: 15,
  },
  {
    id: 150,
    Jahr: 2026,
    Energietraeger: 'Total',
    Anzahl: 559,
  },
  {
    id: 151,
    Jahr: 2027,
    Energietraeger: 'Gas',
    Anzahl: 160,
  },
  {
    id: 152,
    Jahr: 2027,
    Energietraeger: 'Öl',
    Anzahl: 0,
  },
  {
    id: 153,
    Jahr: 2027,
    Energietraeger: 'BHKW (=Blockheizkraftwerk)',
    Anzahl: 1,
  },
  {
    id: 154,
    Jahr: 2027,
    Energietraeger: 'Elektro-Direktheizungen',
    Anzahl: 0,
  },
  {
    id: 155,
    Jahr: 2027,
    Energietraeger: 'Fernwärme',
    Anzahl: 200,
  },
  {
    id: 156,
    Jahr: 2027,
    Energietraeger: 'Wärmepumpen',
    Anzahl: 50,
  },
  {
    id: 157,
    Jahr: 2027,
    Energietraeger: 'Holz',
    Anzahl: 80,
  },
  {
    id: 158,
    Jahr: 2027,
    Energietraeger: 'Photovoltaikanlagen',
    Anzahl: 50,
  },
  {
    id: 159,
    Jahr: 2027,
    Energietraeger: 'Solarthermieanlagen',
    Anzahl: 16,
  },
  {
    id: 160,
    Jahr: 2027,
    Energietraeger: 'Total',
    Anzahl: 557,
  },
  {
    id: 161,
    Jahr: 2028,
    Energietraeger: 'Gas',
    Anzahl: 145,
  },
  {
    id: 162,
    Jahr: 2028,
    Energietraeger: 'Öl',
    Anzahl: 0,
  },
  {
    id: 163,
    Jahr: 2028,
    Energietraeger: 'BHKW (=Blockheizkraftwerk)',
    Anzahl: 1,
  },
  {
    id: 164,
    Jahr: 2028,
    Energietraeger: 'Elektro-Direktheizungen',
    Anzahl: 0,
  },
  {
    id: 165,
    Jahr: 2028,
    Energietraeger: 'Fernwärme',
    Anzahl: 225,
  },
  {
    id: 166,
    Jahr: 2028,
    Energietraeger: 'Wärmepumpen',
    Anzahl: 60,
  },
  {
    id: 167,
    Jahr: 2028,
    Energietraeger: 'Holz',
    Anzahl: 70,
  },
  {
    id: 168,
    Jahr: 2028,
    Energietraeger: 'Photovoltaikanlagen',
    Anzahl: 53,
  },
  {
    id: 169,
    Jahr: 2028,
    Energietraeger: 'Solarthermieanlagen',
    Anzahl: 16,
  },
  {
    id: 170,
    Jahr: 2028,
    Energietraeger: 'Total',
    Anzahl: 570,
  },
  {
    id: 171,
    Jahr: 2029,
    Energietraeger: 'Gas',
    Anzahl: 130,
  },
  {
    id: 172,
    Jahr: 2029,
    Energietraeger: 'Öl',
    Anzahl: 0,
  },
  {
    id: 173,
    Jahr: 2029,
    Energietraeger: 'BHKW (=Blockheizkraftwerk)',
    Anzahl: 1,
  },
  {
    id: 174,
    Jahr: 2029,
    Energietraeger: 'Elektro-Direktheizungen',
    Anzahl: 0,
  },
  {
    id: 175,
    Jahr: 2029,
    Energietraeger: 'Fernwärme',
    Anzahl: 250,
  },
  {
    id: 176,
    Jahr: 2029,
    Energietraeger: 'Wärmepumpen',
    Anzahl: 70,
  },
  {
    id: 177,
    Jahr: 2029,
    Energietraeger: 'Holz',
    Anzahl: 60,
  },
  {
    id: 178,
    Jahr: 2029,
    Energietraeger: 'Photovoltaikanlagen',
    Anzahl: 55,
  },
  {
    id: 179,
    Jahr: 2029,
    Energietraeger: 'Solarthermieanlagen',
    Anzahl: 17,
  },
  {
    id: 180,
    Jahr: 2029,
    Energietraeger: 'Total',
    Anzahl: 583,
  },
  {
    id: 181,
    Jahr: 2030,
    Energietraeger: 'Gas',
    Anzahl: 115,
  },
  {
    id: 182,
    Jahr: 2030,
    Energietraeger: 'Öl',
    Anzahl: 0,
  },
  {
    id: 183,
    Jahr: 2030,
    Energietraeger: 'BHKW (=Blockheizkraftwerk)',
    Anzahl: 1,
  },
  {
    id: 184,
    Jahr: 2030,
    Energietraeger: 'Elektro-Direktheizungen',
    Anzahl: 0,
  },
  {
    id: 185,
    Jahr: 2030,
    Energietraeger: 'Fernwärme',
    Anzahl: 275,
  },
  {
    id: 186,
    Jahr: 2030,
    Energietraeger: 'Wärmepumpen',
    Anzahl: 75,
  },
  {
    id: 187,
    Jahr: 2030,
    Energietraeger: 'Holz',
    Anzahl: 50,
  },
  {
    id: 188,
    Jahr: 2030,
    Energietraeger: 'Photovoltaikanlagen',
    Anzahl: 57,
  },
  {
    id: 189,
    Jahr: 2030,
    Energietraeger: 'Solarthermieanlagen',
    Anzahl: 17,
  },
  {
    id: 190,
    Jahr: 2030,
    Energietraeger: 'Total',
    Anzahl: 590,
  },
  {
    id: 191,
    Jahr: 2031,
    Energietraeger: 'Gas',
    Anzahl: 100,
  },
  {
    id: 192,
    Jahr: 2031,
    Energietraeger: 'Öl',
    Anzahl: 0,
  },
  {
    id: 193,
    Jahr: 2031,
    Energietraeger: 'BHKW (=Blockheizkraftwerk)',
    Anzahl: 1,
  },
  {
    id: 194,
    Jahr: 2031,
    Energietraeger: 'Elektro-Direktheizungen',
    Anzahl: 0,
  },
  {
    id: 195,
    Jahr: 2031,
    Energietraeger: 'Fernwärme',
    Anzahl: 300,
  },
  {
    id: 196,
    Jahr: 2031,
    Energietraeger: 'Wärmepumpen',
    Anzahl: 80,
  },
  {
    id: 197,
    Jahr: 2031,
    Energietraeger: 'Holz',
    Anzahl: 45,
  },
  {
    id: 198,
    Jahr: 2031,
    Energietraeger: 'Photovoltaikanlagen',
    Anzahl: 60,
  },
  {
    id: 199,
    Jahr: 2031,
    Energietraeger: 'Solarthermieanlagen',
    Anzahl: 18,
  },
  {
    id: 200,
    Jahr: 2031,
    Energietraeger: 'Total',
    Anzahl: 604,
  },
  {
    id: 201,
    Jahr: 2032,
    Energietraeger: 'Gas',
    Anzahl: 90,
  },
  {
    id: 202,
    Jahr: 2032,
    Energietraeger: 'Öl',
    Anzahl: 0,
  },
  {
    id: 203,
    Jahr: 2032,
    Energietraeger: 'BHKW (=Blockheizkraftwerk)',
    Anzahl: 1,
  },
  {
    id: 204,
    Jahr: 2032,
    Energietraeger: 'Elektro-Direktheizungen',
    Anzahl: 0,
  },
  {
    id: 205,
    Jahr: 2032,
    Energietraeger: 'Fernwärme',
    Anzahl: 325,
  },
  {
    id: 206,
    Jahr: 2032,
    Energietraeger: 'Wärmepumpen',
    Anzahl: 85,
  },
  {
    id: 207,
    Jahr: 2032,
    Energietraeger: 'Holz',
    Anzahl: 40,
  },
  {
    id: 208,
    Jahr: 2032,
    Energietraeger: 'Photovoltaikanlagen',
    Anzahl: 63,
  },
  {
    id: 209,
    Jahr: 2032,
    Energietraeger: 'Solarthermieanlagen',
    Anzahl: 18,
  },
  {
    id: 210,
    Jahr: 2032,
    Energietraeger: 'Total',
    Anzahl: 622,
  },
  {
    id: 211,
    Jahr: 2033,
    Energietraeger: 'Gas',
    Anzahl: 60,
  },
  {
    id: 212,
    Jahr: 2033,
    Energietraeger: 'Öl',
    Anzahl: 0,
  },
  {
    id: 213,
    Jahr: 2033,
    Energietraeger: 'BHKW (=Blockheizkraftwerk)',
    Anzahl: 1,
  },
  {
    id: 214,
    Jahr: 2033,
    Energietraeger: 'Elektro-Direktheizungen',
    Anzahl: 0,
  },
  {
    id: 215,
    Jahr: 2033,
    Energietraeger: 'Fernwärme',
    Anzahl: 350,
  },
  {
    id: 216,
    Jahr: 2033,
    Energietraeger: 'Wärmepumpen',
    Anzahl: 90,
  },
  {
    id: 217,
    Jahr: 2033,
    Energietraeger: 'Holz',
    Anzahl: 30,
  },
  {
    id: 218,
    Jahr: 2033,
    Energietraeger: 'Photovoltaikanlagen',
    Anzahl: 65,
  },
  {
    id: 219,
    Jahr: 2033,
    Energietraeger: 'Solarthermieanlagen',
    Anzahl: 19,
  },
  {
    id: 220,
    Jahr: 2033,
    Energietraeger: 'Total',
    Anzahl: 615,
  },
  {
    id: 221,
    Jahr: 2034,
    Energietraeger: 'Gas',
    Anzahl: 30,
  },
  {
    id: 222,
    Jahr: 2034,
    Energietraeger: 'Öl',
    Anzahl: 0,
  },
  {
    id: 223,
    Jahr: 2034,
    Energietraeger: 'BHKW (=Blockheizkraftwerk)',
    Anzahl: 1,
  },
  {
    id: 224,
    Jahr: 2034,
    Energietraeger: 'Elektro-Direktheizungen',
    Anzahl: 0,
  },
  {
    id: 225,
    Jahr: 2034,
    Energietraeger: 'Fernwärme',
    Anzahl: 375,
  },
  {
    id: 226,
    Jahr: 2034,
    Energietraeger: 'Wärmepumpen',
    Anzahl: 95,
  },
  {
    id: 227,
    Jahr: 2034,
    Energietraeger: 'Holz',
    Anzahl: 25,
  },
  {
    id: 228,
    Jahr: 2034,
    Energietraeger: 'Photovoltaikanlagen',
    Anzahl: 67,
  },
  {
    id: 229,
    Jahr: 2034,
    Energietraeger: 'Solarthermieanlagen',
    Anzahl: 19,
  },
  {
    id: 230,
    Jahr: 2034,
    Energietraeger: 'Total',
    Anzahl: 612,
  },
  {
    id: 231,
    Jahr: 2035,
    Energietraeger: 'Gas',
    Anzahl: 0,
  },
  {
    id: 232,
    Jahr: 2035,
    Energietraeger: 'Öl',
    Anzahl: 0,
  },
  {
    id: 233,
    Jahr: 2035,
    Energietraeger: 'BHKW (=Blockheizkraftwerk)',
    Anzahl: 0,
  },
  {
    id: 234,
    Jahr: 2035,
    Energietraeger: 'Elektro-Direktheizungen',
    Anzahl: 0,
  },
  {
    id: 235,
    Jahr: 2035,
    Energietraeger: 'Fernwärme',
    Anzahl: 400,
  },
  {
    id: 236,
    Jahr: 2035,
    Energietraeger: 'Wärmepumpen',
    Anzahl: 100,
  },
  {
    id: 237,
    Jahr: 2035,
    Energietraeger: 'Holz',
    Anzahl: 20,
  },
  {
    id: 238,
    Jahr: 2035,
    Energietraeger: 'Photovoltaikanlagen',
    Anzahl: 70,
  },
  {
    id: 239,
    Jahr: 2035,
    Energietraeger: 'Solarthermieanlagen',
    Anzahl: 20,
  },
  {
    id: 240,
    Jahr: 2035,
    Energietraeger: 'Total',
    Anzahl: 610,
  },
];
