export const energietraegerFv = [
  {
    id: 1,
    Jahr: 2012,
    Kategorie: 'Wohnliegenschaften',
    Energietraeger: 'Gas',
    Anzahl: 249,
  },
  {
    id: 2,
    Jahr: 2012,
    Kategorie: 'Wohnliegenschaften',
    Energietraeger: 'Gas_zentral',
    Anzahl: 114,
  },
  {
    id: 3,
    Jahr: 2012,
    Kategorie: 'Wohnliegenschaften',
    Energietraeger: 'Gas_einzel',
    Anzahl: 135,
  },
  {
    id: 4,
    Jahr: 2012,
    Kategorie: 'Wohnliegenschaften',
    Energietraeger: 'Öl',
    Anzahl: 143,
  },
  {
    id: 5,
    Jahr: 2012,
    Kategorie: 'Wohnliegenschaften',
    Energietraeger: 'Öl_zentral',
    Anzahl: 8,
  },
  {
    id: 6,
    Jahr: 2012,
    Kategorie: 'Wohnliegenschaften',
    Energietraeger: 'Öl_einzel',
    Anzahl: 135,
  },
  {
    id: 7,
    Jahr: 2012,
    Kategorie: 'Wohnliegenschaften',
    Energietraeger: 'BHKW (=Blockheizkraftwerk)',
    Anzahl: 0,
  },
  {
    id: 8,
    Jahr: 2012,
    Kategorie: 'Wohnliegenschaften',
    Energietraeger: 'Elektrizität',
    Anzahl: 3,
  },
  {
    id: 9,
    Jahr: 2012,
    Kategorie: 'Wohnliegenschaften',
    Energietraeger: 'Fernwärme',
    Anzahl: 79,
  },
  {
    id: 10,
    Jahr: 2012,
    Kategorie: 'Wohnliegenschaften',
    Energietraeger: 'Wärmepumpen',
    Anzahl: 2,
  },
  {
    id: 11,
    Jahr: 2012,
    Kategorie: 'Wohnliegenschaften',
    Energietraeger: 'Holz',
    Anzahl: 133,
  },
  {
    id: 12,
    Jahr: 2012,
    Kategorie: 'Wohnliegenschaften',
    Energietraeger: 'Holz_zentral',
    Anzahl: 0,
  },
  {
    id: 13,
    Jahr: 2012,
    Kategorie: 'Wohnliegenschaften',
    Energietraeger: 'Holz_einzel',
    Anzahl: 133,
  },
  {
    id: 14,
    Jahr: 2012,
    Kategorie: 'Wohnliegenschaften',
    Energietraeger: 'Photovoltaikanlagen',
    Anzahl: 0,
  },
  {
    id: 15,
    Jahr: 2012,
    Kategorie: 'Wohnliegenschaften',
    Energietraeger: 'Solaranlagen',
    Anzahl: 7,
  },
  {
    id: 16,
    Jahr: 2012,
    Kategorie: 'Geschäftsliegenschaften',
    Energietraeger: 'Gas',
    Anzahl: 19,
  },
  {
    id: 17,
    Jahr: 2012,
    Kategorie: 'Geschäftsliegenschaften',
    Energietraeger: 'Gas_zentral',
    Anzahl: 19,
  },
  {
    id: 18,
    Jahr: 2012,
    Kategorie: 'Geschäftsliegenschaften',
    Energietraeger: 'Gas_einzel',
    Anzahl: 0,
  },
  {
    id: 19,
    Jahr: 2012,
    Kategorie: 'Geschäftsliegenschaften',
    Energietraeger: 'Öl',
    Anzahl: 4,
  },
  {
    id: 20,
    Jahr: 2012,
    Kategorie: 'Geschäftsliegenschaften',
    Energietraeger: 'Öl_zentral',
    Anzahl: 4,
  },
  {
    id: 21,
    Jahr: 2012,
    Kategorie: 'Geschäftsliegenschaften',
    Energietraeger: 'Öl_einzel',
    Anzahl: 0,
  },
  {
    id: 22,
    Jahr: 2012,
    Kategorie: 'Geschäftsliegenschaften',
    Energietraeger: 'BHKW (=Blockheizkraftwerk)',
    Anzahl: 1,
  },
  {
    id: 23,
    Jahr: 2012,
    Kategorie: 'Geschäftsliegenschaften',
    Energietraeger: 'Elektrizität',
    Anzahl: 1,
  },
  {
    id: 24,
    Jahr: 2012,
    Kategorie: 'Geschäftsliegenschaften',
    Energietraeger: 'Fernwärme',
    Anzahl: 8,
  },
  {
    id: 25,
    Jahr: 2012,
    Kategorie: 'Geschäftsliegenschaften',
    Energietraeger: 'Wärmepumpen',
    Anzahl: 1,
  },
  {
    id: 26,
    Jahr: 2012,
    Kategorie: 'Geschäftsliegenschaften',
    Energietraeger: 'Holz',
    Anzahl: 4,
  },
  {
    id: 27,
    Jahr: 2012,
    Kategorie: 'Geschäftsliegenschaften',
    Energietraeger: 'Holz_zentral',
    Anzahl: 0,
  },
  {
    id: 28,
    Jahr: 2012,
    Kategorie: 'Geschäftsliegenschaften',
    Energietraeger: 'Holz_einzel',
    Anzahl: 4,
  },
  {
    id: 29,
    Jahr: 2012,
    Kategorie: 'Geschäftsliegenschaften',
    Energietraeger: 'Photovoltaikanlagen',
    Anzahl: 1,
  },
  {
    id: 30,
    Jahr: 2012,
    Kategorie: 'Geschäftsliegenschaften',
    Energietraeger: 'Solaranlagen',
    Anzahl: 0,
  },
  {
    id: 31,
    Jahr: 2012,
    Kategorie: 'Landwirtschaft',
    Energietraeger: 'Gas',
    Anzahl: 0,
  },
  {
    id: 32,
    Jahr: 2012,
    Kategorie: 'Landwirtschaft',
    Energietraeger: 'Gas_zentral',
    Anzahl: 0,
  },
  {
    id: 33,
    Jahr: 2012,
    Kategorie: 'Landwirtschaft',
    Energietraeger: 'Gas_einzel',
    Anzahl: 0,
  },
  {
    id: 34,
    Jahr: 2012,
    Kategorie: 'Landwirtschaft',
    Energietraeger: 'Öl',
    Anzahl: 1,
  },
  {
    id: 35,
    Jahr: 2012,
    Kategorie: 'Landwirtschaft',
    Energietraeger: 'Öl_zentral',
    Anzahl: 1,
  },
  {
    id: 36,
    Jahr: 2012,
    Kategorie: 'Landwirtschaft',
    Energietraeger: 'Öl_einzel',
    Anzahl: 0,
  },
  {
    id: 37,
    Jahr: 2012,
    Kategorie: 'Landwirtschaft',
    Energietraeger: 'BHKW (=Blockheizkraftwerk)',
    Anzahl: 0,
  },
  {
    id: 38,
    Jahr: 2012,
    Kategorie: 'Landwirtschaft',
    Energietraeger: 'Elektrizität',
    Anzahl: 0,
  },
  {
    id: 39,
    Jahr: 2012,
    Kategorie: 'Landwirtschaft',
    Energietraeger: 'Fernwärme',
    Anzahl: 0,
  },
  {
    id: 40,
    Jahr: 2012,
    Kategorie: 'Landwirtschaft',
    Energietraeger: 'Wärmepumpen',
    Anzahl: 1,
  },
  {
    id: 41,
    Jahr: 2012,
    Kategorie: 'Landwirtschaft',
    Energietraeger: 'Holz',
    Anzahl: 1,
  },
  {
    id: 42,
    Jahr: 2012,
    Kategorie: 'Landwirtschaft',
    Energietraeger: 'Holz_zentral',
    Anzahl: 1,
  },
  {
    id: 43,
    Jahr: 2012,
    Kategorie: 'Landwirtschaft',
    Energietraeger: 'Holz_einzel',
    Anzahl: 0,
  },
  {
    id: 44,
    Jahr: 2012,
    Kategorie: 'Landwirtschaft',
    Energietraeger: 'Photovoltaikanlagen',
    Anzahl: 0,
  },
  {
    id: 45,
    Jahr: 2012,
    Kategorie: 'Landwirtschaft',
    Energietraeger: 'Solaranlagen',
    Anzahl: 0,
  },
  {
    id: 46,
    Jahr: 2013,
    Kategorie: 'Wohnliegenschaften',
    Energietraeger: 'Gas',
    Anzahl: 246,
  },
  {
    id: 47,
    Jahr: 2013,
    Kategorie: 'Wohnliegenschaften',
    Energietraeger: 'Gas_zentral',
    Anzahl: 114,
  },
  {
    id: 48,
    Jahr: 2013,
    Kategorie: 'Wohnliegenschaften',
    Energietraeger: 'Gas_einzel',
    Anzahl: 132,
  },
  {
    id: 49,
    Jahr: 2013,
    Kategorie: 'Wohnliegenschaften',
    Energietraeger: 'Öl',
    Anzahl: 141,
  },
  {
    id: 50,
    Jahr: 2013,
    Kategorie: 'Wohnliegenschaften',
    Energietraeger: 'Öl_zentral',
    Anzahl: 9,
  },
  {
    id: 51,
    Jahr: 2013,
    Kategorie: 'Wohnliegenschaften',
    Energietraeger: 'Öl_einzel',
    Anzahl: 132,
  },
  {
    id: 52,
    Jahr: 2013,
    Kategorie: 'Wohnliegenschaften',
    Energietraeger: 'BHKW (=Blockheizkraftwerk)',
    Anzahl: 0,
  },
  {
    id: 53,
    Jahr: 2013,
    Kategorie: 'Wohnliegenschaften',
    Energietraeger: 'Elektrizität',
    Anzahl: 2,
  },
  {
    id: 54,
    Jahr: 2013,
    Kategorie: 'Wohnliegenschaften',
    Energietraeger: 'Fernwärme',
    Anzahl: 77,
  },
  {
    id: 55,
    Jahr: 2013,
    Kategorie: 'Wohnliegenschaften',
    Energietraeger: 'Wärmepumpen',
    Anzahl: 5,
  },
  {
    id: 56,
    Jahr: 2013,
    Kategorie: 'Wohnliegenschaften',
    Energietraeger: 'Holz',
    Anzahl: 131,
  },
  {
    id: 57,
    Jahr: 2013,
    Kategorie: 'Wohnliegenschaften',
    Energietraeger: 'Holz_zentral',
    Anzahl: 0,
  },
  {
    id: 58,
    Jahr: 2013,
    Kategorie: 'Wohnliegenschaften',
    Energietraeger: 'Holz_einzel',
    Anzahl: 131,
  },
  {
    id: 59,
    Jahr: 2013,
    Kategorie: 'Wohnliegenschaften',
    Energietraeger: 'Photovoltaikanlagen',
    Anzahl: 0,
  },
  {
    id: 60,
    Jahr: 2013,
    Kategorie: 'Wohnliegenschaften',
    Energietraeger: 'Solaranlagen',
    Anzahl: 7,
  },
  {
    id: 61,
    Jahr: 2013,
    Kategorie: 'Geschäftsliegenschaften',
    Energietraeger: 'Gas',
    Anzahl: 19,
  },
  {
    id: 62,
    Jahr: 2013,
    Kategorie: 'Geschäftsliegenschaften',
    Energietraeger: 'Gas_zentral',
    Anzahl: 19,
  },
  {
    id: 63,
    Jahr: 2013,
    Kategorie: 'Geschäftsliegenschaften',
    Energietraeger: 'Gas_einzel',
    Anzahl: 0,
  },
  {
    id: 64,
    Jahr: 2013,
    Kategorie: 'Geschäftsliegenschaften',
    Energietraeger: 'Öl',
    Anzahl: 4,
  },
  {
    id: 65,
    Jahr: 2013,
    Kategorie: 'Geschäftsliegenschaften',
    Energietraeger: 'Öl_zentral',
    Anzahl: 4,
  },
  {
    id: 66,
    Jahr: 2013,
    Kategorie: 'Geschäftsliegenschaften',
    Energietraeger: 'Öl_einzel',
    Anzahl: 0,
  },
  {
    id: 67,
    Jahr: 2013,
    Kategorie: 'Geschäftsliegenschaften',
    Energietraeger: 'BHKW (=Blockheizkraftwerk)',
    Anzahl: 1,
  },
  {
    id: 68,
    Jahr: 2013,
    Kategorie: 'Geschäftsliegenschaften',
    Energietraeger: 'Elektrizität',
    Anzahl: 2,
  },
  {
    id: 69,
    Jahr: 2013,
    Kategorie: 'Geschäftsliegenschaften',
    Energietraeger: 'Fernwärme',
    Anzahl: 8,
  },
  {
    id: 70,
    Jahr: 2013,
    Kategorie: 'Geschäftsliegenschaften',
    Energietraeger: 'Wärmepumpen',
    Anzahl: 1,
  },
  {
    id: 71,
    Jahr: 2013,
    Kategorie: 'Geschäftsliegenschaften',
    Energietraeger: 'Holz',
    Anzahl: 0,
  },
  {
    id: 72,
    Jahr: 2013,
    Kategorie: 'Geschäftsliegenschaften',
    Energietraeger: 'Holz_zentral',
    Anzahl: 0,
  },
  {
    id: 73,
    Jahr: 2013,
    Kategorie: 'Geschäftsliegenschaften',
    Energietraeger: 'Holz_einzel',
    Anzahl: 0,
  },
  {
    id: 74,
    Jahr: 2013,
    Kategorie: 'Geschäftsliegenschaften',
    Energietraeger: 'Photovoltaikanlagen',
    Anzahl: 1,
  },
  {
    id: 75,
    Jahr: 2013,
    Kategorie: 'Geschäftsliegenschaften',
    Energietraeger: 'Solaranlagen',
    Anzahl: 0,
  },
  {
    id: 76,
    Jahr: 2013,
    Kategorie: 'Landwirtschaft',
    Energietraeger: 'Gas',
    Anzahl: 0,
  },
  {
    id: 77,
    Jahr: 2013,
    Kategorie: 'Landwirtschaft',
    Energietraeger: 'Gas_zentral',
    Anzahl: 0,
  },
  {
    id: 78,
    Jahr: 2013,
    Kategorie: 'Landwirtschaft',
    Energietraeger: 'Gas_einzel',
    Anzahl: 0,
  },
  {
    id: 79,
    Jahr: 2013,
    Kategorie: 'Landwirtschaft',
    Energietraeger: 'Öl',
    Anzahl: 1,
  },
  {
    id: 80,
    Jahr: 2013,
    Kategorie: 'Landwirtschaft',
    Energietraeger: 'Öl_zentral',
    Anzahl: 1,
  },
  {
    id: 81,
    Jahr: 2013,
    Kategorie: 'Landwirtschaft',
    Energietraeger: 'Öl_einzel',
    Anzahl: 0,
  },
  {
    id: 82,
    Jahr: 2013,
    Kategorie: 'Landwirtschaft',
    Energietraeger: 'BHKW (=Blockheizkraftwerk)',
    Anzahl: 0,
  },
  {
    id: 83,
    Jahr: 2013,
    Kategorie: 'Landwirtschaft',
    Energietraeger: 'Elektrizität',
    Anzahl: 0,
  },
  {
    id: 84,
    Jahr: 2013,
    Kategorie: 'Landwirtschaft',
    Energietraeger: 'Fernwärme',
    Anzahl: 0,
  },
  {
    id: 85,
    Jahr: 2013,
    Kategorie: 'Landwirtschaft',
    Energietraeger: 'Wärmepumpen',
    Anzahl: 1,
  },
  {
    id: 86,
    Jahr: 2013,
    Kategorie: 'Landwirtschaft',
    Energietraeger: 'Holz',
    Anzahl: 1,
  },
  {
    id: 87,
    Jahr: 2013,
    Kategorie: 'Landwirtschaft',
    Energietraeger: 'Holz_zentral',
    Anzahl: 1,
  },
  {
    id: 88,
    Jahr: 2013,
    Kategorie: 'Landwirtschaft',
    Energietraeger: 'Holz_einzel',
    Anzahl: 0,
  },
  {
    id: 89,
    Jahr: 2013,
    Kategorie: 'Landwirtschaft',
    Energietraeger: 'Photovoltaikanlagen',
    Anzahl: 0,
  },
  {
    id: 90,
    Jahr: 2013,
    Kategorie: 'Landwirtschaft',
    Energietraeger: 'Solaranlagen',
    Anzahl: 0,
  },
  {
    id: 91,
    Jahr: 2014,
    Kategorie: 'Wohnliegenschaften',
    Energietraeger: 'Gas',
    Anzahl: 251,
  },
  {
    id: 92,
    Jahr: 2014,
    Kategorie: 'Wohnliegenschaften',
    Energietraeger: 'Gas_zentral',
    Anzahl: 119,
  },
  {
    id: 93,
    Jahr: 2014,
    Kategorie: 'Wohnliegenschaften',
    Energietraeger: 'Gas_einzel',
    Anzahl: 132,
  },
  {
    id: 94,
    Jahr: 2014,
    Kategorie: 'Wohnliegenschaften',
    Energietraeger: 'Öl',
    Anzahl: 139,
  },
  {
    id: 95,
    Jahr: 2014,
    Kategorie: 'Wohnliegenschaften',
    Energietraeger: 'Öl_zentral',
    Anzahl: 7,
  },
  {
    id: 96,
    Jahr: 2014,
    Kategorie: 'Wohnliegenschaften',
    Energietraeger: 'Öl_einzel',
    Anzahl: 132,
  },
  {
    id: 97,
    Jahr: 2014,
    Kategorie: 'Wohnliegenschaften',
    Energietraeger: 'BHKW (=Blockheizkraftwerk)',
    Anzahl: 0,
  },
  {
    id: 98,
    Jahr: 2014,
    Kategorie: 'Wohnliegenschaften',
    Energietraeger: 'Elektrizität',
    Anzahl: 2,
  },
  {
    id: 99,
    Jahr: 2014,
    Kategorie: 'Wohnliegenschaften',
    Energietraeger: 'Fernwärme',
    Anzahl: 79,
  },
  {
    id: 100,
    Jahr: 2014,
    Kategorie: 'Wohnliegenschaften',
    Energietraeger: 'Wärmepumpen',
    Anzahl: 5,
  },
  {
    id: 101,
    Jahr: 2014,
    Kategorie: 'Wohnliegenschaften',
    Energietraeger: 'Holz',
    Anzahl: 138,
  },
  {
    id: 102,
    Jahr: 2014,
    Kategorie: 'Wohnliegenschaften',
    Energietraeger: 'Holz_zentral',
    Anzahl: 1,
  },
  {
    id: 103,
    Jahr: 2014,
    Kategorie: 'Wohnliegenschaften',
    Energietraeger: 'Holz_einzel',
    Anzahl: 137,
  },
  {
    id: 104,
    Jahr: 2014,
    Kategorie: 'Wohnliegenschaften',
    Energietraeger: 'Photovoltaikanlagen',
    Anzahl: 1,
  },
  {
    id: 105,
    Jahr: 2014,
    Kategorie: 'Wohnliegenschaften',
    Energietraeger: 'Solaranlagen',
    Anzahl: 7,
  },
  {
    id: 106,
    Jahr: 2014,
    Kategorie: 'Geschäftsliegenschaften',
    Energietraeger: 'Gas',
    Anzahl: 19,
  },
  {
    id: 107,
    Jahr: 2014,
    Kategorie: 'Geschäftsliegenschaften',
    Energietraeger: 'Gas_zentral',
    Anzahl: 19,
  },
  {
    id: 108,
    Jahr: 2014,
    Kategorie: 'Geschäftsliegenschaften',
    Energietraeger: 'Gas_einzel',
    Anzahl: 0,
  },
  {
    id: 109,
    Jahr: 2014,
    Kategorie: 'Geschäftsliegenschaften',
    Energietraeger: 'Öl',
    Anzahl: 3,
  },
  {
    id: 110,
    Jahr: 2014,
    Kategorie: 'Geschäftsliegenschaften',
    Energietraeger: 'Öl_zentral',
    Anzahl: 3,
  },
  {
    id: 111,
    Jahr: 2014,
    Kategorie: 'Geschäftsliegenschaften',
    Energietraeger: 'Öl_einzel',
    Anzahl: 0,
  },
  {
    id: 112,
    Jahr: 2014,
    Kategorie: 'Geschäftsliegenschaften',
    Energietraeger: 'BHKW (=Blockheizkraftwerk)',
    Anzahl: 1,
  },
  {
    id: 113,
    Jahr: 2014,
    Kategorie: 'Geschäftsliegenschaften',
    Energietraeger: 'Elektrizität',
    Anzahl: 2,
  },
  {
    id: 114,
    Jahr: 2014,
    Kategorie: 'Geschäftsliegenschaften',
    Energietraeger: 'Fernwärme',
    Anzahl: 8,
  },
  {
    id: 115,
    Jahr: 2014,
    Kategorie: 'Geschäftsliegenschaften',
    Energietraeger: 'Wärmepumpen',
    Anzahl: 2,
  },
  {
    id: 116,
    Jahr: 2014,
    Kategorie: 'Geschäftsliegenschaften',
    Energietraeger: 'Holz',
    Anzahl: 0,
  },
  {
    id: 117,
    Jahr: 2014,
    Kategorie: 'Geschäftsliegenschaften',
    Energietraeger: 'Holz_zentral',
    Anzahl: 0,
  },
  {
    id: 118,
    Jahr: 2014,
    Kategorie: 'Geschäftsliegenschaften',
    Energietraeger: 'Holz_einzel',
    Anzahl: 0,
  },
  {
    id: 119,
    Jahr: 2014,
    Kategorie: 'Geschäftsliegenschaften',
    Energietraeger: 'Photovoltaikanlagen',
    Anzahl: 1,
  },
  {
    id: 120,
    Jahr: 2014,
    Kategorie: 'Geschäftsliegenschaften',
    Energietraeger: 'Solaranlagen',
    Anzahl: 0,
  },
  {
    id: 121,
    Jahr: 2014,
    Kategorie: 'Landwirtschaft',
    Energietraeger: 'Gas',
    Anzahl: 0,
  },
  {
    id: 122,
    Jahr: 2014,
    Kategorie: 'Landwirtschaft',
    Energietraeger: 'Gas_zentral',
    Anzahl: 0,
  },
  {
    id: 123,
    Jahr: 2014,
    Kategorie: 'Landwirtschaft',
    Energietraeger: 'Gas_einzel',
    Anzahl: 0,
  },
  {
    id: 124,
    Jahr: 2014,
    Kategorie: 'Landwirtschaft',
    Energietraeger: 'Öl',
    Anzahl: 1,
  },
  {
    id: 125,
    Jahr: 2014,
    Kategorie: 'Landwirtschaft',
    Energietraeger: 'Öl_zentral',
    Anzahl: 1,
  },
  {
    id: 126,
    Jahr: 2014,
    Kategorie: 'Landwirtschaft',
    Energietraeger: 'Öl_einzel',
    Anzahl: 0,
  },
  {
    id: 127,
    Jahr: 2014,
    Kategorie: 'Landwirtschaft',
    Energietraeger: 'BHKW (=Blockheizkraftwerk)',
    Anzahl: 0,
  },
  {
    id: 128,
    Jahr: 2014,
    Kategorie: 'Landwirtschaft',
    Energietraeger: 'Elektrizität',
    Anzahl: 0,
  },
  {
    id: 129,
    Jahr: 2014,
    Kategorie: 'Landwirtschaft',
    Energietraeger: 'Fernwärme',
    Anzahl: 0,
  },
  {
    id: 130,
    Jahr: 2014,
    Kategorie: 'Landwirtschaft',
    Energietraeger: 'Wärmepumpen',
    Anzahl: 1,
  },
  {
    id: 131,
    Jahr: 2014,
    Kategorie: 'Landwirtschaft',
    Energietraeger: 'Holz',
    Anzahl: 1,
  },
  {
    id: 132,
    Jahr: 2014,
    Kategorie: 'Landwirtschaft',
    Energietraeger: 'Holz_zentral',
    Anzahl: 1,
  },
  {
    id: 133,
    Jahr: 2014,
    Kategorie: 'Landwirtschaft',
    Energietraeger: 'Holz_einzel',
    Anzahl: 0,
  },
  {
    id: 134,
    Jahr: 2014,
    Kategorie: 'Landwirtschaft',
    Energietraeger: 'Photovoltaikanlagen',
    Anzahl: 0,
  },
  {
    id: 135,
    Jahr: 2014,
    Kategorie: 'Landwirtschaft',
    Energietraeger: 'Solaranlagen',
    Anzahl: 0,
  },
  {
    id: 136,
    Jahr: 2015,
    Kategorie: 'Wohnliegenschaften',
    Energietraeger: 'Gas',
    Anzahl: 249,
  },
  {
    id: 137,
    Jahr: 2015,
    Kategorie: 'Wohnliegenschaften',
    Energietraeger: 'Gas_zentral',
    Anzahl: 117,
  },
  {
    id: 138,
    Jahr: 2015,
    Kategorie: 'Wohnliegenschaften',
    Energietraeger: 'Gas_einzel',
    Anzahl: 132,
  },
  {
    id: 139,
    Jahr: 2015,
    Kategorie: 'Wohnliegenschaften',
    Energietraeger: 'Öl',
    Anzahl: 139,
  },
  {
    id: 140,
    Jahr: 2015,
    Kategorie: 'Wohnliegenschaften',
    Energietraeger: 'Öl_zentral',
    Anzahl: 7,
  },
  {
    id: 141,
    Jahr: 2015,
    Kategorie: 'Wohnliegenschaften',
    Energietraeger: 'Öl_einzel',
    Anzahl: 132,
  },
  {
    id: 142,
    Jahr: 2015,
    Kategorie: 'Wohnliegenschaften',
    Energietraeger: 'BHKW (=Blockheizkraftwerk)',
    Anzahl: 0,
  },
  {
    id: 143,
    Jahr: 2015,
    Kategorie: 'Wohnliegenschaften',
    Energietraeger: 'Elektrizität',
    Anzahl: 1,
  },
  {
    id: 144,
    Jahr: 2015,
    Kategorie: 'Wohnliegenschaften',
    Energietraeger: 'Fernwärme',
    Anzahl: 79,
  },
  {
    id: 145,
    Jahr: 2015,
    Kategorie: 'Wohnliegenschaften',
    Energietraeger: 'Wärmepumpen',
    Anzahl: 6,
  },
  {
    id: 146,
    Jahr: 2015,
    Kategorie: 'Wohnliegenschaften',
    Energietraeger: 'Holz',
    Anzahl: 138,
  },
  {
    id: 147,
    Jahr: 2015,
    Kategorie: 'Wohnliegenschaften',
    Energietraeger: 'Holz_zentral',
    Anzahl: 1,
  },
  {
    id: 148,
    Jahr: 2015,
    Kategorie: 'Wohnliegenschaften',
    Energietraeger: 'Holz_einzel',
    Anzahl: 137,
  },
  {
    id: 149,
    Jahr: 2015,
    Kategorie: 'Wohnliegenschaften',
    Energietraeger: 'Photovoltaikanlagen',
    Anzahl: 1,
  },
  {
    id: 150,
    Jahr: 2015,
    Kategorie: 'Wohnliegenschaften',
    Energietraeger: 'Solaranlagen',
    Anzahl: 7,
  },
  {
    id: 151,
    Jahr: 2015,
    Kategorie: 'Geschäftsliegenschaften',
    Energietraeger: 'Gas',
    Anzahl: 26,
  },
  {
    id: 152,
    Jahr: 2015,
    Kategorie: 'Geschäftsliegenschaften',
    Energietraeger: 'Gas_zentral',
    Anzahl: 26,
  },
  {
    id: 153,
    Jahr: 2015,
    Kategorie: 'Geschäftsliegenschaften',
    Energietraeger: 'Gas_einzel',
    Anzahl: 0,
  },
  {
    id: 154,
    Jahr: 2015,
    Kategorie: 'Geschäftsliegenschaften',
    Energietraeger: 'Öl',
    Anzahl: 3,
  },
  {
    id: 155,
    Jahr: 2015,
    Kategorie: 'Geschäftsliegenschaften',
    Energietraeger: 'Öl_zentral',
    Anzahl: 3,
  },
  {
    id: 156,
    Jahr: 2015,
    Kategorie: 'Geschäftsliegenschaften',
    Energietraeger: 'Öl_einzel',
    Anzahl: 0,
  },
  {
    id: 157,
    Jahr: 2015,
    Kategorie: 'Geschäftsliegenschaften',
    Energietraeger: 'BHKW (=Blockheizkraftwerk)',
    Anzahl: 1,
  },
  {
    id: 158,
    Jahr: 2015,
    Kategorie: 'Geschäftsliegenschaften',
    Energietraeger: 'Elektrizität',
    Anzahl: 2,
  },
  {
    id: 159,
    Jahr: 2015,
    Kategorie: 'Geschäftsliegenschaften',
    Energietraeger: 'Fernwärme',
    Anzahl: 9,
  },
  {
    id: 160,
    Jahr: 2015,
    Kategorie: 'Geschäftsliegenschaften',
    Energietraeger: 'Wärmepumpen',
    Anzahl: 2,
  },
  {
    id: 161,
    Jahr: 2015,
    Kategorie: 'Geschäftsliegenschaften',
    Energietraeger: 'Holz',
    Anzahl: 0,
  },
  {
    id: 162,
    Jahr: 2015,
    Kategorie: 'Geschäftsliegenschaften',
    Energietraeger: 'Holz_zentral',
    Anzahl: 0,
  },
  {
    id: 163,
    Jahr: 2015,
    Kategorie: 'Geschäftsliegenschaften',
    Energietraeger: 'Holz_einzel',
    Anzahl: 0,
  },
  {
    id: 164,
    Jahr: 2015,
    Kategorie: 'Geschäftsliegenschaften',
    Energietraeger: 'Photovoltaikanlagen',
    Anzahl: 1,
  },
  {
    id: 165,
    Jahr: 2015,
    Kategorie: 'Geschäftsliegenschaften',
    Energietraeger: 'Solaranlagen',
    Anzahl: 0,
  },
  {
    id: 166,
    Jahr: 2015,
    Kategorie: 'Landwirtschaft',
    Energietraeger: 'Gas',
    Anzahl: 0,
  },
  {
    id: 167,
    Jahr: 2015,
    Kategorie: 'Landwirtschaft',
    Energietraeger: 'Gas_zentral',
    Anzahl: 0,
  },
  {
    id: 168,
    Jahr: 2015,
    Kategorie: 'Landwirtschaft',
    Energietraeger: 'Gas_einzel',
    Anzahl: 0,
  },
  {
    id: 169,
    Jahr: 2015,
    Kategorie: 'Landwirtschaft',
    Energietraeger: 'Öl',
    Anzahl: 1,
  },
  {
    id: 170,
    Jahr: 2015,
    Kategorie: 'Landwirtschaft',
    Energietraeger: 'Öl_zentral',
    Anzahl: 1,
  },
  {
    id: 171,
    Jahr: 2015,
    Kategorie: 'Landwirtschaft',
    Energietraeger: 'Öl_einzel',
    Anzahl: 0,
  },
  {
    id: 172,
    Jahr: 2015,
    Kategorie: 'Landwirtschaft',
    Energietraeger: 'BHKW (=Blockheizkraftwerk)',
    Anzahl: 0,
  },
  {
    id: 173,
    Jahr: 2015,
    Kategorie: 'Landwirtschaft',
    Energietraeger: 'Elektrizität',
    Anzahl: 0,
  },
  {
    id: 174,
    Jahr: 2015,
    Kategorie: 'Landwirtschaft',
    Energietraeger: 'Fernwärme',
    Anzahl: 0,
  },
  {
    id: 175,
    Jahr: 2015,
    Kategorie: 'Landwirtschaft',
    Energietraeger: 'Wärmepumpen',
    Anzahl: 1,
  },
  {
    id: 176,
    Jahr: 2015,
    Kategorie: 'Landwirtschaft',
    Energietraeger: 'Holz',
    Anzahl: 1,
  },
  {
    id: 177,
    Jahr: 2015,
    Kategorie: 'Landwirtschaft',
    Energietraeger: 'Holz_zentral',
    Anzahl: 1,
  },
  {
    id: 178,
    Jahr: 2015,
    Kategorie: 'Landwirtschaft',
    Energietraeger: 'Holz_einzel',
    Anzahl: 0,
  },
  {
    id: 179,
    Jahr: 2015,
    Kategorie: 'Landwirtschaft',
    Energietraeger: 'Photovoltaikanlagen',
    Anzahl: 0,
  },
  {
    id: 180,
    Jahr: 2015,
    Kategorie: 'Landwirtschaft',
    Energietraeger: 'Solaranlagen',
    Anzahl: 0,
  },
  {
    id: 181,
    Jahr: 2016,
    Kategorie: 'Wohnliegenschaften',
    Energietraeger: 'Gas',
    Anzahl: 252,
  },
  {
    id: 182,
    Jahr: 2016,
    Kategorie: 'Wohnliegenschaften',
    Energietraeger: 'Gas_zentral',
    Anzahl: 120,
  },
  {
    id: 183,
    Jahr: 2016,
    Kategorie: 'Wohnliegenschaften',
    Energietraeger: 'Gas_einzel',
    Anzahl: 132,
  },
  {
    id: 184,
    Jahr: 2016,
    Kategorie: 'Wohnliegenschaften',
    Energietraeger: 'Öl',
    Anzahl: 137,
  },
  {
    id: 185,
    Jahr: 2016,
    Kategorie: 'Wohnliegenschaften',
    Energietraeger: 'Öl_zentral',
    Anzahl: 7,
  },
  {
    id: 186,
    Jahr: 2016,
    Kategorie: 'Wohnliegenschaften',
    Energietraeger: 'Öl_einzel',
    Anzahl: 130,
  },
  {
    id: 187,
    Jahr: 2016,
    Kategorie: 'Wohnliegenschaften',
    Energietraeger: 'BHKW (=Blockheizkraftwerk)',
    Anzahl: 0,
  },
  {
    id: 188,
    Jahr: 2016,
    Kategorie: 'Wohnliegenschaften',
    Energietraeger: 'Elektrizität',
    Anzahl: 2,
  },
  {
    id: 189,
    Jahr: 2016,
    Kategorie: 'Wohnliegenschaften',
    Energietraeger: 'Fernwärme',
    Anzahl: 79,
  },
  {
    id: 190,
    Jahr: 2016,
    Kategorie: 'Wohnliegenschaften',
    Energietraeger: 'Wärmepumpen',
    Anzahl: 11,
  },
  {
    id: 191,
    Jahr: 2016,
    Kategorie: 'Wohnliegenschaften',
    Energietraeger: 'Holz',
    Anzahl: 132,
  },
  {
    id: 192,
    Jahr: 2016,
    Kategorie: 'Wohnliegenschaften',
    Energietraeger: 'Holz_zentral',
    Anzahl: 1,
  },
  {
    id: 193,
    Jahr: 2016,
    Kategorie: 'Wohnliegenschaften',
    Energietraeger: 'Holz_einzel',
    Anzahl: 131,
  },
  {
    id: 194,
    Jahr: 2016,
    Kategorie: 'Wohnliegenschaften',
    Energietraeger: 'Photovoltaikanlagen',
    Anzahl: 5,
  },
  {
    id: 195,
    Jahr: 2016,
    Kategorie: 'Wohnliegenschaften',
    Energietraeger: 'Solaranlagen',
    Anzahl: 9,
  },
  {
    id: 196,
    Jahr: 2016,
    Kategorie: 'Geschäftsliegenschaften',
    Energietraeger: 'Gas',
    Anzahl: 26,
  },
  {
    id: 197,
    Jahr: 2016,
    Kategorie: 'Geschäftsliegenschaften',
    Energietraeger: 'Gas_zentral',
    Anzahl: 26,
  },
  {
    id: 198,
    Jahr: 2016,
    Kategorie: 'Geschäftsliegenschaften',
    Energietraeger: 'Gas_einzel',
    Anzahl: 0,
  },
  {
    id: 199,
    Jahr: 2016,
    Kategorie: 'Geschäftsliegenschaften',
    Energietraeger: 'Öl',
    Anzahl: 3,
  },
  {
    id: 200,
    Jahr: 2016,
    Kategorie: 'Geschäftsliegenschaften',
    Energietraeger: 'Öl_zentral',
    Anzahl: 3,
  },
  {
    id: 201,
    Jahr: 2016,
    Kategorie: 'Geschäftsliegenschaften',
    Energietraeger: 'Öl_einzel',
    Anzahl: 0,
  },
  {
    id: 202,
    Jahr: 2016,
    Kategorie: 'Geschäftsliegenschaften',
    Energietraeger: 'BHKW (=Blockheizkraftwerk)',
    Anzahl: 1,
  },
  {
    id: 203,
    Jahr: 2016,
    Kategorie: 'Geschäftsliegenschaften',
    Energietraeger: 'Elektrizität',
    Anzahl: 4,
  },
  {
    id: 204,
    Jahr: 2016,
    Kategorie: 'Geschäftsliegenschaften',
    Energietraeger: 'Fernwärme',
    Anzahl: 10,
  },
  {
    id: 205,
    Jahr: 2016,
    Kategorie: 'Geschäftsliegenschaften',
    Energietraeger: 'Wärmepumpen',
    Anzahl: 2,
  },
  {
    id: 206,
    Jahr: 2016,
    Kategorie: 'Geschäftsliegenschaften',
    Energietraeger: 'Holz',
    Anzahl: 2,
  },
  {
    id: 207,
    Jahr: 2016,
    Kategorie: 'Geschäftsliegenschaften',
    Energietraeger: 'Holz_zentral',
    Anzahl: 0,
  },
  {
    id: 208,
    Jahr: 2016,
    Kategorie: 'Geschäftsliegenschaften',
    Energietraeger: 'Holz_einzel',
    Anzahl: 2,
  },
  {
    id: 209,
    Jahr: 2016,
    Kategorie: 'Geschäftsliegenschaften',
    Energietraeger: 'Photovoltaikanlagen',
    Anzahl: 1,
  },
  {
    id: 210,
    Jahr: 2016,
    Kategorie: 'Geschäftsliegenschaften',
    Energietraeger: 'Solaranlagen',
    Anzahl: 0,
  },
  {
    id: 211,
    Jahr: 2016,
    Kategorie: 'Landwirtschaft',
    Energietraeger: 'Gas',
    Anzahl: 0,
  },
  {
    id: 212,
    Jahr: 2016,
    Kategorie: 'Landwirtschaft',
    Energietraeger: 'Gas_zentral',
    Anzahl: 0,
  },
  {
    id: 213,
    Jahr: 2016,
    Kategorie: 'Landwirtschaft',
    Energietraeger: 'Gas_einzel',
    Anzahl: 0,
  },
  {
    id: 214,
    Jahr: 2016,
    Kategorie: 'Landwirtschaft',
    Energietraeger: 'Öl',
    Anzahl: 1,
  },
  {
    id: 215,
    Jahr: 2016,
    Kategorie: 'Landwirtschaft',
    Energietraeger: 'Öl_zentral',
    Anzahl: 1,
  },
  {
    id: 216,
    Jahr: 2016,
    Kategorie: 'Landwirtschaft',
    Energietraeger: 'Öl_einzel',
    Anzahl: 0,
  },
  {
    id: 217,
    Jahr: 2016,
    Kategorie: 'Landwirtschaft',
    Energietraeger: 'BHKW (=Blockheizkraftwerk)',
    Anzahl: 0,
  },
  {
    id: 218,
    Jahr: 2016,
    Kategorie: 'Landwirtschaft',
    Energietraeger: 'Elektrizität',
    Anzahl: 0,
  },
  {
    id: 219,
    Jahr: 2016,
    Kategorie: 'Landwirtschaft',
    Energietraeger: 'Fernwärme',
    Anzahl: 0,
  },
  {
    id: 220,
    Jahr: 2016,
    Kategorie: 'Landwirtschaft',
    Energietraeger: 'Wärmepumpen',
    Anzahl: 1,
  },
  {
    id: 221,
    Jahr: 2016,
    Kategorie: 'Landwirtschaft',
    Energietraeger: 'Holz',
    Anzahl: 1,
  },
  {
    id: 222,
    Jahr: 2016,
    Kategorie: 'Landwirtschaft',
    Energietraeger: 'Holz_zentral',
    Anzahl: 1,
  },
  {
    id: 223,
    Jahr: 2016,
    Kategorie: 'Landwirtschaft',
    Energietraeger: 'Holz_einzel',
    Anzahl: 0,
  },
  {
    id: 224,
    Jahr: 2016,
    Kategorie: 'Landwirtschaft',
    Energietraeger: 'Photovoltaikanlagen',
    Anzahl: 0,
  },
  {
    id: 225,
    Jahr: 2016,
    Kategorie: 'Landwirtschaft',
    Energietraeger: 'Solaranlagen',
    Anzahl: 0,
  },
  {
    id: 226,
    Jahr: 2017,
    Kategorie: 'Wohnliegenschaften',
    Energietraeger: 'Gas',
    Anzahl: 249,
  },
  {
    id: 227,
    Jahr: 2017,
    Kategorie: 'Wohnliegenschaften',
    Energietraeger: 'Gas_zentral',
    Anzahl: 117,
  },
  {
    id: 228,
    Jahr: 2017,
    Kategorie: 'Wohnliegenschaften',
    Energietraeger: 'Gas_einzel',
    Anzahl: 132,
  },
  {
    id: 229,
    Jahr: 2017,
    Kategorie: 'Wohnliegenschaften',
    Energietraeger: 'Öl',
    Anzahl: 138,
  },
  {
    id: 230,
    Jahr: 2017,
    Kategorie: 'Wohnliegenschaften',
    Energietraeger: 'Öl_zentral',
    Anzahl: 8,
  },
  {
    id: 231,
    Jahr: 2017,
    Kategorie: 'Wohnliegenschaften',
    Energietraeger: 'Öl_einzel',
    Anzahl: 130,
  },
  {
    id: 232,
    Jahr: 2017,
    Kategorie: 'Wohnliegenschaften',
    Energietraeger: 'BHKW (=Blockheizkraftwerk)',
    Anzahl: 0,
  },
  {
    id: 233,
    Jahr: 2017,
    Kategorie: 'Wohnliegenschaften',
    Energietraeger: 'Elektrizität',
    Anzahl: 2,
  },
  {
    id: 234,
    Jahr: 2017,
    Kategorie: 'Wohnliegenschaften',
    Energietraeger: 'Fernwärme',
    Anzahl: 79,
  },
  {
    id: 235,
    Jahr: 2017,
    Kategorie: 'Wohnliegenschaften',
    Energietraeger: 'Wärmepumpen',
    Anzahl: 11,
  },
  {
    id: 236,
    Jahr: 2017,
    Kategorie: 'Wohnliegenschaften',
    Energietraeger: 'Holz',
    Anzahl: 132,
  },
  {
    id: 237,
    Jahr: 2017,
    Kategorie: 'Wohnliegenschaften',
    Energietraeger: 'Holz_zentral',
    Anzahl: 1,
  },
  {
    id: 238,
    Jahr: 2017,
    Kategorie: 'Wohnliegenschaften',
    Energietraeger: 'Holz_einzel',
    Anzahl: 131,
  },
  {
    id: 239,
    Jahr: 2017,
    Kategorie: 'Wohnliegenschaften',
    Energietraeger: 'Photovoltaikanlagen',
    Anzahl: 7,
  },
  {
    id: 240,
    Jahr: 2017,
    Kategorie: 'Wohnliegenschaften',
    Energietraeger: 'Solaranlagen',
    Anzahl: 9,
  },
  {
    id: 241,
    Jahr: 2017,
    Kategorie: 'Geschäftsliegenschaften',
    Energietraeger: 'Gas',
    Anzahl: 28,
  },
  {
    id: 242,
    Jahr: 2017,
    Kategorie: 'Geschäftsliegenschaften',
    Energietraeger: 'Gas_zentral',
    Anzahl: 28,
  },
  {
    id: 243,
    Jahr: 2017,
    Kategorie: 'Geschäftsliegenschaften',
    Energietraeger: 'Gas_einzel',
    Anzahl: 0,
  },
  {
    id: 244,
    Jahr: 2017,
    Kategorie: 'Geschäftsliegenschaften',
    Energietraeger: 'Öl',
    Anzahl: 1,
  },
  {
    id: 245,
    Jahr: 2017,
    Kategorie: 'Geschäftsliegenschaften',
    Energietraeger: 'Öl_zentral',
    Anzahl: 1,
  },
  {
    id: 246,
    Jahr: 2017,
    Kategorie: 'Geschäftsliegenschaften',
    Energietraeger: 'Öl_einzel',
    Anzahl: 0,
  },
  {
    id: 247,
    Jahr: 2017,
    Kategorie: 'Geschäftsliegenschaften',
    Energietraeger: 'BHKW (=Blockheizkraftwerk)',
    Anzahl: 1,
  },
  {
    id: 248,
    Jahr: 2017,
    Kategorie: 'Geschäftsliegenschaften',
    Energietraeger: 'Elektrizität',
    Anzahl: 4,
  },
  {
    id: 249,
    Jahr: 2017,
    Kategorie: 'Geschäftsliegenschaften',
    Energietraeger: 'Fernwärme',
    Anzahl: 11,
  },
  {
    id: 250,
    Jahr: 2017,
    Kategorie: 'Geschäftsliegenschaften',
    Energietraeger: 'Wärmepumpen',
    Anzahl: 3,
  },
  {
    id: 251,
    Jahr: 2017,
    Kategorie: 'Geschäftsliegenschaften',
    Energietraeger: 'Holz',
    Anzahl: 2,
  },
  {
    id: 252,
    Jahr: 2017,
    Kategorie: 'Geschäftsliegenschaften',
    Energietraeger: 'Holz_zentral',
    Anzahl: 0,
  },
  {
    id: 253,
    Jahr: 2017,
    Kategorie: 'Geschäftsliegenschaften',
    Energietraeger: 'Holz_einzel',
    Anzahl: 2,
  },
  {
    id: 254,
    Jahr: 2017,
    Kategorie: 'Geschäftsliegenschaften',
    Energietraeger: 'Photovoltaikanlagen',
    Anzahl: 1,
  },
  {
    id: 255,
    Jahr: 2017,
    Kategorie: 'Geschäftsliegenschaften',
    Energietraeger: 'Solaranlagen',
    Anzahl: 0,
  },
  {
    id: 256,
    Jahr: 2017,
    Kategorie: 'Landwirtschaft',
    Energietraeger: 'Gas',
    Anzahl: 0,
  },
  {
    id: 257,
    Jahr: 2017,
    Kategorie: 'Landwirtschaft',
    Energietraeger: 'Gas_zentral',
    Anzahl: 0,
  },
  {
    id: 258,
    Jahr: 2017,
    Kategorie: 'Landwirtschaft',
    Energietraeger: 'Gas_einzel',
    Anzahl: 0,
  },
  {
    id: 259,
    Jahr: 2017,
    Kategorie: 'Landwirtschaft',
    Energietraeger: 'Öl',
    Anzahl: 1,
  },
  {
    id: 260,
    Jahr: 2017,
    Kategorie: 'Landwirtschaft',
    Energietraeger: 'Öl_zentral',
    Anzahl: 1,
  },
  {
    id: 261,
    Jahr: 2017,
    Kategorie: 'Landwirtschaft',
    Energietraeger: 'Öl_einzel',
    Anzahl: 0,
  },
  {
    id: 262,
    Jahr: 2017,
    Kategorie: 'Landwirtschaft',
    Energietraeger: 'BHKW (=Blockheizkraftwerk)',
    Anzahl: 0,
  },
  {
    id: 263,
    Jahr: 2017,
    Kategorie: 'Landwirtschaft',
    Energietraeger: 'Elektrizität',
    Anzahl: 0,
  },
  {
    id: 264,
    Jahr: 2017,
    Kategorie: 'Landwirtschaft',
    Energietraeger: 'Fernwärme',
    Anzahl: 0,
  },
  {
    id: 265,
    Jahr: 2017,
    Kategorie: 'Landwirtschaft',
    Energietraeger: 'Wärmepumpen',
    Anzahl: 1,
  },
  {
    id: 266,
    Jahr: 2017,
    Kategorie: 'Landwirtschaft',
    Energietraeger: 'Holz',
    Anzahl: 1,
  },
  {
    id: 267,
    Jahr: 2017,
    Kategorie: 'Landwirtschaft',
    Energietraeger: 'Holz_zentral',
    Anzahl: 1,
  },
  {
    id: 268,
    Jahr: 2017,
    Kategorie: 'Landwirtschaft',
    Energietraeger: 'Holz_einzel',
    Anzahl: 0,
  },
  {
    id: 269,
    Jahr: 2017,
    Kategorie: 'Landwirtschaft',
    Energietraeger: 'Photovoltaikanlagen',
    Anzahl: 0,
  },
  {
    id: 270,
    Jahr: 2017,
    Kategorie: 'Landwirtschaft',
    Energietraeger: 'Solaranlagen',
    Anzahl: 0,
  },
  {
    id: 271,
    Jahr: 2018,
    Kategorie: 'Wohnliegenschaften',
    Energietraeger: 'Gas',
    Anzahl: 245,
  },
  {
    id: 272,
    Jahr: 2018,
    Kategorie: 'Wohnliegenschaften',
    Energietraeger: 'Gas_zentral',
    Anzahl: 116,
  },
  {
    id: 273,
    Jahr: 2018,
    Kategorie: 'Wohnliegenschaften',
    Energietraeger: 'Gas_einzel',
    Anzahl: 129,
  },
  {
    id: 274,
    Jahr: 2018,
    Kategorie: 'Wohnliegenschaften',
    Energietraeger: 'Öl',
    Anzahl: 139,
  },
  {
    id: 275,
    Jahr: 2018,
    Kategorie: 'Wohnliegenschaften',
    Energietraeger: 'Öl_zentral',
    Anzahl: 12,
  },
  {
    id: 276,
    Jahr: 2018,
    Kategorie: 'Wohnliegenschaften',
    Energietraeger: 'Öl_einzel',
    Anzahl: 127,
  },
  {
    id: 277,
    Jahr: 2018,
    Kategorie: 'Wohnliegenschaften',
    Energietraeger: 'BHKW (=Blockheizkraftwerk)',
    Anzahl: 0,
  },
  {
    id: 278,
    Jahr: 2018,
    Kategorie: 'Wohnliegenschaften',
    Energietraeger: 'Elektrizität',
    Anzahl: 3,
  },
  {
    id: 279,
    Jahr: 2018,
    Kategorie: 'Wohnliegenschaften',
    Energietraeger: 'Fernwärme',
    Anzahl: 79,
  },
  {
    id: 280,
    Jahr: 2018,
    Kategorie: 'Wohnliegenschaften',
    Energietraeger: 'Wärmepumpen',
    Anzahl: 15,
  },
  {
    id: 281,
    Jahr: 2018,
    Kategorie: 'Wohnliegenschaften',
    Energietraeger: 'Holz',
    Anzahl: 130,
  },
  {
    id: 282,
    Jahr: 2018,
    Kategorie: 'Wohnliegenschaften',
    Energietraeger: 'Holz_zentral',
    Anzahl: 1,
  },
  {
    id: 283,
    Jahr: 2018,
    Kategorie: 'Wohnliegenschaften',
    Energietraeger: 'Holz_einzel',
    Anzahl: 129,
  },
  {
    id: 284,
    Jahr: 2018,
    Kategorie: 'Wohnliegenschaften',
    Energietraeger: 'Photovoltaikanlagen',
    Anzahl: 12,
  },
  {
    id: 285,
    Jahr: 2018,
    Kategorie: 'Wohnliegenschaften',
    Energietraeger: 'Solaranlagen',
    Anzahl: 9,
  },
  {
    id: 286,
    Jahr: 2018,
    Kategorie: 'Geschäftsliegenschaften',
    Energietraeger: 'Gas',
    Anzahl: 27,
  },
  {
    id: 287,
    Jahr: 2018,
    Kategorie: 'Geschäftsliegenschaften',
    Energietraeger: 'Gas_zentral',
    Anzahl: 27,
  },
  {
    id: 288,
    Jahr: 2018,
    Kategorie: 'Geschäftsliegenschaften',
    Energietraeger: 'Gas_einzel',
    Anzahl: 0,
  },
  {
    id: 289,
    Jahr: 2018,
    Kategorie: 'Geschäftsliegenschaften',
    Energietraeger: 'Öl',
    Anzahl: 1,
  },
  {
    id: 290,
    Jahr: 2018,
    Kategorie: 'Geschäftsliegenschaften',
    Energietraeger: 'Öl_zentral',
    Anzahl: 1,
  },
  {
    id: 291,
    Jahr: 2018,
    Kategorie: 'Geschäftsliegenschaften',
    Energietraeger: 'Öl_einzel',
    Anzahl: 0,
  },
  {
    id: 292,
    Jahr: 2018,
    Kategorie: 'Geschäftsliegenschaften',
    Energietraeger: 'BHKW (=Blockheizkraftwerk)',
    Anzahl: 1,
  },
  {
    id: 293,
    Jahr: 2018,
    Kategorie: 'Geschäftsliegenschaften',
    Energietraeger: 'Elektrizität',
    Anzahl: 4,
  },
  {
    id: 294,
    Jahr: 2018,
    Kategorie: 'Geschäftsliegenschaften',
    Energietraeger: 'Fernwärme',
    Anzahl: 11,
  },
  {
    id: 295,
    Jahr: 2018,
    Kategorie: 'Geschäftsliegenschaften',
    Energietraeger: 'Wärmepumpen',
    Anzahl: 3,
  },
  {
    id: 296,
    Jahr: 2018,
    Kategorie: 'Geschäftsliegenschaften',
    Energietraeger: 'Holz',
    Anzahl: 2,
  },
  {
    id: 297,
    Jahr: 2018,
    Kategorie: 'Geschäftsliegenschaften',
    Energietraeger: 'Holz_zentral',
    Anzahl: 0,
  },
  {
    id: 298,
    Jahr: 2018,
    Kategorie: 'Geschäftsliegenschaften',
    Energietraeger: 'Holz_einzel',
    Anzahl: 2,
  },
  {
    id: 299,
    Jahr: 2018,
    Kategorie: 'Geschäftsliegenschaften',
    Energietraeger: 'Photovoltaikanlagen',
    Anzahl: 1,
  },
  {
    id: 300,
    Jahr: 2018,
    Kategorie: 'Geschäftsliegenschaften',
    Energietraeger: 'Solaranlagen',
    Anzahl: 0,
  },
  {
    id: 301,
    Jahr: 2018,
    Kategorie: 'Landwirtschaft',
    Energietraeger: 'Gas',
    Anzahl: 0,
  },
  {
    id: 302,
    Jahr: 2018,
    Kategorie: 'Landwirtschaft',
    Energietraeger: 'Gas_zentral',
    Anzahl: 0,
  },
  {
    id: 303,
    Jahr: 2018,
    Kategorie: 'Landwirtschaft',
    Energietraeger: 'Gas_einzel',
    Anzahl: 0,
  },
  {
    id: 304,
    Jahr: 2018,
    Kategorie: 'Landwirtschaft',
    Energietraeger: 'Öl',
    Anzahl: 1,
  },
  {
    id: 305,
    Jahr: 2018,
    Kategorie: 'Landwirtschaft',
    Energietraeger: 'Öl_zentral',
    Anzahl: 1,
  },
  {
    id: 306,
    Jahr: 2018,
    Kategorie: 'Landwirtschaft',
    Energietraeger: 'Öl_einzel',
    Anzahl: 0,
  },
  {
    id: 307,
    Jahr: 2018,
    Kategorie: 'Landwirtschaft',
    Energietraeger: 'BHKW (=Blockheizkraftwerk)',
    Anzahl: 0,
  },
  {
    id: 308,
    Jahr: 2018,
    Kategorie: 'Landwirtschaft',
    Energietraeger: 'Elektrizität',
    Anzahl: 0,
  },
  {
    id: 309,
    Jahr: 2018,
    Kategorie: 'Landwirtschaft',
    Energietraeger: 'Fernwärme',
    Anzahl: 0,
  },
  {
    id: 310,
    Jahr: 2018,
    Kategorie: 'Landwirtschaft',
    Energietraeger: 'Wärmepumpen',
    Anzahl: 1,
  },
  {
    id: 311,
    Jahr: 2018,
    Kategorie: 'Landwirtschaft',
    Energietraeger: 'Holz',
    Anzahl: 1,
  },
  {
    id: 312,
    Jahr: 2018,
    Kategorie: 'Landwirtschaft',
    Energietraeger: 'Holz_zentral',
    Anzahl: 1,
  },
  {
    id: 313,
    Jahr: 2018,
    Kategorie: 'Landwirtschaft',
    Energietraeger: 'Holz_einzel',
    Anzahl: 0,
  },
  {
    id: 314,
    Jahr: 2018,
    Kategorie: 'Landwirtschaft',
    Energietraeger: 'Photovoltaikanlagen',
    Anzahl: 0,
  },
  {
    id: 315,
    Jahr: 2018,
    Kategorie: 'Landwirtschaft',
    Energietraeger: 'Solaranlagen',
    Anzahl: 0,
  },
  {
    id: 316,
    Jahr: 2019,
    Kategorie: 'Wohnliegenschaften',
    Energietraeger: 'Gas',
    Anzahl: 243,
  },
  {
    id: 317,
    Jahr: 2019,
    Kategorie: 'Wohnliegenschaften',
    Energietraeger: 'Gas_zentral',
    Anzahl: 116,
  },
  {
    id: 318,
    Jahr: 2019,
    Kategorie: 'Wohnliegenschaften',
    Energietraeger: 'Gas_einzel',
    Anzahl: 127,
  },
  {
    id: 319,
    Jahr: 2019,
    Kategorie: 'Wohnliegenschaften',
    Energietraeger: 'Öl',
    Anzahl: 145,
  },
  {
    id: 320,
    Jahr: 2019,
    Kategorie: 'Wohnliegenschaften',
    Energietraeger: 'Öl_zentral',
    Anzahl: 15,
  },
  {
    id: 321,
    Jahr: 2019,
    Kategorie: 'Wohnliegenschaften',
    Energietraeger: 'Öl_einzel',
    Anzahl: 130,
  },
  {
    id: 322,
    Jahr: 2019,
    Kategorie: 'Wohnliegenschaften',
    Energietraeger: 'BHKW (=Blockheizkraftwerk)',
    Anzahl: 1,
  },
  {
    id: 323,
    Jahr: 2019,
    Kategorie: 'Wohnliegenschaften',
    Energietraeger: 'Elektrizität',
    Anzahl: 3,
  },
  {
    id: 324,
    Jahr: 2019,
    Kategorie: 'Wohnliegenschaften',
    Energietraeger: 'Fernwärme',
    Anzahl: 86,
  },
  {
    id: 325,
    Jahr: 2019,
    Kategorie: 'Wohnliegenschaften',
    Energietraeger: 'Wärmepumpen',
    Anzahl: 20,
  },
  {
    id: 326,
    Jahr: 2019,
    Kategorie: 'Wohnliegenschaften',
    Energietraeger: 'Holz',
    Anzahl: 135,
  },
  {
    id: 327,
    Jahr: 2019,
    Kategorie: 'Wohnliegenschaften',
    Energietraeger: 'Holz_zentral',
    Anzahl: 1,
  },
  {
    id: 328,
    Jahr: 2019,
    Kategorie: 'Wohnliegenschaften',
    Energietraeger: 'Holz_einzel',
    Anzahl: 134,
  },
  {
    id: 329,
    Jahr: 2019,
    Kategorie: 'Wohnliegenschaften',
    Energietraeger: 'Photovoltaikanlagen',
    Anzahl: 20,
  },
  {
    id: 330,
    Jahr: 2019,
    Kategorie: 'Wohnliegenschaften',
    Energietraeger: 'Solaranlagen',
    Anzahl: 12,
  },
  {
    id: 331,
    Jahr: 2019,
    Kategorie: 'Geschäftsliegenschaften',
    Energietraeger: 'Gas',
    Anzahl: 26,
  },
  {
    id: 332,
    Jahr: 2019,
    Kategorie: 'Geschäftsliegenschaften',
    Energietraeger: 'Gas_zentral',
    Anzahl: 26,
  },
  {
    id: 333,
    Jahr: 2019,
    Kategorie: 'Geschäftsliegenschaften',
    Energietraeger: 'Gas_einzel',
    Anzahl: 0,
  },
  {
    id: 334,
    Jahr: 2019,
    Kategorie: 'Geschäftsliegenschaften',
    Energietraeger: 'Öl',
    Anzahl: 0,
  },
  {
    id: 335,
    Jahr: 2019,
    Kategorie: 'Geschäftsliegenschaften',
    Energietraeger: 'Öl_zentral',
    Anzahl: 0,
  },
  {
    id: 336,
    Jahr: 2019,
    Kategorie: 'Geschäftsliegenschaften',
    Energietraeger: 'Öl_einzel',
    Anzahl: 0,
  },
  {
    id: 337,
    Jahr: 2019,
    Kategorie: 'Geschäftsliegenschaften',
    Energietraeger: 'BHKW (=Blockheizkraftwerk)',
    Anzahl: 0,
  },
  {
    id: 338,
    Jahr: 2019,
    Kategorie: 'Geschäftsliegenschaften',
    Energietraeger: 'Elektrizität',
    Anzahl: 4,
  },
  {
    id: 339,
    Jahr: 2019,
    Kategorie: 'Geschäftsliegenschaften',
    Energietraeger: 'Fernwärme',
    Anzahl: 11,
  },
  {
    id: 340,
    Jahr: 2019,
    Kategorie: 'Geschäftsliegenschaften',
    Energietraeger: 'Wärmepumpen',
    Anzahl: 3,
  },
  {
    id: 341,
    Jahr: 2019,
    Kategorie: 'Geschäftsliegenschaften',
    Energietraeger: 'Holz',
    Anzahl: 4,
  },
  {
    id: 342,
    Jahr: 2019,
    Kategorie: 'Geschäftsliegenschaften',
    Energietraeger: 'Holz_zentral',
    Anzahl: 2,
  },
  {
    id: 343,
    Jahr: 2019,
    Kategorie: 'Geschäftsliegenschaften',
    Energietraeger: 'Holz_einzel',
    Anzahl: 2,
  },
  {
    id: 344,
    Jahr: 2019,
    Kategorie: 'Geschäftsliegenschaften',
    Energietraeger: 'Photovoltaikanlagen',
    Anzahl: 1,
  },
  {
    id: 345,
    Jahr: 2019,
    Kategorie: 'Geschäftsliegenschaften',
    Energietraeger: 'Solaranlagen',
    Anzahl: 0,
  },
  {
    id: 346,
    Jahr: 2019,
    Kategorie: 'Landwirtschaft',
    Energietraeger: 'Gas',
    Anzahl: 0,
  },
  {
    id: 347,
    Jahr: 2019,
    Kategorie: 'Landwirtschaft',
    Energietraeger: 'Gas_zentral',
    Anzahl: 0,
  },
  {
    id: 348,
    Jahr: 2019,
    Kategorie: 'Landwirtschaft',
    Energietraeger: 'Gas_einzel',
    Anzahl: 0,
  },
  {
    id: 349,
    Jahr: 2019,
    Kategorie: 'Landwirtschaft',
    Energietraeger: 'Öl',
    Anzahl: 1,
  },
  {
    id: 350,
    Jahr: 2019,
    Kategorie: 'Landwirtschaft',
    Energietraeger: 'Öl_zentral',
    Anzahl: 1,
  },
  {
    id: 351,
    Jahr: 2019,
    Kategorie: 'Landwirtschaft',
    Energietraeger: 'Öl_einzel',
    Anzahl: 0,
  },
  {
    id: 352,
    Jahr: 2019,
    Kategorie: 'Landwirtschaft',
    Energietraeger: 'BHKW (=Blockheizkraftwerk)',
    Anzahl: 0,
  },
  {
    id: 353,
    Jahr: 2019,
    Kategorie: 'Landwirtschaft',
    Energietraeger: 'Elektrizität',
    Anzahl: 0,
  },
  {
    id: 354,
    Jahr: 2019,
    Kategorie: 'Landwirtschaft',
    Energietraeger: 'Fernwärme',
    Anzahl: 0,
  },
  {
    id: 355,
    Jahr: 2019,
    Kategorie: 'Landwirtschaft',
    Energietraeger: 'Wärmepumpen',
    Anzahl: 1,
  },
  {
    id: 356,
    Jahr: 2019,
    Kategorie: 'Landwirtschaft',
    Energietraeger: 'Holz',
    Anzahl: 1,
  },
  {
    id: 357,
    Jahr: 2019,
    Kategorie: 'Landwirtschaft',
    Energietraeger: 'Holz_zentral',
    Anzahl: 1,
  },
  {
    id: 358,
    Jahr: 2019,
    Kategorie: 'Landwirtschaft',
    Energietraeger: 'Holz_einzel',
    Anzahl: 0,
  },
  {
    id: 359,
    Jahr: 2019,
    Kategorie: 'Landwirtschaft',
    Energietraeger: 'Photovoltaikanlagen',
    Anzahl: 1,
  },
  {
    id: 360,
    Jahr: 2019,
    Kategorie: 'Landwirtschaft',
    Energietraeger: 'Solaranlagen',
    Anzahl: 0,
  },
  {
    id: 361,
    Jahr: 2020,
    Kategorie: 'Wohnliegenschaften',
    Energietraeger: 'Gas',
    Anzahl: 240,
  },
  {
    id: 362,
    Jahr: 2020,
    Kategorie: 'Wohnliegenschaften',
    Energietraeger: 'Gas_zentral',
    Anzahl: 113,
  },
  {
    id: 363,
    Jahr: 2020,
    Kategorie: 'Wohnliegenschaften',
    Energietraeger: 'Gas_einzel',
    Anzahl: 127,
  },
  {
    id: 364,
    Jahr: 2020,
    Kategorie: 'Wohnliegenschaften',
    Energietraeger: 'Öl',
    Anzahl: 148,
  },
  {
    id: 365,
    Jahr: 2020,
    Kategorie: 'Wohnliegenschaften',
    Energietraeger: 'Öl_zentral',
    Anzahl: 18,
  },
  {
    id: 366,
    Jahr: 2020,
    Kategorie: 'Wohnliegenschaften',
    Energietraeger: 'Öl_einzel',
    Anzahl: 130,
  },
  {
    id: 367,
    Jahr: 2020,
    Kategorie: 'Wohnliegenschaften',
    Energietraeger: 'BHKW (=Blockheizkraftwerk)',
    Anzahl: 1,
  },
  {
    id: 368,
    Jahr: 2020,
    Kategorie: 'Wohnliegenschaften',
    Energietraeger: 'Elektrizität',
    Anzahl: 2,
  },
  {
    id: 369,
    Jahr: 2020,
    Kategorie: 'Wohnliegenschaften',
    Energietraeger: 'Fernwärme',
    Anzahl: 86,
  },
  {
    id: 370,
    Jahr: 2020,
    Kategorie: 'Wohnliegenschaften',
    Energietraeger: 'Wärmepumpen',
    Anzahl: 23,
  },
  {
    id: 371,
    Jahr: 2020,
    Kategorie: 'Wohnliegenschaften',
    Energietraeger: 'Holz',
    Anzahl: 129,
  },
  {
    id: 372,
    Jahr: 2020,
    Kategorie: 'Wohnliegenschaften',
    Energietraeger: 'Holz_zentral',
    Anzahl: 1,
  },
  {
    id: 373,
    Jahr: 2020,
    Kategorie: 'Wohnliegenschaften',
    Energietraeger: 'Holz_einzel',
    Anzahl: 128,
  },
  {
    id: 374,
    Jahr: 2020,
    Kategorie: 'Wohnliegenschaften',
    Energietraeger: 'Photovoltaikanlagen',
    Anzahl: 22,
  },
  {
    id: 375,
    Jahr: 2020,
    Kategorie: 'Wohnliegenschaften',
    Energietraeger: 'Solaranlagen',
    Anzahl: 12,
  },
  {
    id: 376,
    Jahr: 2020,
    Kategorie: 'Geschäftsliegenschaften',
    Energietraeger: 'Gas',
    Anzahl: 25,
  },
  {
    id: 377,
    Jahr: 2020,
    Kategorie: 'Geschäftsliegenschaften',
    Energietraeger: 'Gas_zentral',
    Anzahl: 25,
  },
  {
    id: 378,
    Jahr: 2020,
    Kategorie: 'Geschäftsliegenschaften',
    Energietraeger: 'Gas_einzel',
    Anzahl: 0,
  },
  {
    id: 379,
    Jahr: 2020,
    Kategorie: 'Geschäftsliegenschaften',
    Energietraeger: 'Öl',
    Anzahl: 1,
  },
  {
    id: 380,
    Jahr: 2020,
    Kategorie: 'Geschäftsliegenschaften',
    Energietraeger: 'Öl_zentral',
    Anzahl: 1,
  },
  {
    id: 381,
    Jahr: 2020,
    Kategorie: 'Geschäftsliegenschaften',
    Energietraeger: 'Öl_einzel',
    Anzahl: 0,
  },
  {
    id: 382,
    Jahr: 2020,
    Kategorie: 'Geschäftsliegenschaften',
    Energietraeger: 'BHKW (=Blockheizkraftwerk)',
    Anzahl: 0,
  },
  {
    id: 383,
    Jahr: 2020,
    Kategorie: 'Geschäftsliegenschaften',
    Energietraeger: 'Elektrizität',
    Anzahl: 4,
  },
  {
    id: 384,
    Jahr: 2020,
    Kategorie: 'Geschäftsliegenschaften',
    Energietraeger: 'Fernwärme',
    Anzahl: 11,
  },
  {
    id: 385,
    Jahr: 2020,
    Kategorie: 'Geschäftsliegenschaften',
    Energietraeger: 'Wärmepumpen',
    Anzahl: 3,
  },
  {
    id: 386,
    Jahr: 2020,
    Kategorie: 'Geschäftsliegenschaften',
    Energietraeger: 'Holz',
    Anzahl: 4,
  },
  {
    id: 387,
    Jahr: 2020,
    Kategorie: 'Geschäftsliegenschaften',
    Energietraeger: 'Holz_zentral',
    Anzahl: 2,
  },
  {
    id: 388,
    Jahr: 2020,
    Kategorie: 'Geschäftsliegenschaften',
    Energietraeger: 'Holz_einzel',
    Anzahl: 2,
  },
  {
    id: 389,
    Jahr: 2020,
    Kategorie: 'Geschäftsliegenschaften',
    Energietraeger: 'Photovoltaikanlagen',
    Anzahl: 1,
  },
  {
    id: 390,
    Jahr: 2020,
    Kategorie: 'Geschäftsliegenschaften',
    Energietraeger: 'Solaranlagen',
    Anzahl: 0,
  },
  {
    id: 391,
    Jahr: 2020,
    Kategorie: 'Landwirtschaft',
    Energietraeger: 'Gas',
    Anzahl: 0,
  },
  {
    id: 392,
    Jahr: 2020,
    Kategorie: 'Landwirtschaft',
    Energietraeger: 'Gas_zentral',
    Anzahl: 0,
  },
  {
    id: 393,
    Jahr: 2020,
    Kategorie: 'Landwirtschaft',
    Energietraeger: 'Gas_einzel',
    Anzahl: 0,
  },
  {
    id: 394,
    Jahr: 2020,
    Kategorie: 'Landwirtschaft',
    Energietraeger: 'Öl',
    Anzahl: 1,
  },
  {
    id: 395,
    Jahr: 2020,
    Kategorie: 'Landwirtschaft',
    Energietraeger: 'Öl_zentral',
    Anzahl: 1,
  },
  {
    id: 396,
    Jahr: 2020,
    Kategorie: 'Landwirtschaft',
    Energietraeger: 'Öl_einzel',
    Anzahl: 0,
  },
  {
    id: 397,
    Jahr: 2020,
    Kategorie: 'Landwirtschaft',
    Energietraeger: 'BHKW (=Blockheizkraftwerk)',
    Anzahl: 0,
  },
  {
    id: 398,
    Jahr: 2020,
    Kategorie: 'Landwirtschaft',
    Energietraeger: 'Elektrizität',
    Anzahl: 0,
  },
  {
    id: 399,
    Jahr: 2020,
    Kategorie: 'Landwirtschaft',
    Energietraeger: 'Fernwärme',
    Anzahl: 0,
  },
  {
    id: 400,
    Jahr: 2020,
    Kategorie: 'Landwirtschaft',
    Energietraeger: 'Wärmepumpen',
    Anzahl: 1,
  },
  {
    id: 401,
    Jahr: 2020,
    Kategorie: 'Landwirtschaft',
    Energietraeger: 'Holz',
    Anzahl: 1,
  },
  {
    id: 402,
    Jahr: 2020,
    Kategorie: 'Landwirtschaft',
    Energietraeger: 'Holz_zentral',
    Anzahl: 1,
  },
  {
    id: 403,
    Jahr: 2020,
    Kategorie: 'Landwirtschaft',
    Energietraeger: 'Holz_einzel',
    Anzahl: 0,
  },
  {
    id: 404,
    Jahr: 2020,
    Kategorie: 'Landwirtschaft',
    Energietraeger: 'Photovoltaikanlagen',
    Anzahl: 1,
  },
  {
    id: 405,
    Jahr: 2020,
    Kategorie: 'Landwirtschaft',
    Energietraeger: 'Solaranlagen',
    Anzahl: 0,
  },
  {
    id: 406,
    Jahr: 2021,
    Kategorie: 'Wohnliegenschaften',
    Energietraeger: 'Gas',
    Anzahl: 240,
  },
  {
    id: 407,
    Jahr: 2021,
    Kategorie: 'Wohnliegenschaften',
    Energietraeger: 'Gas_zentral',
    Anzahl: 113,
  },
  {
    id: 408,
    Jahr: 2021,
    Kategorie: 'Wohnliegenschaften',
    Energietraeger: 'Gas_einzel',
    Anzahl: 127,
  },
  {
    id: 409,
    Jahr: 2021,
    Kategorie: 'Wohnliegenschaften',
    Energietraeger: 'Öl',
    Anzahl: 148,
  },
  {
    id: 410,
    Jahr: 2021,
    Kategorie: 'Wohnliegenschaften',
    Energietraeger: 'Öl_zentral',
    Anzahl: 18,
  },
  {
    id: 411,
    Jahr: 2021,
    Kategorie: 'Wohnliegenschaften',
    Energietraeger: 'Öl_einzel',
    Anzahl: 130,
  },
  {
    id: 412,
    Jahr: 2021,
    Kategorie: 'Wohnliegenschaften',
    Energietraeger: 'BHKW (=Blockheizkraftwerk)',
    Anzahl: 1,
  },
  {
    id: 413,
    Jahr: 2021,
    Kategorie: 'Wohnliegenschaften',
    Energietraeger: 'Elektrizität',
    Anzahl: 2,
  },
  {
    id: 414,
    Jahr: 2021,
    Kategorie: 'Wohnliegenschaften',
    Energietraeger: 'Fernwärme',
    Anzahl: 86,
  },
  {
    id: 415,
    Jahr: 2021,
    Kategorie: 'Wohnliegenschaften',
    Energietraeger: 'Wärmepumpen',
    Anzahl: 23,
  },
  {
    id: 416,
    Jahr: 2021,
    Kategorie: 'Wohnliegenschaften',
    Energietraeger: 'Holz',
    Anzahl: 129,
  },
  {
    id: 417,
    Jahr: 2021,
    Kategorie: 'Wohnliegenschaften',
    Energietraeger: 'Holz_zentral',
    Anzahl: 1,
  },
  {
    id: 418,
    Jahr: 2021,
    Kategorie: 'Wohnliegenschaften',
    Energietraeger: 'Holz_einzel',
    Anzahl: 128,
  },
  {
    id: 419,
    Jahr: 2021,
    Kategorie: 'Wohnliegenschaften',
    Energietraeger: 'Photovoltaikanlagen',
    Anzahl: 24,
  },
  {
    id: 420,
    Jahr: 2021,
    Kategorie: 'Wohnliegenschaften',
    Energietraeger: 'Solaranlagen',
    Anzahl: 12,
  },
  {
    id: 421,
    Jahr: 2021,
    Kategorie: 'Geschäftsliegenschaften',
    Energietraeger: 'Gas',
    Anzahl: 25,
  },
  {
    id: 422,
    Jahr: 2021,
    Kategorie: 'Geschäftsliegenschaften',
    Energietraeger: 'Gas_zentral',
    Anzahl: 25,
  },
  {
    id: 423,
    Jahr: 2021,
    Kategorie: 'Geschäftsliegenschaften',
    Energietraeger: 'Gas_einzel',
    Anzahl: 0,
  },
  {
    id: 424,
    Jahr: 2021,
    Kategorie: 'Geschäftsliegenschaften',
    Energietraeger: 'Öl',
    Anzahl: 1,
  },
  {
    id: 425,
    Jahr: 2021,
    Kategorie: 'Geschäftsliegenschaften',
    Energietraeger: 'Öl_zentral',
    Anzahl: 1,
  },
  {
    id: 426,
    Jahr: 2021,
    Kategorie: 'Geschäftsliegenschaften',
    Energietraeger: 'Öl_einzel',
    Anzahl: 0,
  },
  {
    id: 427,
    Jahr: 2021,
    Kategorie: 'Geschäftsliegenschaften',
    Energietraeger: 'BHKW (=Blockheizkraftwerk)',
    Anzahl: 0,
  },
  {
    id: 428,
    Jahr: 2021,
    Kategorie: 'Geschäftsliegenschaften',
    Energietraeger: 'Elektrizität',
    Anzahl: 4,
  },
  {
    id: 429,
    Jahr: 2021,
    Kategorie: 'Geschäftsliegenschaften',
    Energietraeger: 'Fernwärme',
    Anzahl: 11,
  },
  {
    id: 430,
    Jahr: 2021,
    Kategorie: 'Geschäftsliegenschaften',
    Energietraeger: 'Wärmepumpen',
    Anzahl: 3,
  },
  {
    id: 431,
    Jahr: 2021,
    Kategorie: 'Geschäftsliegenschaften',
    Energietraeger: 'Holz',
    Anzahl: 4,
  },
  {
    id: 432,
    Jahr: 2021,
    Kategorie: 'Geschäftsliegenschaften',
    Energietraeger: 'Holz_zentral',
    Anzahl: 2,
  },
  {
    id: 433,
    Jahr: 2021,
    Kategorie: 'Geschäftsliegenschaften',
    Energietraeger: 'Holz_einzel',
    Anzahl: 2,
  },
  {
    id: 434,
    Jahr: 2021,
    Kategorie: 'Geschäftsliegenschaften',
    Energietraeger: 'Photovoltaikanlagen',
    Anzahl: 1,
  },
  {
    id: 435,
    Jahr: 2021,
    Kategorie: 'Geschäftsliegenschaften',
    Energietraeger: 'Solaranlagen',
    Anzahl: 0,
  },
  {
    id: 436,
    Jahr: 2021,
    Kategorie: 'Landwirtschaft',
    Energietraeger: 'Gas',
    Anzahl: 0,
  },
  {
    id: 437,
    Jahr: 2021,
    Kategorie: 'Landwirtschaft',
    Energietraeger: 'Gas_zentral',
    Anzahl: 0,
  },
  {
    id: 438,
    Jahr: 2021,
    Kategorie: 'Landwirtschaft',
    Energietraeger: 'Gas_einzel',
    Anzahl: 0,
  },
  {
    id: 439,
    Jahr: 2021,
    Kategorie: 'Landwirtschaft',
    Energietraeger: 'Öl',
    Anzahl: 1,
  },
  {
    id: 440,
    Jahr: 2021,
    Kategorie: 'Landwirtschaft',
    Energietraeger: 'Öl_zentral',
    Anzahl: 1,
  },
  {
    id: 441,
    Jahr: 2021,
    Kategorie: 'Landwirtschaft',
    Energietraeger: 'Öl_einzel',
    Anzahl: 0,
  },
  {
    id: 442,
    Jahr: 2021,
    Kategorie: 'Landwirtschaft',
    Energietraeger: 'BHKW (=Blockheizkraftwerk)',
    Anzahl: 0,
  },
  {
    id: 443,
    Jahr: 2021,
    Kategorie: 'Landwirtschaft',
    Energietraeger: 'Elektrizität',
    Anzahl: 0,
  },
  {
    id: 444,
    Jahr: 2021,
    Kategorie: 'Landwirtschaft',
    Energietraeger: 'Fernwärme',
    Anzahl: 0,
  },
  {
    id: 445,
    Jahr: 2021,
    Kategorie: 'Landwirtschaft',
    Energietraeger: 'Wärmepumpen',
    Anzahl: 1,
  },
  {
    id: 446,
    Jahr: 2021,
    Kategorie: 'Landwirtschaft',
    Energietraeger: 'Holz',
    Anzahl: 1,
  },
  {
    id: 447,
    Jahr: 2021,
    Kategorie: 'Landwirtschaft',
    Energietraeger: 'Holz_zentral',
    Anzahl: 1,
  },
  {
    id: 448,
    Jahr: 2021,
    Kategorie: 'Landwirtschaft',
    Energietraeger: 'Holz_einzel',
    Anzahl: 0,
  },
  {
    id: 449,
    Jahr: 2021,
    Kategorie: 'Landwirtschaft',
    Energietraeger: 'Photovoltaikanlagen',
    Anzahl: 1,
  },
  {
    id: 450,
    Jahr: 2021,
    Kategorie: 'Landwirtschaft',
    Energietraeger: 'Solaranlagen',
    Anzahl: 0,
  },
];
