export const progPhotovoltaikFv = [
  {
    id: 1,
    Jahr: 2012,
    Anzahl: 1,
  },
  {
    id: 2,
    Jahr: 2013,
    Anzahl: 1,
  },
  {
    id: 3,
    Jahr: 2014,
    Anzahl: 2,
  },
  {
    id: 4,
    Jahr: 2015,
    Anzahl: 2,
  },
  {
    id: 5,
    Jahr: 2016,
    Anzahl: 6,
  },
  {
    id: 6,
    Jahr: 2017,
    Anzahl: 8,
  },
  {
    id: 7,
    Jahr: 2018,
    Anzahl: 13,
  },
  {
    id: 8,
    Jahr: 2019,
    Anzahl: 21,
  },
  {
    id: 9,
    Jahr: 2020,
    Anzahl: 23,
  },
  {
    id: 10,
    Jahr: 2021,
    Anzahl: 25,
  },
  {
    id: 11,
    Jahr: 2022,
    Anzahl: 33,
  },
  {
    id: 12,
    Jahr: 2023,
    Anzahl: 34,
  },
  {
    id: 13,
    Jahr: 2024,
    Anzahl: 36,
  },
  {
    id: 14,
    Jahr: 2025,
    Anzahl: 44,
  },
  {
    id: 15,
    Jahr: 2026,
    Anzahl: 47,
  },
  {
    id: 16,
    Jahr: 2027,
    Anzahl: 50,
  },
  {
    id: 17,
    Jahr: 2028,
    Anzahl: 53,
  },
  {
    id: 18,
    Jahr: 2029,
    Anzahl: 55,
  },
  {
    id: 19,
    Jahr: 2030,
    Anzahl: 57,
  },
  {
    id: 20,
    Jahr: 2031,
    Anzahl: 60,
  },
  {
    id: 21,
    Jahr: 2032,
    Anzahl: 63,
  },
  {
    id: 22,
    Jahr: 2033,
    Anzahl: 65,
  },
  {
    id: 23,
    Jahr: 2034,
    Anzahl: 67,
  },
  {
    id: 24,
    Jahr: 2035,
    Anzahl: 70,
  },
];
