export const denkmalpflegeVv = [
  {
    id: 1,
    Jahr: 2018,
    Kategorie: 'Verwaltungsgebäude',
    Beachtenswert: 1,
    Erhaltenswert: 2,
    Schuetzenswert: 3,
    Nicht_Inventarisiert: 3,
    WorldHeritage: 8,
    Gebaeude_Nach_1990: 0,
  },
  {
    id: 2,
    Jahr: 2018,
    Kategorie: 'Schulen',
    Beachtenswert: 43,
    Erhaltenswert: 34,
    Schuetzenswert: 74,
    Nicht_Inventarisiert: 34,
    WorldHeritage: 12,
    Gebaeude_Nach_1990: 6,
  },
  {
    id: 3,
    Jahr: 2018,
    Kategorie: 'Sportanlagen',
    Beachtenswert: 1,
    Erhaltenswert: 2,
    Schuetzenswert: 0,
    Nicht_Inventarisiert: 11,
    WorldHeritage: 0,
    Gebaeude_Nach_1990: 3,
  },
  {
    id: 4,
    Jahr: 2018,
    Kategorie: 'Eis-Wasseranlagen',
    Beachtenswert: 2,
    Erhaltenswert: 1,
    Schuetzenswert: 3,
    Nicht_Inventarisiert: 4,
    WorldHeritage: 0,
    Gebaeude_Nach_1990: 0,
  },
  {
    id: 5,
    Jahr: 2018,
    Kategorie: 'Kulturgebäude',
    Beachtenswert: 0,
    Erhaltenswert: 0,
    Schuetzenswert: 1,
    Nicht_Inventarisiert: 0,
    WorldHeritage: 5,
    Gebaeude_Nach_1990: 0,
  },
  {
    id: 6,
    Jahr: 2018,
    Kategorie: 'Werkhöfe',
    Beachtenswert: 3,
    Erhaltenswert: 0,
    Schuetzenswert: 0,
    Nicht_Inventarisiert: 18,
    WorldHeritage: 0,
    Gebaeude_Nach_1990: 0,
  },
  {
    id: 7,
    Jahr: 2018,
    Kategorie: 'Jugend-/Quartiertreffs',
    Beachtenswert: 2,
    Erhaltenswert: 1,
    Schuetzenswert: 6,
    Nicht_Inventarisiert: 3,
    WorldHeritage: 0,
    Gebaeude_Nach_1990: 0,
  },
  {
    id: 8,
    Jahr: 2018,
    Kategorie: 'Wohnhäuser/-heime',
    Beachtenswert: 1,
    Erhaltenswert: 0,
    Schuetzenswert: 1,
    Nicht_Inventarisiert: 6,
    WorldHeritage: 1,
    Gebaeude_Nach_1990: 0,
  },
  {
    id: 9,
    Jahr: 2019,
    Kategorie: 'Verwaltungsgebäude',
    Beachtenswert: 0,
    Erhaltenswert: 2,
    Schuetzenswert: 3,
    Nicht_Inventarisiert: 3,
    WorldHeritage: 8,
    Gebaeude_Nach_1990: 0,
  },
  {
    id: 10,
    Jahr: 2019,
    Kategorie: 'Schulen',
    Beachtenswert: 37,
    Erhaltenswert: 33,
    Schuetzenswert: 86,
    Nicht_Inventarisiert: 39,
    WorldHeritage: 3,
    Gebaeude_Nach_1990: 6,
  },
  {
    id: 11,
    Jahr: 2019,
    Kategorie: 'Sportanlagen',
    Beachtenswert: 1,
    Erhaltenswert: 0,
    Schuetzenswert: 0,
    Nicht_Inventarisiert: 15,
    WorldHeritage: 0,
    Gebaeude_Nach_1990: 3,
  },
  {
    id: 12,
    Jahr: 2019,
    Kategorie: 'Eis-Wasseranlagen',
    Beachtenswert: 2,
    Erhaltenswert: 1,
    Schuetzenswert: 3,
    Nicht_Inventarisiert: 4,
    WorldHeritage: 0,
    Gebaeude_Nach_1990: 0,
  },
  {
    id: 13,
    Jahr: 2019,
    Kategorie: 'Kulturgebäude',
    Beachtenswert: 0,
    Erhaltenswert: 0,
    Schuetzenswert: 1,
    Nicht_Inventarisiert: 0,
    WorldHeritage: 5,
    Gebaeude_Nach_1990: 0,
  },
  {
    id: 14,
    Jahr: 2019,
    Kategorie: 'Werkhöfe',
    Beachtenswert: 3,
    Erhaltenswert: 0,
    Schuetzenswert: 0,
    Nicht_Inventarisiert: 17,
    WorldHeritage: 0,
    Gebaeude_Nach_1990: 0,
  },
  {
    id: 15,
    Jahr: 2019,
    Kategorie: 'Jugend-/Quartiertreffs',
    Beachtenswert: 2,
    Erhaltenswert: 1,
    Schuetzenswert: 6,
    Nicht_Inventarisiert: 3,
    WorldHeritage: 0,
    Gebaeude_Nach_1990: 0,
  },
  {
    id: 16,
    Jahr: 2019,
    Kategorie: 'Wohnhäuser/-heime',
    Beachtenswert: 1,
    Erhaltenswert: 1,
    Schuetzenswert: 2,
    Nicht_Inventarisiert: 6,
    WorldHeritage: 1,
    Gebaeude_Nach_1990: 0,
  },
  {
    id: 17,
    Jahr: 2019,
    Kategorie: 'Restaurants',
    Beachtenswert: 0,
    Erhaltenswert: 0,
    Schuetzenswert: 2,
    Nicht_Inventarisiert: 1,
    WorldHeritage: 1,
    Gebaeude_Nach_1990: 0,
  },
  {
    id: 18,
    Jahr: 2020,
    Kategorie: 'Verwaltungsgebäude',
    Beachtenswert: 0,
    Erhaltenswert: 2,
    Schuetzenswert: 3,
    Nicht_Inventarisiert: 3,
    WorldHeritage: 8,
    Gebaeude_Nach_1990: 0,
  },
  {
    id: 19,
    Jahr: 2020,
    Kategorie: 'Schulen',
    Beachtenswert: 38,
    Erhaltenswert: 36,
    Schuetzenswert: 77,
    Nicht_Inventarisiert: 34,
    WorldHeritage: 12,
    Gebaeude_Nach_1990: 6,
  },
  {
    id: 20,
    Jahr: 2020,
    Kategorie: 'Sportanlagen',
    Beachtenswert: 1,
    Erhaltenswert: 1,
    Schuetzenswert: 0,
    Nicht_Inventarisiert: 11,
    WorldHeritage: 0,
    Gebaeude_Nach_1990: 3,
  },
  {
    id: 21,
    Jahr: 2020,
    Kategorie: 'Eis-Wasseranlagen',
    Beachtenswert: 2,
    Erhaltenswert: 1,
    Schuetzenswert: 3,
    Nicht_Inventarisiert: 4,
    WorldHeritage: 0,
    Gebaeude_Nach_1990: 0,
  },
  {
    id: 22,
    Jahr: 2020,
    Kategorie: 'Kulturgebäude',
    Beachtenswert: 0,
    Erhaltenswert: 1,
    Schuetzenswert: 5,
    Nicht_Inventarisiert: 0,
    WorldHeritage: 5,
    Gebaeude_Nach_1990: 0,
  },
  {
    id: 23,
    Jahr: 2020,
    Kategorie: 'Werkhöfe',
    Beachtenswert: 3,
    Erhaltenswert: 2,
    Schuetzenswert: 3,
    Nicht_Inventarisiert: 25,
    WorldHeritage: 0,
    Gebaeude_Nach_1990: 2,
  },
  {
    id: 24,
    Jahr: 2020,
    Kategorie: 'Jugend-/Quartiertreffs',
    Beachtenswert: 2,
    Erhaltenswert: 1,
    Schuetzenswert: 6,
    Nicht_Inventarisiert: 3,
    WorldHeritage: 0,
    Gebaeude_Nach_1990: 0,
  },
  {
    id: 25,
    Jahr: 2020,
    Kategorie: 'Wohnhäuser/-heime',
    Beachtenswert: 1,
    Erhaltenswert: 1,
    Schuetzenswert: 1,
    Nicht_Inventarisiert: 6,
    WorldHeritage: 1,
    Gebaeude_Nach_1990: 0,
  },
  {
    id: 26,
    Jahr: 2020,
    Kategorie: 'Restaurants',
    Beachtenswert: 0,
    Erhaltenswert: 0,
    Schuetzenswert: 2,
    Nicht_Inventarisiert: 1,
    WorldHeritage: 1,
    Gebaeude_Nach_1990: 0,
  },
  {
    id: 27,
    Jahr: 2021,
    Kategorie: 'Verwaltungsgebäude',
    Beachtenswert: 0,
    Erhaltenswert: 2,
    Schuetzenswert: 3,
    Nicht_Inventarisiert: 4,
    WorldHeritage: 8,
    Gebaeude_Nach_1990: 0,
  },
  {
    id: 28,
    Jahr: 2021,
    Kategorie: 'Schulen',
    Beachtenswert: 37,
    Erhaltenswert: 36,
    Schuetzenswert: 67,
    Nicht_Inventarisiert: 36,
    WorldHeritage: 10,
    Gebaeude_Nach_1990: 7,
  },
  {
    id: 29,
    Jahr: 2021,
    Kategorie: 'Sportanlagen',
    Beachtenswert: 1,
    Erhaltenswert: 1,
    Schuetzenswert: 0,
    Nicht_Inventarisiert: 10,
    WorldHeritage: 0,
    Gebaeude_Nach_1990: 3,
  },
  {
    id: 30,
    Jahr: 2021,
    Kategorie: 'Eis-Wasseranlagen',
    Beachtenswert: 2,
    Erhaltenswert: 1,
    Schuetzenswert: 3,
    Nicht_Inventarisiert: 4,
    WorldHeritage: 0,
    Gebaeude_Nach_1990: 0,
  },
  {
    id: 31,
    Jahr: 2021,
    Kategorie: 'Kulturgebäude',
    Beachtenswert: 0,
    Erhaltenswert: 0,
    Schuetzenswert: 1,
    Nicht_Inventarisiert: 0,
    WorldHeritage: 5,
    Gebaeude_Nach_1990: 0,
  },
  {
    id: 32,
    Jahr: 2021,
    Kategorie: 'Werkhöfe',
    Beachtenswert: 4,
    Erhaltenswert: 3,
    Schuetzenswert: 3,
    Nicht_Inventarisiert: 26,
    WorldHeritage: 0,
    Gebaeude_Nach_1990: 2,
  },
  {
    id: 33,
    Jahr: 2021,
    Kategorie: 'Jugend-/Quartiertreffs',
    Beachtenswert: 2,
    Erhaltenswert: 1,
    Schuetzenswert: 6,
    Nicht_Inventarisiert: 3,
    WorldHeritage: 0,
    Gebaeude_Nach_1990: 0,
  },
  {
    id: 34,
    Jahr: 2021,
    Kategorie: 'Wohnhäuser/-heime',
    Beachtenswert: 3,
    Erhaltenswert: 1,
    Schuetzenswert: 1,
    Nicht_Inventarisiert: 6,
    WorldHeritage: 2,
    Gebaeude_Nach_1990: 0,
  },
  {
    id: 35,
    Jahr: 2021,
    Kategorie: 'Restaurants',
    Beachtenswert: 0,
    Erhaltenswert: 0,
    Schuetzenswert: 2,
    Nicht_Inventarisiert: 1,
    WorldHeritage: 1,
    Gebaeude_Nach_1990: 0,
  },
];
