import { Component, OnInit } from '@angular/core';
import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
import { Label } from 'ng2-charts';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { ChartColorService } from '../shared/chart-color.service';
import { InfoModalComponent } from '../info-modal/info-modal.component';
import { SmallScreenFilterLegendComponent } from '../smallScreenFilterLegend/smallScreenFilterLegend.component';
import { ernEnergienFv } from './ern-energien-fv.data';
import { ernEnergienVv } from './ern-energien-vv.data';
import { ernEnergienVvNeu } from './ern-energien-vv-neu.data';

@Component({
  selector: 'nim-ern-energien-barchart',
  templateUrl: './ern-energien-barchart.component.html',
  styleUrls: ['./ern-energien-barchart.component.css'],
})
export class ErnEnergienBarchartComponent implements OnInit {
  public barChartOptions: ChartOptions = {
    maintainAspectRatio: false,
    layout: { padding: { bottom: 30 } },
    legend: {
      display: false,
    },
    hover: { mode: null },
    scales: {
      xAxes: [
        {
          stacked: true,

          ticks: {
            maxRotation: 0,
            autoSkip: false,
            padding: 30,
          },
        },
      ],
      yAxes: [
        {
          scaleLabel: {
            display: true,
            labelString: 'Anzahl Objekte',
          },
          ticks: { beginAtZero: true },
        },
      ],
    },
    plugins: {
      datalabels: {
        anchor: 'start',
        align: 'start',
        rotation: 45,
        clip: false,
        formatter(value, context) {
          if (context.datasetIndex % context.dataset.hitRadius[0] === 0)
            return context.dataset.stack;
          return '';
        },
      },
    },
    tooltips: {
      mode: 'x',
    },
  };

  public barChartType: ChartType = 'bar';

  public barChartLegend = true;

  public barChartPlugins = [pluginDataLabels];

  barChartLabels: Label[];

  barChartData: ChartDataSets[];

  public barChartColors;

  public barChartLabelsFV: Label[] = [
    'Wohnliegenschaften',
    'Geschäftsliegenschaften',
    'Landwirtschaft',
  ];

  public barChartLabelsVV: Label[] = [
    'Verwaltungsgebäude',
    'Bildung',
    'Sport und Freizeit',
    'Wohnen und Aufenthalt',
    'Kultur und Geselligkeit',
    'Infrastrukturbauten',
  ];

  public barChartDataVV: ChartDataSets[] = [];

  public barChartDataFV: ChartDataSets[] = [];

  public dataLoadedFV = false;

  public dataLoadedVV = false;

  allDataIsZeroFV = false;

  allDataIsZeroVV = false;

  labels = ['Erneuerbar', 'Nicht erneuerbar'];

  labelsFV = ['Erneuerbar', 'Nicht erneuerbar'];

  labelsVV = ['Erneuerbar', 'Nicht erneuerbar'];

  public useNewSystem: boolean;

  dataLabels = [
    ['Anz_Erneuerbar', 'Erneuerbar'],
    ['Anz_Nicht_Erneuerbar', 'Nicht erneuerbar'],
  ];

  categoriesVv = [
    'Verwaltungsgebäude',
    'Schulen',
    'Sportanlagen',
    'Eis-Wasseranlagen',
    'Kulturgebäude',
    'Werkhöfe',
    'Jugend-/Quartiertreffs',
    'Wohnhäuser/-heime',
    'Restaurants',
  ];

  categoriesFv = [
    'Wohnliegenschaften',
    'Geschäftsliegenschaften',
    'Landwirtschaft',
  ];

  categoriesVvAlt = [
    'Verwaltungsgebäude',
    'Schulen',
    'Sportanlagen',
    'Eis-Wasseranlagen',
    'Kulturgebäude',
    'Werkhöfe',
    'Jugend-/Quartiertreffs',
    'Wohnhäuser/-heime',
    'Restaurants',
  ];

  categoriesVvNeu = [
    'Verwaltungsgebäude',
    'Bildung',
    'Sport und Freizeit',
    'Wohnen und Aufenthalt',
    'Kultur und Geselligkeit',
    'Infrastrukturbauten',
  ];

  categoriesFvFiltered = [];

  categoriesVvFiltered = [];

  yearsVv = [];

  yearsFv = [];

  yearsFvFiltered = [];

  yearsVvFiltered = [];

  newSystemYears = [2019, 2020, 2021];

  oldSystemYears = [];

  public labelsFilteredFV = [];

  public labelsFilteredVV = [];

  dataFV;

  dataVV;

  dataVVneu;

  chartHeight = this.getChartHeight();

  constructor(
    private chartColorService: ChartColorService,
    public dialog: MatDialog,
    private breakpointObserver: BreakpointObserver,
    private smallScreenFilterDialog: MatDialog,
  ) {}

  public smallScreen: boolean;

  ngOnInit(): void {
    this.barChartColors = this.chartColorService.getEnergyChartColors();
    this.getFvData();
    this.getVvData();
    this.getVvNeuData();
    this.checkIfSmallScreen();
  }

  private getFvData() {
    const res = ernEnergienFv;
    this.dataFV = res;
    this.getYearsFV();
    this.prepDataFv(this.categoriesFv, this.labels, res, this.yearsFv);
    setTimeout(() => {
      this.dataLoadedFV = true;
    }, 500);
  }

  private getVvData() {
    const res = ernEnergienVv;
    this.dataVV = res;
    this.getYearsVV();
  }

  private getVvNeuData() {
    const res = ernEnergienVvNeu;
    this.dataVVneu = res;
    this.useNewSystem = true;
    this.categoriesVv = this.categoriesVvNeu;
    this.yearsVv = this.newSystemYears;
    this.prepDataVv(this.categoriesVv, this.labels, res, this.yearsVv);
    this.dataLoadedVV = true;
  }

  private checkIfSmallScreen() {
    this.breakpointObserver
      .observe(['(min-width: 900px)'])
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          this.smallScreen = false;
        } else {
          this.smallScreen = true;
        }
      });
  }

  openDialog(asset: string) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    if (asset === 'FV') {
      dialogConfig.data = {
        labels: this.labels,
        years: this.yearsFv,
        categories: this.categoriesFv,
        labelsFiltered: this.labelsFilteredFV,
        yearsFiltered: this.yearsFvFiltered,
        categoriesFiltered: this.categoriesFvFiltered,
        showNewSystemFilter: false,
        showThirdFilter: true,
      };

      this.smallScreenFilterDialog
        .open(SmallScreenFilterLegendComponent, dialogConfig)
        .afterClosed()
        .subscribe((changes) => {
          if (changes) this.changeFilterFV(changes);
        });
    } else {
      dialogConfig.data = {
        labels: this.labels,
        years: this.yearsVv,
        categories: this.categoriesVv,
        labelsFiltered: this.labelsFilteredVV,
        yearsFiltered: this.yearsVvFiltered,
        categoriesFiltered: this.categoriesVvFiltered,
        showThirdFilter: true,
        showNewSystemFilter: true,
        useNewSystem: this.useNewSystem,
      };

      this.smallScreenFilterDialog
        .open(SmallScreenFilterLegendComponent, dialogConfig)
        .afterClosed()
        .subscribe((changes) => {
          if (changes) this.changeFilterVV(changes);
        });
    }
  }

  private getYearsFV() {
    this.dataFV.forEach((element) => {
      if (!this.yearsFv.includes(element.Jahr)) this.yearsFv.push(element.Jahr);
    });
  }

  private getYearsVV() {
    this.dataVV.forEach((element) => {
      if (!this.oldSystemYears.includes(element.Jahr))
        this.oldSystemYears.push(element.Jahr);
    });
  }

  private prepDataFv(categories: string[], labels: string[], data, years) {
    this.allDataIsZeroFV = true;
    this.barChartDataFV = [];
    years.forEach((j) => {
      labels.forEach((l) => {
        const dataSet = {
          label: l,
          data: [],
          stack: String(j),
          backgroundColor: this.getColor(l).backgroundColor,
          borderColor: 'rgba(0, 0, 0, 0)',
          hitRadius: [this.labelsFV.length],
        };
        const dataFiltered = data.filter((o) => o.Jahr === j);
        categories.forEach((c) => {
          if (dataFiltered.find((o) => o.Kategorie === c) !== undefined) {
            dataSet.data.push(
              dataFiltered.find((o) => o.Kategorie === c)[this.labelToDB(l)],
            );
          }
        });
        this.barChartDataFV.push(dataSet);
      });
    });
    this.checkIfFVDataCanBeVisualized();
  }

  private prepDataVv(categories: string[], labels: string[], data, years) {
    this.allDataIsZeroVV = true;
    this.barChartDataVV = [];
    years.forEach((j) => {
      labels.forEach((l) => {
        const dataSet = {
          label: l,
          data: [],
          stack: String(j),
          backgroundColor: this.getColor(l).backgroundColor,
          borderColor: 'rgba(0, 0, 0, 0)',
          hitRadius: [this.labelsVV.length],
        };
        const dataFiltered = data.filter((o) => o.Jahr === j);
        categories.forEach((c) => {
          if (dataFiltered.find((o) => o.Kategorie === c) !== undefined) {
            dataSet.data.push(
              dataFiltered.find((o) => o.Kategorie === c)[this.labelToDB(l)],
            );
          }
        });
        this.barChartDataVV.push(dataSet);
      });
    });
    this.checkIfVVDataCanBeVisualized();
  }

  private checkIfVVDataCanBeVisualized() {
    this.barChartDataVV.forEach((dataSet) => {
      if (dataSet.data.length !== 0) {
        this.allDataIsZeroVV = false;
      }
    });
    if (this.allDataIsZero(this.barChartDataVV)) this.allDataIsZeroVV = true;
  }

  private checkIfFVDataCanBeVisualized() {
    this.barChartDataFV.forEach((dataSet) => {
      if (dataSet.data.length !== 0) {
        this.allDataIsZeroFV = false;
      }
    });
    if (this.allDataIsZero(this.barChartDataFV)) this.allDataIsZeroFV = true;
  }

  private allDataIsZero(dataSet) {
    let allZero = true;
    dataSet.forEach((dataObject) => {
      dataObject.data.forEach((element) => {
        if (element !== 0) allZero = false;
      });
    });
    return allZero;
  }

  private getColor(label: string) {
    switch (label) {
      case 'Erneuerbar': {
        return this.barChartColors[4];
      }
      case 'Nicht erneuerbar': {
        return this.barChartColors[7];
      }
      default: {
        return this.barChartColors[4];
      }
    }
  }

  private labelToDB(label: string) {
    if (label === 'Erneuerbar') return 'Anz_Erneuerbar';
    if (label === 'Nicht erneuerbar') return 'Anz_Nicht_Erneuerbar';
    return '';
  }

  public changeFilterFV(changes) {
    let catFV: string[];
    let yearsFV: number[];
    if (changes.cat.length > 0) {
      catFV = changes.cat;
      this.barChartLabelsFV = changes.cat;
      this.categoriesFvFiltered = changes.cat;
    } else {
      catFV = this.categoriesFv;
      this.barChartLabelsFV = this.categoriesFv;
      this.categoriesFvFiltered = [];
    }
    if (changes.years.length > 0) {
      yearsFV = changes.years;
      this.yearsFvFiltered = changes.years;
    } else {
      this.yearsFvFiltered = [];
      yearsFV = this.yearsFv;
    }
    if (changes.labels.length > 0) {
      this.labelsFV = changes.labels;
      this.labelsFilteredFV = changes.labels;
    } else {
      this.labelsFV = this.labels;
      this.labelsFilteredFV = [];
    }
    this.prepDataFv(catFV, this.labelsFV, this.dataFV, yearsFV);
  }

  public changeFilterVV(changes) {
    let catVV: string[];
    let yearsVV: number[];
    let dataVV;
    if (changes.newSystem === this.useNewSystem) {
      if (changes.cat.length > 0) {
        this.categoriesVvFiltered = changes.cat;
        catVV = changes.cat;
        this.barChartLabelsVV = changes.cat;
      } else {
        this.categoriesVvFiltered = [];
        catVV = this.categoriesVv;
        this.barChartLabelsVV = this.categoriesVv;
      }
      if (changes.years.length > 0) {
        yearsVV = changes.years;
        this.yearsVvFiltered = changes.years;
      } else {
        yearsVV = this.yearsVv;
        this.yearsVvFiltered = [];
      }
      if (changes.labels.length > 0) {
        this.labelsVV = changes.labels;
        this.labelsFilteredVV = changes.labels;
      } else {
        this.labelsVV = this.labels;
        this.labelsFilteredVV = [];
      }
    } else if (changes.newSystem) {
      this.useNewSystem = true;
      dataVV = this.dataVVneu;
      this.categoriesVv = this.categoriesVvNeu;
      catVV = this.categoriesVv;
      this.barChartLabelsVV = this.categoriesVvNeu;
      this.labelsVV = this.labels;
      this.yearsVvFiltered = this.newSystemYears;
      this.categoriesVvFiltered = [];
      this.labelsFilteredVV = [];
      yearsVV = this.newSystemYears;
      this.yearsVv = this.newSystemYears;
    } else {
      this.useNewSystem = false;
      dataVV = this.dataVV;
      this.categoriesVv = this.categoriesVvAlt;
      catVV = this.categoriesVv;
      this.barChartLabelsVV = this.categoriesVvAlt;
      this.labelsVV = this.labels;
      this.yearsVvFiltered = [];
      this.categoriesVvFiltered = [];
      this.labelsFilteredVV = [];
      yearsVV = this.oldSystemYears;
      this.yearsVv = this.oldSystemYears;
    }
    this.useNewSystem ? (dataVV = this.dataVVneu) : (dataVV = this.dataVV);
    this.prepDataVv(catVV, this.labelsVV, dataVV, yearsVV);
  }

  onInfoButtonTap(content: string) {
    this.dialog.open(InfoModalComponent, {
      data: {
        content,
      },
    });
  }

  public getChartHeight() {
    return window.innerWidth < 700 ? '300px' : '430px';
  }
}
