import { trigger, state, style, transition, animate } from '@angular/animations';
import { Component, HostListener } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'nim-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],animations: [
    trigger('openClose', [
      state(
        'open',
        style({
          opacity: 1,
        }),
      ),
      state(
        'closed',
        style({
          opacity: 0,
        }),
      ),
      transition('open => closed', [animate('3s')]),
      transition('closed => open', [animate('3s')]),
    ]),
  ],
})
export class AppComponent {
  title = 'Nachhaltiges Immobilienmanagement';
  isOpen = true;
  backgroundTimer: any = null;
  private readonly numberOfAssets = 26;

  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private router: Router,
  ) {
    this.matIconRegistry.addSvgIcon(
      'info_button',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/info-button.svg',
      ),
    );
    router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.change_background();
      }
    });
  }

  ngOnInit() {
    this.change_background();
  }

  @HostListener('window:resize', ['$event'])
  private onResize(event: any) {
    if (event.type === 'resize') {
      this.change_background();
    }
  }

  private getRandomAssetNumber() {
    return Math.floor(Math.random() * (this.numberOfAssets - 1 + 1) + 1);
  }

  private randomizeBackground(background: HTMLElement){this.isOpen = false;
    const url = `url(../assets/background/${
      this.getRandomAssetNumber() || 1
    }.jpg)`;setTimeout(() => {
      background.style.backgroundImage =
        '  linear-gradient(to bottom, transparent, transparent 40%, white 65%, white 75%),' +
        url;
      this.isOpen = true;
    }, 2000);}

  public change_background() {
    const background = document.getElementById('image');
    if (!(window.innerWidth < 1024)) {
      if (!this.backgroundTimer) {
        this.randomizeBackground(background);
      this.backgroundTimer =  setTimeout(() => {
        this.randomizeBackground(background);
      }, 19500);
      }
      
    } else {background.style.backgroundImage = 'none';clearInterval(this.backgroundTimer);
    this.backgroundTimer = null;};
  }
}
