import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { EnergietraegerBarchartComponent } from './energietraeger-barchart/energietraeger-barchart.component';
import { HomeComponent } from './home/home.component';
import { NachhaltigkeitsRadialchartComponent } from './nachhaltigkeits-radialchart/nachhaltigkeits-radialchart.component';
import { BaujahreLinechartComponent } from './baujahre-linechart/baujahre-linechart.component';
import { VeloplaetzeLinechartComponent } from './veloplaetze-linechart/veloplaetze-linechart.component';
import { AutoplaetzeLinechartComponent } from './autoplaetze-linechart/autoplaetze-linechart.component';
import { DenkmalpflegeBarchartComponent } from './denkmalpflege-barchart/denkmalpflege-barchart.component';
import { ErnEnergienBarchartComponent } from './ern-energien-barchart/ern-energien-barchart.component';
import { HindernisfreiLinechartComponent } from './hindernisfrei-linechart/hindernisfrei-linechart.component';
import { VersFlaechenLinechartComponent } from './vers-flaechen-linechar/vers-flaechen-linechart.component';
import { HandlungsbedarfTableComponent } from './handlungsbedarf-table/handlungsbedarf-table.component';
import { AusgewerteteBarchartComponent } from './ausgewertete-barchart/ausgewertete-barchart.component';
import { ProgEnergietraegerLinechartComponent } from './prog-energietraeger-linechart/prog-energietraeger-linechart.component';
import { ProgPhotovoltaikLinechartComponent } from './prog-photovoltaik-linechart/prog-photovoltaik-linechart.component';
import { ImpressumComponent } from './impressum/impressum.component';
import { DatenschutzComponent } from './datenschutz/datenschutz.component';

const routes: Routes = [
  { path: 'home', component: HomeComponent },
  { path: 'energietraeger', component: EnergietraegerBarchartComponent },
  {
    path: 'nachhaltigkeitsrosette',
    component: NachhaltigkeitsRadialchartComponent,
  },
  { path: 'baujahre', component: BaujahreLinechartComponent },
  { path: 'veloplaetze', component: VeloplaetzeLinechartComponent },
  { path: 'autoplaetze', component: AutoplaetzeLinechartComponent },
  { path: 'denkmalpflege', component: DenkmalpflegeBarchartComponent },
  { path: 'erneuerbareEnergien', component: ErnEnergienBarchartComponent },
  { path: 'hindernisfrei', component: HindernisfreiLinechartComponent },
  { path: 'versFlaechen', component: VersFlaechenLinechartComponent },
  { path: 'handlungsbedarf', component: HandlungsbedarfTableComponent },
  { path: 'ausgewertete', component: AusgewerteteBarchartComponent },
  /* {
    path: 'prog-energietraeger',
    component: ProgEnergietraegerLinechartComponent,
  }, */
/*   { path: 'prog-photovoltaik', component: ProgPhotovoltaikLinechartComponent }, */
  { path: 'impressum', component: ImpressumComponent },
  { path: 'datenschutz', component: DatenschutzComponent },
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: '**', redirectTo: 'home', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
