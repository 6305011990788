<div class="visualization">
  <h1>Prognose Energieträger bis 2035</h1>
  <mat-tab-group>
    <mat-tab label="Finanz-/Fondsvermögen">
      <button
        mat-icon-button
        aria-label="Informationen"
        (click)="onInfoButtonTap('prog-energietraeger.fv')"
        class="info-button"
      >
        <mat-icon>info_button</mat-icon>
      </button>
      <div class="filter">
        <mat-form-field class="select-form">
          <mat-label>Kategorie</mat-label>
          <mat-select
            [(ngModel)]="selectedCategoriesFV"
            name="categoriesFv"
            multiple
            class="filter-select"
            (selectionChange)="selectionChangeFV($event)"
            #categorySelectFV="ngModel"
            ><mat-optgroup disabled="disabled" class="filter-option">
              <button
                mat-raised-button
                class="mat-accent fill text-sm"
                (click)="deselectAllCategoriesFV(categorySelectFV)"
              >
                Zurücksetzen
              </button>
            </mat-optgroup>
            <mat-option
              *ngFor="let category of categoriesFV"
              [value]="category"
            >
              {{ category }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <button
          mat-raised-button
          color="warn"
          style="margin-left: 1vw"
          (click)="deselectAllCategoriesFV(categorySelectFV)"
        >
          zurücksetzen
        </button>
      </div>
      <div class="filterList">
        Ausgewählte Filter:
        <span *ngFor="let category of selectedCategoriesFV">
          {{ category }}
        </span>
      </div>
      <app-data-legend-table
        [dataLoaded]="dataLoadedFV"
        [labels]="categoriesFV"
      ></app-data-legend-table>
      <div class="chart-container">
        <canvas
          baseChart
          [ngStyle]="{ height: chartHeight }"
          [datasets]="lineChartDataFV"
          [labels]="yearsFV"
          [options]="lineChartOptions"
          [legend]="lineChartLegend"
          [chartType]="lineChartType"
          [plugins]="lineChartPlugins"
        >
        </canvas>
      </div>
    </mat-tab>
    <mat-tab label="Verwaltungsvermögen">
      <div class="filter">
        <button
          mat-icon-button
          aria-label="Informationen"
          (click)="onInfoButtonTap('prog-energietraeger.vv')"
          class="info-button"
        >
          <mat-icon>info_button</mat-icon>
        </button>

        <mat-form-field class="select-form">
          <mat-label>Kategorie</mat-label>
          <mat-select
            [(ngModel)]="selectedCategoriesVV"
            name="categoriesVv"
            multiple
            class="filter-select"
            (selectionChange)="selectionChangeVV($event)"
            #categorySelectVV="ngModel"
            ><mat-optgroup disabled="disabled" class="filter-option">
              <button
                mat-raised-button
                class="mat-accent fill text-sm"
                (click)="deselectAllCategoriesVV(categorySelectVV)"
              >
                Zurücksetzen
              </button>
            </mat-optgroup>
            <mat-option
              *ngFor="let category of categoriesVV"
              [value]="category"
            >
              {{ category }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <button
          mat-raised-button
          color="warn"
          style="margin-left: 1vw"
          (click)="deselectAllCategoriesVV(categorySelectVV)"
        >
          zurücksetzen
        </button>
      </div>
      <div class="filterList">
        Ausgewählte Filter:
        <span *ngFor="let category of selectedCategoriesVV">
          {{ category }}
        </span>
      </div>
      <app-data-legend-table
        [dataLoaded]="dataLoadedVV"
        [labels]="categoriesVV"
      ></app-data-legend-table>
      <div class="chart-container">
        <canvas
          baseChart
          [ngStyle]="{ height: chartHeight }"
          [datasets]="lineChartDataVV"
          [labels]="yearsVV"
          [options]="lineChartOptions"
          [legend]="lineChartLegend"
          [chartType]="lineChartType"
          [plugins]="lineChartPlugins"
        >
        </canvas>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>
