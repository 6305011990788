import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { ChartsModule } from 'ng2-charts';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTableModule } from '@angular/material/table';
import { MatSelectModule } from '@angular/material/select';
import { MatGridListModule } from '@angular/material/grid-list';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { EnergietraegerBarchartComponent } from './energietraeger-barchart/energietraeger-barchart.component';
import { HomeComponent } from './home/home.component';
import { HeaderComponent } from './header/header.component';
import { NachhaltigkeitsRadialchartComponent } from './nachhaltigkeits-radialchart/nachhaltigkeits-radialchart.component';
import { BaujahreLinechartComponent } from './baujahre-linechart/baujahre-linechart.component';
import { VeloplaetzeLinechartComponent } from './veloplaetze-linechart/veloplaetze-linechart.component';
import { AutoplaetzeLinechartComponent } from './autoplaetze-linechart/autoplaetze-linechart.component';
import { DenkmalpflegeBarchartComponent } from './denkmalpflege-barchart/denkmalpflege-barchart.component';
import { ErnEnergienBarchartComponent } from './ern-energien-barchart/ern-energien-barchart.component';
import { HindernisfreiLinechartComponent } from './hindernisfrei-linechart/hindernisfrei-linechart.component';
import { VersFlaechenLinechartComponent } from './vers-flaechen-linechar/vers-flaechen-linechart.component';
import { HandlungsbedarfTableComponent } from './handlungsbedarf-table/handlungsbedarf-table.component';
import { DataLegendTableComponent } from './data-legend-table/data-legend-table.component';
import { FilterComponent } from './filter/filter.component';
import { MatCardModule } from '@angular/material/card';
import { MatMenuModule } from '@angular/material/menu';
import { AusgewerteteBarchartComponent } from './ausgewertete-barchart/ausgewertete-barchart.component';
import { ProgEnergietraegerLinechartComponent } from './prog-energietraeger-linechart/prog-energietraeger-linechart.component';
import { ProgPhotovoltaikLinechartComponent } from './prog-photovoltaik-linechart/prog-photovoltaik-linechart.component';
import { InfoModalComponent } from './info-modal/info-modal.component';
import { MatDialogModule } from '@angular/material/dialog';
import { LayoutModule } from '@angular/cdk/layout';
import { SmallScreenFilterLegendComponent } from './smallScreenFilterLegend/smallScreenFilterLegend.component';
import { DialogExampleProjetsComponent } from './baujahre-linechart/dialog-example-projets/dialog-example-projets.component';
import { ImpressumComponent } from './impressum/impressum.component';
import { DatenschutzComponent } from './datenschutz/datenschutz.component';

@NgModule({
  declarations: [		
    AppComponent,
    EnergietraegerBarchartComponent,
    HomeComponent,
    HeaderComponent,
    NachhaltigkeitsRadialchartComponent,
    BaujahreLinechartComponent,
    VeloplaetzeLinechartComponent,
    AutoplaetzeLinechartComponent,
    DenkmalpflegeBarchartComponent,
    ErnEnergienBarchartComponent,
    HindernisfreiLinechartComponent,
    VersFlaechenLinechartComponent,
    HandlungsbedarfTableComponent,
    DataLegendTableComponent,
    FilterComponent,
    AusgewerteteBarchartComponent,
    ProgEnergietraegerLinechartComponent,
    ProgPhotovoltaikLinechartComponent,
    InfoModalComponent,
    SmallScreenFilterLegendComponent,
    DialogExampleProjetsComponent,
      ImpressumComponent,
      DatenschutzComponent
   ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    MatIconModule,
    AppRoutingModule,
    ChartsModule,
    MatButtonModule,
    MatToolbarModule,
    MatTabsModule,
    MatTableModule,
    MatDialogModule,
    MatGridListModule,
    MatFormFieldModule,
    MatSelectModule,
    BrowserAnimationsModule,
    MatCardModule,
    MatMenuModule,
    MatIconModule,
    LayoutModule,
  ],
  providers: [],
  entryComponents: [InfoModalComponent, SmallScreenFilterLegendComponent],
  bootstrap: [AppComponent],
})
export class AppModule {}
