import { Component, Input, OnInit } from '@angular/core';
import { ChartColorService } from '../shared/chart-color.service';

@Component({
  selector: 'app-data-legend-table',
  templateUrl: './data-legend-table.component.html',
  styleUrls: ['./data-legend-table.component.scss'],
})
export class DataLegendTableComponent implements OnInit {
  @Input() dataLoaded: boolean;

  @Input() labels: string[];

  public fondsColors;

  public vvNeuColors;

  public vvAltColors;

  public radarChartColors;

  public energyChartColors;

  public denkmalpflegeColors;

  constructor(private colorService: ChartColorService) {}

  ngOnInit() {
    this.fondsColors = this.colorService.getDefaultFondsColors();
    this.vvAltColors = this.colorService.getDefaultVVAltColors();
    this.vvNeuColors = this.colorService.getDefaultVVNeuColors();
    this.energyChartColors = this.colorService.getEnergyChartColors();
    this.radarChartColors = this.colorService.getRadialChartColors();
    this.denkmalpflegeColors = this.colorService.getDenkmalpflegeColors();
  }

  public getColor(category: string) {
    switch (category) {
      case 'UNESCO Weltkulturerbe': {
        return this.denkmalpflegeColors[0];
      }
      case 'Schützenswert': {
        return this.denkmalpflegeColors[1];
      }
      case 'Erhaltenswert': {
        return this.denkmalpflegeColors[2];
      }
      case 'Beachtenswert': {
        return this.denkmalpflegeColors[3];
      }
      case 'Nicht inventarisiert': {
        return this.denkmalpflegeColors[4];
      }
      case 'Gebäude nach 1990': {
        return this.denkmalpflegeColors[5];
      }
      case 'Erneuerbar': {
        return this.energyChartColors[4];
      }
      case 'Nicht erneuerbar': {
        return this.energyChartColors[7];
      }

      case 'Solaranlagen': {
        return this.energyChartColors[0];
      }
      case 'Solarthermieanlagen': {
        return this.energyChartColors[0];
      }
      case 'Photovoltaikanlagen': {
        return this.energyChartColors[1];
      }
      case 'Holz': {
        return this.energyChartColors[2];
      }

      case 'Fernwärme': {
        return this.energyChartColors[5];
      }
      case 'Wärmepumpen': {
        return this.energyChartColors[4];
      }
      case 'Gas': {
        return this.energyChartColors[7];
      }
      case 'Kombi Gas/Öl': {
        return this.energyChartColors[8];
      }
      case 'BHKW (=Blockheizkraftwerk)': {
        return this.energyChartColors[9];
      }
      case 'Öl': {
        return this.energyChartColors[10];
      }
      case 'Elektrizität': {
        return this.energyChartColors[11];
      }
      case 'Elektro-Direktheizungen': {
        return this.energyChartColors[11];
      }
      case 'Wohnliegenschaften (pro Wohnung)': {
        return this.fondsColors[0];
      }
      case 'Wohnliegenschaften (pro Zimmer)': {
        return this.fondsColors[0];
      }
      case 'Geschäftsliegenschaften (pro Objekt)': {
        return this.fondsColors[1];
      }
      case 'Verwaltungsgebäude (pro Objekt)': {
        return this.vvAltColors[0];
      }
      case 'Schulanlage (pro Objekt)': {
        return this.vvAltColors[1];
      }
      case 'Sportanlage (pro Objekt)': {
        return this.vvAltColors[2];
      }
      case 'Eis-Wasseranlage (pro Objekt)': {
        return this.vvAltColors[3];
      }
      case 'Kulturgebäude (pro Objekt)': {
        return this.vvAltColors[4];
      }
      case 'Werkhöfe (pro Objekt)': {
        return this.vvAltColors[5];
      }
      case 'Jugend-/Quartiertreffs (pro Objekt)': {
        return this.vvAltColors[6];
      }
      case 'Wohnhäuser/-heime (pro Objekt)': {
        return this.vvAltColors[7];
      }
      case 'Restaurants (pro Objekt)': {
        return this.vvAltColors[8];
      }
      case 'Hindernisfreie Wohnungen': {
        return this.fondsColors[0];
      }
      case 'Hindernisfreie Geschäftsräume': {
        return this.fondsColors[1];
      }
      case 'Hindernisfreie Wohnungen (Landwirtschaft)': {
        return this.fondsColors[2];
      }
      case 'Verwaltungsgebäude': {
        return this.vvAltColors[0];
      }
      case 'Schulen': {
        return this.vvAltColors[1];
      }
      case 'Sportanlagen': {
        return this.vvAltColors[2];
      }
      case 'Eis-Wasseranlagen': {
        return this.vvAltColors[3];
      }
      case 'Kulturgebäude': {
        return this.vvAltColors[4];
      }
      case 'Werkhöfe': {
        return this.vvAltColors[5];
      }
      case 'Jugend-/Quartiertreffs': {
        return this.vvAltColors[6];
      }
      case 'Wohnhäuser/-heime': {
        return this.vvAltColors[7];
      }
      case 'Restaurants': {
        return this.vvAltColors[8];
      }
      case 'Wohnliegenschaften': {
        return this.fondsColors[0];
      }
      case 'Geschäftsliegenschaften': {
        return this.fondsColors[1];
      }
      case 'Landwirtschaft': {
        return this.fondsColors[2];
      }

      case '2012': {
        return this.radarChartColors[8];
      }
      case '2013': {
        return this.radarChartColors[9];
      }
      case '2014': {
        return this.radarChartColors[10];
      }
      case '2015': {
        return this.radarChartColors[2];
      }
      case '2016': {
        return this.radarChartColors[3];
      }
      case '2017': {
        return this.radarChartColors[4];
      }
      case '2018': {
        return this.radarChartColors[5];
      }
      case '2019': {
        return this.radarChartColors[6];
      }
      case '2020': {
        return this.radarChartColors[7];
      }
      case '2021': {
        return this.radarChartColors[11];
      }

      // '2017' != '2017 ' (space!)
      case '2017 ': {
        return this.radarChartColors[4];
      }
      case '2018 ': {
        return this.radarChartColors[5];
      }
      case '2019 ': {
        return this.radarChartColors[6];
      }
      case '2020 ': {
        return this.radarChartColors[7];
      }
      case '2021 ': {
        return this.radarChartColors[11];
      }
      case 'Gebäude': {
        return this.radarChartColors[2];
      }
      case 'Teilgebäude': {
        return this.radarChartColors[3];
      }

      case 'Bildung': {
        return this.vvNeuColors[1];
      }
      case 'Sport und Freizeit': {
        return this.vvNeuColors[2];
      }
      case 'Wohnen und Aufenthalt': {
        return this.vvNeuColors[3];
      }
      case 'Kultur und Geselligkeit': {
        return this.vvNeuColors[4];
      }
      case 'Infrastrukturbauten': {
        return this.vvNeuColors[5];
      }
      default: {
        return this.vvNeuColors[0];
      }
    }
  }
}
