import { Component, OnInit } from '@angular/core';
import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
import { Label } from 'ng2-charts';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { ChartColorService } from '../shared/chart-color.service';
import { InfoModalComponent } from '../info-modal/info-modal.component';
import { SmallScreenFilterLegendComponent } from '../smallScreenFilterLegend/smallScreenFilterLegend.component';
import { ausgewerteteFv } from './ausgewertete-fv.data';
import { ausgewerteteVv } from './ausgewertete-vv.data';
import { ausgewerteteVvNeu } from './ausgewertete-vv-neu.data';

@Component({
  selector: 'app-ausgewertete-barchart',
  templateUrl: './ausgewertete-barchart.component.html',
  styleUrls: ['./ausgewertete-barchart.component.scss'],
})
export class AusgewerteteBarchartComponent implements OnInit {
  public barChartOptionsFV: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    layout: { padding: { bottom: 30 } },
    legend: {
      display: false,
    },
    hover: { mode: null },
    scales: {
      xAxes: [
        {
          ticks: {
            maxRotation: 0,
            autoSkip: false,
            padding: 30,
          },
        },
      ],
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
          scaleLabel: {
            display: true,
            labelString: 'Anzahl ausgewertete Gebäude',
          },
        },
      ],
    },
    tooltips: {
      mode: 'x',
      callbacks: {
        label: (tooltipItem, data) => {
          const currentDatasetIndex = tooltipItem.datasetIndex;
          const currentDataset = data.datasets[currentDatasetIndex];
          if (this.dataFV) {
            const point = this.dataFV.find((d) => {
              return (
                String(d.Jahr) === String(currentDataset.label) &&
                String(d.Kategorie) === this.categoriesFv[tooltipItem.index]
              );
            });
            return `${currentDataset.label}: ${point.Anzahl_Gebaeude} `;
          }
        },
      },
    },
    plugins: {
      datalabels: {
        anchor: 'start',
        align: 'start',
        rotation: 45,
        clip: false,
        formatter(value, context) {
          return context.dataset.label;
        },
      },
    },
  };

  public barChartOptionsVV: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    layout: { padding: { bottom: 30 } },
    legend: {
      display: false,
    },
    hover: { mode: null },
    scales: {
      xAxes: [
        {
          ticks: {
            maxRotation: 0,
            autoSkip: false,
            padding: 30,
          },
        },
      ],
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
          scaleLabel: {
            display: true,
            labelString: 'Anzahl ausgewertete Gebäude',
          },
        },
      ],
    },
    plugins: {
      datalabels: {
        anchor: 'start',
        align: 'start',
        rotation: 45,
        clip: false,
        formatter(value, context) {
          return context.dataset.label;
        },
      },
    },
    tooltips: {
      mode: 'x',
    },
  };

  public chartHeight = this.getChartHeight();

  public barChartPlugins = [pluginDataLabels];

  public barChartType: ChartType = 'bar';

  public barChartLegend = true;

  public barChartColors;

  public barChartLabelsFV = [
    'Wohnliegenschaften',
    'Geschäftsliegenschaften',
    'Landwirtschaft',
  ];

  public barChartLabelsVV: Label[] = [
    'Verwaltungsgebäude',
    'Schulen',
    'Sportanlagen',
    'Eis-Wasseranlagen',
    'Kulturgebäude',
    'Werkhöfe',
    'Jugend-/Quartiertreffs',
    'Wohnhäuser/-heime',
    'Restaurants',
  ];

  public barChartDataFV: ChartDataSets[] = [];

  public barChartDataVV: ChartDataSets[] = [];

  constructor(
    private chartColorService: ChartColorService,
    public dialog: MatDialog,
    private breakpointObserver: BreakpointObserver,
    private smallScreenFilterDialog: MatDialog,
  ) {}

  public smallScreen: boolean;

  public dataLoadedFV = false;

  public dataLoadedVV = false;

  labelsVV = ['2015', '2016', '2017', '2018', '2019', '2020', '2021'];

  labelsVVNeu = ['2019', '2020', '2021'];

  labelsFV = [
    '2012',
    '2013',
    '2014',
    '2015',
    '2016',
    '2017',
    '2018',
    '2019',
    '2020',
    '2021',
  ];

  dataLabels = [
    ['Anzahl_Teilgebaeude', 'Teilgebäude'],
    ['Anzahl_Gebaeude', 'Gebäude'],
  ];

  categoriesVv;

  categoriesVvAlt = [
    'Verwaltungsgebäude',
    'Schulanlagen',
    'Sportanlagen',
    'Eis- und Wasseranlagen',
    'Kulturgebäude',
    'Werkhöfe',
    'Jugend-/Quartiertreffs',
    'Wohnhäuser/-heime',
    'Restaurants',
  ];

  categoriesVvNeu = [
    'Verwaltungsgebäude',
    'Bildung',
    'Sport und Freizeit',
    'Wohnen und Aufenthalt',
    'Kultur und Geselligkeit',
    'Infrastrukturbauten',
  ];

  categoriesVvFiltered = [];

  public useNewSystem: boolean;

  categoriesFv = [
    'Wohnliegenschaften',
    'Geschäftsliegenschaften',
    'Landwirtschaft',
  ];

  categoriesFvFiltered = [];

  yearsVv = [];

  yearsFv = [];

  yearsFvFiltered = [];

  yearsVvFiltered = [];

  newSystemYears = [2019, 2020, 2021];

  oldSystemYears = [];

  public labelsFilteredFV = this.labelsFV;

  public labelsFilteredVV = this.labelsVVNeu;

  dataFV;

  dataVV;

  dataVVneu;

  allDataIsZeroFV = false;

  allDataIsZeroVV = false;

  ngOnInit(): void {
    this.barChartColors = this.chartColorService.getRadialChartColors();
    this.getFvData();
    this.getVvData();
    this.getVvNeuData();
    this.checkIfSmallScreen();
  }

  private getFvData() {
    const res = ausgewerteteFv;
    this.dataFV = res;
    this.getYearsFV();
    this.prepDataFv(this.categoriesFv, this.labelsFV, res, this.yearsFv);
    setTimeout(() => {
      this.dataLoadedFV = true;
    }, 500);
  }

  private getVvData() {
    const res = ausgewerteteVv;
    this.dataVV = res;
    this.getYearsVV();
  }

  private getVvNeuData() {
    const res = ausgewerteteVvNeu;
    this.dataVVneu = ausgewerteteVvNeu;
    this.useNewSystem = true;
    this.categoriesVv = this.categoriesVvNeu;
    this.yearsVv = this.newSystemYears;
    this.prepDataVv(this.categoriesVv, res, this.newSystemYears);
    this.dataLoadedVV = true;
  }

  private checkIfSmallScreen() {
    this.breakpointObserver
      .observe(['(min-width: 900px)'])
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          this.smallScreen = false;
        } else {
          this.smallScreen = true;
        }
      });
  }

  openDialog(asset: string) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    if (asset === 'FV') {
      dialogConfig.data = {
        labels: this.labelsFV,
        years: this.yearsFv,
        categories: this.categoriesFv,
        labelsFiltered: [],
        yearsFiltered: this.yearsFvFiltered,
        categoriesFiltered: this.categoriesFvFiltered,
        showNewSystemFilter: false,
        showThirdFilter: false,
      };

      this.smallScreenFilterDialog
        .open(SmallScreenFilterLegendComponent, dialogConfig)
        .afterClosed()
        .subscribe((changes) => {
          if (changes) this.changeFilterFV(changes);
        });
    } else {
      dialogConfig.data = {
        labels: this.labelsVV,
        years: this.yearsVv,
        categories: this.categoriesVv,
        labelsFiltered: [],
        yearsFiltered: this.yearsVvFiltered,
        categoriesFiltered: this.categoriesVvFiltered,
        showThirdFilter: false,
        showNewSystemFilter: true,
        useNewSystem: this.useNewSystem,
      };

      this.smallScreenFilterDialog
        .open(SmallScreenFilterLegendComponent, dialogConfig)
        .afterClosed()
        .subscribe((changes) => {
          if (changes) this.changeFilterVV(changes);
        });
    }
  }

  private getYearsFV() {
    this.dataFV.forEach((element) => {
      if (!this.yearsFv.includes(element.Jahr)) this.yearsFv.push(element.Jahr);
    });
  }

  private getYearsVV() {
    this.dataVV.forEach((element) => {
      if (!this.oldSystemYears.includes(element.Jahr))
        this.oldSystemYears.push(element.Jahr);
    });
  }

  private prepDataFv(categories: string[], labels: string[], data, years) {
    this.allDataIsZeroFV = true;
    this.barChartDataFV = [];
    years.forEach((j) => {
      const dataSet = {
        label: j,
        data: [],
        backgroundColor: this.getColor(String(j)).backgroundColor,
        borderColor: this.getColor(String(j)).borderColor,
        hitRadius: [this.labelsFilteredFV.length],
      };
      const dataFiltered = data.filter((o) => o.Jahr === j);
      categories.forEach((c) => {
        if (dataFiltered.find((o) => o.Kategorie === c) !== undefined) {
          const point = dataFiltered.find((o) => o.Kategorie === c);
          dataSet.data.push(point.Anzahl_Gebaeude);
        } else dataSet.data.push(0);
      });
      this.barChartDataFV.push(dataSet);
      this.checkIfFVDataCanBeVisualized();
    });
  }

  private prepDataVv(categories: string[], data, years) {
    this.allDataIsZeroVV = true;
    this.barChartDataVV = [];
    years.forEach((j) => {
      const dataSet = {
        label: j,
        data: [],
        backgroundColor: this.getColor(String(j)).backgroundColor,
        borderColor: this.getColor(String(j)).borderColor,
        hitRadius: [this.labelsFilteredVV.length],
      };
      const dataFiltered = data.filter((o) => o.Jahr === j);
      categories.forEach((c) => {
        if (dataFiltered.find((o) => o.Kategorie === c) !== undefined) {
          dataSet.data.push(
            dataFiltered.find((o) => o.Kategorie === c).Anzahl_Gebaeude,
          );
        } else dataSet.data.push(0);
      });
      this.barChartDataVV.push(dataSet);
    });
    this.checkIfVVDataCanBeVisualized();
  }

  private checkIfVVDataCanBeVisualized() {
    this.barChartDataVV.forEach((dataSet) => {
      if (dataSet.data.length !== 0) {
        this.allDataIsZeroVV = false;
      }
    });
    if (this.allDataIsZero(this.barChartDataVV)) this.allDataIsZeroVV = true;
  }

  private checkIfFVDataCanBeVisualized() {
    this.barChartDataFV.forEach((dataSet) => {
      if (dataSet.data.length !== 0) {
        this.allDataIsZeroFV = false;
      }
    });
    if (this.allDataIsZero(this.barChartDataFV)) this.allDataIsZeroFV = true;
  }

  private allDataIsZero(dataSet) {
    let allZero = true;
    dataSet.forEach((dataObject) => {
      dataObject.data.forEach((element) => {
        if (element !== 0) allZero = false;
      });
    });
    return allZero;
  }

  private labelToDB(label: string) {
    if (label === 'Gebäude') return 'Anzahl_Gebaeude';
    if (label === 'Teilgebäude') return 'Anzahl_Teilgebaeude';
    return '';
  }

  private getColor(label: string) {
    switch (label) {
      case '2012': {
        return this.barChartColors[8];
      }
      case '2013': {
        return this.barChartColors[9];
      }
      case '2014': {
        return this.barChartColors[10];
      }
      case '2015': {
        return this.barChartColors[2];
      }
      case '2016': {
        return this.barChartColors[3];
      }
      case '2017': {
        return this.barChartColors[4];
      }
      case '2018': {
        return this.barChartColors[5];
      }
      case '2019': {
        return this.barChartColors[6];
      }
      case '2020': {
        return this.barChartColors[7];
      }
      case '2021': {
        return this.barChartColors[11];
      }
      default: {
        return this.barChartColors[0];
      }
    }
  }

  public changeFilterFV(changes) {
    let yearsFV;
    if (changes.cat.length > 0) {
      this.barChartLabelsFV = changes.cat;
      this.categoriesFvFiltered = changes.cat;
    } else {
      this.barChartLabelsFV = this.categoriesFv;
      this.categoriesFvFiltered = [];
    }
    if (changes.years.length > 0) {
      yearsFV = changes.years;
      this.yearsFvFiltered = changes.years;
    } else {
      this.yearsFvFiltered = [];
      yearsFV = this.yearsFv;
    }
    this.labelsFilteredFV = this.yearsFvFiltered.map((y) => String(y));
    this.prepDataFv(
      this.barChartLabelsFV,
      this.labelsFilteredFV,
      this.dataFV,
      yearsFV,
    );
  }

  public changeFilterVV(changes) {
    let catVV;
    let yearsVV: number[];
    let dataVV;
    if (changes.newSystem === this.useNewSystem) {
      if (changes.cat.length > 0) {
        this.categoriesVvFiltered = changes.cat;
        catVV = changes.cat;
        this.barChartLabelsVV = changes.cat;
      } else {
        this.categoriesVvFiltered = [];
        catVV = this.categoriesVv;
        this.barChartLabelsVV = this.categoriesVv;
      }
      if (changes.years.length > 0) {
        yearsVV = changes.years;
        this.yearsVvFiltered = changes.years;
        this.labelsFilteredVV = changes.years.map((y) => String(y));
      } else {
        yearsVV = this.yearsVv;
        this.yearsVvFiltered = [];
        this.labelsFilteredVV = this.labelsVV;
      }
    } else {
      if (changes.newSystem) {
        this.useNewSystem = true;
        dataVV = this.dataVVneu;
        this.categoriesVv = this.categoriesVvNeu;
        this.categoriesVvFiltered = [];
        this.labelsFilteredVV = this.labelsVVNeu;
        this.yearsVv = this.newSystemYears;
      } else {
        this.labelsFilteredVV = this.labelsVV;
        this.useNewSystem = false;
        dataVV = this.dataVV;
        this.categoriesVv = this.categoriesVvAlt;
        this.categoriesVvFiltered = [];
        this.yearsVv = this.oldSystemYears;
      }
      this.barChartLabelsVV = this.categoriesVv;
      catVV = this.categoriesVv;

      changes.years.length > 0
        ? (yearsVV = changes.years)
        : (yearsVV = this.yearsVv);
    }
    this.useNewSystem ? (dataVV = this.dataVVneu) : (dataVV = this.dataVV);
    this.prepDataVv(catVV, dataVV, yearsVV);
  }

  onInfoButtonTap(content: string) {
    this.dialog.open(InfoModalComponent, {
      data: {
        content,
      },
    });
  }

  public getChartHeight() {
    return window.innerWidth < 700 ? '300px' : '440px';
  }
}
