import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'dialog-example-projets',
  templateUrl: './dialog-example-projets.component.html',
  styleUrls: ['./dialog-example-projets.component.css'],
})
export class DialogExampleProjetsComponent implements OnInit {
  constructor() {}
  numberOfCols;

  ngOnInit() {
    this.numberOfCols = window.innerWidth <= 900 ? 1 : 3;
  }

  onResize(event) {
    this.numberOfCols = event.target.innerWidth <= 900 ? 1 : 3;
  }
}
